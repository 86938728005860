(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedInspectionProgramAssigneesFilter",
      function (ManagedAssigneesFilter) {
        function ManagedInspectionProgramAssigneesFilter(manager, options) {
          if (!options) options = {};
          options.queryField = "inspection_components.assignee_users";
          ManagedAssigneesFilter.call(this, manager, options);
        }

        // Extend the managed assignees filter properly here
        ManagedInspectionProgramAssigneesFilter.prototype = Object.create(
          ManagedAssigneesFilter.prototype
        );
        ManagedInspectionProgramAssigneesFilter.prototype.constructor =
          ManagedInspectionProgramAssigneesFilter;

        ManagedInspectionProgramAssigneesFilter.prototype.applyFilter =
          function (filterValue, inputModel) {
            var existingFilterValue = this.getFilterValue(this.manager);

            if (filterValue === "null" && existingFilterValue) {
              // If we are filtering by only "Unassigned" IPs, we need to remove everyone else
              this.manager.removeModelFieldFilter(this.getQueryField());
            } else if (
              filterValue !== "null" &&
              existingFilterValue &&
              existingFilterValue.match(/null/)
            ) {
              // If we are adding a normal assignee, we need to clear the "Unassigned"" value
              var existingAssignees = existingFilterValue.replace(
                /,null|null|null,/,
                ""
              );

              if (existingAssignees === "$in") {
                // there were no other assigneees other than "Unassigned", just clear it so we can add this new one
                this.manager.removeModelFieldFilter(this.getQueryField());
              } else {
                // Overrwite our current assignees with our new one that doesn't contain "Unassigned"
                this.manager.addModelFieldFilter(
                  this.getQueryField(),
                  existingAssignees
                );
              }
            }

            ManagedAssigneesFilter.prototype.applyFilter.call(
              this,
              filterValue,
              inputModel
            );
          };

        return ManagedInspectionProgramAssigneesFilter;
      }
    );
})();
