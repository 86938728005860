(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.assetCostEstimation
   *
   * @param {Object} asset  the asset object
   */
  angular
    .module("akitabox.ui.components.assetCostEstimation", [
      "akitabox.constants",
      "akitabox.core.services.asset",
      "akitabox.core.services.costLine",
      "akitabox.core.services.flag",
      "akitabox.core.services.organization",
      "akitabox.core.toast",
      "akitabox.core.services.costAdjustment",
      "akitabox.core.services.costAdjustmentRule",
      "akitabox.ui.dialogs.asset.costLineSelection",
      "akitabox.ui.dialogs.asset.costAdjustment",
      "akitabox.ui.dialogs.asset.lifeCycle",
    ])
    .component("abxAssetCostEstimation", {
      bindings: {
        asset: "<abxAsset",
      },
      controller: AbxAssetCostEstimationController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/asset-cost-estimation/asset-cost-estimation.component.html",
    });

  /* @ngInject */
  function AbxAssetCostEstimationController(
    // Angular
    $log,
    $timeout,
    // Services
    AssetService,
    CostLineService,
    CostAdjustmentService,
    CostAdjustmentRuleService,
    FeatureFlagService,
    UserService,
    OrganizationService,
    ToastService,
    // Dialogs
    AssetCostLineSelectionDialog,
    AssetCostAdjustmentDialog,
    AssetLifeCycleDialog,
    // Other
    Utils
  ) {
    let self = this;

    const _permissions = UserService.getPermissions();
    const _organization = OrganizationService.getCurrent();

    // Functions
    self.editBaseCost = editBaseCost;
    self.editCostAdjustment = editCostAdjustment;

    // Attributes
    self.loading = true;
    self.organization = _organization;
    self.permissions = _permissions;
    self.showEditBaseCost = false;
    self.showEditCostAdjustment = false;
    self.costAdjustments = [];
    self.asset_cost = null;

    self.$onInit = function () {
      if (!self.asset) {
        $log.error("abxAssetCostEstimation: abx-asset is required");
      }

      if (self.permissions && self.permissions.cost_line) {
        self.showEditBaseCost =
          self.permissions.cost_line.create &&
          self.permissions.cost_line.update &&
          self.permissions.cost_line.remove;
      }

      if (self.permissions && self.permissions.cost_adjustment) {
        self.showEditCostAdjustment =
          self.permissions.cost_adjustment.create &&
          self.permissions.cost_adjustment.update &&
          self.permissions.cost_adjustment.remove;
      }
    };

    self.$onChanges = function (changes) {
      if (changes) {
        getData();
      }
    };

    // ------------------------
    //   Private Functions
    // ------------------------

    function getData(assetId) {
      self.loading = true;
      self.costAdjustments = [];

      // Base Cost
      self.baseCost = null;
      self.uniformat = null;
      self.description = null;
      self.showNoBaseCostMessage = false;

      self.groupCostAdjustmentRules = groupCostAdjustmentRules;

      // Cost Adjustment
      self.showNoCostAdjustmentMessage = false;
      CostLineService.getOneByAsset(self.asset)
        .then(function (costLine) {
          if (costLine) {
            self.baseCost = costLine.total_cost;
            self.uniformat = costLine.uniformat;
            self.description = costLine.description;
            self.asset_cost = costLine.total_cost_with_adjustments;
          } else {
            self.showNoBaseCostMessage = true;
            self.baseCost = null;
            self.uniformat = null;
            self.description = null;
          }

          return CostAdjustmentService.get(self.organization._id, {
            asset: self.asset._id,
            sort: "adjustment_percent,desc",
          });
        })
        .then(function (costAdjustments) {
          if (costAdjustments && costAdjustments.length > 0) {
            self.costAdjustments = costAdjustments;
          } else {
            self.showNoCostAdjustmentMessage = true;
          }
        })
        .then(() => {
          if (self.organization.show_fca) return getOrganizationData();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function getOrganizationData() {
      return CostAdjustmentRuleService.getAll(self.organization._id, {
        sort: "created_date,asc",
      })
        .then((result) => {
          const groupedRules = groupCostAdjustmentRules(result);
          self.costAdjustments.push(...groupedRules);
          self.showNoCostAdjustmentMessage = self.costAdjustments.length < 1;
        })
        .catch(ToastService.showError);
    }

    function groupCostAdjustmentRules(rules) {
      const buildingAndOrgRules = rules
        .filter((rule) => !rule.pin_type)
        .filter((rule) => {
          if (!rule.building) return true;
          else {
            return rule.building._id === self.asset.building;
          }
        });

      const categoryRules = rules.filter((rule) => {
        if (rule.pin_type && rule.building) {
          return (
            rule.pin_type.name === self.asset.pinType.name &&
            rule.building._id === self.asset.building
          );
        }
      });

      return [...buildingAndOrgRules, ...categoryRules];
    }
    function editBaseCost() {
      const locals = {
        assets: [self.asset],
      };
      if (
        self.organization.show_fca &&
        FeatureFlagService.isEnabled("asset_life_cycle")
      ) {
        return AssetLifeCycleDialog.show({ locals })
          .catch(ToastService.showError)
          .then((refreshAssets) => {
            if (refreshAssets) {
              // wait for the dialog service to update the information, because it is not immediate
              $timeout(() => {
                fetchAssetCost();
                getData();
              }, 1500);
            }
          })
          .finally(() => {
            self.loading = false;
          });
      }
      return AssetCostLineSelectionDialog.show({ locals })
        .catch(ToastService.showError)
        .then((refreshAssets) => {
          if (refreshAssets) {
            // wait for the dialog service to update the information, because it is not immediate
            $timeout(() => {
              fetchAssetCost();
              getData();
            }, 1500);
          }
        })
        .finally(() => {
          self.loading = false;
        });
    }

    function editCostAdjustment() {
      AssetCostAdjustmentDialog.show({
        locals: { assets: [self.asset] },
      })
        .catch(ToastService.showError)
        .then((shouldRefresh) => {
          if (shouldRefresh) {
            fetchAssetCost();
            getData();
          }
        });
    }

    /**
     * Get asset and attach the cost to self.asset_cost
     */
    function fetchAssetCost() {
      CostLineService.getOneByAsset(self.asset).then(function (costLine) {
        if (costLine && costLine.total_cost_with_adjustments) {
          self.asset_cost = costLine.total_cost_with_adjustments;
        }
      });
    }

    self.roundNumber = Utils.roundNumber;
  }
})();
