(function () {
  angular
    .module("akitabox.core.auth.logout")
    .controller("LogoutController", LogoutController);

  /* @ngInject */
  function LogoutController(
    // Angular
    // Third Party
    $stateParams,
    $window,
    // Services
    AppcuesService,
    AuthNService,
    FilterService,
    IdentityService,
    RecentService,
    SessionService,
    ToastService,
    TokenService
  ) {
    var self = this;

    // Get state params if there are any
    self.redirect_url = $stateParams.redirect_url;

    // so log out already
    logout();

    function logout() {
      // Invalidate the `recents` cookie
      RecentService.clearCookie();

      // Invalidate cached filters
      FilterService.clearAllFilters();
      SessionService.clearSession();
      TokenService.removeAuthToken();

      // make api call to clear access token and user cookies
      AuthNService.logout(self.redirect_url)
        .then(({ login_url = "" }) => {
          AppcuesService.removeInstance();
          IdentityService.setCurrent(null);
          $window.location.href = login_url || "/login";
        })
        .catch(function (err) {
          ToastService.showError(err);
        });
    }
  }
})();
