(function () {
  /**
   * @component
   * Component for displaying a form to collect data for an Inspection Component.
   * Contains all building-specific data used for inspection programs.
   *
   * This component is currently completely uncontrolled and basically for
   * display only. Bindings need to be added to report changes and allow for
   * loading of initial values when editing.
   */
  angular
    .module(
      "akitabox.core.ui.dialogs.inspectionProgram.create.inspectionComponentSection"
    )
    .component("abxInspectionComponentSection", {
      bindings: {
        value: "<abxValue",
        checklistTemplate: "<abxChecklistTemplate",
        onValueChange: "&abxOnValueChange",
        assigneeOptions: "<abxAssigneeOptions",
        onLoadingComplete: "<?abxOnLoadingComplete",
        stopChecklistAssociations: "<?abxStopChecklistAssociations",
      },
      controller: AbxInspectionComponentController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/dialogs/inspection-program/create/inspection-component-section/inspection-component-section.template.html",
    });

  /** @ngInject */
  function AbxInspectionComponentController(
    // angular
    $q,
    // Akitabox
    Utils,
    // Services
    BuildingService,
    OrganizationService,
    MaintenanceTypeService,
    ToastService,
    TradeService
  ) {
    var self = this;

    var tradesRequest;

    self.showOverrides = false;
    self.loading = true;
    self.fetchTypes = fetchTypes;
    self.fetchTrades = fetchTrades;
    self.handleBuildingChange = handleBuildingChange;
    self.handleTradeChange = handleTradeChange;
    self.handleTypeChange = handleTypeChange;
    self.handleAssigneesChange = handleAssigneesChange;
    self.handleRoundTemplateChange = handleRoundTemplateChange;
    self.handleOverridesChange = handleOverridesChange;
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();

    self.$onChanges = function (changes) {
      if (changes.value) {
        var oldValue = changes.value.previousValue;
        var newValue = self.value;

        if (!oldValue || changes.value.isFirstChange()) {
          // noop;
        } else {
          if (
            !Utils.isSameModel(oldValue.building, newValue.building) &&
            newValue.building
          ) {
            fetchTypes(newValue.building._id);
            fetchTrades(newValue.building._id);
          }
          if (
            !Utils.isSameModel(
              oldValue.maintenance_type,
              newValue.maintenance_type
            )
          ) {
            var newType = newValue.maintenance_type;
            if (newType && newType.trade && !newValue.trade) {
              fetchTrades(newValue.building._id).then(function (trades) {
                // Don't set trade if one was selected while tradesRequest was executing
                if (self.value.trade) return;

                // Find trade object based on the trade ID given by the new type
                var newTradeId = newType.trade;
                for (var i = 0; i < trades.length; ++i) {
                  var trade = trades[i];
                  if (trade._id === newTradeId) {
                    handleTradeChange({ model: trade });
                    break;
                  }
                }
              });
            }
          }
          if (!Utils.isSameModel(oldValue.trade, newValue.trade)) {
            var newTrade = newValue.trade;
            if (!newTrade) return;

            var formHasAssignees = Boolean(
              self.value.assignee_users && self.value.assignee_users.length
            );
            var tradeHasDefaultAssignee = !!newTrade.default_assignee_user;

            if (!tradeHasDefaultAssignee || formHasAssignees) {
              return;
            }

            var assignees = filterAssignees();
            // Find assignee object based on the assignee ID given by the new trade
            var newAssigneeId = newTrade.default_assignee_user;
            for (var i = 0; i < assignees.length; ++i) {
              var assignee = assignees[i];
              if (assignee._id === newAssigneeId) {
                self.onValueChange({
                  $event: angular.extend({}, self.value, {
                    assignee_users: [assignee],
                  }),
                });
                break;
              }
            }
          }
        }
      }
    };

    self.$onInit = function () {
      self.loading = true;
      if (self.onLoadingComplete) {
        self.onLoadingComplete(false);
      }

      var newValue = angular.extend({}, self.value || {});
      if (self.building && !newValue.building) {
        newValue.building = self.building;
      }
      // if assignee_users come in as strings, replace them with their full
      // users
      if (
        newValue.assignee_users &&
        newValue.assignee_users.length &&
        typeof newValue.assignee_users[0] === "string" &&
        newValue.building
      ) {
        var assigneeIds = self.value.assignee_users;
        newValue.assignee_users = [];

        var assignee_users = filterAssignees(newValue.building._id);
        var finalAssignees = [];
        if (assignee_users) {
          var assigneeLookup = assignee_users.reduce(function (map, assignee) {
            map[assignee._id] = assignee;
            return map;
          }, {});
          for (var i = 0; i < assigneeIds.length; ++i) {
            var id = assigneeIds[i];
            var user = assigneeLookup[id];
            if (user) {
              finalAssignees.push(user);
            }
          }
        }
        newValue.assignee_users = finalAssignees;
      }

      self.onValueChange({ $event: newValue });

      var promises = [];

      if (newValue.building) {
        promises.push(fetchTypes(newValue.building._id));
        promises.push(fetchTrades(newValue.building._id));
      }

      $q.all(promises)
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          if (self.onLoadingComplete) {
            self.onLoadingComplete(true);
          }
        });
    };

    // ------------------------
    //   Public Functions
    // ------------------------
    function handleBuildingChange(event) {
      var newValue = angular.extend({}, self.value);
      var oldBuilding = self.value.building;
      newValue.building = event.model;
      if (!Utils.isSameModel(event.model, oldBuilding) && newValue.building) {
        newValue.assignee_users = [];
      }
      if (!newValue.building) {
        newValue.trade = null;
        newValue.maintenance_type = null;
        newValue.assignee_users = [];
      }
      self.onValueChange({ $event: newValue });
    }

    function handleRoundTemplateChange(event) {
      self.onValueChange({
        $event: angular.extend({}, self.value, {
          round_template: event.model,
        }),
      });
    }

    function handleOverridesChange($event) {
      self.onValueChange({
        $event: angular.extend({}, self.value, { overrideLists: $event.model }),
      });
    }

    /**
     * Populates the trade input with the newly selected type's default trade,
     * iff there isn't a trade selected currently.
     */
    function handleTypeChange(event) {
      var selectedType = event.model;
      self.onValueChange({
        $event: angular.extend({}, self.value, {
          maintenance_type: selectedType,
        }),
      });
    }

    /**
     * Populates the assignees input with the newly selected trade's default assignee,
     * if there isn't an assignee selected currently.
     */
    function handleTradeChange(event) {
      var selectedTrade = event.model;
      self.onValueChange({
        $event: angular.extend({}, self.value, { trade: selectedTrade }),
      });
    }

    function handleAssigneesChange(event) {
      self.onValueChange({
        $event: angular.extend({}, self.value, { assignee_users: event.value }),
      });
      // the <abx-multi-assignees /> input requires a promise for the on-change param
      return $q.resolve();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Get get the correct types to choose from for the building.
     *
     * @return {Promise<Array>}   Resolves with types for the building, limited to Issue Types if this workOrder
     * is being created from a Service Request
     */
    function fetchTypes(buildingId) {
      return MaintenanceTypeService.getAll(
        buildingId || self.value.building._id,
        {},
        {
          cache: false,
        }
      )
        .then(function (types) {
          self.types = types.map(function (type) {
            return {
              model: type,
              value: type.name,
            };
          });
        })
        .catch(ToastService.showError);
    }

    /**
     * Get all available trades to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all trades for the building
     */
    function fetchTrades(buildingId) {
      // Return outstanding request
      if (tradesRequest) return tradesRequest;
      tradesRequest = TradeService.getAll(
        buildingId || self.value.building._id,
        {},
        { cache: false }
      )
        .then(function (trades) {
          self.trades = trades.map(function (t) {
            return {
              model: t,
              value: t.name,
            };
          });
          tradesRequest = undefined;
          return trades;
        })
        .catch(ToastService.showError);
      return tradesRequest;
    }

    /**
     * Filter assignees by building
     *
     * @return {Array<User>} Returns all users for the building
     */
    function filterAssignees(buildingId) {
      var buildingIdToFilterBy = buildingId || self.value.building._id;
      var filteredAssignees = [];
      for (var i = 0; i < self.assigneeOptions.length; i++) {
        var user = self.assigneeOptions[i];
        for (var j = 0; j < user.buildings.length; j++) {
          var currentBuilding = user.buildings[j];
          var currentBuildingId = currentBuilding._id || currentBuilding;
          if (currentBuildingId === buildingIdToFilterBy) {
            filteredAssignees.push(user);
            break;
          }
        }
      }
      return filteredAssignees;
    }
  }
})();
