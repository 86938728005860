(function () {
  angular
    .module("akitabox.desktop.inspectionProgram")
    .controller(
      "BuildingInspectionProgramListController",
      BuildingInspectionProgramListController
    );

  /** @ngInject */
  function BuildingInspectionProgramListController(
    // Angular
    $location,
    $q,
    $scope,
    $timeout,
    // Constants
    EVENT_INSPECTION_PROGRAM_CREATE,
    // Services
    ChecklistTemplateService,
    FilterBarManager,
    InspectionProgramService,
    MaintenanceTypeService,
    ManagedFilterHelpers,
    ManagedInspectionProgramAssigneesFilter,
    ManagedModelFieldFilter,
    ToastService,
    TradeService,
    UserService,
    // resolve
    building,
    organization,
    status
  ) {
    var self = this;
    self.inspectionPrograms = [];
    self.status = status;
    self.building = building;

    // Functions
    self.fetchInspectionPrograms = fetchInspectionPrograms;
    self.fetchAllInspectionPrograms = fetchAllInspectionPrograms;
    self.changeFilters = changeFilters;

    // Events
    $scope.$on(EVENT_INSPECTION_PROGRAM_CREATE, onInspectionProgramCreate);

    function onInspectionProgramCreate($event, inspectionProgram) {
      // check and make sure this ip has a component within this building
      var match = false;
      for (var i = 0; i < inspectionProgram.inspection_components.length; i++) {
        var buildingId = inspectionProgram.inspection_components[i].building;
        if (buildingId._id) buildingId = buildingId._id;
        if (self.building._id === buildingId) {
          match = true;
          break;
        }
      }
      if (!match) return; // this ip doesn't have anything to do with this building

      if (!self.inspectionPrograms) self.inspectionPrograms = [];
      self.inspectionPrograms.unshift(inspectionProgram);
      $timeout(function () {
        $scope.$broadcast("list:refreshClickEvents");
      });
    }

    // Filter Bar
    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?status=${status} portion of the query
        query.status = $location.search().status;
        // update the list
        changeFilters(query);
      },
      countRoute: "/organizations/" + organization._id + "/inspection_programs",
    });

    var subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "name",
    });

    var idConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      inputType: "number",
      prefix: "IP-",
      modelValueToChipText: function (inputValue) {
        return "IP-" + inputValue;
      },
    });

    var priorityConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Priority",
      queryField: "priority",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Low", "Medium", "High", "Emergency"];
      },
      modelValueToFilterValue: function (priority) {
        return priority.toLowerCase();
      },
      modelValueToChipText: function (priority) {
        return priority.charAt(0).toUpperCase() + priority.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        return [queryStringValue];
      },
    });

    var UNASSIGNED_USER = {
      _id: "null",
      is_deactivated: false,
      email: "Unassigned",
      display_name: "Unassigned",
    };

    var assigneesConfig = new ManagedInspectionProgramAssigneesFilter(
      self.filterBarManager,
      {
        getEnumOptions: function () {
          return fetchAssignees().then(function (assignees) {
            var models = assignees.map(function (user) {
              var value =
                user.display_name !== user.email
                  ? user.display_name + " | " + user.email
                  : user.display_name;

              return {
                model: user,
                value: value,
              };
            });

            // Add `Unassigned` as a typeahead option
            models.unshift({
              model: UNASSIGNED_USER,
              value: UNASSIGNED_USER.display_name,
            });

            return models;
          });
        },
        modelValueToFilterValue: function (user) {
          return user._id || user;
        },
        modelValueToChipText: function (user) {
          return user.display_name;
        },
        filterValueToModelValue: function (assigneeQueryValue) {
          var models = [];
          // assigneeQueryValue is a string
          var userIdList = assigneeQueryValue.split(",").slice(1);
          // Remove duplicates
          userIdList = userIdList.filter(function (_userId, index) {
            return userIdList.indexOf(_userId) === index;
          });

          var userQuery = ["$in"];
          for (var i = 0; i < userIdList.length; i++) {
            var userId = userIdList[i];
            if (userId === "null") {
              models.push(UNASSIGNED_USER);
            } else {
              userQuery.push(userId);
            }
          }

          return UserService.getAll(organization._id, {
            _id: userQuery.join(","),
            sort: "firstOrEmail,asc",
          }).then(function (users) {
            return models.concat(users);
          });
        },
      }
    );

    var maintenanceTypeConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Maintenance Type",
        queryField: "inspection_components.maintenance_type",
        inputType: "typeahead",
        getEnumOptions: function () {
          return fetchTypes()
            .then(function (types) {
              return types.map(function (type) {
                return {
                  model: type,
                  value: type.name,
                };
              });
            })
            .catch(ToastService.showError);
        },
        modelValueToFilterValue: function (type) {
          return type._id;
        },
        modelValueToChipText: function (type) {
          return type.name + " (" + type.intent + ")";
        },
        filterValueToModelValue: function (typeId) {
          return fetchTypes({ _id: typeId }).then(function (types) {
            if (!types) {
              return [];
            }
            return types;
          });
        },
        onAdd: function () {
          if (!building.show_issue_types) {
            return $q.reject();
          }
        },
      }
    );

    var tradeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Trade",
      queryField: "inspection_components.trade",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchTrades().then(ManagedFilterHelpers.mapModelsToEnumOption);
      },
      modelValueToFilterValue: ManagedFilterHelpers.modelToId,
      modelValueToChipText: ManagedFilterHelpers.modelToName,
      filterValueToModelValue: function (tradeId) {
        return TradeService.getById(building._id, tradeId).then(function (
          trade
        ) {
          return [trade];
        });
      },
      onAdd: function () {
        if (!building.show_trades) {
          return $q.reject();
        }
      },
    });

    var checklistConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Checklist Template",
      queryField: "checklist_template",
      // typeahead because we want them to be able to choose the template
      inputType: "typeahead",
      filterValueToModelValue: function (queryStringValue) {
        // We assume the queryStringValue is the id, if not, it'll still be fine as it returns a nothing
        return ChecklistTemplateService.getById(
          organization._id,
          queryStringValue
        ).then(function (template) {
          // we have to return an array type
          return [template];
        });
      },
      getEnumOptions: function () {
        // fn required by typeahead inputTypes.  responsible for grabbing the droopdown values
        return fetchChecklistTemplates().then(function (templates) {
          return templates.map(function (template) {
            return {
              model: template,
              value: template.name,
            };
          });
        });
      },
      modelValueToChipText: function (template) {
        // fn to determine what text is shown on the filter's chip
        return template.name;
      },
      modelValueToFilterValue: function (template) {
        // fn to determine what value is sent to the API when filtering by checklist templates
        return template._id;
      },
    });

    [
      subjectConfig,
      idConfig,
      priorityConfig,
      assigneesConfig,
      checklistConfig,
      maintenanceTypeConfig,
      tradeConfig,
    ].forEach(function (config) {
      self.filterBarManager.addFilterConfiguration(config);
    });

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchInspectionPrograms(skip, limit) {
      var doFetch = function () {
        var params = {
          "inspection_components.building": building._id,
          skip: skip,
          limit: limit,
        };
        var paramsAndFilters = angular.extend({}, params, self.activeFilters);

        return InspectionProgramService.getByOrganization(
          organization._id,
          paramsAndFilters
        )
          .then(function (inspectionPrograms) {
            if (angular.isArray(self.inspectionPrograms)) {
              self.inspectionPrograms =
                self.inspectionPrograms.concat(inspectionPrograms);
            } else {
              self.inspectionPrograms = inspectionPrograms;
            }
            return inspectionPrograms.length;
          })
          .catch(ToastService.showError);
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllInspectionPrograms(limit) {
      var doFetch = function () {
        var params = {
          "inspection_components.building": building._id,
          limit: limit,
        };

        return InspectionProgramService.getAllByOrganization(
          organization._id,
          params
        )
          .then(function (inspectionPrograms) {
            self.inspectionPrograms = inspectionPrograms;
            return inspectionPrograms.length;
          })
          .catch(ToastService.showError);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = angular.isEmpty(activeFilters, true)
        ? null
        : activeFilters;
      self.inspectionPrograms = [];
      $scope.$broadcast("list:refresh");
    }

    // -----------------------
    //    Private Functions
    // ------------------------

    function fetchAssignees() {
      return UserService.getAll(organization._id, {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
        // status: "active", may want to filter on deactivated user
      }).catch(ToastService.showError);
    }

    function fetchTrades(params) {
      return TradeService.getAll(building._id, params).catch(
        ToastService.showError
      );
    }

    function fetchTypes(params) {
      if (!params) params = {};

      return MaintenanceTypeService.getAll(building._id, params, {
        cache: false,
      }).catch(ToastService.showError);
    }

    /**
     * fetches all checklist templates within this org
     */
    function fetchChecklistTemplates() {
      var params = {};
      if (self.status === "active") {
        params.status = "active";
      }
      return ChecklistTemplateService.getAll(organization._id, params).catch(
        ToastService.showError
      );
    }
  }
})();
