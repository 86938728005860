(function () {
  angular
    .module("akitabox.core.services.authn", [
      "akitabox.core.services.http",
      "akitabox.core.services.flag",
    ])
    .factory("AuthNService", AuthNService);

  /* @ngInject */
  function AuthNService(HttpService, FeatureFlagService) {
    function logout(redirect_url = "") {
      // we cannot rely on FeatureFlagService here because it is a race for when it gets a response back
      // for which flaga are enabled vs when we check here. So we much request the flags directly

      return FeatureFlagService.getEnabled(
        "customer_accounts_in_identities"
      ).then(function (enabled) {
        if (!enabled) {
          return HttpService.post("/logout").then(() => ({
            login_url: `/login${
              redirect_url ? `?redirect_url=${redirect_url}` : ""
            }`,
          }));
        } else {
          const url = `/auth/logout${
            redirect_url ? `?redirect_url=${redirect_url}` : ""
          }`;
          return HttpService.get(url).then(function (data) {
            return data.data;
          });
        }
      });
    }

    const service = {
      logout,
    };

    return service;
  }
})();
