(function () {
  angular
    .module("akitabox.ui.components.workOrderList", [
      "akitabox.ui.components.input",
      "akitabox.ui.components.workOrderList.workOrderQuickActions",
    ])
    .component("abxWoList", {
      bindings: {
        buildings: "<abxBuildings",
        workOrders: "<abxWorkOrders",
        showPriority: "=?abxShowPriority",
        onWorkOrderUpdate: "&",
        onWorkOrderDetailsNavigation: "&",
      },
      controller: AbxWorkOrderListController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/work-order-list/work-order-list.component.html",
    });

  function AbxWorkOrderListController(
    $mdMedia,
    FeatureFlagService,
    PrintDialog,
    OrganizationService,
    UserService,
    WorkOrderService
  ) {
    var self = this;
    var organization = OrganizationService.getCurrent();
    var userPermissions = UserService.getPermissions();
    var currentUser = UserService.getCurrent();

    // Functions
    self.quickActionWO = null;
    self.checkPermissionsForWorkOrder = checkPermissionsForWorkOrder;
    self.showQuickActions = showQuickActions;
    self.handleWorkOrderUpdate = handleWorkOrderUpdate;
    self.print = print;
    self.getAsset = WorkOrderService.getAsset;
    self.getRoom = WorkOrderService.getRoom;
    self.getLevel = WorkOrderService.getLevel;
    self.getLevels = WorkOrderService.getLevels;
    self.hasMultipleStops = function (workOrder) {
      return workOrder.round.length > 1;
    };
    self.userMap = {};

    self.visibility = {
      priority: false,
      multipleBuildings: false,
    };

    // Lifecycle
    self.$onInit = function () {
      // Update visibility
      self.visibility.priority = self.showPriority;
      self.visibility.multipleBuildings = self.buildings.length > 1;
    };

    self.$onChanges = function (changes) {
      if (changes.workOrders && self.workOrders) {
        const userIds = new Set([]);
        for (const workOrder of self.workOrders) {
          for (const userId of workOrder.assignee_users) {
            userIds.add(userId);
          }
        }
        UserService.getAll(organization._id, {
          _id: `$in,${Array.from(userIds).join(",")}`,
        }).then((users) => {
          for (const user of users) {
            self.userMap[user._id] = user;
          }
          for (const workOrder of self.workOrders) {
            workOrder.assignee_users = workOrder.assignee_users.map(
              (userId) => self.userMap[userId]
            );
          }
        });
      }
    };

    /**
     * Checks the permissions for the user based on whether they are an assignee or not
     * If the user has no additional permissions besides default, return false, otherwise return true
     *
     * (I.E. If the current user can only print the work-order then don't show the quick-actions menu dropdown, just show the print button)
     *
     * @param {*} workOrder
     * @returns {Boolean}
     */
    function checkPermissionsForWorkOrder(workOrder) {
      // Always show for smaller screens to avoid information loss
      if ($mdMedia("sm") || $mdMedia("xs")) {
        return true;
      }

      var isOwn = workOrder.assignee_users
        .map(function (assignee) {
          return assignee._id;
        })
        .includes(currentUser._id);

      var canLogOnAny =
        userPermissions.work_order_log.create_own_log_on_any_task ||
        userPermissions.work_order_log.create_any;

      if (
        isOwn ||
        (!isOwn && userPermissions.task.complete_any) ||
        userPermissions.task.cancel ||
        userPermissions.task.assign ||
        userPermissions.task.update ||
        (!isOwn && canLogOnAny)
      ) {
        return true;
      }

      return false;
    }

    function print(workOrder) {
      var buildingId = Object.prototype.hasOwnProperty.call(
        workOrder.building,
        "_id"
      )
        ? workOrder.building._id
        : workOrder.building;
      var route = WorkOrderService.buildListRoute(buildingId);
      var locals = {
        selected: [workOrder._id],
        route: route,
      };

      return PrintDialog.show({ locals: locals });
    }

    function handleWorkOrderUpdate(workOrder) {
      self.onWorkOrderUpdate({ workOrder: workOrder });
    }

    function showQuickActions(wo) {
      self.quickActionWO = self.quickActionWO === wo ? null : wo;
    }
  }
})();
