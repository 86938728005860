(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.dialogs.schedule.create
   */
  angular
    .module("akitabox.ui.dialogs.schedule.create")
    .controller(
      "CreateScheduleDialogController",
      CreateScheduleDialogController
    );

  /**
   * @ngdoc controller
   * @module akitabox.ui.dialogs.schedule.create
   * @name CreateScheduleDialogController
   *
   * @description
   * Controller for the maintenance schedule creation dialog
   *
   * @ngInject
   */
  function CreateScheduleDialogController(
    // Angular
    $q,
    $timeout,
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Libraries
    moment,
    // Constants
    PRIORITIES,
    models,
    // Services
    Router,
    AssetService,
    FloorService,
    MaintenanceTypeService,
    OrganizationService,
    PinValueService,
    RoomService,
    ScheduleService,
    TimeZoneService,
    ToastService,
    TradeService,
    UserService,
    Utils
  ) {
    var self = this;

    var multipleFloorsProvided = false;
    var multipleRoomsProvided = false;
    var multipleAssetsProvided = false;
    var tradesRequest;
    var assigneesRequest;

    var invalidStartDate = false;
    var invalidDuration = false;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset(self.building);
    self.timeZone = TimeZoneService.getCurrentTimeZone(self.building);
    self.organization = OrganizationService.getCurrent();
    self.formData = {
      // Details
      priority: "medium",
      estimated_man_hr: 1,
      // Schedule
      period: 1,
      interval: "days",
      days_until_due: 1,
    };
    self.activeTab = 0;
    self.saving = false;
    self.loading = true;
    self.progress = 0;
    self.showProgress = false;
    self.locationProvided = false;
    self.newSchedule = null;
    self.model = models.MAINTENANCE_SCHEDULE.PLURAL;
    self.assignee_users = [];

    // Location
    self.floor = self.locals && self.locals.floor;
    self.room = self.locals && self.locals.room;
    self.asset = null;

    // Details
    self.priorityOptions = getPriorityOptions();

    // Schedule
    self.scheduleInvalid = false;
    self.enabledDays = [];
    self.intervalOptions = getIntervalOptions();
    self.showWeekdays = false;
    // need to set this to the start of the day because selecting dates in the abx-date-picker sends back start-of-day
    // dates, so to correctly calculate the difference (days_until_due), we must use start of day
    var nowMoment = moment();
    if (self.utcOffset) {
      nowMoment.utcOffset(self.utcOffset);
    }
    var startMoment = nowMoment.clone().startOf("day");
    self.today = startMoment.toDate();
    self.tomorrow = startMoment.clone().add(1, "days").toDate();
    self.startDate = angular.copy(self.today);
    self.dueDate = angular.copy(self.tomorrow);
    self.minDueDate = angular.copy(self.today);

    // Functions
    self.buildSummary = buildSummary;
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.isDisabled = isDisabled;
    self.next = next;
    self.onChange = onChange;
    self.onDurationChange = onDurationChange;
    self.onIntervalChange = onIntervalChange;
    self.onSelectStartDate = onSelectStartDate;
    self.onSelectWeekday = onSelectWeekday;
    self.previous = previous;
    self.selectTab = selectTab;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Navigate to the next tab
     */
    function next() {
      self.activeTab++;
    }

    /**
     * Navigate to the previous tab
     */
    function previous() {
      self.activeTab--;
    }

    /**
     * Select a tab
     *
     * @param  {Number} tab     Tab index
     */
    function selectTab(tab) {
      self.activeTab = tab;
    }

    /**
     * Handle field change
     *
     * @param {Event}   $event  Change event
     * @param {String}  field   Name of field that changed
     */
    function onChange($event, field) {
      if (
        !$event.model &&
        !["building", "floor", "room", "asset"].includes(field)
      ) {
        return;
      }
      var changed = !Utils.isSameModel(self[field], $event.model);
      switch (field) {
        case "building":
          self.building = $event.model;
          if (changed) {
            self.floor = null;
            self.room = null;
            self.asset = null;
            if (self.building) {
              onBuildingChange();
            }
          }
          break;
        case "asset":
          self.asset = $event.model;
          if (changed && self.asset) {
            if (!self.room) {
              const asset = self.asset;
              if (asset && asset.room) {
                var params = {
                  _id: asset.room._id,
                };
                RoomService.getBFF(
                  asset.building._id,
                  params,
                  {},
                  self.organization._id
                ).then(function (room) {
                  if (room) {
                    self.asset.room = room;
                    setRoomWithAsset();
                  }
                });
              }
            }
            if (!self.floor) setFloorWithAsset();
          }
          break;
        case "room":
          self.room = $event.model;
          if (changed) {
            self.asset = null;
            if (self.room && !self.floor) {
              setFloorWithRoom();
            }
          }
          break;
        case "floor":
          self[field] = $event.model;
          if (changed) {
            self.room = null;
            self.asset = null;
          }
          break;
        case "assignees":
          self.assignee_users = $event.value;
          // <abx-multiple-assignee /> inut requires a promise to be returned
          return $q.resolve();
        case "type":
          self.type = $event.model;
          if (changed) {
            onTypeChange();
          }
          break;
        case "trade":
          self.trade = $event.model;
          if (changed) {
            onTradeChange();
          }
          break;
        case "period":
          if ($event && !$event.invalid) {
            self.buildSummary($event.model);
          }
          break;
        case "duration":
          if ($event && !$event.invalid) {
            onDurationChange($event.model);
          } else {
            invalidDuration = true;
          }
          break;
        case "interval":
          if ($event && !event.invalid) {
            onIntervalChange($event.model);
          }
          break;
        default:
          self.formData[field] = $event.model;
      }
    }
    function updateBuildingTimezone() {
      self.utcOffset = TimeZoneService.getCurrentUTCOffset(self.building);
      self.timeZone = TimeZoneService.getCurrentTimeZone(self.building);
      var nowMoment = moment();

      nowMoment.utcOffset(self.utcOffset);

      var startMoment = nowMoment.clone().startOf("day");
      self.today = startMoment.toDate();
      self.tomorrow = startMoment.clone().add(1, "days").toDate();
      self.startDate = angular.copy(self.today);
      self.dueDate = angular.copy(self.tomorrow);
      self.minDueDate = angular.copy(self.today);
    }

    /**
     * Handle duration (days until due) change and update due date accordingly
     *
     * @param  {Number} duration    Selected duration
     */
    function onDurationChange(duration) {
      self.formData.days_until_due = duration;
      var start = moment(self.startDate);
      self.dueDate = start.add(duration, "days").toDate();
      invalidDuration = false;
    }

    /**
     * Handle schedule interval change
     *
     * @param  {String} interval    Selected interval
     */
    function onIntervalChange(interval) {
      if (interval) {
        for (var k in self.intervalOptions) {
          if (self.intervalOptions[k] === interval) {
            interval = k;
            break;
          }
        }
      }

      self.formData.interval = interval;

      self.showWeekdays = interval === "weeks";
      if (self.showWeekdays) {
        var weekday = moment().weekday();
        // Disable all days
        disableAllDays();
        // Enable today
        self.onSelectWeekday(weekday, true);
        switch (weekday) {
          case 0:
            self.formData.is_weekly_sunday = true;
            break;
          case 1:
            self.formData.is_weekly_monday = true;
            break;
          case 2:
            self.formData.is_weekly_tuesday = true;
            break;
          case 3:
            self.formData.is_weekly_wednesday = true;
            break;
          case 4:
            self.formData.is_weekly_thursday = true;
            break;
          case 5:
            self.formData.is_weekly_friday = true;
            break;
          case 6:
            self.formData.is_weekly_saturday = true;
            break;
        }
      } else {
        resetWeekdays();
      }
      self.buildSummary();
    }

    /**
     * Handle start date selection, update due date
     */
    function onSelectStartDate($event) {
      if ($event) {
        self.startDate = $event.model;
        if ($event.invalid) {
          invalidStartDate = true;
        } else {
          invalidStartDate = false;
        }
      }
      self.minDueDate = self.startDate;

      var start = moment(self.startDate);
      var end = start.add(self.formData.days_until_due, "days");
      self.dueDate = end.toDate();

      self.buildSummary();
    }

    /**
     * Handle weekday checkbox selection
     *
     * @param  {Number}     day         Selected day of the week
     * @param  {Boolean}    checked     If checkbox is checked
     */
    function onSelectWeekday(day, checked) {
      self.enabledDays[day] = checked;
      // Determine the next valid start date based on selected weekdays
      self.scheduleInvalid = true;
      var todayEnabled = self.enabledDays[self.today.getDay()];
      if (todayEnabled) {
        // Set start date to today
        self.startDate = angular.copy(self.today);
        self.scheduleInvalid = false;
      } else {
        // Find the next valid day for start date
        var futureDate = moment().tz(self.timeZone); //If there is no time zone it will set the one in the system and it will show a different time zone in "Work Order Open"
        for (var i = 1; i <= 7; ++i) {
          futureDate.add(1, "days");
          if (self.enabledDays[futureDate.weekday()]) {
            self.startDate = futureDate.startOf("day").toDate(); //Adding startOf("day") will reset the hour the futureDate is set
            self.scheduleInvalid = false;
            break;
          }
        }
      }
      // Update duration, end date, and summary
      self.formData.days_until_due = 1;
      self.onDurationChange(1);
      self.buildSummary();
    }

    /**
     * Create a maintenance schedule
     */
    function create() {
      if (self.saving) return;
      self.saving = true;

      var amt = self.count || 1;
      self.showProgress = amt > 1;
      var increment = 100 / amt;

      var requests = [];
      for (var i = 0; i < amt; ++i) {
        var data = getData(i);
        var r = ScheduleService.create(self.building._id, data).then(function (
          schedule
        ) {
          if (self.showProgress) self.progress += increment;
          return schedule;
        });
        requests.push(r);
      }

      return $q
        .all(requests)
        .then(function (results) {
          if (results.length) {
            // One schedule created. Show toast with "View" action
            if (results.length === 1) {
              self.newSchedule = results[0];
              ToastService.complex()
                .text("Successfully created maintenance schedule")
                .action("View", goToNewSchedule)
                .show();
              // Batch create. Show simple toast
            } else {
              ToastService.showSimple(
                "Successfully created " +
                  results.length +
                  " maintenance schedules"
              );
            }
          }
          $mdDialog.hide(results);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });

      function getData(index) {
        var data = angular.copy(self.formData);
        var roomId;
        var floorId;

        // send IDs instead of entire objects for
        // building, maintenance_type, trade, and assignees
        if (self.building) {
          data.building = self.building._id;
        }
        if (self.type) {
          data.maintenance_type = self.type._id;
        }
        if (self.trade) {
          data.trade = self.trade._id;
        }
        if (self.assignee_users.length) {
          data.assignee_users = self.assignee_users.map(function (assignee) {
            return assignee._id;
          });
        }

        data.start_date = angular.copy(self.startDate);
        data.time_zone = angular.copy(self.timeZone);

        if (multipleAssetsProvided) {
          var asset = self.assets[index];
          roomId = PinValueService.getRoomId(asset.values);
          floorId = asset.level
            ? asset.level._id
            : PinValueService.getFloorId(asset.values);
          if (floorId) data.level = floorId;
          if (roomId) data.room = roomId;
          data.asset = asset._id;
        } else if (multipleRoomsProvided) {
          var room = self.rooms[index];
          floorId = room.level
            ? room.level._id
            : PinValueService.getFloorId(room.values);
          if (floorId) data.level = floorId;
          data.room = room._id;
        } else if (multipleFloorsProvided) {
          data.level = self.floors[index]._id;
        } else {
          if (self.floor) data.level = self.floor._id;
          if (self.room) data.room = self.room._id;
          if (self.asset) data.asset = self.asset._id;
        }
        return data;
      }
    }

    /**
     * Build summary text
     */
    function buildSummary(period) {
      if (period) {
        self.formData.period = period;
      }
      $timeout(function () {
        self.freqSummary = getFrequencySummary();
      });
    }

    /**
     * Determine if the form is disabled
     *
     * @return {Boolean}  True if disabled, false if not
     */
    function isDisabled() {
      return (
        self.saving ||
        self.scheduleInvalid ||
        !self.building ||
        !self.formData.subject ||
        !self.formData.priority ||
        self.formData.estimated_man_hr < 0 ||
        !self.startDate ||
        !self.dueDate ||
        self.formData.days_until_due < 0 ||
        self.startDate < self.today ||
        invalidStartDate ||
        invalidDuration ||
        (self.showType && self.requireType && !self.type) ||
        (self.showTrade && self.requireTrade && !self.trade)
      );
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, build default summary text, fetch floors
     */
    function init() {
      resetWeekdays();
      // Generate initial summary text
      self.buildSummary();

      setTypeOptions(self.building);
      setTradeOptions(self.building);

      if (self.building) {
        // Check if we have been provided multiple floors, rooms, or assets
        // If so, there's no need to use the location tab
        if (!angular.isEmpty(self.floors) && self.floors.length > 1) {
          multipleFloorsProvided = true;
          self.count = self.floors.length;
        } else if (!angular.isEmpty(self.rooms) && self.rooms.length > 1) {
          multipleRoomsProvided = true;
          self.count = self.rooms.length;
        } else if (!angular.isEmpty(self.assets) && self.assets.length > 1) {
          multipleAssetsProvided = true;
          self.count = self.assets.length;
        }
        setDefaults()
          .catch(ToastService.showError)
          .finally(function () {
            self.loading = false;
          });
      } else {
        self.loading = false;
      }
    }

    /**
     * Get scheduling interval options
     *
     * @return {Object} Map of interval options
     */
    function getIntervalOptions() {
      return {
        days: "Days",
        weeks: "Weeks",
        months: "Months",
        years: "Years",
      };
    }

    /**
     * Get the frequency summary based on the selected scheduling options
     *
     * @return {String} Summary text
     */
    function getFrequencySummary() {
      if (self.scheduleInvalid || !self.startDate || !self.formData.period) {
        return "Unavailable with selected options";
      }

      var schedule = {
        start_date: self.startDate,
        period: self.formData.period,
        interval: self.formData.interval,
        is_weekly_sunday: self.formData.is_weekly_sunday,
        is_weekly_monday: self.formData.is_weekly_monday,
        is_weekly_tuesday: self.formData.is_weekly_tuesday,
        is_weekly_wednesday: self.formData.is_weekly_wednesday,
        is_weekly_thursday: self.formData.is_weekly_thursday,
        is_weekly_friday: self.formData.is_weekly_friday,
        is_weekly_saturday: self.formData.is_weekly_saturday,
      };

      return ScheduleService.getFrequencySummary(schedule, self.utcOffset);
    }

    /**
     * Reset all weekday boolean flags
     */
    function resetWeekdays() {
      self.formData.is_weekly_sunday = false;
      self.formData.is_weekly_monday = false;
      self.formData.is_weekly_tuesday = false;
      self.formData.is_weekly_wednesday = false;
      self.formData.is_weekly_thursday = false;
      self.formData.is_weekly_friday = false;
      self.formData.is_weekly_saturday = false;
      enableAllDays();
    }

    /**
     * Enable all weekdays for the date pickers
     */
    function enableAllDays() {
      self.enabledDays = [true, true, true, true, true, true, true];
    }

    /**
     * Disable all weekdays for the date pickers
     */
    function disableAllDays() {
      self.enabledDays = [false, false, false, false, false, false, false];
    }

    /**
     * Go to the newly created maintenance schedule's detail page
     */
    function goToNewSchedule() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.building._id : null,
        scheduleId: self.newSchedule._id,
      };
      Router.go("app.schedule", stateParams);
    }

    function onBuildingChange() {
      // Clear existing data that depends on building
      // Floor, room, and asset clear themselves
      self.type = null;
      self.trade = null;
      self.assignee_users = [];
      // Clear any outstanding requests and cached options
      assigneesRequest = null;
      tradesRequest = null;
      self.maintenanceTypes = null;
      self.maintenanceTypeOptions = null;
      self.trades = null;
      self.tradeOptions = null;
      // Set type and trade options accordingly along with any defaults
      setTypeOptions(self.building);
      setTradeOptions(self.building);
      updateBuildingTimezone();
      setDefaults();
    }

    /**
     * Set default floor, room, asset, maintenance type, trade, and assignee
     * according to the data provided to this dialog and the building defaults
     */
    function setDefaults() {
      var requests = [fetchAllAssignees()];

      if (
        multipleFloorsProvided ||
        multipleRoomsProvided ||
        multipleAssetsProvided
      ) {
        self.activeTab = 1;
        self.locationProvided = true;
      } else if (!angular.isEmpty(self.assets)) {
        self.asset = self.assets[0];
        requests = [setRoomWithAsset(), setFloorWithAsset()];
      } else if (!angular.isEmpty(self.rooms)) {
        self.room = self.rooms[0];
        requests = [setFloorWithRoom()];
      } else if (!angular.isEmpty(self.floors)) {
        self.floor = self.floors[0];
      }

      if (self.showType) {
        requests.push(fetchAllMaintenanceTypes());
      }
      if (self.showTrade) {
        requests.push(fetchAllTrades());
      }

      return $q.all(requests);
    }

    /**
     * Set trade shown and required flags according to the building
     *
     * @param {Building} building Selected building
     */
    function setTradeOptions(building) {
      self.showTrade = building ? building.show_trades : false;
      self.requireTrade = building ? building.require_trades : false;
    }

    /**
     * Set type shown and required flags according to the building
     *
     * @param {Building} building Selected building
     */
    function setTypeOptions(building) {
      self.showType = building ? building.show_maintenance_types : false;
      self.requireType = building ? building.require_maintenance_types : false;
    }

    /**
     * Get all available maintenance types to choose from for the building.
     *
     * @return {Promise<Array|Error>}   Resolves with all maintenance types for the building
     */
    function fetchAllMaintenanceTypes() {
      return MaintenanceTypeService.getAll(
        self.building._id,
        {},
        { cache: false }
      )
        .then(function (maintenanceTypes) {
          self.maintenanceTypes = maintenanceTypes;
          self.maintenanceTypesOptions = self.maintenanceTypes.map(function (
            mType
          ) {
            return { value: mType.name, model: mType };
          });
          return maintenanceTypes;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.maintenanceTypes = [];
          self.maintenanceTypesOptions = [];
        });
    }

    /**
     * Get all available trades to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all trades for the building
     */
    function fetchAllTrades() {
      if (tradesRequest) return tradesRequest;

      // Return outstanding request
      tradesRequest = TradeService.getAll(
        self.building._id,
        {},
        { cache: false }
      )
        .then(function (trades) {
          self.trades = trades;
          self.tradesOptions = self.trades.map(function (trade) {
            return { value: trade.name, model: trade };
          });
          return trades;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.trades = [];
          self.tradesOptions = [];
        });

      return tradesRequest;
    }

    /**
     * Get all available assignees to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all assignees for the building
     */
    function fetchAllAssignees() {
      if (assigneesRequest) return assigneesRequest;

      assigneesRequest = UserService.getAll(self.organization._id, {
        buildings: self.building._id,
        identity: "$ne,null",
        status: "active",
        sort: "firstOrEmail,asc",
      }).catch(ToastService.showError);

      return assigneesRequest;
    }

    function getPriorityOptions() {
      var options = [];
      Object.keys(PRIORITIES).forEach(function (k) {
        options.push({
          model: k,
          value: PRIORITIES[k],
        });
      });
      return options;
    }

    /**
     * Populates the trade input with the newly selected type's default trade, iff there isn't a trade selected
     * currently.
     */
    function onTypeChange() {
      var selectedType = self.type;
      if (
        !self.showTrade ||
        !selectedType ||
        !selectedType.trade ||
        self.trade
      ) {
        return;
      }
      fetchAllTrades().then(function (trades) {
        // Don't set trade if one is already selected
        if (self.trade) return;

        // Find trade object based on the trade ID given by the new type
        var newTradeId = selectedType.trade;
        for (var i = 0; i < trades.length; ++i) {
          var trade = trades[i];
          if (trade._id === newTradeId) {
            self.trade = trade;
            onTradeChange();
            break;
          }
        }
      });
    }

    /**
     * Populates the assignee input with the newly selected trade's default assignee, iff there isn't an assignee
     * selected currently.
     */
    function onTradeChange() {
      var selectedTrade = self.trade;
      if (!selectedTrade) return;

      var formHasAssignees = !!self.assignee_users.length;
      var tradeHasDefaultAssignee = !!selectedTrade.default_assignee_user;

      if (!tradeHasDefaultAssignee || formHasAssignees) {
        return;
      }

      // 'assignees' are users
      fetchAllAssignees().then(function (assignees) {
        // Find assignee object based on the assignee ID given by the new trade
        var newAssigneeId = selectedTrade.default_assignee_user;
        for (var i = 0; i < assignees.length; ++i) {
          var assignee = assignees[i];
          if (assignee._id === newAssigneeId) {
            self.assignee_users = [assignee];
            break;
          }
        }
      });
    }

    function setRoomWithAsset() {
      if (self.asset.room && self.asset.room._id) {
        return $q(function (resolve) {
          self.room = self.asset.room;
          self.room.building = self.asset.building;
          resolve();
        });
      } else {
        return AssetService.getRoom(self.building._id, self.asset.values).then(
          function (room) {
            self.room = room || null;
          }
        );
      }
    }

    function setFloorWithAsset() {
      if (self.asset.level && self.asset._id) {
        return FloorService.getById(
          self.asset.building._id || self.asset.building,
          self.asset.level._id || self.asset.level
        ).then(function (level) {
          self.floor = level || null;
        });
      } else {
        return AssetService.getFloor(self.building._id, self.asset.values).then(
          function (floor) {
            self.floor = floor || null;
          }
        );
      }
    }

    function setFloorWithRoom() {
      return self.room._id && self.room.level
        ? $q(function (resolve) {
            self.floor = self.room.level;
            self.floor.building = self.room.building;
            resolve();
          })
        : RoomService.getFloor(self.building._id, self.room.values).then(
            function (floor) {
              self.floor = floor || null;
            }
          );
    }
  }
})();
