(function () {
  angular
    .module("akitabox.desktop.room.detail")
    .controller("RoomOverviewTabController", RoomOverviewTabController);

  /* @ngInject */
  function RoomOverviewTabController(
    // Angular
    $scope,
    $q,
    $window,
    // Constants
    WORK_ORDER_STATUS_OPEN,
    // Dialogs
    CreateRequestDialog,
    CreateWorkOrderDialog,
    CreateScheduleDialog,
    DecommissionDialog,
    DeleteDialog,
    FilePreviewDialog,
    // Resolve
    building,
    room,
    organization,
    // Services
    Router,
    NoteService,
    RoomService,
    ToastService,
    WorkOrderService,
    UserService,
    WorkOrdersBFFService,
    // Third-party
    $state
  ) {
    var self = this;

    var OVERDUE_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      due_date: "$lte," + Date.now(),
      room: room._id,
    };
    var OPEN_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      start_date: "$lte," + Date.now(),
      due_date: "$gte," + Date.now(),
      room: room._id,
    };

    var permissions = UserService.getPermissions();

    // Attributes
    self.building = building;
    self.organization = organization;
    self.room = room;
    self.roomForOneWayBinds = angular.copy(room);
    self.allOverdueWorkOrdersState = {
      state: "app.workOrders",
      params: OVERDUE_QUERY,
    };
    self.allOpenWorkOrdersState = {
      state: "app.workOrders",
      params: OPEN_QUERY,
    };
    self.overdueWorkOrders = [];
    self.openWorkOrders = [];
    self.overdueWorkOrdersCount = 0;
    self.openWorkOrdersCount = 0;
    self.activityParams = {
      room: self.room._id,
    };
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Room",
      account: self.room.created_by,
      building: self.room.building,
      time: self.room.created_date,
    };
    self.floor = angular.copy(self.room.level); // Used to prevent floor plan showing before location form save
    self.permissions = getDefaultPermissions();

    // Save off populated pin type (populated in asset detail route resolve)
    // so we don't have to re-fetch it every time we make an update to the asset
    // and the back end gives us a pinType ID instead of a pinType object
    var pinType = angular.copy(self.room.pinType);

    // Functions
    self.createNote = createNote;
    self.createRequest = createRequest;
    self.createWorkOrder = createWorkOrder;
    self.createSchedule = createSchedule;
    self.decommissionRoom = decommissionRoom;
    self.deleteRoom = deleteRoom;
    self.openInPlanView = openInPlanView;
    self.showRelatedWorkOrders = showRelatedWorkOrders;
    self.onLocationChange = onLocationChange;
    self.updateLocation = updateLocation;
    self.getOverdueWorkOrdersLimit = getOverdueWorkOrdersLimit;
    self.getOpenWorkOrdersLimit = getOpenWorkOrdersLimit;
    self.openAttachment = openAttachment;

    // listen to external room changes
    $scope.$on("room-updated", function (event, args) {
      if (args.room) {
        self.room = angular.extend({}, self.room, args.room);
      }
    });

    init();

    function init() {
      if (organization.show_tasks) {
        // Populate lists
        getOverdueWorkOrdersLimit();
        getOpenWorkOrdersLimit();

        // Get total work order list counts
        getOverdueWorkOrdersCount();
        getOpenWorkOrdersCount();
      }
    }
    /**
     * This function overwrites the function used
     * to open the attachment on <abx-attachment-thumbnail> component
     */
    function openAttachment($event) {
      if (!$event || !$event.document || !self.room) {
        ToastService.showError("Document selected is not available");
      } else {
        const { document, documentOrder } = $event;
        FilePreviewDialog.show({
          pin: self.room,
          documentId: document._id,
          supportMultipleFiles: true,
          documentOrder,
        });
      }
    }

    function getDefaultPermissions() {
      return {
        canCreateRequest: organization.show_tasks && permissions.request.create,
        canCreateWorkOrder: organization.show_tasks && permissions.task.create,
        canCreateSchedule:
          organization.show_tasks && permissions.future_task.create,
        canUpdateLevel: permissions.room.update_level,
        canSetLocation: permissions.room.set_location,
        canDelete: permissions.room.remove,
        canDecommission: permissions.room.decommission,
        canAddAttachments: permissions.room.add_attachment,
      };
    }

    /**
     * Update the room's location fields (in this case, just its floor)
     */
    function updateLocation(buildingId, roomId, data) {
      self.floor = data.populatedFloor;
      var body = {
        value: data.level,
      };
      var params = {
        include_values: true,
      };
      return RoomService.updateFloor(buildingId, roomId, body, params);
    }

    function showRelatedWorkOrders() {
      if (!self.organization.show_tasks) return false;
      else if (self.room.is_decommissioned) return false;
      return true;
    }

    function onLocationChange($event) {
      if ($event && $event.model) {
        refreshActivity();
        $event.model.pinType = pinType;
        $event.model.level = self.floor;
        /**
         * This will make sure the top level room of all these room routes is
         * updated, so when navigating between all room routes, you get the latest room
         * without ever doing a full refresh. This needs to be done because we do not
         * refresh the resolved room on any of these asset routes
         */
        angular.copy($event.model, self.room);
        /**
         * Since the room is also one-way bound some some child components, we need
         * to trigger angular to perform a re-render of them room, we do so by doing
         * a es4 version of self.room = { ...self.room } to refresh the memory address
         * so angular knows to update all the child component's room
         */
        self.roomForOneWayBinds = angular.extend({}, $event.model);
      }
    }

    function createNote(noteText) {
      var data = {
        room: self.room._id,
        building: self.building._id,
        text: noteText,
      };

      NoteService.create(self.building._id, data)
        .then(function () {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    function createRequest() {
      var locals = {
        building: self.building,
        room: self.room,
      };
      CreateRequestDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createWorkOrder() {
      var locals = {
        building: self.building,
        rooms: [self.room],
      };
      CreateWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          var newWorkOrderRound = workOrders[0].round;

          for (var i = 0; i < newWorkOrderRound.length; i++) {
            if (
              newWorkOrderRound[i].room &&
              newWorkOrderRound[i].room._id === self.room._id
            ) {
              self.openWorkOrders.unshift(workOrders[0]);
            }
          }
        })
        .catch(ToastService.showError);
    }

    function createSchedule() {
      var locals = {
        building: self.building,
        rooms: [self.room],
      };
      CreateScheduleDialog.show({ locals: locals })
        .then(function () {
          getOpenWorkOrdersLimit();
        })
        .catch(ToastService.showError);
    }

    function decommissionRoom() {
      var locals = { room: self.room };
      DecommissionDialog.show({ locals: locals })
        .then(function (decommissionedRoom) {
          $state.go($state.current, {}, { reload: true });
        })
        .catch(ToastService.showError);
    }

    function deleteRoom() {
      var locals = {
        room: self.room,
      };
      DeleteDialog.show({ locals: locals })
        .then(function () {
          Router.go("app.rooms");
        })
        .catch(ToastService.showError);
    }

    function openInPlanView() {
      var url = $state.href("planView.building.floor", {
        buildingId: building._id,
        floorId: self.floor._id,
        room: self.room._id,
      });
      $window.open(url + "&mode=PLACEMENT", "_blank");
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function getOverdueWorkOrdersLimit() {
      var query = angular.extend({}, OVERDUE_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        query
      )
        .then(function (overdueWorkOrders) {
          self.overdueWorkOrders = overdueWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersLimit() {
      var query = angular.extend({}, OPEN_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        query
      )
        .then(function (openWorkOrders) {
          self.openWorkOrders = openWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOverdueWorkOrdersCount() {
      var query = angular.extend({}, OVERDUE_QUERY, { count: true });
      WorkOrderService.getByBuildingId(self.building._id, query)
        .then(function (response) {
          self.overdueWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersCount() {
      var query = angular.extend({}, OPEN_QUERY, { count: true });
      WorkOrderService.getByBuildingId(self.building._id, query)
        .then(function (response) {
          self.openWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }
  }
})();
