(function () {
  /**
   * @ngdoc component
   * @name abxMarkupCollaboratorLayerList
   *
   * @param {Function} reloadMarkups - Refetches the collaborator markups for this floor
   * @param {Boolean} isOpen - Show/Hide dropdown content
   *
   * @description
   * Container for a list of collaborator markups
   */
  angular
    .module("akitabox.planView")
    .component("abxMarkupCollaboratorLayerList", {
      controller: abxMarkupCollaboratorLayerListController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-collaborator-layer-list/markup-collaborator-layer-list.html",
    });

  /* @ngInject */
  function abxMarkupCollaboratorLayerListController(
    // Akitabox
    IdentityService,
    ShadowService,
    MarkupService,
    UserService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";

    var _permissions = UserService.getPermissions();
    self.currentUser = UserService.getCurrent();

    self.canDelete = _permissions.markup.remove_any;
    self.canAdminOnly = false;
    self.selectedLayer = MarkupService.getActiveMarkup;
    self.reloadMarkups = reloadMarkups;
    self.getMarkups;
    self.loading = false;
    self.$onInit = init;

    function init() {
      IdentityService.getCurrent().then(function (identity) {
        if (
          identity.site_permissions &&
          identity.site_permissions.is_super_admin
        ) {
          self.canAdminOnly = true;
        }

        self.getMarkups = function () {
          return initCollaboratorMarkups(self.currentUser);
        };
      });
    }

    function initCollaboratorMarkups(user) {
      return MarkupService.getActiveMarkups().filter(
        MarkupService.filterByCreAccount(user, true)
      );
    }

    function reloadMarkups() {
      self.loading = true;
      MarkupService.reloadActiveMarkups().finally(function () {
        self.loading = false;
      });

      ShadowService.sendEvent(GA_CATEGORY, "refresh-markups", null, null);
    }
  }
})();
