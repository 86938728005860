(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.dialogs.document.edtTagFilter
   */
  angular
    .module("akitabox.ui.dialogs.document.editTagFilter")
    .controller("EditTagFilterDialogController", EditTagFilterDialogController);

  /**
   * @ngdoc controller
   * @module akitabox.ui.dialogs.document.editTagFilter
   * @name EditTagFilterDialogController
   *
   * @description
   * Controller for the tag filter edit dialog
   *
   * @ngInject
   */
  function EditTagFilterDialogController(
    // Angular
    $mdDialog,
    $q,
    $scope,
    // Services
    AssetService,
    DocumentService,
    RoomService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.cancel = $mdDialog.cancel;
    self.documents = null;
    self.activeFilters = null;
    self.startingFilters = formatTagsToFilters(self.pinValue.value);
    self.tagFilters = self.pinValue.value;

    self.save = save;
    self.fetch = fetch;
    self.changeFilters = changeFilters;
    self.hasFilters = hasFilters;

    // =================
    // Public Methods
    // =================

    /**
     * Save the selected tag filters. Funtionality depends on the architecture
     * of the calling controller.
     *
     * @return {Promise<Object|Object[]>} - The resolved value of this function
     *     depends on `self.skipPinValueUpdate`.
     *   - If this instance of the dialog was told to be responsible for saving
     *       new tag filters, this function will resolve with the updated pin after
     *       the update.
     *   - If this instance of the dialog was told to not be responsible for
     *       for saving the new tag filters, this function will resolve with
     *       the newly selected tag filters, and the calling controller can use
     *       them to facilitate the save on its own.
     */
    function save() {
      if (self.skipPinValueUpdate) {
        $mdDialog.hide(self.tagFilters).catch(ToastService.showError);
      } else {
        var updatePromise;
        var data = {
          value: self.tagFilters,
        };

        if (self.isAsset) {
          updatePromise = AssetService.updateValue(
            self.building._id,
            self.pin._id,
            self.pinValue._id,
            data
          );
        } else if (self.isRoom) {
          updatePromise = RoomService.updateValue(
            self.building._id,
            self.pin._id,
            self.pinValue._id,
            data
          );
        }

        self.saving = true;
        updatePromise
          .then(function (updatedModel) {
            $mdDialog.hide(updatedModel);
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.saving = false;
          });
      }
    }

    function fetch(skip, limit) {
      // We don't want to query if they don't provide filters!!!!
      if (angular.isEmpty(self.activeFilters)) {
        return $q.resolve(0);
      }

      var params = angular.extend(
        {},
        {
          skip: skip,
          limit: limit,
          sort_by: "modified_date,desc",
        },
        self.activeFilters
      );

      return DocumentService.get(self.building._id, params).then(function (
        documents
      ) {
        if (angular.isArray(self.documents)) {
          self.documents = self.documents.concat(documents);
        } else {
          self.documents = documents;
        }

        return documents.length;
      });
    }

    function changeFilters(activeFilters) {
      var keys = Object.keys(activeFilters);

      self.activeFilters = activeFilters;
      self.tagFilters = [];

      for (var i = 0; i < keys.length; i++) {
        var tagCategory = self.filters[keys[i]];
        var tags = tagCategory.values();
        var value;

        for (var k = 0; k < tags.length; k++) {
          if (tags[k].value === activeFilters[keys[i]]) {
            value = tags[k];
            break;
          }
        }

        self.tagFilters.push({
          cat_name:
            tagCategory.key.substring(0, 5) === "tags_"
              ? tagCategory.key.slice(5)
              : tagCategory.key,
          cat_alias: tagCategory.name,
          value_name: value.value,
          value_alias: value.alias,
        });
      }

      self.documents = null;
      $scope.$broadcast("list:refresh");
    }

    function hasFilters() {
      return !angular.isEmpty(self.activeFilters);
    }

    function formatTagsToFilters(tags) {
      var formatted = {};

      for (var i = 0; i < tags.length; i++) {
        formatted["tags_" + tags[i].cat_name] = tags[i].value_name;
      }

      return formatted;
    }
  }
})();
