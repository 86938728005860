(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.complete")
    .controller(
      "CompleteWorkOrderDialogController",
      CompleteWorkOrderDialogController
    );

  /* @ngInject */
  function CompleteWorkOrderDialogController(
    // Angular
    $rootScope,
    $q,
    // Material
    $mdDialog,
    // Services
    AssemblyService,
    AssetConditionService,
    FeatureFlagService,
    IdentityService,
    OrganizationService,
    ShadowService,
    ToastService,
    UserService,
    WorkOrderService,
    WorkOrderLogService,
    // Third Party
    moment
  ) {
    var self = this;

    var _permissions = UserService.getPermissions();

    // Constants
    var GA_CATEGORY = "workorder";
    var GA_COMPLETE_ACTION = "complete-work-order";
    var GA_COMPLETE_PREVENTIVE_LABEL = "preventive";
    var GA_COMPLETE_REACTIVE_LABEL = "reactive";

    // Attributes
    self.activeTab = 0;
    self.saving = false;
    self.organization = OrganizationService.getCurrent();
    self.showLogForm = false;
    self.showConditionForm = false;
    self.logForm = {};
    self.conditionForm = {
      rating: null,
      date: null,
      invalid: true,
    };
    self.canAddAttachments = false;
    self.buildingId = null; // Only set when completing a single work order
    self.progress = 0;
    // have to set default false in order to run tests since workOrderIDs is passed in through locals
    self.multiple = self.workOrders ? self.workOrders.length > 1 : false;
    self.workOrderForm = {};
    self.attachments = [];
    self.completed = [];
    self.errors = [];
    self.today = new Date();
    self.allWorkOrdersContainLogs = true;
    self.loading = false;
    self.mostRecentLogs = {};
    self.asset = null;
    self.assembly = null;
    self.conditionError = null;
    self.showRecordConditionWarning =
      self.multiple && self.organization.show_rpi_fci;

    self.conditionTabLabel = "Record Assembly Condition";
    self.helpTextLabel = "assembly";
    // Functions
    self.close = close;
    self.next = next;
    self.complete = complete;
    self.allUploadsComplete = allUploadsComplete;
    self.handleFilesChange = handleFilesChange;
    self.onConditionRatingChange = onConditionRatingChange;
    self.onAssessmentDateChange = onAssessmentDateChange;
    self.logFormVisibilityToggle = logFormVisibilityToggle;
    self.isNextDisabled = isNextDisabled;
    self.isDisabled = isDisabled;

    init();

    function init() {
      self.loading = true;
      // default the date to today
      self.workOrderForm.completed_date = new Date();

      if (self.workOrders) {
        if (self.workOrders.length === 1) {
          self.canAddAttachments = _permissions.task.add_attachment;
          self.buildingId = getBuildingId(self.workOrders[0]);
        }
        $q.all(
          // get the most recent work order log for each work order
          // this will be used to populate the task completion fields if the user
          // completes the work order without logging any more work
          self.workOrders.map(function (workOrder) {
            var buildingId = workOrder.building._id || workOrder.building;
            return WorkOrderLogService.getByTaskId(buildingId, workOrder._id, {
              limit: 1,
            }).then(function (workOrderLogs) {
              if (workOrderLogs.length === 0) {
                // there is at least one work order with no logs, require user to add a log
                self.allWorkOrdersContainLogs = false;
                self.showLogForm = true;
              } else {
                var taskId = workOrderLogs[0].task;
                self.mostRecentLogs[taskId] = workOrderLogs[0];
              }
            });
          })
        )
          .then(function () {
            if (
              self.workOrders.length === 1 &&
              self.organization.show_rpi_fci
            ) {
              var workOrder = self.workOrders[0];
              var buildingId = workOrder.building._id || workOrder.building;
              var round = workOrder.round;
              if (round.length > 1) {
                self.averageCondition = true;
              }
              if (round && round.length) {
                self.asset = round[0].asset;
              } else {
                self.asset = workOrder.asset;
              }
              if (self.asset) {
                var assemblyQuery;
                assemblyQuery = {
                  associated_assets: self.asset._id || self.asset,
                  limit: 1,
                };

                return AssemblyService.getByBuilding(buildingId, assemblyQuery)
                  .then(function (assemblies) {
                    if (assemblies.length > 0) {
                      if (
                        assemblies[0].is_auto_condition &&
                        !self.averageCondition
                      ) {
                        self.assembly = null;
                      } else {
                        self.assembly = assemblies[0];
                        self.asset = null;
                      }
                    }
                  })
                  .catch(ToastService.showError);
              }
            }
          })
          .finally(function () {
            self.loading = false;
          });

        // If user selected only one work order, populate the form with those values
        if (self.workOrders.length === 1) {
          var workOrder = self.workOrders[0];
          self.minDate = new Date(
            moment.utc(workOrder.start_date).format("MMMM D, YYYY")
          );
        } else {
          // we want everything to be blank except for the minDate, which should be the latest start date to
          // avoid "completion date must be after start date errors"
          self.minDate = new Date(
            moment.utc(self.workOrders[0].start_date).format("MMMM D, YYYY")
          );
          for (var i = 1; i < self.workOrders.length; i++) {
            var startDate = self.workOrders[i].start_date;
            if (moment(startDate).isAfter(moment(self.minDate))) {
              self.minDate = new Date(
                moment.utc(startDate).format("MMMM D, YYYY")
              );
            }
          }
        }

        // pre-populate some log form data
        self.logForm.work_performed_by_user = UserService.getCurrent();
        self.logForm.work_performed_date = new Date();
      }
    }

    function logFormVisibilityToggle() {
      self.showLogForm = !self.showLogForm;
    }

    function getBuildingId(workOrder) {
      return Object.prototype.hasOwnProperty.call(workOrder.building, "_id")
        ? workOrder.building._id
        : workOrder.building;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function handleFilesChange(files) {
      self.attachments = files;
    }

    function allUploadsComplete() {
      return self.attachments.reduce(function (prev, current) {
        return prev && current.complete;
      }, true);
    }

    function close() {
      if (self.progress >= 100) {
        $mdDialog.hide(self.completed);
      } else {
        $mdDialog.cancel();
      }
    }

    function next() {
      self.activeTab++;
    }

    function onConditionRatingChange($event) {
      self.conditionForm.rating = $event.model;
    }

    function onAssessmentDateChange($event) {
      self.conditionForm.date = $event.model;
      self.conditionForm.invalid = $event.invalid;
    }

    /**
     * Complete work orders, log work, and save condition if for assembly
     */
    function complete() {
      self.saving = true;
      self.progress = 0;
      self.completed = [];
      self.errors = [];

      var increment = (1 / self.workOrders.length) * 100;
      var completeWorkOrderPromises = [];
      var loggingMoreWork = false;

      // log form is visible, so user is logging more work.
      // copy the log form data to self.workOrderForm to fill out required task fields
      if (self.showLogForm || !self.allWorkOrdersContainLogs) {
        loggingMoreWork = true;
      }

      var preventiveWorkOrders = 0;
      var reactiveWorkOrders = 0;

      if (self.attachments.length > 0) {
        self.workOrderForm.attachments = self.attachments.map(function (
          attachment
        ) {
          return attachment._document._id;
        });
      }

      // Complete the work orders and log work
      for (var i = 0; i < self.workOrders.length; i++) {
        var workOrder = self.workOrders[i];
        if (workOrder.intent === "preventive") {
          preventiveWorkOrders++;
        } else {
          reactiveWorkOrders++;
        }
        // if logging work, ensure log is created before attempting to complete WO
        var newWorkOrderForm = angular.copy(self.workOrderForm);
        if (loggingMoreWork) {
          var makeNextPromise = function (wo) {
            var buildingId = getBuildingId(wo);
            // create log then complete the work order
            return createWorkOrderLog(buildingId, wo._id, self.logForm)
              .then(function () {
                return WorkOrderService.complete(
                  buildingId,
                  wo._id,
                  newWorkOrderForm
                );
              })
              .then(function (completedWorkOrder) {
                self.completed.push(completedWorkOrder);
                return completedWorkOrder;
              })
              .catch(function (err) {
                handleError(err, wo);
                return null;
              })
              .finally(function () {
                self.progress = Math.ceil(self.progress + increment);
              });
          };

          completeWorkOrderPromises[i] = makeNextPromise(workOrder);
        } else {
          // not logging more work. get last WO log to populate required task fields
          // and build array of completion promises
          newWorkOrderForm = angular.copy(self.workOrderForm);

          var buildingId = getBuildingId(workOrder);
          completeWorkOrderPromises[i] = (function (index) {
            return WorkOrderService.complete(
              buildingId,
              workOrder._id,
              newWorkOrderForm
            )
              .then(function (completedWorkOrder) {
                self.completed.push(completedWorkOrder);
                return completedWorkOrder;
              })
              .catch(function (err) {
                handleError(err, self.workOrders[index]);
                return null;
              })
              .finally(function () {
                self.progress = Math.ceil(self.progress + increment);
              });
          })(i);
        }
      }

      if (preventiveWorkOrders) {
        ShadowService.sendEvent(
          GA_CATEGORY,
          GA_COMPLETE_ACTION,
          GA_COMPLETE_PREVENTIVE_LABEL,
          preventiveWorkOrders
        );
      }
      if (reactiveWorkOrders) {
        ShadowService.sendEvent(
          GA_CATEGORY,
          GA_COMPLETE_ACTION,
          GA_COMPLETE_REACTIVE_LABEL,
          reactiveWorkOrders
        );
      }

      // if not logging more work, resolve the wo completion promises
      // Assembly
      if (completeWorkOrderPromises.length > 0) {
        if (self.assembly && self.workOrders.length === 1) {
          var workOrderId = self.workOrders[0]._id;
          var assemblyBuildingId =
            self.assembly.building._id || self.assembly.building;
          completeWorkOrderPromises.push(
            AssemblyService.update(assemblyBuildingId, self.assembly._id, {
              action: "updateCondition",
              condition_rating: self.conditionForm.rating,
              date_recorded: self.conditionForm.date,
              task: workOrderId,
            }).catch(function (err) {
              self.conditionError =
                err.data && err.data.error
                  ? err.data.error.message
                  : err.statusText || "Unknown error occurred";
            })
          );
        }
        // Asset
        if (
          !self.assembly &&
          self.asset &&
          self.workOrders.length === 1 &&
          self.conditionForm.rating
        ) {
          var data = {
            condition_rating: self.conditionForm.rating,
            date_recorded: self.conditionForm.date,
          };
          completeWorkOrderPromises.push(
            AssetConditionService.create(
              self.asset.building,
              self.asset._id,
              data
            ).catch(function (err) {
              self.conditionError =
                err.data && err.data.error
                  ? err.data.error.message
                  : err.statusText || "Unknown error occurred";
            })
          );
        }
        // not logging more work, just complete the work orders
        $q.all(completeWorkOrderPromises)
          .then(function () {
            self.workOrders.forEach(function (workOrder) {
              $rootScope.$broadcast("attachments:update", {
                entityId: workOrder._id,
              });
            });
            if (self.errors.length === 0 && !self.conditionError) {
              $mdDialog.hide(self.completed);
            }
          })
          .catch(function (err) {
            ToastService.showError(err);
          })
          .finally(function () {
            self.saving = false;
          });
      }
    }

    function createWorkOrderLog(buildingId, taskId, logForm) {
      logForm.task = taskId;
      // get work_performed_by id if it is an object
      if (
        logForm.work_performed_by_user &&
        logForm.work_performed_by_user._id
      ) {
        logForm.work_performed_by_user = logForm.work_performed_by_user._id;
      }

      if (logForm.timeCodeSelected && logForm.timeCodeSelected._id) {
        logForm.time_code = logForm.timeCodeSelected._id;
      }

      var newLogForm = angular.copy(logForm);
      return WorkOrderLogService.create(buildingId, newLogForm);
    }

    function handleError(err, workOrder) {
      var message = "Unknown error occurred";
      if (err.data && err.data.error) {
        message = err.data.error.message;
      } else if (err.statusText) {
        message = err.statusText;
      }
      self.errors.push({
        task: workOrder,
        message: message,
      });
    }

    function isNextDisabled() {
      return self.showLogForm && !isLogFormComplete();
    }

    function isLogFormComplete() {
      return (
        self.logForm.work_performed_by_user &&
        self.logForm.work_performed_date &&
        self.logForm.work_minutes &&
        self.logForm.work_minutes > 0 &&
        (!self.logForm.cost || self.logForm.cost >= 0) &&
        self.logForm.actions_taken &&
        allUploadsComplete()
      );
    }

    function isConditionFormComplete() {
      return (
        self.conditionForm.rating &&
        self.conditionForm.date &&
        !self.conditionForm.invalid
      );
    }

    function isDisabled() {
      if (self.saving) {
        return true;
      }

      return (
        (self.showLogForm && !isLogFormComplete()) ||
        (self.assembly && !isConditionFormComplete()) ||
        (self.organization.require_time_codes && !self.logForm.timeCodeSelected)
      );
    }
  }
})();
