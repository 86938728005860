(function () {
  angular
    .module("akitabox.planView.dialogs.markup.create", [
      "ngMaterial",
      "akitabox.core.constants",
      "akitabox.core.router",
      "akitabox.core.services.markup",
    ])
    .controller("CreateMarkupDialogController", CreateMarkupDialogController);

  /* @ngInject */
  function CreateMarkupDialogController(
    // Third-party
    $stateParams,
    moment,
    // Material
    $mdDialog,
    // Constants
    models,
    // Services
    IdentityService,
    ShadowService,
    MarkupService,
    UserService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";
    var GA_ACTION = "save-new";

    // Attributes
    self.formData = {};
    self.saving = false;
    self.model = models.MARKUP.PLURAL;

    // Functions
    self.cancel = cancel;
    self.create = create;
    self.onChange = onChange;
    self.getMarkupCount = getMarkupCount;
    self.$onInit = function () {
      generateDefaultName();
    };

    function generateDefaultName() {
      // Default Name: <markup layers length + 1>-<user's name>
      IdentityService.getCurrent().then(function (identity) {
        const currentUser = UserService.getCurrent();
        // parse email to get name components
        var emailPieces =
          identity && identity.email ? identity.email.split("@") : null;
        var userNamePortion = emailPieces ? emailPieces[0] : "unknown";
        var markupCount = getMarkupCount(currentUser);
        self.defaultName = markupCount + 1 + "-" + userNamePortion;
        self.formData.name = self.defaultName;
      });
    }

    function getMarkupCount(user) {
      var markups = MarkupService.getActiveMarkups();
      return markups.filter(MarkupService.filterByCreAccount(user)).length;
    }

    function onChange($event, key) {
      self.formData[key] = $event.model;
    }

    function create() {
      self.saving = true;
      return MarkupService.createActiveMarkup(self.formData)
        .then(function () {
          $mdDialog.cancel();
        })
        .finally(function () {
          self.saving = false;
          if (self.formData.name === self.defaultName) {
            ShadowService.sendEvent(
              GA_CATEGORY,
              GA_ACTION,
              "standard-name",
              null
            );
          } else {
            ShadowService.sendEvent(
              GA_CATEGORY,
              GA_ACTION,
              "custom-name",
              null
            );
          }
        });
    }

    function cancel() {
      ShadowService.sendEvent(GA_CATEGORY, "cancel-save", null, null);
      $mdDialog.cancel();
    }
  }
})();
