import type { ICompileProvider } from "angular";

/**
 * This module is necessary in order to prevent an error in $location
 * which apparently relies on this $rootElement in order to be injected
 *
 * References:
 * https://stackoverflow.com/a/28400389
 * https://docs.angularjs.org/api/ng/service/$rootElement
 */
angular.module("mockApp", []).provider({
  $rootElement: function (this: any) {
    this.$get = function () {
      return angular.element("<div ng-app></div>");
    };
  },
});

// angular.element(document).ready(bootstrap);
export function bootstrap(): Promise<angular.auto.IInjectorService> {
  // Manually inject the services that we need
  const $injector = angular.injector([
    // Angular
    "ng",
    "ngCookies",
    // Fix $rootElement issue
    "mockApp",
    // Constants
    "akitabox.core.constants",
    // Services
    "akitabox.core.services.auth",
    "akitabox.core.services.env",
    "akitabox.core.services.flag",
    "akitabox.core.services.organization",
    // Material Theme
    "ngMaterial",
    "akitabox.ui.themes",
  ]);
  const $cookies = $injector.get<any>("$cookies");
  const $window = $injector.get<any>("$window");
  const AuthService = $injector.get<any>("AuthService");
  const EnvService = $injector.get<any>("EnvService");
  const FeatureFlagService: any = $injector.get<any>("FeatureFlagService");
  const OrganizationService = $injector.get<any>("OrganizationService");
  const SAML_LOGIN_REDIRECT = $injector.get<any>("SAML_LOGIN_REDIRECT");

  const handleRedirectOrBootstrap = () => {
    const redirectUrl = $cookies.get(SAML_LOGIN_REDIRECT);
    if (redirectUrl) {
      const domain =
        "." + EnvService.getBaseUrl().split("://")[1].split(":")[0];

      let url = decodeURI(redirectUrl.replace(/~2F/g, "/"));
      if (redirectUrl.indexOf("//") < 0) {
        url = EnvService.getBaseUrl(url);
      }

      $cookies.remove(SAML_LOGIN_REDIRECT, { path: "/", domain: domain });
      $window.location.href = url;
    } else {
      return bootstrapApplication();
    }
  };

  return FeatureFlagService.init()
    .then(AuthService.isAuthenticated)
    .then(function (authenticated: boolean) {
      if (!authenticated) return null;
      return OrganizationService.init();
    })
    .then(handleRedirectOrBootstrap, handleRedirectOrBootstrap);

  /**
   * Bootstrap the application
   */
  function bootstrapApplication() {
    /**
     * Configure root module for [common production settings](https://docs.angularjs.org/guide/production)
     *
     * This block is injected with $compileProvider.debugInfoEnabled(false) when gulp tasks are run
     * with --production flag.
     */
    angular.module("akitabox").config([
      "$compileProvider",
      function ($compileProvider: ICompileProvider) {
        if (process.env.NODE_ENV === "production") {
          $compileProvider.debugInfoEnabled(false);
        } else {
          $compileProvider.debugInfoEnabled(true);
        }
        /**
         * We're not using Comment and CSS Class directives - disabling for better performace.
         */
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
      },
    ]);

    return angular.bootstrap(document, ["akitabox"], {
      strictDi: true,
    });
  }
}
