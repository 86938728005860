(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.attachmentThumbnail
   */
  angular
    .module("akitabox.ui.directives.attachmentThumbnail", [
      "akitabox.core.constants",
      "akitabox.core.toast",
    ])

    .directive(
      "abxAttachmentThumbnail",
      function AbxAttachmentThumbnail(
        IdentityService,
        EnvService,
        FeatureFlagService,
        moment,
        models,
        $q,
        $timeout,
        $window
      ) {
        return {
          restrict: "E",
          templateUrl:
            "app/core/ui/directives/attachment-thumbnail/attachment-thumbnail.html",
          replace: true,
          scope: {
            upload: "=?",
            attachment: "=?",
            onRemove: "&",
            overwriteClick: "<?",
            onClick: "&",
            documentOrder: "<?",
            removable: "<",
          },
          link: function (scope, element) {
            if (
              (scope.upload && scope.attachment) ||
              !(scope.upload || scope.attachment)
            ) {
              throw new Error(
                "AbxAttachmentThumbnail requires upload xor attachment"
              );
            }

            if (scope.attachment) {
              scope.upload = {
                progress: 100,
                document: $q.resolve(scope.attachment.document),
              };
            }

            var vm = (scope.vm = {
              isMobile: EnvService.getBrowser().mobile,
              isPortal: EnvService.isPortalDomain(),
              upload: scope.upload,
              attachmentUploading: true,
              onRemove: function (event) {
                event.stopPropagation();
                scope.onRemove();
              },
              removable: scope.removable,
            });

            scope.upload.document.then(function (document) {
              vm.attachmentUploading = false;
              var thumbnailUrl;

              if (scope.upload.file) {
                thumbnailUrl = URL.createObjectURL(scope.upload.file);
              }

              //In this case, the click behavior must be
              //defined in the <abx-attachment-container> component
              if (scope.overwriteClick) {
                if (typeof scope.onClick === "function") {
                  vm.openAttachment = function () {
                    scope.onClick({
                      $event: {
                        document,
                        documentOrder: scope.documentOrder,
                      },
                    });
                  };
                } else {
                  if (!document) {
                    throw new Error(
                      "On attachment click function must be defined."
                    );
                  }
                }
              } else if (!vm.isPortal) {
                // Only add click functionality to attachment thumbnails when not inside of the portal
                IdentityService.getCurrent().then(
                  function (identity) {
                    if (identity) {
                      vm.openAttachment = function () {
                        var isMedia =
                          models.DOCUMENT.MEDIA_VIEWER_SUPPORTED_TYPES.includes(
                            document.extension.toLowerCase()
                          );

                        if (isMedia && !EnvService.getBrowser().mobile) {
                          $window.open(document.media_uri, "_blank");
                        } else {
                          $window.open(document.public_url, "_blank");
                        }
                      };
                    }
                  },
                  function () {
                    vm.openAttachment = function () {
                      $window.open(thumbnailUrl, "_blank");
                    };
                  }
                );
              }

              var thumbnailTypes = [".pdf"].concat(
                models.DOCUMENT.MEDIA_VIEWER_SUPPORTED_TYPES
              );
              if (scope.upload.file) {
                thumbnailTypes = thumbnailTypes.filter(function (extension) {
                  return !extension.startsWith(".tif");
                });
              }

              if (thumbnailTypes.includes(document.extension.toLowerCase())) {
                vm.thumbnailLoading = true;
                vm.thumbnailUrl = vm.isPortal
                  ? thumbnailUrl
                  : document.public_thumbnail_url_large;
                $timeout(function () {
                  vm.thumbnailLoading = false;
                }, 10000);
              } else {
                vm.name = document.name + document.extension;
              }

              if (document) {
                var datetime = moment(document.created_date);

                vm.date = datetime.format("MMM D, YYYY");
                vm.time = datetime.format("h:mmA");
                vm.fileName = document.path;
              }
            });
          },
        };
      }
    )
    .directive("onThumbnailLoad", function () {
      return {
        restrict: "A",
        scope: {
          onLoad: "&onThumbnailLoad",
        },
        link: function (scope, element) {
          element.bind("load", function () {
            scope.$apply(function () {
              scope.onLoad();
            });
          });
        },
      };
    });
})();
