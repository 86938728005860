(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedAssigneesFilter",
      function ($q, ManagedModelFieldFilter, OrganizationService, UserService) {
        /**
         * @param {object} options
         * @param {() => string} options.getBuildingId - A function that should return the
         *  building ID to use for fetching enum options/chip text.
         * @param {() => object} options.getEnumOptions - A function that should return
         *  the a list of assignable identities.
         * @param {() => object} [options.modelValueToChipText] - Model Value To chip text override
         * @param {() => object} [options.filterValueToModelValue] - Filter value to model value override
         * @param {() => object} [options.modelValueToFilterValue] - Model value to Filter value override
         */
        function ManagedAssigneesFilter(manager, options) {
          if (!options) {
            throw new Error("ManagedAssigneesFilter: Options must be provided");
          }

          if (
            typeof options.getBuildingId !== "function" &&
            typeof options.filterValueToModelValue !== "function"
          ) {
            throw new Error(
              "ManagedAssigneesFilter: Invalid options, getBuildingId must be a function."
            );
          }

          if (typeof options.getEnumOptions !== "function") {
            throw new Error(
              "ManagedAssigneesFilter: Invalid options, getEnumOptions must be a function."
            );
          }

          this._getEnumOptions = options.getEnumOptions;

          // Overides

          if (options.getBuildingId) {
            this.getBuildingId = options.getBuildingId;
          }

          if (options.modelValueToChipText) {
            this.modelValueToChipText = options.modelValueToChipText;
          }

          if (options.filterValueToModelValue) {
            this.filterValueToModelValue = options.filterValueToModelValue;
          }

          if (options.modelValueToFilterValue) {
            this.modelValueToFilterValue = options.modelValueToFilterValue;
          }

          this.optionsGroupKey = "secondaryOptionName";

          var filterConfigurationOptions = {
            displayName: "Assignee",
            queryField: options.queryField || "assignee_users",
            inputType: "typeahead",
            isMultiChip: true,
          };

          ManagedModelFieldFilter.call(
            this,
            manager,
            filterConfigurationOptions
          );
        }

        // ManagedAssigneesFilter extends ManagedModelFieldFilter
        ManagedAssigneesFilter.prototype = Object.create(
          ManagedModelFieldFilter.prototype
        );

        /**
         * Fetch enum options and decorate them with grouping fields.
         */
        ManagedAssigneesFilter.prototype.getEnumOptions =
          function getEnumOptions() {
            return this._getEnumOptions().then(function (enumOptions) {
              for (var i = 0; i < enumOptions.length; i++) {
                var option = enumOptions[i];
                var user = option.model;
                option.secondaryOptionName = user.is_deactivated
                  ? "Inactive"
                  : "Active";
                option.secondaryOption = user.is_deactivated;
              }

              /**
               * Make sure the active users appear first on the list
               */
              return enumOptions.sort(function (a, b) {
                if (a.model.is_deactivated && !b.model.is_deactivated) {
                  return 1;
                } else if (!a.model.is_deactivated && b.model.is_deactivated) {
                  return -1;
                } else {
                  return 0;
                }
              });
            });
          };
        ManagedAssigneesFilter.prototype.constructor = ManagedAssigneesFilter;

        /**
         * Provide models for a given filter value string
         * @param {string} userIds
         * @return { Promise<User> }
         */
        ManagedAssigneesFilter.prototype.filterValueToModelValue = function (
          userIds
        ) {
          var UNASSIGNED_USER = {
            _id: "null",
            email: "Unassigned",
            display_name: "Unassigned",
          };
          var organization = OrganizationService.getCurrent();
          var userPromises = [];
          var userIdList = userIds.split(",").slice(1);
          if (!userIdList.length) {
            return [UNASSIGNED_USER];
          }
          for (var i = 0; i < userIdList.length; ++i) {
            var userId = userIdList[i];
            if (userId === "null") {
              userPromises.push($q.resolve(UNASSIGNED_USER));
            } else {
              userPromises.push(UserService.getById(organization._id, userId));
            }
          }

          return $q.all(userPromises);
        };

        /**@
         * @param {object={}} params - What to pass into the mongo query as the filters
         *
         * Fetch enum options for this filter.
         * @return {Promise<[]>} - An array of enum options, one for each room.
         *
         * @param {object} params
         * @param {boolean} [params.noLimit]
         */

        ManagedAssigneesFilter.prototype.applyFilter = function (
          filterValue,
          inputModel
        ) {
          var filterConfig = this;
          var existingFilterValue = this.getFilterValue(filterConfig.manager);

          if (!existingFilterValue) {
            filterValue = "$in," + filterValue;
          } else {
            var parts = existingFilterValue.split(",");
            parts.push(filterValue);
            filterValue = parts.join(",");
          }

          ManagedModelFieldFilter.prototype.applyFilter.call(
            filterConfig,
            filterValue,
            inputModel
          );
        };

        ManagedAssigneesFilter.prototype.removeFilter = function (inputModel) {
          var filterConfig = this;
          var valueToRemove = filterConfig.modelValueToFilterValue(inputModel);
          var newFilterValue = filterConfig
            .getFilterValue(filterConfig.manager)
            .split(",")
            .filter(function (filterValue) {
              return filterValue !== valueToRemove;
            })
            .join(",");

          if (newFilterValue === "$in") {
            // nothing left, remove entirely
            filterConfig.manager.removeModelFieldFilter(
              filterConfig.getQueryField(inputModel)
            );
            return;
          }

          // update and remove the desired filter portion
          ManagedModelFieldFilter.prototype.applyFilter.call(
            filterConfig,
            newFilterValue,
            inputModel
          );
        };

        ManagedAssigneesFilter.prototype.modelValueToFilterValue = function (
          user
        ) {
          return user._id || user;
        };

        ManagedAssigneesFilter.prototype.modelValueToChipText = function (
          user
        ) {
          return user.display_name;
        };

        return ManagedAssigneesFilter;
      }
    );
})();
