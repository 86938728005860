(function () {
  angular
    .module("akitabox.desktop.request.detail")
    .controller("RequestOverviewTabController", RequestOverviewTabController);

  /** @ngInject */
  function RequestOverviewTabController(
    // Angular
    $scope,
    $state,
    $window,
    // Resolves
    building,
    request,
    // Services
    DocumentService,
    NoteService,
    Router,
    WorkOrderService,
    OrganizationService,
    ToastService,
    UserService,
    // Utils
    Utils
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.organization = OrganizationService.getCurrent();
    self.request = request;
    self.activityParams = {
      request: self.request._id,
    };
    self.newNote = null;
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Service Request",
      time: self.request.created_date,
      account_display: self.request.requester_email,
    };
    self.workOrderState = null;
    self.populatedWorkOrderLoaded = false;
    self.assigneesLimit = 10;
    self.floorDocument = null;
    self.floorLink = null;
    self.roomLink = null;
    self.assetLink = null;
    self.canViewWorkOrder = getCanViewWorkOrder();

    // Functions
    self.createNote = createNote;
    self.formatPhoneNumber = Utils.formatPhoneNumber;
    self.goToBuilding = goToBuilding;
    self.showAllAssignees = showAllAssignees;

    self.userMap = {};

    init();

    function init() {
      if (self.request.task) {
        getWorkOrderState();
        getPopulatedWorkOrder();
        fetchUserMap(self.request.task.assignee_users);
      }
      getFloorDocument();

      // Set up location links
      var stateParams = {
        accountId: self.building.cre_account,
        buildingId: self.building._id,
      };
      if (self.request.level) {
        var floorParams = angular.extend({}, stateParams, {
          floorId: self.request.level._id,
        });
        self.floorLink = Router.href("app.floor", floorParams);
      }
      if (self.request.room) {
        var roomParams = angular.extend({}, stateParams, {
          roomId: self.request.room._id,
        });
        self.roomLink = Router.href("app.room", roomParams);
      }
      if (self.request.asset) {
        var assetParams = angular.extend({}, stateParams, {
          assetId: self.request.asset._id,
        });
        self.assetLink = Router.href("app.asset", assetParams);
      }
    }

    function getCanViewWorkOrder() {
      var user = UserService.getCurrent();
      var permissions = UserService.getPermissions();
      var canViewWorkOrder = false;
      if (permissions.task.read_all) {
        canViewWorkOrder = true;
      } else if (self.request.task) {
        var assignees = self.request.task.assignee_users;
        canViewWorkOrder = assignees.indexOf(user._id) > -1;
      }
      return canViewWorkOrder;
    }

    function getWorkOrderState() {
      self.workOrderState = {
        name: "app.workOrder",
        params: {
          workOrderId: self.request.task._id,
        },
      };
    }

    function createNote(noteText, sendToRequester) {
      var data = {
        request: self.request._id,
        building: self.building._id,
        text: noteText,
        is_public: sendToRequester,
      };

      if (sendToRequester) {
        data.emailRequester = true;
      }
      if (self.request.task) {
        data.task = self.request.task._id || self.request.task;
      }

      NoteService.create(self.building._id, data)
        .then(function (note) {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    function getFloorDocument() {
      if (!self.request.level || !self.request.level.document) {
        self.floorDocument = null;
        return;
      }

      DocumentService.getById(
        self.building._id,
        self.request.level.document
      ).then(function (document) {
        self.floorDocument = document;
      });
    }

    function getPopulatedWorkOrder() {
      WorkOrderService.getById(
        self.building._id,
        self.request.task._id || self.request.task
      )
        .then(function (task) {
          self.request.task = task;
          self.populatedWorkOrderLoaded = true;
        })
        .catch(ToastService.showError);
    }

    function fetchUserMap(assigneeUserIds) {
      if (!assigneeUserIds || !assigneeUserIds.length) {
        return;
      }
      UserService.get(self.organization._id, {
        _id: `$in,${assigneeUserIds.join(",")}`,
      }).then(function (users) {
        for (var i = 0; i < users.length; i++) {
          var user = users[i];
          self.userMap[user._id] = user;
        }
      });
    }

    function showAllAssignees() {
      self.assigneesLimit = null;
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function goToBuilding() {
      var url = $state.href("app.building.detail", {
        buildingId: building._id,
      });
      $window.open(url, "_self");
    }
  }
})();
