(function () {
  angular
    .module("akitabox.desktop.request.list")
    .controller("RequestListController", RequestListController);

  /* @ngInject */
  function RequestListController(
    // Angular
    $location,
    $scope,
    $timeout,
    $q,
    // Constants
    EVENT_REQUEST_CREATE,
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_OPEN_NEW,
    SERVICE_REQUEST_STATUS_DENIED,
    SERVICE_REQUEST_STATUS_COMPLETED,
    // Services
    IssueTypeService,
    OrganizationService,
    RequestService,
    ToastService,
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedAssetFilter,
    ManagedRoomFilter,
    ManagedLevelFilter,
    ManagedFilterHelpers,
    // Dialogs
    CreateRequestDialog,
    ExportDialog,
    // Resolve
    status,
    building
  ) {
    var self = this;
    var roomConfig;
    var levelConfig;
    var assetConfig;
    var issueTypeConfig;
    var subjectConfig;
    var idConfig;
    var descriptionConfig;
    var requesterEmailConfig;
    var completionDateConfig;
    var submittedDateConfig;
    var deniedDateConfig;
    var customFieldConfig;

    // Attributes
    self.requests = null;
    self.status = status;
    self.organization = OrganizationService.getCurrent();
    self.building = building;
    self.is_open = getIsOpen();
    self.subtitle = getSubtitle();
    self.fabActions = getFabActions();
    self.activeFilters = null;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the fixed ?status=... query
        query.status = status;
        // update the list
        changeFilters(query);
      },
    });

    subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    var assetRoomLevelBaseOptions = {
      getBuildingId: function () {
        return self.building._id;
      },
      getOrganization: OrganizationService.getCurrent,
    };

    var roomOptions = angular.extend({}, assetRoomLevelBaseOptions, {
      allowTextValue: true,
      textValueQueryField: "roomNameOrNumber",
    });

    levelConfig = new ManagedLevelFilter(
      self.filterBarManager,
      assetRoomLevelBaseOptions
    );
    roomConfig = new ManagedRoomFilter(self.filterBarManager, roomOptions);
    assetConfig = new ManagedAssetFilter(
      self.filterBarManager,
      assetRoomLevelBaseOptions
    );

    ManagedFilterHelpers.associateAssetRoomLevelFilters({
      level: levelConfig,
      room: roomConfig,
      asset: assetConfig,
    });

    idConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      inputType: "number",
      prefix: "SR-",
      modelValueToChipText: function (inputValue) {
        return "SR-" + inputValue;
      },
    });

    descriptionConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Description",
      queryField: "description",
    });

    issueTypeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Issue Type",
      queryField: "issue_type",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchIssueTypes().then(
          ManagedFilterHelpers.mapModelsToEnumOption
        );
      },
      modelValueToFilterValue: ManagedFilterHelpers.modelToId,
      modelValueToChipText: ManagedFilterHelpers.modelToName,
      filterValueToModelValue: function (issueTypeId) {
        return IssueTypeService.get(self.building._id, {
          _id: issueTypeId,
        }).then(function (issueType) {
          return [issueType];
        });
      },
      onAdd: function () {
        if (!self.building.show_issue_types) {
          return $q.reject();
        }
      },
    });

    requesterEmailConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Requester Email",
      queryField: "requester_email",
    });

    completionDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Completion Date",
      queryField: "completed_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    submittedDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Date Submitted",
      queryField: "created_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    deniedDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Date Denied",
      queryField: "denied_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(descriptionConfig);
    self.filterBarManager.addFilterConfiguration(issueTypeConfig);
    self.filterBarManager.addFilterConfiguration(levelConfig);
    self.filterBarManager.addFilterConfiguration(roomConfig);
    self.filterBarManager.addFilterConfiguration(assetConfig);
    self.filterBarManager.addFilterConfiguration(requesterEmailConfig);
    self.filterBarManager.addFilterConfiguration(submittedDateConfig);
    self.filterBarManager.addFilterConfiguration(completionDateConfig);
    self.filterBarManager.addFilterConfiguration(deniedDateConfig);

    if (self.organization.show_custom_srp_field) {
      var customLabel = self.organization.srp_custom_label;
      var customOptions = self.organization.srp_custom_options;
      if (customOptions && customOptions.length) {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
          inputType: "typeahead",
          getEnumOptions: function () {
            return customOptions;
          },
          modelValueToFilterValue: function (option) {
            return option;
          },
          modelValueToChipText: function (option) {
            return option;
          },
          filterValueToModelValue: function (queryStringValue) {
            return [queryStringValue];
          },
        });
      } else {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
        });
      }
      self.filterBarManager.addFilterConfiguration(customFieldConfig);
    }

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.addRequest = addRequest;
    self.fetchRequests = fetchRequests;
    self.fetchAllRequests = fetchAllRequests;
    self.changeFilters = changeFilters;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_REQUEST_CREATE, onRequestCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getSubtitle() {
      var prefix = null;
      var name = "Service Requests";
      switch (status) {
        case SERVICE_REQUEST_STATUS_NEW:
          prefix = "New";
          break;
        case SERVICE_REQUEST_STATUS_OPEN:
          prefix = "Open";
          break;
        case SERVICE_REQUEST_STATUS_DENIED:
          prefix = "Denied";
          break;
        case SERVICE_REQUEST_STATUS_COMPLETED:
          prefix = "Completed";
          break;
        default:
      }
      return {
        name: prefix ? prefix + " " + name : name,
      };
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportRequests,
        },
      ];
    }

    function fetchIssueTypes(params) {
      return IssueTypeService.getAll(building._id, params).catch(
        ToastService.showError
      );
    }

    function exportRequests() {
      var filters = angular.extend({}, { status: status }, self.activeFilters);
      var locals = {
        route: RequestService.buildListRoute(building._id),
        filters: filters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    function getIsOpen() {
      var openStatuses = [
        SERVICE_REQUEST_STATUS_OPEN_NEW,
        SERVICE_REQUEST_STATUS_NEW,
        SERVICE_REQUEST_STATUS_OPEN,
      ];
      return openStatuses.indexOf(self.status) > -1;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function addRequest() {
      var locals = { building: self.building };
      CreateRequestDialog.show({ locals: locals })
        .then(function (requests) {
          // We know we're only getting one back, so take the first item in the returned array
          var newRequest = requests[0];

          // Don't add to the list if the statuses don't agree
          if (!self.status.match(newRequest.status)) return;

          if (angular.isArray(self.requests)) {
            self.requests.unshift(newRequest);
          } else {
            self.requests = [newRequest];
          }
          $timeout(function () {
            $scope.$broadcast("list:updateSelectedIndices", requests.length);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function fetchRequests(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RequestService.get(building._id, filters).then(function (
          requests
        ) {
          if (angular.isArray(self.requests)) {
            self.requests = self.requests.concat(requests);
          } else {
            self.requests = requests;
          }
          return requests.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllRequests(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RequestService.getAll(building._id, filters).then(function (
          requests
        ) {
          self.requests = requests;
          return requests.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.requests = null;
      $scope.$broadcast("list:refresh");
    }

    /**
     * Handle request creation event
     *
     * @param {Event}     $event    Angular event
     * @param {Request[]} requests  List of new requests
     */
    function onRequestCreate($event, requests) {
      // We can only add one request at a time
      var request = requests[0];
      if (request.building === self.building._id) {
        if (!self.requests) {
          self.requests = [];
        }
        self.requests.unshift(request);
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      }
    }
  }
})();
