(function () {
  angular
    .module("akitabox.ui.dialogs.inspectionProgram.create")
    .controller(
      "CreateInspectionProgramDialogController",
      CreateInspectionProgramDialogController
    );

  /** @ngInject */
  function CreateInspectionProgramDialogController(
    // Angular
    $timeout,
    $q,
    // Material
    $mdDialog,
    // Libraries
    moment,
    // Services
    InspectionProgramService,
    OrganizationService,
    Router,
    TimeZoneService,
    ToastService,
    FeatureFlagService,
    UserService,
    RoundTemplateService,
    PermissionGroupsService,
    // Classes
    InspectionStopAssociationMap,
    // Constants
    PRIORITIES
  ) {
    var self = this;

    // Dialog State
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.organization = OrganizationService.getCurrent();
    self.editMode = angular.isDefined(self.editMode) ? self.editMode : false;
    self.activeTab = 0;
    self.saving = false;
    self.intervalOptions = getIntervalOptions();
    self.users = [];
    self.loadingUsers = true;
    /**
     * @type { InspectionStopAssociationMap }
     */
    self.associationMap = new InspectionStopAssociationMap();
    // need to set this to the start of the day because selecting dates in the abx-date-picker sends back start-of-day
    // dates, so to correctly calculate the difference (days_until_due), we must use start of day
    var nowMoment = moment();
    if (self.utcOffset) {
      nowMoment.utcOffset(self.utcOffset);
    }
    var startMoment = nowMoment.clone().startOf("day");
    self.today = startMoment.toDate();
    self.tomorrow = startMoment.clone().add(1, "days").toDate();
    self.title = getTitle();
    self.stillLoadingComponentSection = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.update = update;
    self.handleAddComponentClick = handleAddComponentClick;
    self.handleRemoveComponentClick = handleRemoveComponentClick;
    self.handleToggleComponentClick = handleToggleComponentClick;
    self.handleComponentChange = handleComponentChange;
    self.isDialogValid = isDialogValid;
    self.areComponentsValid = areComponentsValid;
    self.areDetailsValid = areDetailsValid;
    self.isScheduleValid = isScheduleValid;
    self.next = next;
    self.previous = previous;
    self.selectTab = selectTab;
    self.handleChecklistTemplateChange = handleChecklistTemplateChange;
    // Schedule related functions
    self.onChangeSchedule = onChangeSchedule;
    self.onDurationBlur = onDurationBlur;
    self.onSelectStartDate = onSelectStartDate;
    self.onSelectWeekday = onSelectWeekday;
    self.onComponentSectionLoadingComplete = onComponentSectionLoadingComplete;

    init();

    /**
     * Initialize the dialog, build default summary text, fetch floors
     */
    function init() {
      /**
       * Array of inspection components, augmented with:
       * @property { boolean } __collapsed
       * @property { boolean } useOverrides
       */
      self.components = [{}];
      /** Mainly use to compare with self.components to see which the user deleted */
      self.originalComponents = {};
      fetchUsers();

      if (self.editMode) {
        fetchInspectionStopChecklistAssociations().then(function () {
          // CDTODO: Move below logic in here, add loading state
        });
        self.components = self.inspectionProgram.inspection_components.map(
          function (component) {
            var buildingId = component.building._id || component.building;
            self.originalComponents[buildingId] = buildingId;
            component = angular.copy(component);
            // CDTODO: Implement this properly
            component.useOverrides = true;
            /** Default all components to be collapsed/hidden */
            component.__collapsed = true;
            return component;
          }
        );
        self.subject = self.inspectionProgram.name;
        self.priority = self.inspectionProgram.priority;
        self.checklistTemplate = self.inspectionProgram.checklist_template;
        self.scheduleData = {
          period: self.inspectionProgram.period,
          interval: self.inspectionProgram.interval,
          days_until_due: self.inspectionProgram.days_until_due,
          is_weekly_sunday: self.inspectionProgram.is_weekly_sunday,
          is_weekly_monday: self.inspectionProgram.is_weekly_monday,
          is_weekly_tuesday: self.inspectionProgram.is_weekly_tuesday,
          is_weekly_wednesday: self.inspectionProgram.is_weekly_wednesday,
          is_weekly_thursday: self.inspectionProgram.is_weekly_thursday,
          is_weekly_friday: self.inspectionProgram.is_weekly_friday,
          is_weekly_saturday: self.inspectionProgram.is_weekly_saturday,
        };

        self.enabledDays = [
          self.scheduleData.is_weekly_sunday,
          self.scheduleData.is_weekly_monday,
          self.scheduleData.is_weekly_tuesday,
          self.scheduleData.is_weekly_wednesday,
          self.scheduleData.is_weekly_thursday,
          self.scheduleData.is_weekly_friday,
          self.scheduleData.is_weekly_saturday,
        ];

        self.scheduleInvalid = false;

        self.startDate = new Date(self.inspectionProgram.start_date);
        self.dueDate = moment(self.startDate)
          .add(self.inspectionProgram.days_until_due, "days")
          .toDate();
        self.description = self.inspectionProgram.description;
      } else {
        // IP Details
        self.subject = null;
        self.priority = PRIORITIES.medium.toLowerCase();
        self.checklistTemplate = null;
        self.description = null;

        // IP Schedule
        self.scheduleData = {
          period: 1,
          interval: "days",
          days_until_due: 1,
        };
        self.scheduleInvalid = false;
        self.enabledDays = [];
        self.startDate = angular.copy(self.today);
        self.dueDate = angular.copy(self.tomorrow);
        self.minDueDate = angular.copy(self.today);

        resetWeekdays();
      }

      // Generate initial summary text
      buildSummary();
    }

    /**
     * Navigate to the next tab
     */
    function next() {
      self.activeTab++;
    }

    /**
     * Navigate to the previous tab
     */
    function previous() {
      self.activeTab--;
    }

    /**
     * Select a tab
     *
     * @param  {Number} tab     Tab index
     */
    function selectTab(tab) {
      self.activeTab = tab;
    }

    function handleAddComponentClick() {
      self.components.push({
        __dirty: true,
      });
    }

    function handleRemoveComponentClick(component) {
      if (self.components.length > 1) {
        var indexToRemove = self.components.indexOf(component);
        self.components.splice(indexToRemove, 1);
      } else {
        self.components = [];
      }
    }

    function handleToggleComponentClick(component) {
      component.__collapsed = !component.__collapsed;
    }

    /**
     * Change our flag value of knowing when the section is completely done performing
     * its various asynchronous tasks before a user can interact with it
     */
    function onComponentSectionLoadingComplete(loadingComplete) {
      self.stillLoadingComponentSection = !loadingComplete;
    }

    function handleComponentChange(index, component) {
      /**
       * If the rounds on this component changes, we need to make sure to clear all
       * overrides because they would no longer make sense
       */
      var currentComponent = self.components[index];
      component.__dirty = true;
      if (
        (!currentComponent.round_template && !component.round_template) || // reset, no round template ever selected
        (!currentComponent.round_template && component.round_template) || // reset, going from not having to having
        (currentComponent.round_template && !component.round_template) || // reset, going from having to not having
        currentComponent.round_template._id !== component.round_template._id // reset, they dont match
      ) {
        component.overrideLists = [];
      }

      /**
       * the component.stop_checklist_template_overrides does not
       * get updated anymor eit seems, and we use this prop to feed
       * the override checklist comp on initial load, so we need to keep that
       * in sync with the component.overrideList prop here
       */
      if (Array.isArray(component.overrideLists)) {
        // component.stop_checklist_template_overrides = getOverridesArray(
        //   component.overrideLists
        // );
      }

      self.components[index] = component;
    }

    function handleChecklistTemplateChange($event) {
      self.checklistTemplate = $event.model;
    }

    function isDialogValid() {
      return (
        self.areDetailsValid() &&
        self.areComponentsValid() &&
        self.isScheduleValid()
      );
    }

    /**
     * Determine if the inspection components provided for this IP are valid
     *
     * @return {Boolean}  True if valid, false if not
     */
    function areComponentsValid() {
      if (!self.components.length) {
        return false;
      }
      for (var i = 0; i < self.components.length; i += 1) {
        var component = self.components[i];
        if (!component || !component.building || !component.round_template) {
          return false;
        }
        if (component.useOverrides && component.overrideLists) {
          for (var j = 0; j < component.overrideLists.length; j++) {
            var overrideList = component.overrideLists[j];
            // each override list with stops must have a checklist template
            // selected
            if (!overrideList.checklistTemplate && overrideList.stops.length) {
              return false;
            }
          }
        }
      }
      return true;
    }

    /**
     * Determine if the details provided for this IP are valid
     *
     * @return {Boolean}  True if valid, false if not
     */
    function areDetailsValid() {
      return !!self.subject && !!self.priority && !!self.checklistTemplate;
    }

    /**
     * Determine if the schedule realted data for this IP is valid
     *
     * @return {Boolean}  True if valid, false if not
     */
    function isScheduleValid() {
      var startDateIsValid = self.startDate >= self.today;
      if (self.editMode) {
        startDateIsValid =
          startDateIsValid ||
          moment(self.startDate).isSame(self.inspectionProgram.start_date);
      }

      return Boolean(
        !self.scheduleInvalid &&
          self.scheduleData.days_until_due >= 0 &&
          self.scheduleData.period >= 1 &&
          Object.keys(self.intervalOptions).indexOf(
            self.scheduleData.interval
          ) >= 0 &&
          self.startDate &&
          self.dueDate &&
          self.startDate <= self.dueDate &&
          startDateIsValid
      );
    }

    /**
     * Handle schedule field change
     *
     * @param {Event}   $event  Change event
     * @param {String}  field   Name of field that changed
     */
    function onChangeSchedule($event, field) {
      switch (field) {
        case "period":
          if ($event && !$event.invalid) {
            buildSummary($event.model);
          }
          break;
        case "duration":
          if ($event && !$event.invalid) {
            onDurationChange($event.model);
          }
          break;
        case "interval":
          if ($event && !$event.invalid) {
            onIntervalChange($event.model);
          }
          break;
        default:
          self.scheduleData[field] = $event.model;
      }
    }

    /**
     * Handle duration (days until due) change and update due date accordingly
     *
     * @param  {Number} duration    Selected duration
     */
    function onDurationChange(duration) {
      self.scheduleData.days_until_due = duration;
      var start = moment(self.startDate);
      self.dueDate = start.add(duration, "days").toDate();
    }

    /**
     *
     * @param {Object} $event Event object passed back up from input component on blur
     * @param {Number} $event.newValue The new value of the input
     * @param {Boolean} $event.invalid True if the value is invalid (i.e. negative)
     */
    function onDurationBlur($event) {
      self.scheduleInvalid = $event.invalid;
    }

    /**
     * Handle schedule interval change
     *
     * @param  {String} interval    Selected interval
     */
    function onIntervalChange(interval) {
      if (interval) {
        for (var k in self.intervalOptions) {
          if (self.intervalOptions[k] === interval) {
            interval = k;
            break;
          }
        }
      }

      self.scheduleData.interval = interval;

      var showWeekdays = interval === "weeks";
      if (showWeekdays) {
        var weekday = self.today.getDay();
        // Disable all days
        disableAllDays();
        // Enable today
        self.onSelectWeekday(weekday, true);
        switch (weekday) {
          case 0:
            self.scheduleData.is_weekly_sunday = true;
            break;
          case 1:
            self.scheduleData.is_weekly_monday = true;
            break;
          case 2:
            self.scheduleData.is_weekly_tuesday = true;
            break;
          case 3:
            self.scheduleData.is_weekly_wednesday = true;
            break;
          case 4:
            self.scheduleData.is_weekly_thursday = true;
            break;
          case 5:
            self.scheduleData.is_weekly_friday = true;
            break;
          case 6:
            self.scheduleData.is_weekly_saturday = true;
            break;
        }
      } else {
        resetWeekdays();
      }
      buildSummary();
    }

    /**
     * Handle start date selection, update due date
     */
    function onSelectStartDate($event) {
      if ($event) {
        self.startDate = $event.model;
      }
      self.minDueDate = self.startDate;

      var start = moment(self.startDate);
      var end = start.add(self.scheduleData.days_until_due, "days");
      self.dueDate = end.toDate();

      buildSummary();
    }

    /**
     * Handle weekday checkbox selection
     *
     * @param  {Number}     day         Selected day of the week
     * @param  {Boolean}    checked     If checkbox is checked
     */
    function onSelectWeekday(day, checked) {
      self.enabledDays[day] = checked;
      // Determine the next valid start date based on selected weekdays
      self.scheduleInvalid = true;
      var todayEnabled = self.enabledDays[self.today.getDay()];
      if (todayEnabled) {
        // Set start date to today
        self.startDate = angular.copy(self.today);
        self.scheduleInvalid = false;
      } else {
        // Find the next valid day for start date
        var futureDate = moment().startOf("day");
        for (var i = 1; i <= 7; ++i) {
          futureDate.add(1, "days");
          if (self.enabledDays[futureDate.weekday()]) {
            self.startDate = futureDate.toDate();
            self.scheduleInvalid = false;
            break;
          }
        }
      }
      onDurationChange(self.scheduleData.days_until_due);
      // Update summary
      buildSummary();
    }

    /**
     * Get scheduling interval options
     *
     * @return {Object} Map of interval options
     */
    function getIntervalOptions() {
      return {
        days: "Days",
        weeks: "Weeks",
        months: "Months",
        years: "Years",
      };
    }

    /**
     * Build summary text
     */
    function buildSummary(period) {
      if (period) {
        self.scheduleData.period = period;
      }
      $timeout(function () {
        self.freqSummary = getFrequencySummary();
      });
    }

    /**
     * Get the frequency summary based on the selected scheduling options
     *
     * @return {String} Summary text
     */
    function getFrequencySummary() {
      if (
        self.scheduleInvalid ||
        !self.startDate ||
        !self.scheduleData.period
      ) {
        return "Unavailable with selected options";
      }

      var schedule = {
        start_date: self.startDate,
        period: self.scheduleData.period,
        interval: self.scheduleData.interval,
        is_weekly_sunday: self.scheduleData.is_weekly_sunday,
        is_weekly_monday: self.scheduleData.is_weekly_monday,
        is_weekly_tuesday: self.scheduleData.is_weekly_tuesday,
        is_weekly_wednesday: self.scheduleData.is_weekly_wednesday,
        is_weekly_thursday: self.scheduleData.is_weekly_thursday,
        is_weekly_friday: self.scheduleData.is_weekly_friday,
        is_weekly_saturday: self.scheduleData.is_weekly_saturday,
      };

      return InspectionProgramService.getFrequencySummary(
        schedule,
        self.utcOffset
      );
    }

    /**
     * Reset all weekday boolean flags
     */
    function resetWeekdays() {
      self.scheduleData.is_weekly_sunday = false;
      self.scheduleData.is_weekly_monday = false;
      self.scheduleData.is_weekly_tuesday = false;
      self.scheduleData.is_weekly_wednesday = false;
      self.scheduleData.is_weekly_thursday = false;
      self.scheduleData.is_weekly_friday = false;
      self.scheduleData.is_weekly_saturday = false;
      enableAllDays();
    }

    /**
     * Enable all weekdays for the date pickers
     */
    function enableAllDays() {
      self.enabledDays = [true, true, true, true, true, true, true];
    }

    /**
     * Disable all weekdays for the date pickers
     */
    function disableAllDays() {
      self.enabledDays = [false, false, false, false, false, false, false];
    }

    function getInspectionProgramBody() {
      var associations = [];
      var body = {
        name: self.subject,
        description: self.description,
        priority: self.priority,
        checklist_template: self.checklistTemplate._id,
        period: self.scheduleData.period,
        interval: self.scheduleData.interval,
        time_zone: TimeZoneService.getCurrentTimeZone(),
        days_until_due: self.scheduleData.days_until_due,
        start_date: self.startDate,
        is_weekly_sunday: self.scheduleData.is_weekly_sunday,
        is_weekly_monday: self.scheduleData.is_weekly_monday,
        is_weekly_tuesday: self.scheduleData.is_weekly_tuesday,
        is_weekly_wednesday: self.scheduleData.is_weekly_wednesday,
        is_weekly_thursday: self.scheduleData.is_weekly_thursday,
        is_weekly_friday: self.scheduleData.is_weekly_friday,
        is_weekly_saturday: self.scheduleData.is_weekly_saturday,
        inspection_components: self.components.map(function (component) {
          var componentBody = {
            building: component.building._id || component.building,
            round_template:
              component.round_template._id || component.round_template,
            assignee_users:
              component.assignee_users &&
              component.assignee_users.map(function (assignee) {
                return assignee._id;
              }),
            maintenance_type: component.maintenance_type
              ? component.maintenance_type._id
              : component.maintenance_type,
            trade: component.trade ? component.trade._id : component.trade,
          };
          if (component.overrideLists) {
            if (
              Object.prototype.hasOwnProperty.call(component, "overrideLists")
            ) {
              var stops = getOverridesArray(
                component.overrideLists,
                component.building
              );
              associations = associations.concat(stops);
            }
          }
          return componentBody;
        }),
      };
      body.inspection_stop_checklist_associations = associations;
      return body;
    }
    /**
     * When editing, fetch the inspection stop checklist associations for this IP.
     * Sets self.inspectionStopChecklistAssociations
     * @returns { Promise<void> }
     */
    function fetchInspectionStopChecklistAssociations() {
      if (!self.editMode) {
        return $q.resolve();
      }
      return InspectionProgramService.getChecklistAssociations(
        self.organization._id,
        self.inspectionProgram._id,
        {}
      ).then(function (associations) {
        self.associationMap = new InspectionStopAssociationMap(associations);
      });
    }

    function fetchUsers() {
      self.loadingUsers = true;
      let params = {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
        status: "active",
      };
      PermissionGroupsService.getSRPPermGroupIdByOrg(self.organization._id)
        .then((srpPermissionGroupId) => {
          params.permission_group = `$ne,${srpPermissionGroupId}`;
        })
        .then(() => {
          UserService.getAll(self.organization._id, params)
            .then(function (users) {
              self.users = users;
            })
            .catch(ToastService.showError)
            .finally(function () {
              self.loadingUsers = false;
            });
        })
        .catch(ToastService.showError);
    }

    function update() {
      if (self.saving) return;
      self.saving = true;
      var body = getInspectionProgramBody();

      // check which inspection components the user wants to delete
      var finalComponents = {};
      body.inspection_components.forEach(function (comp) {
        if (comp.building) {
          finalComponents[comp.building._id || comp.building] = true;
        }
      });

      for (var key in self.originalComponents) {
        if (!finalComponents[key]) {
          if (!body.remove_inspection_components) {
            body.remove_inspection_components = [];
          }

          body.remove_inspection_components.push({
            building: key,
          });
        }
      }

      // generate a diff so we can create a sequence update call to bulk add and remove associations

      function generateSequence(originalAssociationMap, finalComponents) {
        var finalMap = new InspectionStopAssociationMap();

        finalComponents.forEach(function (component) {
          var buildingId = component.building._id || component.building;
          var overrideLists = component.overrideLists || [];
          if (!component.__dirty) {
            self.associationMap.forEachInBuilding(
              buildingId,
              finalMap.addAssociation.bind(finalMap)
            );
          } else {
            overrideLists.forEach(function (overrideList) {
              if (!overrideList.checklistTemplate) {
                return;
              }
              var checklistTemplateId =
                overrideList.checklistTemplate._id ||
                overrideList.checklistTemplate;
              var associations = overrideList.stops;
              associations.forEach(function (association) {
                var assoc = {
                  room:
                    association.room &&
                    (association.room._id || association.room),
                  level:
                    association.level &&
                    (association.level._id || association.level),
                  asset:
                    association.asset &&
                    (association.asset._id || association.asset),
                  checklist_template: checklistTemplateId,
                  building: buildingId,
                };
                finalMap.addAssociation(assoc);
              });
            });
          }
        });

        var diff = self.associationMap.diff(finalMap);

        var sparseUpdate = angular.extend({}, { action: "sparseUpdate" }, body);
        var removeAssociations = {
          action: "removeChecklistAssociations",
          stops: diff.removed.toArray(),
        };
        var addAssociations = {
          action: "insertChecklistAssociations",
          associations: diff.added.toArray(),
        };

        var sequence = [sparseUpdate];
        if (removeAssociations.stops.length) {
          sequence.push(removeAssociations);
        }
        if (addAssociations.associations.length) {
          sequence.push(addAssociations);
        }

        return sequence;
      }

      var result = generateSequence(self.associationMap, self.components);

      InspectionProgramService.updateSequence(
        self.organization._id,
        self.inspectionProgram._id,
        result
      )
        .then(function (inspectionProgram) {
          $mdDialog.hide(inspectionProgram);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Create a new inspection program
     */
    function create() {
      if (self.saving) return;
      self.saving = true;

      var body = getInspectionProgramBody();

      InspectionProgramService.create(self.organization._id, body)
        .then(function (results) {
          // One schedule created. Show toast with "View" action
          self.newInspectionProgram = results;
          ToastService.complex()
            .text("Successfully created inspection program")
            .action("View", goToNewInspectionProgramDetails)
            .show();
          $mdDialog.hide(results);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Go to the new inspection program
     */
    function goToNewInspectionProgramDetails() {
      var stateParams = {
        inspectionProgramId: self.newInspectionProgram._id,
      };
      Router.go("app.inspectionProgram", stateParams);
    }

    function getTitle() {
      return self.editMode
        ? "Edit Inspection Program"
        : "Create Inspection Program";
    }

    // Convert an array of OverrideLists into the form used by the API
    function createOverride(stop, checklistTemplate, building) {
      return {
        /**
         * This is possible because the user can add stops before they pick out the checklist template
         * within the UI, once we stop them from doing that, we can remove this option of having checklist_template as null
         */
        building: building ? building._id || building : null,
        checklist_template: checklistTemplate ? checklistTemplate._id : null,
        room: stop.room ? stop.room._id : null,
        asset: stop.asset ? stop.asset._id : null,
        level: stop.level ? stop.levle._id : null,
      };
    }

    function getOverridesArray(overrideLists, building) {
      var overrides = [];
      overrideLists.forEach(function (overrideList) {
        overrideList.stops.forEach(function (stop) {
          overrides.push(
            createOverride(stop, overrideList.checklistTemplate, building)
          );
        });
      });
      return overrides;
    }
  }
})();
