(function () {
  angular
    .module("akitabox.ui.components.inspectionProgramDetails")
    .component("abxInspectionProgramDetails", {
      bindings: {
        inspectionProgram: "<abxInspectionProgram",
      },
      controller: AbxInspectionProgramDetailsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/inspection-program-details/inspection-program-details.component.html",
    });
  function AbxInspectionProgramDetailsController(
    $scope,
    $q,
    // Constants
    PRIORITIES,
    // Events
    EVENT_INSPECTION_PROGRAM_DETAIL_LOAD,
    // Services
    BuildingService,
    ChecklistTemplateService,
    EnvService,
    FeatureFlagService,
    InspectionProgramService,
    MaintenanceTypeService,
    OrganizationService,
    RoundTemplateService,
    ToastService,
    TradeService,
    UserService
  ) {
    var self = this;

    // Feature Flags
    var multipleChecklistEnabled =
      FeatureFlagService.isEnabled("multiple_ip_checklists") || false;

    var userPerms = UserService.getPermissions();

    // Attributes
    self.building = BuildingService.getCurrent();
    self.organization = OrganizationService.getCurrent();
    self.verbalFrequency = "";
    self.checklistTemplate = null;
    self.loadingBuildingDetails = true;
    self.loading = false;
    self.permissions = getPermissions();

    // Functions
    self.getChecklistTemplateUrl = getChecklistTemplateUrl;

    self.$onChanges = function (changes) {
      if (changes.inspectionProgram) {
        self.loading = true;
        $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_LOAD, {
          isLoading: true,
        });
        self.verbalFrequency = InspectionProgramService.getFrequencySummary(
          self.inspectionProgram
        );

        // get a properly capitalized veriosn of the priority string
        self.userFacingPriority = PRIORITIES[self.inspectionProgram.priority];

        // get priority icon
        switch (self.inspectionProgram.priority) {
          case "low":
            self.priorityIcon = "priority_Low";
            break;
          case "medium":
            self.priorityIcon = "priority_Medium";
            break;
          case "high":
          case "emergency":
          default:
            self.priorityIcon = "priority_High";
        }

        if (multipleChecklistEnabled) {
          $q.all([populateChecklistTemplates(), populateComponents()]).finally(
            function () {
              self.loading = false;
              $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_LOAD, {
                isLoading: false,
              });
            }
          );
        } else {
          $q.all([populateChecklistTemplate(), populateComponents()]).finally(
            function () {
              self.loading = false;
              $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_LOAD, {
                isLoading: false,
              });
            }
          );
        }
      }
    };

    /**
     * Populate the default checklist for the current IP into self.checklistTemplates
     * @returns
     */
    function populateChecklistTemplate() {
      var checklistTemplateId = self.inspectionProgram.checklist_template;
      if (typeof checklistTemplateId !== "string") return $q.resolve();

      return ChecklistTemplateService.getById(
        self.organization._id,
        self.inspectionProgram.checklist_template
      )
        .then(function (result) {
          self.checklistTemplates = [result];
          self.inspectionProgram.checklist_template = result;
        })
        .catch(ToastService.showError);
    }

    /**
     * @private
     * Populate all checklist templates that the current IP uses into self.checklistTemplates.
     * The default checklist will always be listed first.
     * @returns
     */
    function populateChecklistTemplates() {
      return InspectionProgramService.getChecklistTemplates(
        self.organization._id,
        self.inspectionProgram._id,
        {}
      )
        .then(function (checklistTemplates) {
          self.checklistTemplates = checklistTemplates;
          // We do this only because the other fn was already doing this, populateChecklistTemplate(0)
          if (!self.inspectionProgram.checklist_template._id) {
            // populate the default checklist template
            for (var i = 0; i < checklistTemplates.length; i++) {
              if (
                checklistTemplates[i]._id ===
                self.inspectionProgram.checklist_template
              ) {
                var defaultChecklist = checklistTemplates[i];
                self.inspectionProgram.checklist_template = defaultChecklist;

                // Always list the default checklist first
                self.checklistTemplates.splice(i, 1);
                self.checklistTemplates.unshift(defaultChecklist);

                break;
              }
            }
          }

          return self.checklistTemplates;
        })
        .catch(ToastService.showError);
    }

    /**
     * Populate maintenance type, trade, building, assignee users, and round template
     * on each inspection component.
     */
    function populateComponents() {
      var requests = [];

      self.inspectionProgram.inspection_components.forEach(function (
        component
      ) {
        if (component.assignee_users && component.assignee_users.length > 0) {
          requests.push(
            UserService.getAll(self.organization._id, {
              _id: "$in," + component.assignee_users.join(","),
            }).then(function (users) {
              component.assignee_users = users;
            })
          );
        }
        // Populate Maintenance Type
        if (
          component.maintenance_type &&
          typeof component.maintenance_type === "string"
        ) {
          requests.push(
            MaintenanceTypeService.getById(
              component.building,
              component.maintenance_type
            ).then(function (maintenanceType) {
              component.maintenance_type = maintenanceType;
            })
          );
        }

        // Populate Trade
        if (component.trade && typeof component.trade === "string") {
          requests.push(
            TradeService.getById(component.building, component.trade).then(
              function (trade) {
                component.trade = trade;
              }
            )
          );
        }

        // Populate buildings
        if (typeof component.building === "string") {
          requests.push(
            BuildingService.getById(component.building).then(function (
              building
            ) {
              component.building = building;
            })
          );
        }

        // Populate Round Tenplates
        if (typeof component.round_template === "string") {
          requests.push(
            RoundTemplateService.getById(
              self.organization._id,
              component.round_template
            ).then(function (roundTemplate) {
              component.round_template = roundTemplate;
            })
          );
        }
      });

      return $q.all(requests).then(function () {
        self.loadingBuildingDetails = false;
      });
    }

    function getChecklistTemplateUrl(checklistTemplate) {
      var subdomain = EnvService.getSubdomain();
      var url = EnvService.getOrgSettingsUrl(
        "/organization/settings/checklist_templates/" + checklistTemplate._id,
        subdomain
      );
      return url;
    }

    function getPermissions() {
      return {
        canViewChecklistTemplate: userPerms.is_administrator,
        canUpdate: userPerms.inspection_program.update,
      };
    }
  }
})();
