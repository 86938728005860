(function () {
  /**
   * @component abxRelatedWorkOrderCard
   * Card for displaying a themed list of work orders.
   * @param { object[] } abxWorkOrders - The work orders to display
   * @param { "overdue" | "open" } abxType - Controls styling and text of
   *  the list.
   * @param { number } abxTotal - Total number of results for the list.
   * @param { { state, params, options } } abxShowAllState - Destination state
   *  for when the "Show All" button is clicked.
   */
  angular
    .module("akitabox.desktop.components.relatedWorkOrderCard", [
      "akitabox.core.lib.moment",
    ])
    .component("abxRelatedWorkOrderCard", {
      bindings: {
        workOrders: "<abxWorkOrders",
        type: "@abxType",
        total: "<abxTotal",
        showAllState: "<abxShowAllState",
      },
      controller: RelatedWorkOrderCardController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/components/related-work-order-card/related-work-order-card.component.html",
    });

  function RelatedWorkOrderCardController(
    // Services
    BuildingService,
    OrganizationService,
    // Libraries
    moment
  ) {
    var self = this;
    self.getFormatedDate = getFormatedDate;
    self.timeZone = getCurrentTimeZone();

    /**
     * @type { string }
     * Word to be displayed before "due" when showing the due dates for work
     * orders.
     */
    self.DUE_DATE_VERB = {
      open: "is",
      overdue: "was",
    }[self.type];

    /**
     * Theme for the work order list.
     * @type { string }
     */
    self.LIST_THEME = {
      open: "success",
      overdue: "danger",
    }[self.type];

    /**
     * Get text to display in the `+n more` tooltip for a work order with more than
     * 2 assignees.
     * @param {object} workOrder - The work order to load assignee tooltip text for
     * @return { string }
     */
    self.getWorkOrderAssigneeTooltipText =
      function getWorkOrderAssigneeTooltipText(workOrder) {
        if (
          !workOrder ||
          !workOrder.assignee_users ||
          workOrder.assignee_users.length <= 2
        ) {
          return "";
        }
        return workOrder.assignee_users
          .map(function (assignee) {
            return assignee.display_name;
          })
          .filter(function (_assignee, index) {
            return index > 1;
          })
          .join(", ");
      };

    function getFormatedDate(date) {
      const dueDate = moment(date).tz(self.timeZone);
      if (dueDate) {
        return `${dueDate.format("LL")} (${dueDate.format("z")})`;
      }
    }

    function getCurrentTimeZone() {
      var currentBuilding = BuildingService.getCurrent();
      if (currentBuilding) {
        return currentBuilding.time_zone;
      }
      var currentOrganization = OrganizationService.getCurrent();
      if (currentOrganization) {
        return currentOrganization.time_zone;
      }
      // default case
      return "America/Chicago";
    }
  }
})();
