(function () {
  /**
   * @ngdoc component
   * @name abxInput
   *
   * @param {String} [buildingId] - Currently selected building's id
   *     Required for asset, assignee, floor, and room inputs.
   * @param {String} [floorId] - Currently selected floor. Required for room inputs.
   * @param {String} [roomId] - Currently selected room, used for asset inputs
   * @param {Boolean} [disabled] - Disable the input. Defaults to false.
   * @param {*[]} [options] - Options to choose from for select and typeahead
   *     inputs.
   *     For typeaheads: If the value and label for the drop down can be
   *       identical for each value, this can be an array of primitive values.
   *       Otherwise, it should be an array of objects of form: { value, label }.
   *     For selects/enums: All options should be primitives.
   * @param {*[]} [disabledOptions] - Options that should be shown for select,
   *  but not be selectable.
   * @param {*[]}[optionsSecondary] - To be used with type="multi-select-and-radio".
   *     Options to choose from for the radio button group. All options should be primitives.
   * @param {String} [optionsGroupKey] - Key to group `options` objects by for
   *     the list of available options for a typeahead input. The value on each
   *     `option[optionsGroupKey]` will be shown, literally, as a header to
   *     the group of options that match that value. e.g. The user of this
   *     component wants to group a combined list of maintenance types and
   *     issue types by their `intent` field, so they would pass in an
   *     `optionsGroupKey` of "intent". NOTE: This will not work for select
   *     inputs, only typeahead.
   * @param {String} [label] - Input label. If omitted, the label,
   *     status indicators, and revert button will not be present.
   * @param {String} [prefix] - Prefix text to use for text input types.
   * @param {*} model - Model value for the input
   * @param {*} modelSecondary - Model value for the secondary input
   *     (e.x.the radio buttons in input typy="multi-select-and-radio")
   * @param {Number}  state - Override input state, from COMPONENT_STATE
   * @param {Function} [onBlur] - To be invoked when the input loses focus. If
   *     `saveOnBlur` is set and this function returns a promise, saving
   *     indicators will show dependent on when that promise resloves.
   *     NOTE: This will not be called if the model value to be sent up
   *     is equal to the model value the input had when it initially
   *     received focus. It will also not get called if `saveOnBlur` is set
   *     and the new model value is invalid.
   * @param {Function} [onClose] - Currently only implemented for multi-select types,
   *     to be invoked when the selection menu is closed.
   * @param {Function} [onChange] - To be invoked when the input's value
   *     changes.
   * @param {Function} [onChangeSecondary] - To be used with type="multi-select-and-radio"
   *     To be invoked when the input's redio button value changes.
   * @param {Function} [onFocus] - To be invoked when the input is focused. Invoked
   *     with no args.
   * @param {Function} [onSelect] - For typeahead inputs, to be invoked when
   *     a user selects an option from the dropdown
   * @param {Boolean} [revertOnBlur] - For typeahead components with blur handling
   *    that save the original model, using revertOnBlur=true will revert the
   *    component to the default, valid state. The child component is responsible
   *    for handling the save and blur functionality - this parameter just controls
   *    the state UI.
   * @param {Boolean} [saveOnBlur=false] - Save on input blur. In other words,
   *     tell this component to show saving indicators to the user while the
   *     parent handles a model change (through `onBlur`).
   * @param {Function} fetchOptions - Fetches new options on change for typeahead
   * inputs
   * @param {Boolean} [blurOnEnter=saveOnBlur] - Blur the input on Enter
   *     keypress. Will trigger `onBlur`. Types not applicable for: boolean,
   *     paragraph, select. NOTE: Will not work for address inputs (split to
   *     WEBAPP-6090).
   * @param {String} type - Type of input. Should match one of our pin field
   *     schema's `data_type` enum values. Available types (and some aliases)
   *     listed below.
   * @param {Boolean} [required] - Visually marks the input as required and
   *     adds validation features.
   * @param {Boolean} [allowBooleanNone] - Indicates that the booleanOptions
   *     should contain the additional `{ label: "Not set", value: "" }` option
   *     to indicate a null selection.
   * @param {Boolean} [urlInspectionMode] - When true, enables button-style rendering of
   *     'url' type inputs.
   * @param {Boolean} [hideRevert] - hide the 'revert' button
   * @param {Boolean} [hideTryAgain] - hide the 'try again' button
   * @param {Boolean} [readOnly] - Property associated with displaying input values
   *     as readonly text
   * @param {Boolean} [showSelectSearch]  Show select search bar
   * @param {String[]} [selectGroupKeys]  Keys to group select options by
   * @param {Number} [selectLimit]        Limit the select options
   * @param {Boolean} [hideClearButton] - Used for typeaheads to hide the clear button ("x")
   * @param { boolean } [allowTextValue] - Used for typeaheads only. If true,changes with no model are
   *  considered valid (eg allows the user to type a partial room name and blur without clearing or
   *  going invalid)
   *
   *
   * @callback onBlur
   * @param {Object} $event
   * @param {*} $event.newValue - Current model value for the input.
   * @param {Boolean} $event.invalid - Signals that the value being sent up
   *     is considered invalid by this input.
   *
   * @callback onChange
   * @param {Object} $event
   * @param {*} $event.model - Current model value for the input (after the
   *      change).
   * @param {*} $event.value - Current (display) value for the input (after the
   *      change).
   * @param {Boolean} $event.invalid - Signals that the new model value is
   *      considered invalid by this input.
   * @param {String} [parentErrMsg] - error message to display if provided by parent element
   * @param {String} [inputId] - ID to apply to input element
   * @param {Boolean} [autocomplete] - Applies autocomplete="on" to input element
   *
   * @param {String} [placeholder] - placeholder attribute for the input element
   * @param {boolean} [showOptionsAfterSelect] - keep the drop down options opened even after user has selected one
   * @param {String} [tooltipIcon] - Optional tooltip icon to show next to input label
   * @param {String} [tooltipText] - Text to use for the provided tooltipIcon. If an icon is provided, tooltipText must also be provided
   * 
   * @param {Function} [dateFilter] - Function expecting a date and returning a boolean whether it can be selected in "day" mode or not.

   * @description
   * Input field.
   *
   * Available `type`s:
   *   - boolean: Yes or no dropdown
   *   - date: Datepicker
   *   - email: email input
   *   - phone: phone number
   *   - float: Floating point number input
   *   - floor: Floor dropdown
   *   - int/number: Whole number input
   *   - paragraph: Textarea
   *   - room: Room dropdown
   *   - round-template: Round template typeahead
   *   - select/enum: Select dropdown
   *   - enum-and-radio: The same as a select dropdown but also includes a radio
   *       button group above the options.
   *   - multi-select: Select dropdown where user can select multiple options at once
   *   - multi-select-and-radio: The same as a multi-select but also includes a radio
   *       button group above the multi-select options.
   *   - string/text: Text input
   *   - typeahead: Typeahead
   *   - qr-code: String input masked for a QR code. Prevents user from typing
   *       non-digit characters. Will only invoke `onChange` when the model
   *       of a QR code actually changes (i.e. valid QR code changing to an
   *       incomplete one, incomplete one changing to an empty one, etc.).
   *       A valid, empty qr code will send up an empty string for `model` and
   *      `value`. An invalid or incomplete QR code will send up `undefined`
   *       for `model` and `value`. A valid QR code will be sent up stripped
   *       of any characters in the QR code mask. Additionally, `onBlur` will
   *       not be triggered when going from one incomplete QR code to another,
   *       as both `model`s will be undefined, and will therefore be seen as
   *       equal. A component of this type will never send up an invalid QR
   *       code. This input type will also show the numeric keypad for mobile
   *       devices.
   *
   * @example The abx-input component is focusable
   * `<abx-input type="string" onChange="vm.handleChange($event)" md-autofocus="true"></abx-input>`
   * `$element.find("abx-input").focus()`
   */
  angular.module("akitabox.ui.components.input").component("abxInput", {
    bindings: {
      type: "@abxType",
      label: "@?abxLabel",
      model: "<abxModel",
      modelSecondary: "<abxModelSecondary",
      value: "<?abxValue",
      state: "<?abxState",
      prefix: "@?abxPrefix",
      onClose: "&?abxOnClose",
      onBlur: "&abxOnBlur",
      onChange: "&abxOnChange",
      onChangeSecondary: "&abxOnChangeSecondary",
      onFocus: "&abxOnFocus",
      buildingId: "<?abxBuildingId",
      floorId: "<?abxFloorId",
      roomId: "<?abxRoomId",
      disabled: "<?abxDisabled",
      options: "<?abxOptions",
      disabledOptions: "<?abxDisabledOptions",
      optionsGroupKey: "@?abxOptionsGroupKey",
      trackBy: "@?abxTrackBy",
      optionsSecondary: "<?abxOptionsSecondary",
      required: "<abxRequired",
      pattern: "<?abxPattern",
      saveOnBlur: "<?abxSaveOnBlur",
      fetchOptions: "&?abxFetchOptions",
      min: "<?abxMin",
      max: "<?abxMax",
      maxLength: "<?abxMaxLength",
      step: "<?abxStep",
      allowBooleanNone: "<?abxAllowBooleanNone",
      urlInspectionMode: "<?abxUrlInspectionMode",
      blurOnEnter: "<?abxBlurOnEnter",
      hideRevert: "<?abxHideRevert",
      hideTryAgain: "<?abxHideTryAgain",
      readOnly: "<?abxReadOnly",
      parentErrMsg: "@?abxParentErrMsg",
      inputId: "@?abxInputId",
      inputName: "@?abxInputName",
      autocomplete: "@?abxAutocomplete",
      helperText: "@?abxHelperText",
      // Type-Ahead
      allowTextValue: "<?abxAllowTextValue",
      // Select
      hideSelectEmptyOption: "<?abxHideSelectEmptyOption",
      showSelectSearch: "&?abxShowSelectSearch",
      selectGroupKeys: "<?abxSelectGroupKeys",
      selectLimit: "<?abxSelectLimit",
      selectMenuContainer: "@abxSelectMenuContainer",
      placeholder: "@abxPlaceholder",
      onSelect: "&abxOnSelect",
      revertOnBlur: "<?abxRevertOnBlur",
      submit: "&abxSubmit",
      showOptionsAfterSelect: "<?abxShowOptionsAfterSelect",
      hideClearButton: "<?abxHideClearButton",
      tooltipText: "@?abxTooltipText",
      tooltipIcon: "@?abxTooltipIcon",
      /**
       * This prop only applies when you've set the abx-type="enum", will choose the default
       * option in the dropdown list
       */
      selectedOption: "<?abxSelectedOption",
      // Date
      timeZoneOffset: "@?abxTimeZoneOffset",
      autofocus: "<?abxAutoFocus",
      dateFilter: "=?abxDateFilter",
      showClearInputIcon: "<?abxShowClearInputIcon",
      onClearInput: "=?abxOnClearInput",
      selectionLimit: "=?abxSelectionLimit",
    },
    controller: AbxInputController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/input/input.component.html",
  });

  /* @ngInject */
  function AbxInputController(
    // Angular
    $attrs,
    $q,
    $filter,
    $scope,
    $timeout,
    $element,
    // AkitaBox
    patterns,
    // Constants
    MINIMUM_RENDER_DELAY,
    COMPONENT_STATE,
    PHONE_VALIDATION_PATTERN,
    KEY_CODES,
    // Third-party
    moment,
    // Helpers
    Utils,
    // Services
    MapService,
    ServiceHelpers,
    FeatureFlagService
  ) {
    var self = this;

    // Constant
    /* ^(?:[^/]|:\/\/)*? makes sure that we're at the start of the URL, and accounts for protocols (https://).
     * It also covers any subdomains.
     * ((?:(?:local\-abx)|(?:beta\-abx)|(?:akitabox))\.com) captures either "local-abx.com", "beta-abx.com", or "akitabox.com"
     * as the hostname. Nothing after the hostname matters for launchUrl's check.
     */
    var AKITABOX_HOSTNAME_PATTERN =
      /^(?:[^/]|:\/\/)*?((?:(?:local-abx)|(?:beta-abx)|(?:akitabox))\.com)/i;
    var DATE_FORMAT = "MM/DD/YYYY";

    // Private
    var blurDisabled = false;
    var finishSuccessState; // Request to go from success state to default state
    var originalModel;
    var originalValue;
    var valueProvided = angular.isDefined($attrs.abxValue);
    var hasBlurred = false;

    // Attributes
    self.value = angular.isDefined(self.value) ? self.value : undefined;
    self.state = COMPONENT_STATE.default;
    self.invalid = false;
    self.failure = false;
    self.errorMessages = {
      required: { type: "required", text: "Value required" },
    };
    self.saveOnBlur = self.saveOnBlur || false;
    self.blurOnEnter =
      self.blurOnEnter === undefined ? self.saveOnBlur : self.blurOnEnter;
    self.dirty = false;
    self.linkTarget;
    self.search = self.showSelectSearch ? self.showSelectSearch() : false;
    self.searchText = null;
    self.step = self.step ? self.step : 1;
    self.showOptionsAfterSelect = self.showOptionsAfterSelect || false;
    self.rawInputVisible = false;
    self.readOnlySelectValue = null;

    if (self.type === "email" && self.pattern !== false) {
      self.pattern = self.pattern || new RegExp(patterns.EMAIL, "i");
    } else if (self.type === "phone" && self.patter !== false) {
      self.pattern = self.pattern || PHONE_VALIDATION_PATTERN;
    } else {
      // only allow email or phone input types to have pattern
      delete self.pattern;
    }

    // Functions
    self.disableBlur = disableBlur;
    self.handleSelect = handleSelect;
    self.handleSelectSecondary = handleSelectSecondary;
    self.handleBlur = handleBlur;
    self.handleChange = handleChange;
    self.handleFocus = handleFocus;
    self.handleOnOpen = handleOnOpen;
    self.handleOnClose = handleOnClose;
    self.isEmpty = isEmpty;
    self.isValueEmpty = isValueEmpty;
    self.launchUrl = launchUrl;
    self.revert = revert;
    self.getErrors = getErrors;
    self.getGroupFilter = getGroupFilter;
    self.handleKeyDown = handleKeyDown;
    self.handleSelectSearch = handleSelectSearch;
    self.enterEditingState = enterEditingState;
    self.applyBooleanFilter = applyBooleanFilter;
    self.toggleRawInputVisibility = toggleRawInputVisibility;
    self.isDisabled = (option) => {
      if (!self.selectionLimit || self.type !== "multi-select") {
        return false;
      }

      // disable the option if
      // 1. there is a limit
      // 2. the option hasn't been selected yet
      // 3. we are at the limit or over
      const match = self.model.length
        ? self.model.find((model) => model._id === option.model._id)
        : null;
      if (!match && self.model.length >= self.selectionLimit) {
        return true;
      }

      return false;
    };

    // Custom Validators
    self.validateEmail = validateEmail;
    self.validatePhone = validatePhone;
    self.validateNumber = validateNumber;
    self.validateInt = validateInt;

    // Custom Errors
    var invalidNumberError = { type: "invalid", text: "Invalid number" };
    var invalidIntError = { type: "invalid", text: "Invalid whole number" };
    var invalidEmailError = { type: "invalid", text: "Invalid email" };
    var invalidPhoneError = { type: "invalid", text: "Invalid phone number" };

    // =================
    // Lifecycle
    // =================

    /**
     * Perform any necessary direct DOM manipulations.
     */
    self.$postLink = function () {
      // If this element is focused (eg: by `md-autofocus`)
      // then instead focus the appropriate child element.
      $element[0].focus = function () {
        $element.find("input,textarea,select,md-select").focus();
      };
    };

    self.$onInit = function () {
      defaultState();
      initializeBooleanOptions();

      if (self.type === "url") {
        setLinkTarget();
      }

      if (self.type === "hours-minutes") {
        separateHoursAndMinutes();
      }

      if (self.type === "select" && self.readOnly) {
        for (var i = 0; i < self.options.length; i++) {
          var currentOption = self.options[i];
          if (currentOption.model === self.model) {
            self.readOnlySelectValue = currentOption.value;
            break;
          }
        }
      }

      if (self.autofocus) {
        // have to use timeout otherwise this executes before input is rendered
        $timeout(function () {
          $element.find("input,textarea,select,md-select").focus();
        }, MINIMUM_RENDER_DELAY);
      }
    };

    self.$onChanges = function (changes) {
      if (changes.type && self.type) {
        // Data types use underscores, but our templates have hyphens
        self.type = self.type.replace(/_/g, "-");

        self.templateUrl = buildTemplateUrl(self.type);
      }

      if (
        changes.model &&
        !changes.model.isFirstChange() &&
        self.type === "assignees"
      ) {
        // there was an extenal change to an assignee input, we want to check for validity again
        checkValidity();
      }

      if (changes.model && !self.dirty) {
        if (angular.isObject(self.model)) {
          // Copy objects for true one-way data binding
          self.model = angular.copy(self.model);
        }

        if (self.type === "url") {
          setLinkTarget();
        }

        if (self.type === "hours-minutes") {
          separateHoursAndMinutes();
        }

        originalModel = self.model;
        hasBlurred = false;
        $q.resolve(parseValue()).then(function () {
          originalValue = self.value;
        });
        var wasInvalid = self.invalid;
        checkValidity();

        // If the input was left in editing state because the model it
        // blurred with was invalid, but the new model is valid, clear editing
        // state
        var clearEditingState =
          wasInvalid && !self.invalid && self.state === COMPONENT_STATE.editing;
        if (clearEditingState) {
          defaultState();
        }
      }

      if (
        (changes.min &&
          !angular.equals(
            changes.min.currentValue,
            changes.min.previousValue
          )) ||
        (changes.max &&
          !angular.equals(changes.max.currentValue, changes.max.previousValue))
      ) {
        if (changes.min) {
          if (angular.isEmpty(self.min)) {
            delete self.errorMessages.min;
          } else {
            var isMinDate = Utils.isDateValid(self.min);
            var minErrorMessage = { type: "min" };
            if (isMinDate) {
              minErrorMessage.text =
                "Value cannot be before " + formatDate(self.min);
            } else {
              minErrorMessage.text = "Value cannot be less than " + self.min;
              if (self.type === "string" || self.type === "text")
                minErrorMessage.text += " characters long";
            }
            self.errorMessages.min = minErrorMessage;
          }
        }
        if (changes.max) {
          if (angular.isEmpty(self.max)) {
            delete self.errorMessages.max;
          } else {
            var isMaxDate = Utils.isDateValid(self.max);
            var maxErrorMessage = { type: "max" };
            if (isMaxDate) {
              maxErrorMessage.text =
                "Value cannot be after " + formatDate(self.max);
            } else {
              maxErrorMessage.text = "Value cannot be more than " + self.max;
              if (self.type === "string" || self.type === "text")
                maxErrorMessage.text += " characters long";
            }
            self.errorMessages.max = maxErrorMessage;
          }
        }

        // check for errors and notify parent in case validity changed
        checkValidity();
        self.onBlur({
          $event: { newValue: self.model, invalid: self.invalid },
        });
      }

      if (changes.parentErrMsg) {
        if (changes.parentErrMsg.currentValue) {
          self.invalid = true;
          self.errorMessages.parentErrMsg = {
            type: "invalid",
            text: self.parentErrMsg,
          };
        } else {
          delete self.errorMessages.parentErrMsg;
          /**
           * TODO: Fix this
           * This change is masking a bug because checkValidity() has no return value,
           * so self.invalid is alway forced to false/undefined here
           * https://akitabox.atlassian.net/browse/WEBAPP-11401
           */
          self.invalid = checkValidity();
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Revert to the last given model
     */
    function revert() {
      self.model = originalModel;
      self.value = originalValue;
      parseValue();
      self.invalid = false;
      self.failure = false;
      self.dirty = false;
      blurDisabled = false;
      $timeout(defaultState);
    }

    /**
     * Disable input blur events. This is for elements other than the actual
     * input box inside the component where we want to suppress the blur event
     * for.
     */
    function disableBlur() {
      blurDisabled = true;
    }

    /**
     * Called on input blur or new model select. Notifies parent components
     * if the model has been changed and is valid.
     *
     * @param {Object} event - Propagated event
     * @param {*} event.model - New model to change to
     * @param {Boolean} [event.valid] - Mark input as valid
     */
    function handleBlur(event) {
      // Another item in the input is being clicked, so ignore the input blur
      if (blurDisabled || hasBlurred) return;
      hasBlurred = true;

      self.dirty = false;
      if (event.valid !== undefined) {
        self.invalid = !event.valid;
      }

      if (self.invalid && self.saveOnBlur) {
        hasBlurred = false;
        return;
      }

      // successState() will call defaultState() after a timeout, so don't reset the state here
      // if currently in the success state
      if (
        modelsAreEqual(originalModel, event.model) &&
        !(self.state === COMPONENT_STATE.success)
      ) {
        defaultState();
        if (self.saveOnBlur) {
          hasBlurred = false;
          return;
        }
      }
      savingState();
      // (DRL 6.2)
      return $q
        .resolve(
          self.onBlur({
            $event: { newValue: event.model, invalid: self.invalid }, // (DRL 6.1)
          })
        )
        .then(successState)
        .catch(failureState)
        .finally(function () {
          hasBlurred = false;
        });
    }

    /**
     * Check for escape key and do nothing when pressed; prevents an error from being thrown in Firefox and some
     * Chrome browsers where editing an input in a planview pin detail sidebar and then hitting escape throws a
     * console error
     * @param {Object}  $event - $event object
     */
    function handleKeyDown($event) {
      if ($event && $event.which && $event.which === KEY_CODES.ESCAPE) {
        $event.stopPropagation();
      }
    }

    /**
     * Allows for 'enter' keydowns to submit model values for boolean inputs
     * Required as functionality is not native to md-selects
     *
     * @param {Object} $event - Propagated keydown event
     * @param {Object} model - Model value based on current input
     */
    function applyBooleanFilter($event, model) {
      if ($event && $event.which && $event.which === KEY_CODES.ENTER) {
        handleChange(model);
        self.submit();
      }
    }

    /**
     * Handles value changes from child components. Should be called
     * any time a user types in a child component's input. Notifies parent
     * components of the `model`, `value`, and `invalid` values for the
     * component after the given change.
     *
     * @param {Object} event - Propagated event
     * @param {*} event.model - New model value based on current input
     * @param {String} [event.value] - New display value. If omitted, it will
     *     be parsed based on the new model given
     * @param {Boolean} [event.clearInput] - Optionally ensure the input is cleared
     *     when the user clicks the "clear" button in a typeahead
     * @param {Boolean} [invalid=false] - New value is invalid
     * @param {Array} [event.validators] - functions to invoke to check for errors
     */
    function handleChange(event) {
      self.model = event.model;
      self.clearInput = event.clearInput || false;
      // It is important to clear the value right away if the model doesn't exist and the input
      // has been cleared for dynamic typeaheads's 'see all' button
      if (event.value || (!event.model && event.value === "")) {
        self.value = event.value;
      } else {
        // parseValue will use the current model, and assign the value itself
        parseValue();
      }

      // This removes the failure state when the user starts typing.
      self.failure = false;
      self.dirty = true;

      // handle custom errors
      delete self.errorMessages.invalid;
      if (event.validators) {
        for (var i = 0; i < event.validators.length; i++) {
          var errorMessageObject = event.validators[i]();
          if (errorMessageObject) {
            self.errorMessages.invalid = errorMessageObject;
            break;
          }
        }
      }

      if (event.invalid) {
        self.invalid = true;
      } else {
        checkValidity();
      }

      // Value of input was changed and the user isn't actively editing it
      // eg. room/asset is cleared due to floor changing
      if (self.state === COMPONENT_STATE.default) {
        blurDisabled = false;
        self.handleBlur({ model: self.model });
      }

      return self.onChange({
        $event: {
          model: self.model,
          value: self.value,
          invalid: self.invalid,
          clearInput: self.clearInput,
        },
      });
    }

    function handleOnOpen() {
      self.searchText = null;
    }

    function handleOnClose() {
      if (angular.isFunction(self.onClose)) {
        self.onClose();
      }
    }

    function getGroupFilter(key) {
      if (self.optionsGroupKey) {
        return function (value) {
          if (value.model) {
            var model = value.model;
            var attr = model[self.optionsGroupKey];
            return attr.toLowerCase() === key.toLowerCase();
          }
          return false;
        };
      }
      return function () {
        return false;
      };
    }

    function handleSelectSearch(value) {
      if (self.searchText && value) {
        var searchText = self.searchText.toLowerCase();
        if (value.model && value.value) {
          value = value.value;
        }
        if (angular.isString(value)) {
          return value.toLowerCase().indexOf(searchText) > -1;
        }
      } else {
        return !self.searchText;
      }
    }

    function handleSelect($event) {
      if (self.type === "address") {
        self.disabled = true;
        MapService.getPlaceDetails($event.model.place_id, $event.value)
          .then(function (place) {
            $event.model = place;
            self.handleChange($event);
          })
          .finally(function () {
            self.disabled = false;
          });
      } else if (self.type === "assignees") {
        return self.onSelect({
          $event: {
            model: $event.model,
            value: $event.value,
            invalid: $event.invalid,
          },
        });
      } else {
        self.handleChange($event);
      }

      if (self.saveOnBlur) {
        self.handleBlur($event);
      } else {
        self.dirty = false;
        defaultState();
      }
    }

    function handleSelectSecondary($event) {
      self.onChangeSecondary({ $event: $event });
    }

    /**
     * Handle user focus, and notify parent components.
     * @param {boolean} [enterEditingState=true] - Flag, false to avoid entering the
     *    editing state.
     */
    function handleFocus(enterEditingState) {
      hasBlurred = false;
      if (arguments.length === 0) {
        enterEditingState = true;
      }
      if (self.onFocus) {
        self.onFocus();
      }
      if (enterEditingState) {
        editingState();
      }
    }

    /**
     * Validates that the input is a valid float or empty input
     * @return {{type: string, text: string}}
     */
    function validateNumber() {
      if (isNaN(self.model)) {
        return invalidNumberError;
      }
    }

    /**
     * Validates that the input is a valid integer or empty input
     * @return {{type: string, text: string}}
     */
    function validateInt() {
      if (
        typeof self.model === "undefined" ||
        (!(
          typeof self.model === "number" &&
          isFinite(self.model) &&
          Math.floor(self.model) === self.model
        ) &&
          !angular.isEmpty(self.model))
      ) {
        return invalidIntError;
      }
    }

    /**
     * Validates that the input is a valid email
     * @return {{type: string, text: string}}
     */
    function validateEmail() {
      if (self.pattern && !self.pattern.test(self.model)) {
        return invalidEmailError;
      }
    }

    /**
     * Validates that the input is a valid phone number
     * @return {{type: string, text: string}}
     */
    function validatePhone() {
      if (self.pattern) {
        var digits = self.model.replace(/\D/g, "");
        if (!self.pattern.test(digits) & !angular.isEmpty(self.model)) {
          return invalidPhoneError;
        }
      }
    }

    /**
     * The current model is empty (`undefined`, `null`, empty string, etc.).
     * Used for required field styling and error messages.
     *
     * @return {Boolean}
     */
    function isEmpty() {
      return angular.isEmpty(self.model);
    }

    function isValueEmpty() {
      return angular.isEmpty(self.value);
    }

    /**
     * Launch the given URL
     * If the URL is an internal one it opens in the same tab, otherwise it opens in a new tab.
     * Expects to be called by an input of type "url", otherwise linkTarget will not be initialized.
     *
     * @param {String} url - The url to be opened
     */
    function launchUrl(url) {
      window.open(url, self.linkTarget);
    }

    function getErrors() {
      var errors = {};
      if (angular.isEmpty(self.model)) {
        if (self.required) {
          errors.required = true;
        }
      } else {
        if (
          !angular.isEmpty(self.min) &&
          (self.model < self.min || self.model.length < self.min)
        ) {
          errors.min = true;
        }
        if (
          !angular.isEmpty(self.max) &&
          (self.model > self.max || self.model.length > self.max)
        ) {
          errors.max = true;
        }
      }

      if (self.type === "number") {
        self.errorMessages.invalid = validateInt();
      } else if (self.type === "float") {
        self.errorMessages.invalid = validateNumber();
      }

      // check for custom errors
      if (self.errorMessages.invalid) {
        errors.invalid = true;
      } else {
        delete errors.invalid;
      }

      if (self.parentErrMsg) {
        errors.invalid = true;
      }

      return errors;
    }

    /**
     * Enter editing state and trigger focus on the input element.
     *
     * Used when triggering the editing state from an element/event other
     * than focusing the input element (ex: URL input when using inspectionMode).
     */
    function enterEditingState() {
      editingState();
      // $timeout ensures the input element is available after state change for focus call
      $timeout($element[0].focus, 0, false);
    }

    function toggleRawInputVisibility() {
      self.rawInputVisible = !self.rawInputVisible;
    }

    // =================
    // Private Functions
    // =================

    /**
     * Gets the template URL for the given input type.
     *
     * @param {String} type - Type of input to display
     */
    function buildTemplateUrl(type) {
      // Allow for `type` aliases
      switch (type) {
        case "enum":
          type = "select";
          break;
        case "enum-radio":
          type = "select-and-radio";
          break;
        case "number":
          type = "int";
          break;
        case "text":
          type = "string";
          break;
        case "text-clear":
          type = "string-clear";
          break;
        case "typeahead":
          if (angular.isDefined($attrs.abxFetchOptions)) {
            type = "typeahead-dynamic";
          }
          break;
        case "multi-select":
        case "select":
          if (self.selectGroupKeys && self.optionsGroupKey) {
            type += "-group";
          }
          break;
        case "assignees":
          type = "typeahead";
          break;
      }
      return "app/core/ui/components/input/templates/" + type + "-input.html";
    }

    /**
     * Show the default state (not editing or saving a new model value).
     */
    function defaultState() {
      $scope.$evalAsync(function () {
        self.state = COMPONENT_STATE.default;
      });
    }

    /**
     * Show the editing state.
     */
    function editingState() {
      if (finishSuccessState) {
        $timeout.cancel(finishSuccessState);
        finishSuccessState = null;
      }

      $scope.$evalAsync(function () {
        self.state = COMPONENT_STATE.editing;
      });
    }

    /**
     * Format the given date for display.
     */
    function formatDate(date) {
      return moment(date).format(DATE_FORMAT);
    }

    /**
     * Show the state for when the parent resolves after a model change and blur.
     * - If responsible for saving on blur, show a saving state to the user.
     * - If not responsible for saving on blur, go back to the default state.
     */
    function savingState() {
      if (!self.saveOnBlur) {
        if (self.invalid) {
          editingState();
        } else {
          defaultState();
        }
        return;
      }

      $scope.$evalAsync(function () {
        self.state = COMPONENT_STATE.saving;
      });
    }

    /**
     * Show the state for when the parent resolves after a model change.
     * - If responsible for saving on blur, show a success state to the user.
     * - If not responsible for saving on blur, go back to the default state.
     */
    function successState() {
      if (!self.saveOnBlur) {
        // Set default state if the component has a blur function to maintain the
        // previously saved value
        if (self.revertOnBlur) {
          self.invalid = false;
          defaultState();
          return;
        }
        if (self.invalid) {
          editingState();
        } else {
          defaultState();
        }
        return;
      }

      $scope.$evalAsync(function () {
        self.failure = false;
        // Show success state for 2 seconds
        self.state = COMPONENT_STATE.success;
        $timeout.cancel(finishSuccessState);
        finishSuccessState = $timeout(function () {
          self.state = COMPONENT_STATE.default;
        }, 2000);
      });
    }

    /**
     * Show the state for when the parent resolves after a failed model change
     * and blur so the user can manually retry.
     */
    function failureState() {
      $scope.$evalAsync(function () {
        // Recreate the editingState work to avoid a race condition
        self.state = COMPONENT_STATE.editing;
        self.failure = true;
      });
    }

    /**
     * Set boolean options (for boolean input ng-options).
     */
    function initializeBooleanOptions() {
      var options = [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ];

      if (self.allowBooleanNone === true) {
        options.push({ label: "Not set", value: "" });
      }
      self.booleanOptions = options;
    }

    /**
     * Parse a value to display to the user, depending on the type of the
     * input.
     */
    function parseValue() {
      // If value is already provided, don't parse it
      // This usually happens when the model is a complex object
      // and the user desires the value to be a property of the model
      if (valueProvided) return;

      var value;

      switch (self.type) {
        case "address":
          value = parseAddressDisplayValue();
          break;
        case "assignees":
          value = self.model && self.model.display_name;
          break;
        case "assignee":
          value =
            self.model && self.model.identity
              ? self.model.identity.display_name
              : self.model.display_name;
          break;
        case "boolean":
          value = parseBooleanDisplayValue();
          break;
        case "date":
          value = parseDateDisplayValue();
          break;
        case "building":
        case "floor":
        case "asset":
        case "round-template":
          value = self.model && self.model.name;
          break;
        case "room":
          return parseRoomDisplayValue();
        default:
          value = angular.copy(self.model);
          break;
      }

      self.value = angular.isEmpty(value) ? undefined : value;

      function parseAddressDisplayValue() {
        if (self.model) {
          return MapService.formatDescription(self.model);
        }
        return null;
      }

      function parseBooleanDisplayValue() {
        if (self.model === true) {
          return "Yes";
        } else if (self.model === false) {
          return "No";
        } else if (self.allowBooleanNone === true && self.model === "") {
          return "Not set";
        }
      }

      function parseDateDisplayValue() {
        if (typeof self.model === "string") {
          // date input passes the literal string as the model if it is a string that is not a valid date
          return self.model;
        } else {
          // do not parse as UTC here; if the specific input should be parsed as UTC, do that before sending it into
          // this component
          return $filter("date")(
            self.model,
            "MM/dd/yyyy",
            self.timeZoneOffset || undefined
          );
        }
      }

      function parseRoomDisplayValue() {
        ServiceHelpers.getRoomDisplayName(self.model).then(function (
          displayName
        ) {
          value = displayName;
          self.value = angular.isEmpty(value) ? null : value;
        });
      }
    }

    /**
     * Depending on the `type` of this input, determine if the two given models
     * are considered equal or not.
     *
     * @param {*} model1 - First model to compare
     * @param {*} model2 - Second model to compare
     */
    function modelsAreEqual(model1, model2) {
      switch (self.type) {
        case "room":
        case "floor":
          // Compare IDs of back end models
          return Utils.isSameModel(model1, model2);

        case "date":
          var bothEmpty = !model1 && !model2;
          var isSameDay = moment.utc(model1).isSame(model2, "day");
          return bothEmpty || isSameDay;

        case "enum":
        case "paragraph":
        case "select":
        case "string":
        case "string-clear":
        case "text":
        case "typeahead":
          // Treat empty strings and `null` as equals for models that are strings,
          // since ng-model will treat them as equals
          if (!model1 && !model2) {
            return true;
          }

        case "boolean":
          // Make sure `undefined` and `null` are treated equally. This is
          // necessary to differentiate between the field and the selectable
          // option for a blank value
          if (angular.isEmpty(model1) && angular.isEmpty(model2)) {
            return true;
          }

        default:
          return angular.equals(model1, model2);
      }
    }

    /**
     * Sets the target attr of a "url" type input
     */
    function setLinkTarget() {
      self.linkTarget = AKITABOX_HOSTNAME_PATTERN.test(self.model)
        ? "_self"
        : "_blank";
    }

    function separateHoursAndMinutes() {
      var numberValue = +self.model;
      self.hours = Math.floor(numberValue / 60);
      self.minutes = numberValue % 60;
    }

    function checkValidity() {
      self.invalid = !angular.isEmpty(getErrors());
    }
  }
})();
