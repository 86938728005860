(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller(
      "WorkOrderOverviewTabController",
      WorkOrderOverviewTabController
    );

  /* @ngInject */
  function WorkOrderOverviewTabController(
    // Angular
    $q,
    $scope,
    $timeout,
    $state,
    $window,
    // Libraries
    moment,
    // Dialogs
    CancelWorkOrderDialog,
    CompleteWorkOrderDialog,
    DeleteWorkOrderDialog,
    WorkOrderLogDialog,
    PrintDialog,
    ReopenWorkOrderDialog,
    RescheduleWorkOrderDialog,
    // Events
    EVENT_LOG_WORK,
    EVENT_RESCHEDULE_WORK_ORDER,
    // Helpers
    Utils,
    // Resolve
    building,
    workOrder,
    Router,
    // Services
    AssociationService,
    EnvService,
    InspectionService,
    InspectionProgramService,
    NoteService,
    OrganizationService,
    PinTypeService,
    RoundTemplateService,
    ScheduleService,
    TimeZoneService,
    ToastService,
    WorkOrderService,
    WorkOrderLogService,
    ShadowService,
    UserService
  ) {
    var self = this;

    var userPermissions = UserService.getPermissions();
    var currentUser = UserService.getCurrent();

    var hasRound = workOrder.round && workOrder.round.length;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset(building);
    self.workOrderLogs = [];
    self.building = building;
    self.workOrder = workOrder;
    self.showRelationships = !hasRound;
    /**
     * This should be used for ANY child components that require the WO object through
     * the means of one way binding.  Our self.updateWorkOrder fn will take care of keeping
     * this and self.workOrder in sync
     */
    self.workOrderForOneWayBinds = angular.copy(workOrder);
    self.isOpen = isOpen();
    self.isClosed = isClosed();
    self.canRemoveAttachments = self.workOrder.inspection ? self.isOpen : true;
    self.fromInspection = fromInspection();
    self.permissions = getDefaultPermissions();
    self.totalWorkHours = 0;
    self.recentActivity = [];
    self.newNote = null;
    self.limit = 5;
    self.saving = false;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Work Order",
      time: self.workOrder.created_date,
      created_by: self.workOrder.created_by,
    };
    self.activityParams = {
      task: self.workOrder._id,
      method: "$in,DELETE,PATCH,POST,PUT",
    };
    self.noteParams = {};
    if (self.workOrder.request) {
      var request = self.workOrder.request._id || self.workOrder.request;
      self.noteParams.$or =
        "task=" + self.workOrder._id + ";request=" + request;
      self.noteParams.task = undefined; // to unset the existing work order query and replace it with the or
    }
    self.description = angular.copy(self.workOrder.description_text);
    self.dateRange = getDateRange();
    self.dateLabels = ["Start Date", "Due Date"];
    self.assignees = [];
    self.organization = OrganizationService.getCurrent();
    self.relationshipsLoading = true;
    self.relationships = [];
    self.associations = [];
    self.pinTypes = {};
    self.mapAssociations = {};

    if ($state.includes("app.building")) {
      self.detailsUiSref = "app.building.workOrder.details";
    } else {
      self.detailsUiSref = "app.workOrder.details";
    }

    // Functions
    self.createNote = createNote;
    self.showCancelDialog = showCancelDialog;
    self.showCompleteDialog = showCompleteDialog;
    self.showDeleteDialog = showDeleteDialog;
    self.print = print;
    self.showRescheduleDialog = showRescheduleDialog;
    self.showReopenDialog = showReopenDialog;
    self.showLogWorkDialog = showLogWorkDialog;
    self.updateDescription = updateDescription;
    self.fabActions = getFabActions();
    self.onUploadComplete = onUploadComplete;
    self.onRemove = onRemove;
    self.onAssigneesChange = onAssigneesChange;
    self.onLocationChange = onLocationChange;
    self.formatPhoneNumber = Utils.formatPhoneNumber;
    self.getScheduleFreqSummary = ScheduleService.getFrequencySummary;
    self.goToBuilding = goToBuilding;
    self.updateDate = updateDate;
    self.isDateInputActive = isDateInputActive;
    self.woUpdateFn = WorkOrderService.setRound;
    self.linkToRoomOrAsset = linkToRoomOrAsset;

    // Register events
    $scope.$on(EVENT_LOG_WORK, onLogWorkEventHandler);
    $scope.$on(EVENT_RESCHEDULE_WORK_ORDER, onRescheduleWorkOrderEventHandler);

    init();

    function init() {
      resetWorkOrderLogs();
      if (self.fromInspection) {
        getInspectionAndRoundTemplate();
      }

      if (self.workOrder.assignee_users) {
        UserService.getAll(self.organization._id, {
          _id: "$in," + self.workOrder.assignee_users.join(","),
        }).then(function (users) {
          self.assignees = users;
        });
      }

      if (self.showRelationships) {
        populateRelationships().then(function (relationships) {
          var populatedRelationships = [];
          var associations = [];
          var promises = [];
          // relationships is an array of rooms and assets in seperate arrays
          // smash them together here - might want to sort later?
          [].concat.apply([], relationships).forEach(function (relationship) {
            // populate the pinType value on the rooms and assets so we can show the pin icon
            promises.push(
              populatePinType(relationship).then(function (
                populatedRelationship
              ) {
                populatedRelationships.push(populatedRelationship);
              })
            );
          });
          $q.all(promises).then(function () {
            self.relationships = sortRelationships(populatedRelationships);

            var nextPromises = [];
            populatedRelationships.forEach(function (relationship) {
              nextPromises.push(
                AssociationService.getAll(self.organization._id, {
                  $in:
                    "upstream_entity=" +
                    relationship._id +
                    ";downstream_entity=" +
                    relationship._id,
                }).then(function (assocs) {
                  assocs.forEach(function (assoc) {
                    var associationsContainsAssoc = associations.find(function (
                      a
                    ) {
                      return a._id === assoc._id;
                    });
                    if (!associationsContainsAssoc) {
                      associations.push(assoc);
                    }
                  });
                })
              );
            });
            $q.all(nextPromises).then(function () {
              var populatedAssociations = associations;
              var finalPromises = [];

              associations.forEach(function (association) {
                finalPromises.push(
                  populatePinType(association.upstream_entity).then(function (
                    populatedAssociation
                  ) {
                    var index = populatedAssociations.findIndex(function (
                      assoc
                    ) {
                      assoc.upstream_entity._id === association.upstream_entity;
                    });
                    populatedAssociations[index] = populatedAssociation;
                  })
                );
                finalPromises.push(
                  populatePinType(association.downstream_entity).then(function (
                    populatedAssociation
                  ) {
                    var index = populatedAssociations.findIndex(function (
                      assoc
                    ) {
                      assoc.downstream_entity._id ===
                        association.downstream_entity;
                    });
                    populatedAssociations[index] = populatedAssociation;
                  })
                );
              });
              $q.all(finalPromises).then(function () {
                self.associations = populatedAssociations;

                self.relationships.forEach(function (relationship) {
                  var down = populatedAssociations.filter(function (assoc) {
                    return assoc.downstream_entity._id === relationship._id;
                  });
                  var up = populatedAssociations.filter(function (assoc) {
                    return assoc.upstream_entity._id === relationship._id;
                  });
                  self.mapAssociations[relationship._id] = [
                    sortAssociations(down, "downstream"),
                    sortAssociations(up, "upstream"),
                  ];
                });
                self.relationshipsLoading = false;
              });
            });
          });
        });
      }
    }

    function sortAssociations(associations, stream) {
      if (stream === "downstream") {
        return associations.sort(function (a, b) {
          // Association Text
          var associationTextA = a.association_type.upstream_text.toLowerCase();
          var associationTextB = b.association_type.upstream_text.toLowerCase();
          // Association Downstream Entity Name
          var upstreamEntityNameA = a.upstream_entity.name.toLowerCase();
          var upstreamEntityNameB = b.upstream_entity.name.toLowerCase();

          // Sort by association text
          if (associationTextA > associationTextB) {
            return 1;
          } else if (associationTextA < associationTextB) {
            return -1;
          } else {
            // Then sort by entity name
            if (upstreamEntityNameA > upstreamEntityNameB) {
              return 1;
            } else if (upstreamEntityNameA < upstreamEntityNameB) {
              return -1;
            }
          }
        });
      }

      if (stream === "upstream") {
        return associations.sort(function (a, b) {
          // Association Text
          var associationTextA =
            a.association_type.downstream_text.toLowerCase();
          var associationTextB =
            b.association_type.downstream_text.toLowerCase();
          // Association Downstream Entity Name
          var downstreamEntityNameA = a.downstream_entity.name.toLowerCase();
          var downstreamEntityNameB = b.downstream_entity.name.toLowerCase();

          // Sort by association text
          if (associationTextA > associationTextB) {
            return 1;
          } else if (associationTextA < associationTextB) {
            return -1;
          } else {
            // Then sort by entity name
            if (downstreamEntityNameA > downstreamEntityNameB) {
              return 1;
            } else if (downstreamEntityNameA < downstreamEntityNameB) {
              return -1;
            }
          }
        });
      }
    }

    function linkToRoomOrAsset(association, entity) {
      var entityModel = "";

      if (association) {
        entityModel =
          association.downstream_entity._id !== entity._id
            ? association.upstream_entity_model
            : association.downstream_entity_model;
      } else {
        entityModel = entity.pinType.protected_type;
      }

      var buildingId = entity.building._id || entity.building;

      $window.open(
        EnvService.getCurrentBaseUrl() +
          "/buildings/" +
          buildingId +
          "/" +
          entityModel.toLowerCase() +
          "s/" +
          entity._id +
          "/overview",
        "_blank"
      );
    }

    function onLogWorkEventHandler(event, eventBody) {
      if (
        eventBody &&
        (eventBody.task === self.workOrder._id || !eventBody.task)
      ) {
        resetWorkOrderLogs();
      }
    }

    function onRescheduleWorkOrderEventHandler(event, eventBody) {
      if (eventBody.startDate && eventBody.endDate) {
        self.dateRange = getDateRange();
      }
    }

    function resetWorkOrderLogs() {
      WorkOrderLogService.getByTaskId(building._id, self.workOrder._id).then(
        function (response) {
          self.workOrderLogSums = response.reduce(function (sumMap, log) {
            var displayName = log.work_performed_by_user.display_name;
            sumMap[displayName] = (sumMap[displayName] || 0) + log.work_minutes;
            return sumMap;
          }, {});
          self.totalWorkHours = response.reduce(function (sum, log) {
            return sum + log.work_minutes;
          }, 0);
        }
      );
    }

    function populateRelationships() {
      return $q.all([
        WorkOrderService.getAssets(workOrder),
        WorkOrderService.getRooms(workOrder),
      ]);
    }

    function populatePinType(relationship) {
      return $q(function (resolve) {
        var exists = self.pinTypes[relationship.pinType];
        if (exists) {
          relationship.pinType = self.pinTypes[relationship.pinType];
          return resolve(relationship);
        } else {
          PinTypeService.getById(relationship.building, relationship.pinType)
            .then(function (pinType) {
              relationship.pinType = pinType;
              if (!self.pinTypes[pinType._id]) {
                self.pinTypes[pinType._id] = pinType;
              }
            })
            .then(function () {
              return resolve(relationship);
            });
        }
      });
    }

    function sortRelationships(populatedRelationships) {
      return populatedRelationships.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }

    function getFabActions() {
      return [
        {
          icon: "print",
          label: "Print",
          action: print,
        },
      ];
    }

    function isOpen() {
      return (
        self.workOrder.is_scheduled ||
        self.workOrder.is_open ||
        self.workOrder.is_overdue
      );
    }

    function isClosed() {
      return self.workOrder.is_completed || self.workOrder.is_canceled;
    }

    function fromInspection() {
      return self.workOrder.source === "inspection";
    }

    function getDefaultPermissions() {
      var isScheduled = self.workOrder.is_scheduled;
      var assignees = self.workOrder.assignee_users.map(function (assignee) {
        if (assignee._id) return assignee._id;
        return assignee;
      });
      var isAssigned = assignees.indexOf(currentUser._id) > -1;
      var canComplete = userPermissions.task.complete_any || isAssigned;
      var canLogWork =
        userPermissions.work_order_log.create_any ||
        userPermissions.work_order_log.create_own_log_on_any_task ||
        isAssigned;

      return {
        canAssign: userPermissions.task.assign && self.isOpen,
        canComplete:
          canComplete && self.isOpen && !isScheduled && !self.fromInspection,
        canCancel: userPermissions.task.cancel && self.isOpen,
        canDelete: userPermissions.task.remove && self.isClosed,
        canReopen: userPermissions.task.reopen && self.isClosed,
        canUpdate: userPermissions.task.update,
        canEditAttachments: userPermissions.task.update,
        canLogWork: canLogWork,
        canViewIP: userPermissions.inspection_program
          ? userPermissions.inspection_program.read
          : false,
      };
    }

    function updatePermissions() {
      self.permissions = getDefaultPermissions();
    }

    function updateWorkOrder(newWorkOrder) {
      if (newWorkOrder) {
        angular.copy(newWorkOrder, self.workOrder);
        self.workOrderForOneWayBinds = angular.extend({}, newWorkOrder);
        self.isOpen = isOpen();
        self.isClosed = isClosed();
        init();
        updatePermissions();
        refreshActivity();
      }
    }

    function onError(err) {
      ToastService.showError(err);
      return $q.reject(err);
    }

    /**
     * Update the work order's description
     */
    function updateDescription($event) {
      var data = {};
      data.description_text = $event.newValue;

      return WorkOrderService.update(
        self.building._id,
        self.workOrder._id,
        data
      )
        .then(function (updatedWorkOrder) {
          updateWorkOrder(updatedWorkOrder);
          self.description = angular.copy(self.workOrder.description_text);
        })
        .catch(onError);
    }

    function onAssigneesChange(event) {
      self.saving = true;

      var assignees = event.value;

      // get _ids to send to the WorkOrderService
      var assigneeIds = assignees.map(function (assignee) {
        return assignee._id;
      });
      var data = {
        assignee_users: angular.isEmpty(assigneeIds) ? [] : assigneeIds,
      };

      return WorkOrderService.assign(
        self.building._id,
        self.workOrder._id,
        data
      )
        .then(function (updatedWorkOrder) {
          // update the assignees in the current or next digest cycle
          $scope.$evalAsync(() => {
            self.assignees = angular.copy(updatedWorkOrder.assignee_users);
          });
          // update the wo in the next digest cycle
          $timeout(
            () => {
              updateWorkOrder(updatedWorkOrder);
            },
            1,
            false
          );
        })
        .catch(onError)
        .finally(() => {
          self.saving = false;
        });
    }

    /**
     * Update the work order's location fields. Fields included:
     */
    function onLocationChange($event) {
      if ($event && $event.model) {
        updateWorkOrder($event.model);
      }
    }

    function showCompleteDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };

      CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          updateWorkOrder(workOrders[0]);
        })
        .catch(ToastService.showError);
    }

    function showDeleteDialog() {
      var locals = {
        workOrders: [{ _id: self.workOrder._id, building: self.building._id }],
      };

      DeleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderID) {
          Router.go("app.workOrders");
        })
        .catch(ToastService.showError);
    }

    function showReopenDialog() {
      var locals = {
        workOrders: [{ _id: self.workOrder._id, building: self.building._id }],
      };

      ReopenWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderID) {
          return WorkOrderService.getById(building._id, workOrderID);
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function showRescheduleDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };

      RescheduleWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          return WorkOrderService.getById(building._id, workOrders[0]._id);
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function showLogWorkDialog() {
      WorkOrderLogDialog.show({
        locals: {
          workOrders: [self.workOrder],
        },
      }).then(function () {
        ShadowService.sendEvent("workorder", "add-log", "actions-card");
        $timeout(function () {
          $scope.$broadcast("activity:refresh");
        });
      });
    }

    function createNote(noteText, sendToRequester) {
      var data = {
        task: self.workOrder._id,
        building: self.building._id,
        text: noteText,
        is_public: sendToRequester,
      };

      if (sendToRequester) {
        data.emailRequester = true;
      }
      if (self.workOrder.request) {
        data.request = self.workOrder.request._id || self.workOrder.request;
      }

      NoteService.create(self.building._id, data)
        .then(function (note) {
          refreshActivity();

          if (sendToRequester) {
            ShadowService.sendEvent("workorder-activity", "send-existing-note");
          }
        })
        .catch(ToastService.showError);
    }

    function print() {
      var route = WorkOrderService.buildListRoute(self.building._id);
      var locals = {
        selected: [self.workOrder._id],
        route: route,
      };

      return PrintDialog.show({ locals: locals });
    }

    function showCancelDialog() {
      var locals = {
        workOrders: [{ _id: self.workOrder._id, building: self.building._id }],
      };

      CancelWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderID) {
          return WorkOrderService.getById(building._id, workOrderID);
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    /**
     * For WOs linked to an inspection, get inspection, inspection program, and round
     * template info to display on the overview page
     */
    function getInspectionAndRoundTemplate() {
      self.inspection = null;
      self.roundTemplate = null;

      var inspectionId = self.workOrder.inspection._id;
      var organizationId = self.building.organization;

      if (!inspectionId || !organizationId) return;

      InspectionService.getById(organizationId, inspectionId)
        .then(function (inspection) {
          self.inspection = inspection;
          self.inspectionProgram = inspection.inspection_program;

          // if inspectionProgram is not set, this is an ad-hoc inspection,
          // and it does not have inspection components. set fromAdHocInspection
          // so we don't show IP stuff on the overview page
          if (!self.inspectionProgram) {
            self.fromAdHocInspection = true;
            return;
          }
          // find the correct inspection component for this building
          var roundTemplateId;
          var inspectionComponents =
            inspection.inspection_program.inspection_components;
          for (var i = 0; i < inspectionComponents.length; i++) {
            if (inspectionComponents[i].building === self.building._id) {
              roundTemplateId = inspectionComponents[i].round_template;
              break;
            }
          }
          return roundTemplateId;
        })
        .then(function (roundTemplateId) {
          if (!roundTemplateId) return;
          // set the round template
          RoundTemplateService.getById(organizationId, roundTemplateId).then(
            function (roundTemplate) {
              self.roundTemplate = roundTemplate;
            }
          );
        })
        .then(function () {
          if (!self.inspectionProgram) {
            return;
          }
          // set display text for the inspection program frequency
          self.inspectionProgramFrequencyText =
            InspectionProgramService.getFrequencySummary(
              self.inspectionProgram
            );
        })
        .catch(onError);
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function onUploadComplete() {
      $timeout(function () {
        refreshActivity();
      }, 0);
    }

    function onRemove() {
      $timeout(function () {
        refreshActivity();
      }, 0);
    }

    function goToBuilding() {
      var url = $state.href("app.building.detail", {
        buildingId: building._id,
      });
      $window.open(url, "_self");
    }

    function getDateRange() {
      var start;
      if (self.utcOffset) {
        start = moment(self.workOrder.start_date).toDate();
      } else {
        // This else block is only here to maintain old logic
        // I can't tell exactly why we're doing this, so I'm afraid that removing it will break stuff
        // I don't think we should be changing the instant in time here like this...
        var offset = moment().utcOffset();
        start = moment(self.workOrder.start_date)
          .utc()
          .utcOffset(offset, true)
          .toDate();
      }
      var end = moment(self.workOrder.due_date).toDate();
      return {
        start: start,
        end: end,
      };
    }

    function updateDate($event) {
      if ($event.invalid || self.saving) {
        return;
      }
      var oldDateRange = getDateRange();
      var oldStart = oldDateRange.start.valueOf() || null;
      var oldEnd = oldDateRange.end.valueOf() || null;
      var newStart = $event.newValue.start.valueOf() || null;
      var newEnd = $event.newValue.end.valueOf() || null;
      // Only saving once user has blurred out of date field
      // Needed because date range onChange events call onBlur as well.
      if (newStart !== oldStart || newEnd !== oldEnd) {
        self.saving = true;
        return WorkOrderService.update(self.building._id, self.workOrder._id, {
          start_date: $event.newValue.start,
          due_date: $event.newValue.end,
        })
          .then(function (updatedWorkOrder) {
            self.saving = false;
            updateWorkOrder(updatedWorkOrder);
          })
          .catch(function () {
            self.saving = false;
            ToastService.showError;
          });
      }
    }

    function isDateInputActive() {
      var $activeClasses = document.activeElement.className;
      return $activeClasses.indexOf("abx-date-input__field") >= 0;
    }
  }
})();
