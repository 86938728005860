(function () {
  angular
    .module("akitabox.core.auth.login")
    .controller("LoginController", LoginController)
    .controller("ForgotPasswordController", ForgotPasswordController);

  /* @ngInject */
  function LoginController(
    // Angular
    $cookies,
    $q,
    $location,
    $window,
    $timeout,
    // AkitaBox
    patterns,
    markdown,
    // UI Router
    $state,
    $stateParams,
    // Material
    $mdDialog,
    // Constants
    MINIMUM_RENDER_DELAY,
    MOBILE_PREFERENCE_COOKIE,
    SAML_LOGIN_REDIRECT,
    // Services
    AuthService,
    BuildingService,
    EnvService,
    HttpService,
    OrganizationService,
    ShadowService,
    PublicBannerMessageService,
    UserService
  ) {
    var self = this;

    var LOGIN_INTERVAL = 300;
    var GEN_ERROR_MSG = "Sorry, we weren't able to complete your request.";
    var EMAIL_PATTERN = new RegExp(patterns.EMAIL, "i");

    // Attributes
    self.loading = false;
    self.showNext = true;
    self.showPasswordForm = false;
    self.errorMessage = null;
    self.emailInvalid = true;
    self.user = {
      email: null,
      password: null,
    };

    self.isCookieEnabled = hasCookies($window);
    if (!self.isCookieEnabled) {
      self.errorMessage = "You must have cookies enabled to complete log in";
    }
    self.bannerMessageText = "";

    // Get state params if there are any
    self.validation_token = $stateParams.validation_token;
    self.redirect_url = $stateParams.redirect_url;

    // do not allow redirects to logout on successful login (can happen if there are simultaneous API calls that both
    // return 401) and also do not redirect if the redirect_url is only a /
    const isUrlAbxDomain = (url) => {
      const hasDomainRegex = new RegExp(
        "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}"
      );
      if (!hasDomainRegex.test(url)) return true;
      const domainURL = new URL(EnvService.getBaseUrl()).host;
      const domainRegex = `^(.*${domainURL}.*)$`;
      return new RegExp(domainRegex, "g").test(url);
    };

    if (
      self.redirect_url &&
      (/^(\/|\/logout|\/login)$/.test(self.redirect_url) ||
        !isUrlAbxDomain(self.redirect_url))
    ) {
      $location.search("redirect_url", null);
      self.redirect_url = null;
    }

    // Functions
    self.resetForm = resetForm;
    self.federate = federate;
    self.login = login;
    self.forgotPassword = forgotPassword;
    self.onEmailChange = onEmailChange;
    self.onPasswordChange = onPasswordChange;

    // ------------------------
    //   Life cycle
    // ------------------------

    self.$onInit = function () {
      if ($stateParams.email) {
        self.onEmailChange({ model: $stateParams.email, invalid: false });
      }
      var md = new markdown();
      var connectivityCheckMessage = "";

      // Check connectivity by pinging /flags
      HttpService.get("/flags")
        .then(function (res) {
          if (res && res["customer_accounts_in_identities"]) {
            $window.location.href = `${EnvService.getBaseUrl()}/auth/login`;
          } else if (res) {
            connectivityCheckMessage = "";
          }
        })
        .catch(function (err) {
          connectivityCheckMessage =
            "We are having trouble connecting to AkitaBox using your current browser.  Please ensure that you are currently connected to the internet and that your browser or network is not blocking any activity to akitabox.com.  If you continue to experience difficulty connecting, please contact our support so we may help!";
        })
        .finally(function () {
          // Then check if we have a specific banner message, override connectivity warning if we do
          PublicBannerMessageService.get().then(function (msgObj) {
            self.bannerMessageText = msgObj
              ? md.render(msgObj.message)
              : connectivityCheckMessage;
          });
        });
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function resetForm() {
      self.showNext = true;
      self.showPasswordForm = false;
      self.user.password = null;
      return;
    }

    function federate() {
      if (self.loading) return;

      if (self.emailInvalid || !self.user.email) {
        self.errorMessage = "Email is invalid";
        return;
      }

      self.loading = true;
      AuthService.federate(self.user.email)
        .then(function (data) {
          if (data && data.redirect) {
            // make sure we save where they were currently trying to go to before we try to log them in through SAML
            // so when they come back, they are taken to where they were suppose to go
            var urlParams = $location.search();
            if (urlParams && urlParams.redirect_url) {
              var domain =
                "." + EnvService.getBaseUrl().split("://")[1].split(":")[0];

              $cookies.put(SAML_LOGIN_REDIRECT, urlParams.redirect_url, {
                path: "/",
                domain: domain,
              });
            }

            // redirect user to SAML login
            var prefix = data.redirect.indexOf("//") >= 0 ? "" : "//";
            $window.location.href = prefix + data.redirect;
          } else {
            // Hide next button
            self.showNext = false;
            $timeout(function () {
              // Show password field
              // Make sure the form only shows up AFTER the Next button has disappeared
              self.showPasswordForm = true;
            }, MINIMUM_RENDER_DELAY);
          }
        })
        .catch(function (response) {
          var message = GEN_ERROR_MSG;
          // Redirect to send validation page if the user tries to login
          // for the first time without the correct validation_token
          if (response) {
            if (
              response.data &&
              response.data.error &&
              response.data.error.message
            ) {
              message = response.data.error.message;
            }
          }

          self.errorMessageEmail = message;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function login() {
      var willNavigateAfterLogin = false;
      if (self.loading || !self.user.email || !self.user.password) {
        return;
      }

      self.loading = true;
      AuthService.login(
        self.user.email,
        self.user.password,
        self.validation_token
      )
        .then((data) => {
          return handleSrpRedirect().then(() => data);
        })
        .then(function (data) {
          var redirect_url = self.redirect_url;
          var devicePreference = $cookies.get(MOBILE_PREFERENCE_COOKIE);
          if (redirect_url) {
            $window.location.href = redirect_url;
            return data;
          } else if (devicePreference === "mobile") {
            $window.location.href = "/m";
            return data;
          }
          // Initialize the organization service
          // which sets the current organization based on subdomain
          return OrganizationService.init()
            .then(function () {
              // Initialize the building service
              // which sets the current building from local storage
              return BuildingService.init();
            })
            .then(function () {
              return data;
            })
            .catch(function (err) {
              // Unable to find organization that corresponds to the subdomain
              if (err && err.status === 404) {
                return $state.go("root.404", null, {
                  location: $location.url(),
                });
              }
              return $q.reject(err);
            });
        })
        .then(function (data) {
          if (data && data.account) {
            willNavigateAfterLogin = true;
            // If the response data has an account with an email domain, then send that to GA
            ShadowService.setUser(data.account);
            var currentBuilding = BuildingService.getCurrent();
            if (currentBuilding) {
              return $state
                .go("app.building.detail", {
                  buildingId: currentBuilding._id,
                })
                .catch(function (err) {
                  // Ignore transition superseded
                  // We need to support redirects to activity/info based
                  // on organization flags (show_tasks)
                  if (err.message !== "transition superseded") {
                    return $q.reject(err);
                  }
                });
            }
            return $state.go("app.dashboard");
          } else {
            self.errorMessage = GEN_ERROR_MSG;
          }
        })
        .catch(function (response) {
          let message = GEN_ERROR_MSG;
          // Redirect to send validation page if the user tries to login
          // for the first time without the correct validation_token
          if (response) {
            if (response.status === 403) {
              return $state.go("auth.sendValidation", {
                email: self.user.email,
              });
            } else if (
              response.data &&
              response.data.error &&
              response.data.error.message
            ) {
              // Check to see if the account has been locked and redirect to correct page
              if (
                response.data.error.message ===
                "Account locked due to too many failed login attempts"
              ) {
                return $state.go("auth.lockedOut", {
                  email: self.user.email,
                });
              }

              message = response.data.error.message;
            }
          }

          self.errorMessage = message;
        })
        .finally(function () {
          if (!willNavigateAfterLogin) {
            $timeout(function () {
              self.loading = false;
            }, LOGIN_INTERVAL);
          }
        });
    }

    function handleSrpRedirect() {
      return $q((resolve) => {
        /**
         * We only care to redirect the user to the SRP if they are not already being redirected to
         * - account settings, because even srp users should be able to change their account info
         * - site admin, because it should handle a non admin correctly by itself
         * - cm, because it should handle a non cm user correctly by itself
         * - org settings, because it should handle a non admin correctly by itself
         */
        // strip protocol from site admin url
        const siteAdminUrl = EnvService.getSiteAdminUrl().replace(
          /(http:|https:)/,
          ""
        );
        const siteAdminRegex = new RegExp(`^${siteAdminUrl}`, "i");
        if (
          self.redirect_url &&
          (/^[/]?account/.test(self.redirect_url) ||
            siteAdminRegex.test(self.redirect_url) ||
            /^[/]?cm/.test(self.redirect_url) ||
            /^[/]?organization\/settings/.test(self.redirect_url))
        ) {
          // the next fn in this promise chain needs data
          return resolve();
        }

        return HttpService.get("/identity/users").then((users) => {
          if (users.length === 0) {
            // this is probably an invitee or an absolutely new account, we don't bother with these
            // go back to normal login process
            return resolve();
          } else if (users.length > 1) {
            // Handle an identity with access to more than one org with the possiblity of them
            // being an SRP user within the current org they are accessing
            return OrganizationService.init($location.url()).then(() => {
              // get the current org this identity is accessing
              const org = OrganizationService.getCurrent();
              if (!org) {
                // user has no access to orgs, so we need to ignore this workflow and return to normal login workflow
                return resolve();
              }
              // see if they ave a srp user within the org
              const orgUser = users.find(
                (user) =>
                  user.organization._id === org._id &&
                  user.permission_group.name === "Service Request Portal"
              );
              if (!orgUser) {
                // user is logging into an org where they have normal access, return to normal login workflow
                return resolve();
              }
              // We've determined the identity is accessing an org which they only have SRP access level
              // redirect them to the srp redirect page

              // the srpRedirect requires the user service having already been initiated
              return UserService.init(orgUser.organization._id).then(() => {
                return $state.go("srpRedirect");
              });
            });
          }

          // currently both are populated, code expects this
          const { organization, permission_group } = users[0];
          if (permission_group.name !== "Service Request Portal") {
            // user has normal access to this org, don't redirect to SRP
            return resolve();
          }

          // this user only has access to this SRP, redirect them to it
          // dont need to resolve() here, as this will force code to reload
          $window.location.href = organization.portal_url;
        });
      });
    }

    function forgotPassword() {
      // Don't do anything when they close the dialog
      $mdDialog.show({
        templateUrl: "app/core/modules/auth/login/forgot-password-dialog.html",
        controller: "ForgotPasswordController",
        controllerAs: "vm",
        locals: {
          email: self.user.email,
        },
        bindToController: true,
      });
    }

    function onEmailChange($event) {
      // Clear out previous error message
      self.errorMessage = null;
      if ($event) {
        var email = $event.model;
        self.user.email = email;
        self.emailInvalid = $event.invalid || !EMAIL_PATTERN.test(email);
        resetForm();
      } else {
        self.emailInvalid = true;
      }
    }

    function onPasswordChange($event) {
      // Clear out previous error message
      self.errorMessage = null;
      if ($event) {
        self.user.password = $event.model;
      }
    }
  }

  /* @ngInject */
  function ForgotPasswordController($mdDialog, AuthService) {
    var self = this;

    // Attributes
    self.sent = false;

    // Functions
    self.cancel = cancel;
    self.resetPassword = resetPassword;

    function cancel() {
      $mdDialog.hide();
    }

    function resetPassword() {
      AuthService.forgotPassword(self.email)
        .then(function () {
          self.sent = true;
        })
        .catch(function (response) {
          var message = "Sorry, we weren't able to complete your request.";
          if (
            response &&
            response.data &&
            response.data.error &&
            response.data.error.message
          ) {
            message = response.data.error.message;
          }

          self.errorMessage = message;
        });
    }
  }

  // =================
  // Private Functions
  // =================
  function hasCookies(window) {
    /**
     * Modernizr test used to detect cookies. Source:
     * https://github.com/Modernizr/Modernizr/blob/acb3f0d990d3424ee7fade52796f9f97920354fc/feature-detects/cookies.js
     */
    // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
    // configurations. For example, when blocking cookies via the Advanced
    // Privacy Settings in IE9, it always returns true. And there have been
    // issues in the past with site-specific exceptions.
    // Don't rely on it.

    // try..catch because some in situations `document.cookie` is exposed but throws a
    // SecurityError if you try to access it; e.g. documents created from data URIs
    // or in sandboxed iframes (depending on flags/context)
    var document = window.document;
    try {
      // Create cookie
      document.cookie = "cookietest=1";
      var ret = document.cookie.indexOf("cookietest=") !== -1;
      // Delete cookie
      document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
      return ret;
    } catch (e) {
      return false;
    }
  }
})();
