(function () {
  angular
    .module("akitabox.desktop.request.organizationList")
    .controller(
      "OrganizationRequestListController",
      OrganizationRequestListController
    );

  /* @ngInject */
  function OrganizationRequestListController(
    // Angular
    $location,
    $scope,
    $timeout,
    $q,
    // Constants
    EVENT_REQUEST_CREATE,
    // Services
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedAssetFilter,
    ManagedRoomFilter,
    ManagedLevelFilter,
    ManagedBuildingFilter,
    ManagedFilterHelpers,
    RequestService,
    ToastService,
    // Dialogs
    ExportDialog,
    // Resolve
    status,
    organization
  ) {
    var self = this;

    // Filter Configs
    var subjectConfig;
    var idConfig;
    var descriptionConfig;
    var requesterEmailConfig;
    var completionDateConfig;
    var submittedDateConfig;
    var deniedDateConfig;
    var buildingConfig;
    var customFieldConfig;

    // Attributes
    self.requests = null;
    self.organization = organization;
    self.status = status;
    self.fabActions = getFabActions();
    self.activeFilters = null;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the fixed ?status=... query
        query.status = status;
        // update the list
        changeFilters(query);
      },
    });

    subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    idConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      inputType: "number",
      prefix: "SR-",
      modelValueToChipText: function (inputValue) {
        return "SR-" + inputValue;
      },
    });

    descriptionConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Description",
      queryField: "description",
    });

    requesterEmailConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Requester Email",
      queryField: "requester_email",
    });

    completionDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Completion Date",
      queryField: "completed_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    submittedDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Date Submitted",
      queryField: "created_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    deniedDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Date Denied",
      queryField: "denied_date",
      inputType: "date-range",
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(descriptionConfig);
    self.filterBarManager.addFilterConfiguration(requesterEmailConfig);
    self.filterBarManager.addFilterConfiguration(submittedDateConfig);
    self.filterBarManager.addFilterConfiguration(completionDateConfig);
    self.filterBarManager.addFilterConfiguration(deniedDateConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);

    if (self.organization.show_custom_srp_field) {
      var customLabel = self.organization.srp_custom_label;
      var customOptions = self.organization.srp_custom_options;
      if (customOptions && customOptions.length) {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
          inputType: "typeahead",
          getEnumOptions: function () {
            return customOptions;
          },
          modelValueToFilterValue: function (option) {
            return option;
          },
          modelValueToChipText: function (option) {
            return option;
          },
          filterValueToModelValue: function (queryStringValue) {
            return [queryStringValue];
          },
        });
      } else {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
        });
      }
      self.filterBarManager.addFilterConfiguration(customFieldConfig);
    }

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.fetchRequests = fetchRequests;
    self.fetchAllRequests = fetchAllRequests;
    self.changeFilters = changeFilters;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_REQUEST_CREATE, onRequestCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportRequests,
        },
      ];
    }

    function exportRequests() {
      var filters = angular.extend({}, { status: status }, self.activeFilters);
      var locals = {
        route: RequestService.buildOrganizationListRoute(organization._id),
        filters: filters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Handle request creation event
     *
     * @param {Event}     $event    Angular event
     * @param {Request[]} requests  List of new requests
     */
    function onRequestCreate($event, requests) {
      // We can only add one request at a time
      var request = requests[0];
      if (!self.requests) {
        self.requests = [];
      }
      self.requests.unshift(request);
      $timeout(function () {
        $scope.$broadcast("list:refreshClickEvents");
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchRequests(skip, limit) {
      var doFetch = function () {
        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RequestService.getByOrganization(organization._id, filters).then(
          function (requests) {
            if (angular.isArray(self.requests)) {
              self.requests = self.requests.concat(requests);
            } else {
              self.requests = requests;
            }
            return requests.length;
          }
        );
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllRequests(limit) {
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RequestService.getAllByOrganization(
          organization._id,
          filters
        ).then(function (requests) {
          self.requests = requests;
          return requests.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.requests = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
