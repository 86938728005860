(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.workOrder
   */
  angular
    .module("akitabox.desktop.directives.details.workOrder", [
      "akitabox.constants",
      "akitabox.core.constants",
      "akitabox.core.services.flag",
      "akitabox.core.services.floor",
      "akitabox.core.services.helpers",
      "akitabox.core.services.identity",
      "akitabox.core.services.issueType",
      "akitabox.core.services.maintenanceType",
      "akitabox.core.services.organization",
      "akitabox.core.services.room",
      "akitabox.core.services.schedule",
      "akitabox.core.services.timeZone",
      "akitabox.core.services.trade",
      "akitabox.core.services.type",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.workOrder.complete",
      "akitabox.ui.dialogs.editLocation",
      "akitabox.ui.dialogs.assign.assign",
      "akitabox.ui",
      "angular.filter",
      "ui.router",
    ])
    .controller("AbxWorkOrderDetailsController", AbxWorkOrderDetailsController)
    .directive("abxWorkOrderDetails", AbxWorkOrderDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.workOrder
   * @name AbxWorkOrderDetailsDirective
   *
   * @description
   * `<abx-floor-details>` displays the details of a work order and allows for editing
   * of fields based on the current user's permissions
   *
   * @param {Object}      ng-model            The component's model (floor)
   * @param {Object}      ng-model-options    Allows tuning of the way in which
   *                                          the `ng-model` is updated
   * @param {expression}  ng-change           Expression evaluated when the model
   *                                          value changes
   * @param {Object}      abx-building        Building that the floor belongs to
   *
   * @usage
   * <hljs lang="html">
   *   <abx-work-order-details
   *       ng-model="vm.workOrder"
   *       ng-change="vm.onWorkOrderChanged(workOrder)"
   *       abx-building="vm.building">
   *   </abx-work-order-details>
   * </hljs>
   *
   * @ngInject
   */
  function AbxWorkOrderDetailsDirective(
    $log,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_OPEN
  ) {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/details/work-order/work-order-details.html",
      require: ["abxWorkOrderDetails", "ngModel"],
      controller: "AbxWorkOrderDetailsController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Verify we have a building
      if (angular.isEmpty(vm.building)) {
        return $log.error(
          "<abx-work-order-details>: abx-building is required, but none was given"
        );
      }

      // Add external change listener
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model value
       *
       * @param  {Object} value   New model value
       * @return {Object}         Formatted model value
       */
      function onExternalChange(value) {
        vm.workOrder = angular.copy(value);
        vm.originalWorkOrder = angular.copy(value);
        vm.isCanceled = angular.equals(
          vm.workOrder.status,
          WORK_ORDER_STATUS_COMPLETED
        );
        vm.isOpen = angular.equals(vm.workOrder.status, WORK_ORDER_STATUS_OPEN);
        vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for abx-floor-details
   *
   * @ngInject
   */
  function AbxWorkOrderDetailsController(
    // Angular
    $q,
    $timeout,
    $state,
    // Constants
    models,
    PRIORITIES,
    // Libraries,
    moment,
    // Dialogs
    CompleteWorkOrderDialog,
    EditLocationDialog,
    AssignDialog,
    // Services
    IssueTypeService,
    MaintenanceTypeService,
    OrganizationService,
    ScheduleService,
    ServiceHelpers,
    TimeZoneService,
    ToastService,
    TradeService,
    TypeService,
    UserService,
    WorkOrderService,
    WorkOrderLogService
  ) {
    var self = this;

    var tradesRequest;

    // ------------------------
    //   Attributes
    // ------------------------

    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.organization = OrganizationService.getCurrent();
    self.building = angular.isDefined(self.building) ? self.building : null;
    self.workOrder = null;
    self.fromInspection = false;
    self.isOpen = null;
    self.isCanceled = null;
    self.isEditing = false;
    self.canUpdate = false;
    self.canComplete = false;
    self.canAssign = false;
    self.PRIORITIES = PRIORITIES;
    self.minCompletedDate = null;
    self.numOpenSections = 0; // Keeps track of open input sections (used for isEditing logic)
    self.rendering = true;
    self.today = new Date();
    self.buildingUrl = null;
    self.workOrderLogStats = {
      totalLoggedMinutes: 0,
      totalCost: 0,
    };
    self.scheduleFrequencyDisplay = null;

    /** work order's level, room, and asset */
    self.level = null;
    self.room = null;
    self.asset = null;

    // Functions
    self.init = init;
    self.complete = complete;
    self.fetchCustomFieldValues = fetchCustomFieldValues;
    self.fetchTypes = fetchTypes;
    self.fetchTrades = fetchTrades;
    self.updateCategory = updateCategory;
    self.updateDetails = updateDetails;
    self.updateLocation = updateLocation;
    self.updateDates = updateDates;
    self.onClose = onClose;
    self.onEdit = onEdit;
    self.onTypeChange = onTypeChange;
    self.calculateWoLogTotals = calculateWoLogTotals;
    self.onEditAssigneesHandler = onEditAssigneesHandler;
    self.onAddStop = onAddStop;
    self.onBlur = onBlur;

    function init() {
      self.buildingUrl = getBuildingUrl();
      setPermissions();
      setDates();
      fetchTrades();
      getAssignees(self.workOrder);
      calculateWoLogTotals();
      populateLocationData();

      // Convert dates to Date objects
      self.workOrder.start_date = new Date(self.workOrder.start_date);
      self.workOrder.due_date = new Date(self.workOrder.due_date);
      if (!angular.isEmpty(self.workOrder.completed_date)) {
        self.workOrder.completed_date = new Date(self.workOrder.completed_date);
      }

      // get scheduleFrequencyDisplay for related maintenance schedule
      if (self.workOrder && self.workOrder.future_task) {
        self.scheduleFrequencyDisplay = ScheduleService.getFrequencySummary(
          self.workOrder.future_task,
          self.utcOffset
        );
      }

      // Set min completed date to one day after the start date
      self.minCompletedDate = new Date(self.workOrder.start_date);
      self.minCompletedDate.setDate(self.minCompletedDate.getDate() + 1);

      self.fromRequest = Boolean(self.workOrder.request);
      self.fromSchedule = Boolean(self.workOrder.future_task);

      // Set types and trades information
      setType();
      self.showTrade = self.building.show_trades;
      self.showIssueTypes =
        !self.fromSchedule && self.building.show_issue_types;
      self.showMaintenanceTypes =
        !self.fromRequest && self.building.show_maintenance_types;
      self.showType = self.showIssueTypes || self.showMaintenanceTypes;
      self.requireTrade = self.building.require_trades;
      self.requireType =
        self.building.require_issue_types &&
        self.building.require_maintenance_types;

      // Custom SRP field info
      var organization = OrganizationService.getCurrent();
      self.showCustomSRField = organization.show_custom_srp_field;
      if (self.showCustomSRField) {
        self.customFieldLabel = organization.srp_custom_label;
        self.customFieldOptions = organization.srp_custom_options;
        self.customFieldPlaceholderText =
          "Enter " + organization.srp_custom_label;
      }

      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    /**
     * Populate's self.room, self.level, and self.asset from the work order.
     * @return { Promise<void> }
     */
    function populateLocationData() {
      self.assets = WorkOrderService.getAssets(self.workOrder);
      self.rooms = WorkOrderService.getRooms(self.workOrder);
      self.levels = WorkOrderService.getLevels(self.workOrder);

      if (self.rooms.length === 1) {
        return parseRoomDisplayValue(self.rooms[0]);
      }
      return $q.resolve();
    }

    function parseRoomDisplayValue(room) {
      if (!room) {
        self.roomDisplayName = null;
        return $q.resolve();
      }
      return ServiceHelpers.getRoomDisplayName(room)
        .then(function (displayName) {
          self.roomDisplayName = displayName || room.name;
        })
        .catch(function (err) {
          self.roomDisplayName = room.name;
          onError(err);
        });
    }

    /**
     * Get the total cost and total minutes worked from logs associated with this work order
     */
    function calculateWoLogTotals() {
      // Check if we have work orders to display the totals
      if (!self.workOrder) {
        // Keep return type the same
        return $q.resolve();
      }

      return $q
        .all([
          WorkOrderLogService.getStatsByTaskId(
            self.building._id,
            self.workOrder._id,
            {
              operator_field: "work_minutes",
              operator: "sum",
            }
          ),
          WorkOrderLogService.getStatsByTaskId(
            self.building._id,
            self.workOrder._id,
            {
              operator_field: "cost",
              operator: "sum",
            }
          ),
        ])
        .then(function (results) {
          var workMinutes = results[0][0].result;
          var cost = results[1][0].result;

          self.workOrderLogStats.totalLoggedMinutes = workMinutes;
          self.workOrderLogStats.totalCost = cost;
        })
        .catch(function (error) {
          ToastService.showError(new Error("Unable to get activity"));
          return $q.reject(error);
        });
    }

    /**
     * Function to be called on promise rejections. Shows error, then propogates it
     */
    function onError(err) {
      ToastService.showError(err);
      return $q.reject(err);
    }

    /**
     * Called whenever an input section is opened
     */
    function onEdit() {
      self.isEditing = true;
      self.numOpenSections += 1;
    }

    function onAddStop() {
      EditLocationDialog.show({
        locals: {
          building: self.building,
          model: self.workOrder,
          modelType: models.WORK_ORDER.MODEL,
          type: "append-inspection-stop",
        },
      }).then(onSave);
    }

    /**
     * Called whenever an input section is closed. Checks the number of open sections before setting isEditing
     */
    function onClose() {
      self.numOpenSections -= 1;
      if (self.numOpenSections < 1) {
        self.isEditing = false;
        self.numOpenSections = 0; // Safeguard in case it somehow becomes negative
      }
    }

    /**
     * Called whenever an input section is successfully saved. Responsible for setting the scoped and parent
     * models.
     */
    function onSave(updatedWorkOrder) {
      self.onClose();
      self.workOrder = updatedWorkOrder;
      self.originalWorkOrder = angular.copy(updatedWorkOrder);
      self.ngModelCtrl.$setViewValue(
        angular.extend(self.ngModelCtrl.$modelValue, self.workOrder)
      );
      populateLocationData();
      getAssignees(self.workOrder);
    }

    /**
     * Fetch and set the current user's permissions for the work order
     */
    function setPermissions() {
      var assignees = self.workOrder.assignee_users.map(function (assignee) {
        if (assignee._id) return assignee._id;
        return assignee;
      });
      var permissions = UserService.getPermissions();
      var user = UserService.getCurrent();
      var fromInspection = self.workOrder.source === "inspection";

      self.canUpdate = permissions.task.update;
      // Can complete if user has permissions to complete any WO,
      // or if user has permissions to complete their own WOs and they are a listed assignee
      self.canComplete =
        (permissions.task.complete_any && !fromInspection) ||
        (permissions.task.complete_own &&
          assignees.indexOf(user._id) > -1 &&
          !fromInspection);
      self.canAssign = permissions.task.assign;
      self.canReopen = permissions.task.reopen && !fromInspection;
      self.canCancel = permissions.task.cancel;
      self.canAddAttachments = permissions.task.add_attachment;
    }

    function setType() {
      self.type = self.workOrder.issue_type || self.workOrder.maintenance_type;

      // NOTE: Using underscore to differentiate from `type` work order virtual
      self.originalWorkOrder._type = angular.copy(self.type);
    }

    /**
     * Set the days_overdue field iff the work order is overdue
     */
    function setDates() {
      if (self.workOrder.days_overdue && self.workOrder.days_until_due >= 0) {
        self.workOrder.days_overdue = undefined;
      }
      if (self.workOrder.days_until_due < 0) {
        self.workOrder.days_overdue = Math.abs(self.workOrder.days_until_due);
      }
    }

    /**
     * Get all available custom SRP field values for the organization
     *
     * @return {Array<String>}   Array of all custom field values for the org
     */
    function fetchCustomFieldValues() {
      var options = self.organization.srp_custom_options;
      var optionList = [];
      for (var i = 0; i < options.length; i++) {
        optionList.push({ name: options[i] });
      }

      return optionList;
    }

    function getAssignees(workOrder) {
      UserService.getAll(self.organization._id, {
        _id: "$in," + workOrder.assignee_users.join(","),
      }).then(function (users) {
        self.assignees = users;
      });
    }

    /**
     * Get all available reactive/preventive types to choose from for the building.
     * Only show issue types if the work order has an associated request, and
     * only maintenance types if it has an associated maintenance schedule.
     *
     * @return {Promise<Array|Error>}   Resolves with all types for the building
     */
    function fetchTypes() {
      if (self.showIssueTypes && !self.showMaintenanceTypes) {
        return IssueTypeService.getAll(self.building._id);
      }
      if (self.showMaintenanceTypes && !self.showIssueTypes) {
        return MaintenanceTypeService.getAll(self.building._id);
      }

      // Show both types in categorized list
      return TypeService.getTypes(self.building).catch(ToastService.showError);
    }

    /**
     * Get all available trades to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all trades for the building
     */
    function fetchTrades() {
      // Return outstanding request
      if (tradesRequest) return tradesRequest;

      tradesRequest = TradeService.getAll(self.building._id)
        .then(function (data) {
          return data;
        })
        .catch(ToastService.showError);
      return tradesRequest;
    }

    /**
     * Populates the trade input with the newly selected type's default trade,
     * iff there isn't a trade selected currently.
     */
    function onTypeChange() {
      var selectedType = self.type;
      if (
        !self.showTrade ||
        !selectedType ||
        !selectedType.trade ||
        self.workOrder.trade
      )
        return;

      tradesRequest.then(function (trades) {
        // Don't set trade if one is already selected
        if (self.workOrder.trade) return;

        // Find trade object based on the trade ID given by the new type
        var newTradeId = selectedType.trade;
        for (var i = 0; i < trades.length; ++i) {
          var trade = trades[i];
          if (trade._id === newTradeId) {
            self.workOrder.trade = trade;
            break;
          }
        }
      });
    }

    /**
     * Update the work order's details. Fields included:
     *      - Subject
     *      - Description
     */
    function updateDetails() {
      var data = {
        subject: self.workOrder.subject,
        description_text: self.workOrder.description_text,
      };
      if (self.showCustomSRField) {
        data.custom_srp_value =
          self.workOrder.custom_srp_value.name ||
          self.workOrder.custom_srp_value;
      }
      return WorkOrderService.update(
        self.building._id,
        self.workOrder._id,
        data
      )
        .then(onSave)
        .catch(onError);
    }

    /**
     * Update the work order's category and assignee fields. Fields included:
     *      - Type
     *      - Trade
     *      - Assignee
     *      - Priority
     *      - Estimated hours
     */
    function updateCategory() {
      var updateRequests = [];

      var categoryParams = {
        trade: self.workOrder.trade,
        priority: self.workOrder.priority,
        estimated_man_hr: self.workOrder.estimated_man_hr,
      };

      // Parse the specified type's category/intent, if it was modified
      if (!angular.equals(self.type, self.originalWorkOrder._type)) {
        var typeIsReactive = self.type && self.type.intent === "Reactive";
        var typeIsPreventive = self.type && self.type.intent === "Preventive";
        categoryParams.issue_type = typeIsReactive ? self.type : null;
        categoryParams.maintenance_type = typeIsPreventive ? self.type : null;
      }

      var categoryRequest = WorkOrderService.update(
        self.building._id,
        self.workOrder._id,
        categoryParams
      );
      updateRequests.push(categoryRequest);

      return $q
        .all(updateRequests)
        .then(function (workOrders) {
          // Grab the updated work order from the most recent request
          var updatedWorkOrder = workOrders[workOrders.length - 1];

          onSave(updatedWorkOrder);
          setType();
        })
        .catch(onError);
    }

    /**
     * Update the work order's location fields. Fields included:
     *      - Floor
     *      - Room
     *      - Asset
     */
    function updateLocation() {
      EditLocationDialog.show({
        locals: {
          building: self.building,
          model: self.workOrder,
          modelType: models.WORK_ORDER.MODEL,
          update: WorkOrderService.setRound,
        },
      })
        .then(onSave)
        .catch(onError);
    }

    /**
     * Handle field change
     *
     * @param {Object} $event blur event
     * @param {String} field  Field to update
     */
    function onBlur($event, field) {
      switch (field) {
        case "start_date":
          var originalStartDate = self.workOrder.start_date;
          self.workOrder.start_date = $event.newValue;
          if (self.workOrder.start_date > self.workOrder.due_date) {
            // keep previous duration between start and due date
            var difference = moment(self.workOrder.due_date).diff(
              moment(originalStartDate),
              "days"
            );
            self.workOrder.due_date = moment(self.workOrder.start_date)
              .add(difference, "days")
              .toDate();
          }
          if (self.workOrder.start_date > self.today) {
            // prevent selecting a dueDate before startDate
            self.minDate = self.workOrder.start_date;
          } else {
            // prevent selecting a dueDate before today if startDate is in the past
            self.minDate = self.today;
          }
          break;
        default:
          self.formData[field] = $event.model;
      }
    }

    /**
     * Update the work order's date fields. Fields included:
     *      - Start date
     *      - Due date
     */
    function updateDates() {
      var start = self.workOrder.start_date;
      var due = self.workOrder.due_date;

      var data = {
        start_date: angular.isDate(start) ? start.getTime() : start,
        due_date: angular.isDate(due) ? due.getTime() : due,
      };

      return WorkOrderService.update(
        self.building._id,
        self.workOrder._id,
        data
      )
        .then(function (workOrder) {
          onSave(workOrder);
          setDates();
        })
        .catch(onError);
    }

    /**
     * Show the complete work order dialog
     */
    function complete() {
      var locals = {
        workOrders: [self.workOrder],
      };
      CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          var updatedWorkOrder = workOrders[0];
          onSave(updatedWorkOrder);
          setPermissions();
          ToastService.showSimple("Work order completed");
        })
        .catch(onError);
    }

    /**
     * Generates url to the building dashboad
     */
    function getBuildingUrl() {
      return $state.href("app.building.detail", {
        buildingId: self.building._id,
      });
    }

    function onEditAssigneesHandler() {
      var options = {
        locals: {
          buildingId: self.building._id,
          workOrderId: self.workOrder._id,
        },
      };
      AssignDialog.show(options)
        .then(function (updatedWorkOrder) {
          onSave(updatedWorkOrder);
        })
        .catch(onError);
    }
  }
})();
