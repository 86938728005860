(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller("ScheduleOverviewTabController", ScheduleOverviewTabController);

  /* @ngInject */
  function ScheduleOverviewTabController(
    // Angular
    $q,
    $scope,
    // Constants
    models,
    // Dialogs
    CancelScheduleDialog,
    EditScheduleFrequencyDialog,
    EditLocationDialog,
    ReopenScheduleDialog,
    DeleteScheduleDialog,
    AssignDialog,
    // Resolve
    Router,
    building,
    schedule,
    // Services
    DocumentService,
    NoteService,
    ScheduleService,
    TimeZoneService,
    ToastService,
    UserService,
    // Utils
    ServiceHelpers
  ) {
    var self = this;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.building = building;
    self.schedule = schedule;
    self.scheduleForOneWayBinds = angular.copy(schedule);
    self.freqSummary = null;
    self.floorDocument = null;
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Maintenance Schedule",
      time: self.schedule.created_date,
      account: self.schedule.created_by,
      buildingUri: building.uri,
    };
    self.activityParams = {
      future_task: self.schedule._id,
    };
    self.floorDocument = null;
    self.description = angular.copy(self.schedule.description_text);
    self.floor = self.schedule.level;
    self.room = self.schedule.room;
    self.asset = self.schedule.asset;
    self.permissions = getDefaultPermissions();

    // Functions
    self.cancelSchedule = cancelSchedule;
    self.reactivateSchedule = reactivateSchedule;
    self.deleteSchedule = deleteSchedule;
    self.showEditFrequencyDialog = showEditFrequencyDialog;
    self.createNote = createNote;
    self.updateDescription = updateDescription;
    self.updateLocation = updateLocation;
    self.onLocationChange = onLocationChange;
    self.onEditAssigneesHandler = onEditAssigneesHandler;

    init();

    function init() {
      parseRoomDisplayValue(self.room);
      getFrequencySummary();
      getFloorDocument();
    }

    function parseRoomDisplayValue(room) {
      if (!room) {
        return;
      }
      ServiceHelpers.getRoomDisplayName(room)
        .then(function (displayName) {
          self.roomDisplayName = displayName || room.name;
        })
        .catch(function (err) {
          self.roomDisplayName = room.name;
          onError(err);
        });
    }

    function getDefaultPermissions() {
      var canUpdate;
      var canCancel;
      var canReopen;
      var canDelete;

      var permissions = UserService.getPermissions();
      canUpdate = permissions.future_task.update;
      canCancel = permissions.future_task.cancel;
      canReopen = permissions.future_task.reopen;
      canDelete = permissions.future_task.remove;

      return {
        canUpdate: self.schedule.is_active && canUpdate,
        canAssign: self.schedule.is_active && canUpdate,
        canCancel: self.schedule.is_active && canCancel,
        canReopen: self.schedule.is_canceled && canReopen,
        canDelete: self.schedule.is_canceled && canDelete,
      };
    }

    function updatePermissions() {
      self.permissions = getDefaultPermissions();
    }

    function updateSchedule(newSchedule) {
      if (newSchedule) {
        angular.copy(newSchedule, self.schedule);
        self.scheduleForOneWayBinds = angular.extend({}, newSchedule);
        self.floor = self.schedule.level;
        self.room = self.schedule.room;
        self.asset = self.schedule.asset;
        init();
        updatePermissions();
        refreshActivity();
      }
    }

    function onError(err) {
      ToastService.showError(err);
      return $q.reject(err);
    }

    /**
     * Update the schedule's description
     */
    function updateDescription($event) {
      var data = {};
      data.description_text = $event.newValue;

      return ScheduleService.update(self.building._id, self.schedule._id, data)
        .then(function (updatedSchedule) {
          updateSchedule(updatedSchedule);
          self.description = angular.copy(self.schedule.description_text);
        })
        .catch(onError);
    }

    /**
     * Update the maintenance schedule's location fields. Fields included:
     *      - Floor
     *      - Room
     *      - Asset
     */
    function updateLocation() {
      EditLocationDialog.show({
        locals: {
          building: self.building,
          model: self.schedule,
          modelType: models.MAINTENANCE_SCHEDULE.MODEL,
        },
      })
        .then(function (updatedSchedule) {
          self.onLocationChange(updatedSchedule);
        })
        .catch(onError);
    }

    function onLocationChange($event) {
      if ($event && $event.model) {
        updateSchedule($event.model);
      }
    }

    function getFloorDocument() {
      if (!self.schedule.level || !self.schedule.level.document) {
        self.floorDocument = null;
        return;
      }

      DocumentService.getById(
        self.building._id,
        self.schedule.level.document
      ).then(function (document) {
        self.floorDocument = document;
      });
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function cancelSchedule() {
      var locals = {
        schedules: [{ _id: self.schedule._id, building: self.building }],
      };
      CancelScheduleDialog.show({ locals: locals })
        .then(function (canceledScheduleIDs) {
          var id = canceledScheduleIDs[0];
          return ScheduleService.getById(building._id, id);
        })
        .then(updateSchedule)
        .catch(ToastService.showError);
    }

    function reactivateSchedule() {
      var locals = {
        schedules: [{ _id: self.schedule._id, building: self.building }],
      };
      ReopenScheduleDialog.show({ locals: locals })
        .then(function (reopenedScheduleIDs) {
          var id = reopenedScheduleIDs[0];
          return ScheduleService.getById(building._id, id);
        })
        .then(updateSchedule)
        .catch(ToastService.showError);
    }

    function deleteSchedule() {
      var locals = {
        schedule: self.schedule,
        building: self.building,
      };
      DeleteScheduleDialog.show({ locals: locals })
        .then(function () {
          var stateParams = {
            buildingId: self.building._id,
          };
          Router.go("app.schedules", stateParams);
        })
        .catch(ToastService.showError);
    }

    function createNote(noteText) {
      var data = {
        future_task: self.schedule._id,
        building: self.building._id,
        text: noteText,
      };
      NoteService.create(self.building._id, data)
        .then(function (note) {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    function showEditFrequencyDialog() {
      var locals = {
        schedule: self.schedule,
      };
      return EditScheduleFrequencyDialog.show({ locals: locals }).then(
        updateSchedule
      );
    }

    function getFrequencySummary() {
      self.freqSummary = ScheduleService.getFrequencySummary(
        self.schedule,
        null,
        self.building
      );
    }

    /**
     * Handles showing the new assign dialog and updating this view's assignees
     */
    function onEditAssigneesHandler() {
      var options = {
        locals: {
          buildingId: self.building._id,
          scheduleId: self.schedule._id,
        },
      };

      AssignDialog.show(options)
        .then(function (updatedMaintenanceSchedule) {
          // we dont call the normal update because it updates too many things
          // we just need this specific update

          // Since assignees prop is an array, we need to DEEP copy it so the
          // other views can see this change and update their components/directives
          angular.copy(
            updatedMaintenanceSchedule.assignee_users,
            self.schedule.assignee_users
          );
          updatePermissions();
          refreshActivity();
        })
        .catch(ToastService.showError);
    }
  }
})();
