import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useRouteLoaderData } from "react-router";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { ExportResponse, OrganizationResponse } from "@akitabox/api-client";

import { useApiMutation } from "../hooks/useApiMutation";
import { api } from "../api";

import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Button,
  Popover,
  styled,
  CardHeader,
  Avatar,
  Box,
} from "@mui/material";

import {
  Cancel,
  CurrencyExchange,
  ErrorOutline,
  FileUploadOutlined,
  Info,
  PhotoLibrary,
  Place,
} from "@mui/icons-material";

const ListItemHover = styled(ListItem)`
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover color */
    cursor: pointer;
  }
`;

type ExportDrawerProps = {
  open: boolean;
  onClose: () => void;
  filters: any;
  organization: OrganizationResponse;
  exportColumns: string[];
};

const ExportDrawer: FunctionComponent<ExportDrawerProps> = ({
  open,
  onClose,
  filters,
  organization,
  exportColumns,
}: ExportDrawerProps) => {
  const { flags } = useRouteLoaderData("shell") as {
    flags: { [key: string]: boolean };
  };

  const [exportId, setExportId] = useState<string>("");

  const { trigger: createExportJob } = useApiMutation(api.myExports.create);
  const { data: exportJobResponse, isLoading: isProcessing } =
    // Note this uses a 3 second refreshInterval and it will be active when there is a defined exportId
    makeUseServiceCall(
      api.myExports.get,
      !!exportId,
      3000
    )({
      exportId,
    });
  const handleExportRequest = async (model: string, filtered = true) => {
    // If filters has an empty values object, delete the key
    if (filters?.values && Object.keys(filters?.values).length === 0) {
      delete filters?.values;
    }
    const formattedFilters: any = {};

    if (filters.values && Object.keys(filters.values).length > 0) {
      Object.keys(filters.values).forEach((key) => {
        formattedFilters[`values.${key}`] = filters.values[key];
      });
    }

    // Copy the rest of the filters into formattedFilters, excluding 'values'
    Object.keys(filters).forEach((key) => {
      if (key !== "values") {
        formattedFilters[key] = filters[key];
      }
    });

    // Convert formattedFilters to a query string
    const params = new URLSearchParams(formattedFilters).toString();

    switch (model) {
      case "assets": {
        const { data: response } = await createExportJob({
          args: [
            {
              myExportsCreateRequest: {
                target_entity: "organization",
                target_id: organization._id,
                export_entity: "assets",
                columns: filtered ? exportColumns : [],
                filters: filtered ? params : "",
              },
            },
          ],
        });
        setExportId(response?.data?.id);
        break;
      }
      case "cost_lines": {
        const { data: response } = await createExportJob({
          args: [
            {
              myExportsCreateRequest: {
                target_entity: "organization",
                target_id: organization._id,
                export_entity: filtered ? "asset_cost_lines" : "cost_lines",
                filters: filtered ? params : "",
              },
            },
          ],
        });
        setExportId(response?.data?.id);
        break;
      }
      case "attachments": {
        const { data: response } = await createExportJob({
          args: [
            {
              myExportsCreateRequest: {
                target_entity: "organization",
                target_id: organization._id,
                export_entity: "asset_photos_files",
                filters: filtered ? params : "",
              },
            },
          ],
        });
        setExportId(response?.data?.id);
        break;
      }
    }
  };

  const [cancelExportId, setCancelExportId] = useState("");
  const { data: cancelJobResponse } = makeUseServiceCall(
    api.myExports.cancel,
    !!cancelExportId
  )({
    exportId: cancelExportId,
  });
  const handleCancelExport = async (exportId: string) => {
    if (exportId) {
      setCancelExportId(exportId);
    }
  };

  const {
    data: exportListReponse,
    isLoading: isProcessingExportList,
    mutate: refetchExports,
  } = makeUseServiceCall(
    api.myExports.list,
    open
  )({
    targetEntity: "organization",
    targetId: organization._id,
    exportEntity: "$in,assets,asset_photos_files,asset_cost_lines,cost_lines",
    status: "$in,processing,completed,errored",
  });
  const exportList = useMemo(() => {
    if (exportListReponse) {
      return exportListReponse?.data.data || [];
    }
  }, [exportListReponse]);

  useEffect(() => {
    if (exportJobResponse) {
      const status = exportJobResponse?.data?.data?.status;
      if (status === "completed" || status === "errored") {
        setExportId("");
      }
      refetchExports();
    }
  }, [exportJobResponse, isProcessing, refetchExports]);
  useEffect(() => {
    if (cancelJobResponse) {
      setCancelExportId("");
      setExportId("");
      refetchExports();
    }
  }, [cancelJobResponse, refetchExports]);

  const [file_id, setFileId] = useState("");
  const { data: fileResponse } = makeUseServiceCall(
    api.files.fileGet,
    !!file_id
  )({
    organization: organization._id,
    file: file_id,
  });
  const [downloadFile, setDownloadFile] = useState(false);
  useEffect(() => {
    if (fileResponse?.data?.public_url && downloadFile) {
      const link = document.createElement("a");
      link.href = fileResponse.data.public_url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadFile(false);
    }
  }, [fileResponse, downloadFile]);
  const handleDownload = (item: ExportResponse) => {
    setDownloadFile(true);
    setFileId(item.file_id);
  };

  const renderListItems = (list: ExportResponse[]) => {
    if (list.length === 0) {
      return (
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText>Recent generated exports will show here.</ListItemText>
        </ListItem>
      );
    }

    return list
      .sort(
        (a, b) =>
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime()
      )
      .map((item: ExportResponse) => {
        const exportDate = new Date(item.created_date);
        const formattedDate = `${exportDate.toLocaleDateString(
          "en-US"
        )} ${exportDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })}`;
        let file_name = item.file_key?.split("/").pop();
        if (item.status === "processing") {
          file_name = "Processing";
        } else if (item.status === "errored") {
          file_name = "Error generating export";
        } else {
          switch (item.export_entity) {
            case "asset_photos_files":
              file_name = "Photos CSV";
              break;
            case "asset_cost_lines":
              file_name = "Lifecycle CSV";
              break;
            case "cost_lines":
              file_name = "Lifecycle CSV";
              break;
            case "assets":
              file_name = "Assets CSV";
              break;
            default:
              break;
          }
          if (item.filters) {
            file_name += " (filtered)";
          } else {
            file_name += " (all data)";
          }
        }

        return (
          <ListItemHover key={item.id}>
            {item.status === "processing" ? (
              <IconButton onClick={() => handleCancelExport(item.id)}>
                <Cancel />
              </IconButton>
            ) : item.status === "errored" ? (
              <IconButton onClick={(event) => handleInfoClick(event, item)}>
                <ErrorOutline />
              </IconButton>
            ) : (
              <IconButton onClick={(event) => handleInfoClick(event, item)}>
                <Info />
              </IconButton>
            )}
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    {file_name}
                  </Typography>
                  {item.status === "processing" && (
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  )}
                </Box>
              }
              secondary={
                <>
                  <Typography variant="caption">
                    {`Created: ${formattedDate}`}
                  </Typography>
                </>
              }
              onClick={() => handleDownload(item ?? "")}
              style={{
                cursor: item.status === "completed" ? "pointer" : "default",
                overflowY: "hidden",
              }}
            />
          </ListItemHover>
        );
      })
      .slice(0, 10);
  };
  const [anchorElInfo, setAnchorElInfo] = React.useState<null | HTMLElement>(
    null
  );
  const openInfo = Boolean(anchorElInfo);
  const idInfo = openInfo ? "simple-popper" : undefined;
  const [exportInfo, setExportInfo] = useState<ExportResponse | null>(null);
  const handleInfoClick = (
    event: React.MouseEvent<HTMLElement>,
    item: ExportResponse
  ) => {
    setAnchorElInfo(anchorElInfo ? null : event.currentTarget);
    setFileId(item.file_id);
    setExportInfo(item);
  };
  const formatFileSize = (size: number) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        position: "relative",
        "& .MuiDrawer-paper": {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
        width: 400,
      }}
    >
      <Grid
        container
        spacing={2}
        columns={1}
        direction="column"
        sx={{
          width: 400,
          paddingLeft: 2,
          paddingRight: 2,
          height: "100%",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 1,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover": {
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                transform: "scale(1.02)",
              },
            }}
          >
            <CardHeader
              title={"Export Asset Data"}
              avatar={
                <Avatar sx={{ bgcolor: "darkgrey" }} alt={"Exports"}>
                  <FileUploadOutlined />
                </Avatar>
              }
              action={<Button onClick={onClose}>Close</Button>}
            />
            <CardContent>
              <List>
                <ListItemButton
                  onClick={() => handleExportRequest("assets", true)}
                  disabled={!!exportId}
                >
                  <ListItemIcon>
                    <Place />
                  </ListItemIcon>
                  Export Assets CSV (current view)
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleExportRequest("assets", false)}
                  disabled={!!exportId}
                >
                  <ListItemIcon>
                    <Place />
                  </ListItemIcon>
                  Export Assets CSV (all data)
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleExportRequest("attachments", true)}
                  disabled={!!exportId}
                >
                  <ListItemIcon>
                    <PhotoLibrary />
                  </ListItemIcon>
                  Export Photos CSV (current view)
                </ListItemButton>
                {flags["asset_life_cycle"] && (
                  <ListItemButton
                    onClick={() => handleExportRequest("cost_lines", true)}
                    disabled={!!exportId}
                  >
                    <ListItemIcon>
                      <CurrencyExchange />
                    </ListItemIcon>
                    Lifecycle CSV (current view)
                  </ListItemButton>
                )}
                {flags["asset_life_cycle"] && (
                  <ListItemButton
                    onClick={() => handleExportRequest("cost_lines")}
                    disabled={!!exportId}
                  >
                    <ListItemIcon>
                      <CurrencyExchange />
                    </ListItemIcon>
                    Lifecycle CSV (all data)
                  </ListItemButton>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              borderRadius: 1,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover": {
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                transform: "scale(1.02)",
              },
            }}
          >
            <CardHeader
              title={"Export List"}
              subheader={"10 most recent exports"}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                overflowY: "auto",
              }}
            >
              <List sx={{ flexGrow: 1, overflowY: "auto" }}>
                {isProcessingExportList && (
                  <ListItem>
                    <ListItemIcon>
                      <CircularProgress size={20} />
                    </ListItemIcon>
                    <ListItemText>Processing...</ListItemText>
                  </ListItem>
                )}
                {exportList && renderListItems(exportList as ExportResponse[])}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Popover
        id={idInfo}
        open={openInfo}
        anchorEl={anchorElInfo}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => setAnchorElInfo(null)}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">File Information</Typography>
            {fileResponse ? (
              <>
                <Typography variant="body2">
                  File Name: {fileResponse.data.name}
                </Typography>
                <Typography variant="body2">
                  File Size:{" "}
                  {formatFileSize(fileResponse.data?.content_length || 0)}
                </Typography>
                <Typography variant="body2">
                  Created on:{" "}
                  {exportInfo && exportInfo.created_date
                    ? new Date(exportInfo.created_date).toLocaleString()
                    : ""}
                </Typography>
                <Typography variant="body2">
                  Filtered: {exportInfo?.filters ? "Yes" : "No"}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">
                {exportInfo?.status === "errored"
                  ? "Unable to generate export at this time"
                  : "No file information available"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    </Drawer>
  );
};

export default ExportDrawer;
