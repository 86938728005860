(function () {
  /**
   * @ngdoc component
   * @name abxRoundStopItem
   *
   * @param {Object} task   Round work order
   * @param {Object} floor  Current floor
   * @param {Object} pin    Selected pin
   *
   * @description
   * Stop List Item for round task stop information
   */
  angular.module("akitabox.planView").component("abxRoundStopItem", {
    bindings: {
      stop: "<abxStop",
      pinType: "<abxPinType",
      allowReopen: "<?abxAllowReopen",
      workOrder: "<abxWorkOrder",
      numAttachments: "<?abxNumAttachments",
      numNotes: "<?abxNumNotes",
      numWorkOrders: "<?abxNumWorkOrders",
      numExternalCMMSWorkOrders: "<?abxNumExternalCmmsWorkOrders",
      updateNumAssociatedItems: "<?abxUpdateNumAssociatedItems",
      readonly: "<?abxReadOnly",
      onAttachmentUploadStart: "<?abxOnAttachmentUploadStart",
      onAttachmentUploadsFinished: "<?abxOnAttachmentUploadsFinished",
    },
    controller: AbxRoundStopItemController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/round-stop-item/round-stop-item.component.html",
  });

  var instanceNumber = 0;

  /** @ngInject */
  function AbxRoundStopItemController(
    // Angular
    $q,
    $scope,
    $rootScope,
    $timeout,
    $element,
    // Third-Party
    moment,
    // AkitaBox
    models,
    // Constants
    EVENT_ROUND_TASK_STOP_SELECTED,
    EVENT_ROUND_TASK_PIN_COLOR_UPDATE,
    EVENT_ROUND_TASK_MOVE_STOP,
    EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES,
    MINIMUM_RENDER_DELAY,
    // Dialogs
    CreateMessageDialog,
    CreateWorkOrderDialog,
    WorkOrderExternalCMMSDialog,
    EditMessageDialog,
    SkipStopDialog,
    // Services
    BuildingService,
    ChecklistService,
    FeatureFlagService,
    IdentityService,
    NoteService,
    OrganizationService,
    ToastService,
    UserService,
    WorkOrderService
  ) {
    var self = this;

    self.classes = [];

    var COLOR_IN_PROGRESS = "#666666";

    var DATE_TIME_FORMAT = "M/DD/YYYY h:mm A";

    var QUESTION_TYPES = models.CHECKLIST_QUESTION.TYPES;
    var NON_QUESTION_CHECKLIST_ITEM_TYPES =
      models.CHECKLIST_QUESTION.NON_QUESTION_CHECKLIST_ITEM_TYPES;
    var CHECKLIST_STATUS_VERBIAGE = models.CHECKLIST.STATUS_VERBIAGE;

    // Attributes
    self.loading = true;
    self.organization = OrganizationService.getCurrent();
    self.skipReasons = self.stop.skip_reasons || [];
    self.usesThirdPartyCMMS =
      self.organization.third_party_cmms_reactive_wos || false;
    self.building = BuildingService.getCurrent() || self.stop.building;
    self.user = UserService.getCurrent();
    self.permissions = UserService.getPermissions();
    self.canUpdate = false;
    self.iconColor = COLOR_IN_PROGRESS;
    self.floor = null;
    self.pin = null;
    self.title = null;
    self.completing = false;
    self.answering = false;
    self.reopening = false;
    self.editingSignature = false;
    self.passFailOptions = getPassFailOptions();
    self.showAddSubmenu = false;
    self.addSubmenuItems = [];
    self.messages = [];
    self.messagesLoading = true;
    self.messagesPlaceholderText = "No messages to show";
    self.recordedDate = null;
    self.workPerformedBy = null;
    self.stopStatus = CHECKLIST_STATUS_VERBIAGE[self.stop.status];
    self.stopStatusIcon = {};

    self.workOrders = [];
    self.externalWorkOrderLinks = [];
    self.workOrdersLoading = true;
    self.workOrdersPlaceholderText = "No work orders to show";
    self.workOrderOpen = !isWorkOrderClosed();

    // Functions
    self.onClose = onClose;
    self.isCompleted = isCompleted;
    self.isOptDisabled = isOptDisabled;
    self.isDisabled = isDisabled;
    self.isMessageEditable = isMessageEditable;
    self.isMessageDeletable = isMessageDeletable;
    self.completeDisabled = completeDisabled;
    self.complete = complete;
    self.reopen = reopen;
    self.getMessageContent = getMessageContent;
    self.addMessage = addMessage;
    self.editMessage = editMessage;
    self.editExternalWoLink = editExternalWoLink;
    self.fetchAllMessages = fetchAllMessages;
    self.toggleAddSubmenu = function () {
      self.showAddSubmenu = !self.showAddSubmenu;
    };
    self.updateAttachmentCount = updateAttachmentCount;
    self.createWorkOrder = createWorkOrder;
    self.displayDateTime = displayDateTime;
    self.scrollListIntoView = scrollListIntoView;
    self.setValue = setValue;

    // Skipping stop functions
    self.skipping = false;
    self.isSkippable = isSkippable;
    self.skip = skip;
    self.isSkipReasonEditable = isSkipReasonEditable;
    self.editSkipReason = editSkipReason;
    self.isSkipped = isSkipped;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      self.classes = getStatusClass([]);
      // If the user can answer questions or complete the checklist they can add messages/attachments
      self.canUpdate =
        canAnswer(self.user.identity) || canComplete(self.user.identity);
      if (self.canUpdate) {
        // Uniquely identify instances so that the 'add attachment' button does not select other inputs
        self.instanceNumber = instanceNumber++;
        if (self.permissions.comment.create) {
          self.addSubmenuItems.push({
            title: "Add Message",
            icon: "chat",
            onClick: addMessage,
          });
        }
        var pinPermission = self.pinType.is_asset
          ? self.permissions.asset.add_attachment
          : self.permissions.room.add_attachment;
        var taskPermission = self.permissions.task.add_attachment;
        var checklistPermission = self.permissions.checklist.add_attachment;
        if (pinPermission && taskPermission && checklistPermission) {
          self.addSubmenuItems.push({
            title: "Add Attachment",
            icon: "attach_file",
            onClick: addAttachment,
          });
        }
      }

      fetchMessages();

      // if the org uses Akitabox for work orders, fetch them here
      if (
        self.stop.reactive_inspection_tasks.length &&
        !self.usesThirdPartyCMMS
      ) {
        fetchWorkOrders(self.stop.reactive_inspection_tasks);
      } else {
        self.workOrdersLoading = false;
      }

      if (self.permissions.task.create && self.permissions.checklist.update) {
        self.addSubmenuItems.push({
          title: "Create Work Order",
          icon: "build",
          onClick: createWorkOrder,
        });
      }

      // Display elements for the read-only version of the header
      if (self.readonly) {
        var lastModified = self.stop.modified_date || self.stop.completed_date;
        if (!lastModified) {
          return null;
        }
        self.recordedDate = moment(lastModified).format(
          "MMM. D, YYYY [at]  h:mmA"
        );

        switch (self.stopStatus) {
          case "Passed":
            self.stopStatusIcon.icon = "check";
            self.stopStatusIcon.class = "status-passed";
            break;
          case "Incomplete":
            self.stopStatusIcon.icon = "donut_large";
            self.stopStatusIcon.class = "status-incomplete";
            break;
          case "Failed":
            self.stopStatusIcon.icon = "error_outline";
            self.stopStatusIcon.class = "status-failed";
            break;
        }

        // TODO: replace with user fields
        var recordedUser =
          self.stop.last_mod_account || self.stop.completed_user;
        if (recordedUser) {
          IdentityService.getById(recordedUser).then(function (identity) {
            self.recordedUser = identity.display_name;
          });
        }
      }
    };

    self.$onChanges = function (changes) {
      if (changes.stop && self.stop) {
        self.loading = true;
        self.floor = self.stop.level;
        self.pin = self.stop.asset || self.stop.room;
        if (self.pin) {
          self.title = self.pin.name;
        } else if (self.floor) {
          self.title = self.floor.name;
        } else {
          self.title = "Unknown";
        }

        // refetch messages for new stop
        fetchMessages();

        // set the correct initial color
        self.classes = getStatusClass(self.classes);

        $timeout(function () {
          self.loading = false;
        }, MINIMUM_RENDER_DELAY);
      }
    };

    // =================
    // Public Functions
    // =================

    function isDisabled() {
      return self.isCompleted() || self.workOrder.is_scheduled;
    }

    function isOptDisabled() {
      if (
        self.readonly ||
        self.isDisabled() ||
        self.isSkipped() ||
        !canAnswer(self.user.identity)
      ) {
        return true;
      }
      return false;
    }

    function isCompleted() {
      return self.stop.status !== models.CHECKLIST.STATUSES.IN_PROGRESS;
    }

    function isMessageEditable(message) {
      if (self.readonly || isWorkOrderClosed()) {
        return false;
      }

      return (
        message.cre_account._id === self.user.identity._id &&
        self.permissions.comment.update
      );
    }

    function isMessageDeletable(message) {
      if (self.readonly || isWorkOrderClosed()) {
        return false;
      }

      return (
        message.cre_account._id === self.user.identity._id ||
        self.permissions.comment.remove_any_internal
      );
    }

    function getMessageContent(message) {
      return '"' + message.text + '"';
    }

    function displayDateTime(date) {
      return moment(date).format(DATE_TIME_FORMAT);
    }

    function scrollListIntoView(listType) {
      var elementId;
      switch (listType) {
        case "messages":
          elementId = "#abx-stop-item-message-list";
          break;
        case "attachments":
          elementId = "#abx-stop-item-attachment-list";
          break;
        case "workOrders":
          elementId = "#abx-stop-item-work-order-list";
          break;
      }
      var listHeader = $element.find(elementId)[0];
      // Scroll item into view
      $timeout(function () {
        listHeader.scrollIntoView({
          behavior: "smooth",
        });
      });
    }

    /**
     * Disable if any questions in stop.items
     * do not have a value.
     */
    function completeDisabled() {
      if (self.completing) return true;
      if (self.editingSignature) return true;

      // Check if user can complete
      if (!canComplete(self.user.identity)) {
        // They need all these perms to be able to complete without being an assignee
        return true;
      }

      var items = self.stop.items;
      for (var i = 0; i < items.length; ++i) {
        var item = items[i];
        var isItemAQuestion =
          NON_QUESTION_CHECKLIST_ITEM_TYPES.indexOf(item.type) === -1;
        if (isItemAQuestion) {
          if (item.type === QUESTION_TYPES.TEXTBOX) {
            if (
              item.enum_options[0].is_passing &&
              (!item.value || !item.value.length)
            ) {
              return true;
            }
          } else if (
            (item.type !== QUESTION_TYPES.ENUM_MULTI_VALUE &&
              item.value === null) ||
            item.value.length === 0
          ) {
            return true;
          }
        }
      }

      return false;
    }

    /**
     * Click handler for the header element
     */
    function onClose() {
      deselectStop();
    }

    function complete() {
      self.completing = true;
      // Remove if condition and else block when JIT Checklists FF is removed
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const stop = self.stop;
        return ChecklistService.completeByBuildingWorkOrder(
          self.building._id,
          stop.task,
          stop.asset ? stop.asset._id : null,
          stop.room ? stop.room._id : null,
          stop.level ? stop.level._id : null
        )
          .then(function (completedChecklist) {
            deselectStop();
            // This helps keep our status visiblity toggles up-to-date and working
            self.stop.checklist_status =
              completedChecklist.status.toUpperCase();
            // Tell the parent to move this stop to the DONE list
            $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
              stop: self.stop,
              to: completedChecklist.status.toUpperCase(),
              from: self.stop.status.toUpperCase(),
            });
            self.stop.status = completedChecklist.status;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.completing = false;
            updatePinStatusAndColor();
            $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
          });
      } else {
        ChecklistService.complete(self.stop.organization, self.stop._id)
          .then(function (completedChecklist) {
            deselectStop();
            // This helps keep our status visiblity toggles up-to-date and working
            self.stop.checklist_status =
              completedChecklist.status.toUpperCase();
            // Tell the parent to move this stop to the DONE list
            $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
              stop: self.stop,
              to: completedChecklist.status.toUpperCase(),
              from: self.stop.status.toUpperCase(),
            });
            self.stop.status = completedChecklist.status;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.completing = false;
            updatePinStatusAndColor();
            $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
          });
      }
    }

    function reopen() {
      if (!self.allowReopen) return;
      self.reopening = true;

      // Remove if condition and else block when JIT Checklists FF is removed
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const stop = self.stop;
        return ChecklistService.reopenByBuildingWorkOrder(
          self.building._id,
          stop.task,
          stop.asset ? stop.asset._id : null,
          stop.room ? stop.room._id : null,
          stop.level ? stop.level._id : null
        )
          .then(function (reopenedChecklist) {
            // This helps keep our status visiblity toggles up-to-date and working
            self.stop.checklist_status = models.CHECKLIST.STATUSES.IN_PROGRESS;
            // Tell the parent to move this stop to the TO DO list
            $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
              stop: self.stop,
              to: models.CHECKLIST.STATUSES.IN_PROGRESS,
              from: self.stop.status.toUpperCase(),
            });
            self.stop.status = reopenedChecklist.status;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.reopening = false;
            updatePinStatusAndColor();
            $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
          });
      } else {
        ChecklistService.reopen(self.stop.organization, self.stop._id)
          .then(function (reopenedChecklist) {
            // This helps keep our status visiblity toggles up-to-date and working
            self.stop.checklist_status = models.CHECKLIST.STATUSES.IN_PROGRESS;
            // Tell the parent to move this stop to the TO DO list
            $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
              stop: self.stop,
              to: models.CHECKLIST.STATUSES.IN_PROGRESS,
              from: self.stop.status.toUpperCase(),
            });
            self.stop.status = reopenedChecklist.status;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.reopening = false;
            updatePinStatusAndColor();
            $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
          });
      }
    }

    function updateAttachmentCount() {
      self.updateNumAssociatedItems("attachments");
    }

    function addMessage() {
      var locals = {
        type: models.CHECKLIST.SINGULAR,
        itemId: self.stop._id,
        taskId: self.workOrder._id,
        // Location data should be on the message as well
        building: self.building,
      };
      if (self.stop.room) {
        locals.roomId = self.stop.room._id;
      } else if (self.stop.asset) {
        locals.assetId = self.stop.asset._id;
      } else if (self.stop.level) {
        locals.levelId = self.stop.level._id;
      }
      CreateMessageDialog.show({ locals: locals })
        .then(function (newMessage) {
          self.updateNumAssociatedItems("messages");
          // Insert into the message list
          if (newMessage) {
            self.messages.unshift(newMessage);
          }
        })
        .catch(ToastService.showError);
    }

    function editMessage(message) {
      var deletable = self.isMessageDeletable(message);
      EditMessageDialog.show({
        locals: {
          message: message,
          building: self.building,
          canDelete: deletable,
        },
      })
        .then(function (update) {
          var deleted = update.deleted;
          var updatedMessage = update.message;
          var messageId;
          if (deleted) {
            // do deletion stuff
            var index = -1;
            for (var i = 0; i < self.messages.length; ++i) {
              messageId = self.messages[i]._id;
              if (messageId === updatedMessage._id) {
                index = i;
                break;
              }
            }
            if (index >= 0) {
              self.messages.splice(index, 1);
              self.updateNumAssociatedItems("messages");
            }
            return;
          }
          // Update the message in the notes array
          for (var j = 0; j < self.messages.length; j++) {
            messageId = self.messages[j]._id;
            if (messageId === message._id && messageId === updatedMessage._id) {
              angular.extend(self.messages[j], updatedMessage);
              return;
            }
          }
          // Throw error if we weren't able to find message to update
          throw new Error("Unable to find updated message in message list");
        })
        .catch(ToastService.showError);
    }

    function fetchAllMessages(params) {
      self.messagesLoading = true;
      var fetchParams = angular.extend({}, params, {
        checklist: self.stop._id,
      });
      NoteService.getAll(self.building._id, fetchParams)
        .then(function (notes) {
          self.messages = notes;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.messagesLoading = false;
        });
    }

    function setValue(question, option) {
      if (self.answering === true)
        return $q.reject(new Error("Already saving..."));
      var oldValue = question.value;

      switch (question.type) {
        case QUESTION_TYPES.ENUM_SINGLE_VALUE:
          // Unset value if it's already set to the selected value
          if (question.value === option.display_value) {
            question.value = null;
          } else {
            question.value = option.display_value;
          }
          break;
        case QUESTION_TYPES.ENUM_SINGLE_VALUE_RADIO:
          // Unset value if it's already set to the selected value
          if (question.value === option.display_value) {
            question.value = null;
          } else {
            question.value = option.display_value;
          }
          break;
        case QUESTION_TYPES.ENUM_MULTI_VALUE:
          // Unset value if it's already set to the selected value
          // See if it's in the value array, if yes, remove it, otherwise put it in
          var alreadyIn = false;
          for (var i = 0; i < question.value.length; i++) {
            if (question.value[i] === option.display_value) {
              question.value.splice(i, 1);
              alreadyIn = true;
              break;
            }
          }
          if (!alreadyIn) question.value.push(option.display_value);
          break;
        case QUESTION_TYPES.SIGNATURE:
          break;
      }

      // Save the new value
      self.answering = true;

      // Remove if condition and else block when JIT Checklists FF is removed
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const stop = self.stop;
        return ChecklistService.updateAnswerByBuildingWorkOrder(
          self.building._id,
          stop.task,
          stop.asset ? stop.asset._id : null,
          stop.room ? stop.room._id : null,
          stop.level ? stop.level._id : null,
          question._id,
          question.value
        )
          .then(function (e) {
            var timestamp = new Date();
            question.updatedAt = timestamp.toISOString();
          })
          .catch(function (err) {
            ToastService.showError(err);
            // if an error occurs and we couldn't save the value, we need to revert it
            question.value = oldValue;
          })
          .finally(function () {
            self.answering = false;
            self.editingSignature = false;
          });
      } else {
        return ChecklistService.updateAnswer(
          self.stop.organization,
          self.stop._id,
          question._id,
          question.value
        )
          .then(function (e) {
            var timestamp = new Date();
            question.updatedAt = timestamp.toISOString();
          })
          .catch(function (err) {
            ToastService.showError(err);
            // if an error occurs and we couldn't save the value, we need to revert it
            question.value = oldValue;
          })
          .finally(function () {
            self.answering = false;
            self.editingSignature = false;
          });
      }
    }

    // ===================
    // Private Functions
    // ===================

    function fetchMessages(params) {
      self.messagesLoading = true;
      var fetchParams = angular.extend({}, params, {
        checklist: self.stop._id,
        limit: 10,
      });
      // Grabs the list of Messages attached to the checklist
      NoteService.get(self.building._id, fetchParams)
        .then(function (notes) {
          self.messages = notes;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.messagesLoading = false;
        });
    }

    /**
     * Work around to get a file input "onClick" on a Button
     * Clicks the file input when you click the button
     */
    function addAttachment() {
      $timeout(function () {
        angular.element("#attachment-input-" + self.instanceNumber).click();
      });
    }

    function fetchWorkOrders(checklistWorkOrders) {
      var completedCount = 0;
      var totalCount = checklistWorkOrders.length;
      var params = {};
      for (var i = 0; i < totalCount; i++) {
        WorkOrderService.getById(
          self.building._id,
          checklistWorkOrders[i]._id,
          params
        )
          .then(function (workOrder) {
            self.workOrders.push(workOrder);

            completedCount += 1;
            if (completedCount === totalCount) {
              self.workOrdersLoading = false;
            }
          })
          .catch(ToastService.showError);
      }
    }

    function createWorkOrder() {
      // if the org uses a 3rd party CMMS, show a different dialog
      if (self.usesThirdPartyCMMS) {
        WorkOrderExternalCMMSDialog.show({
          locals: {
            checklist: self.stop,
          },
        })
          .then(function (checklist) {
            self.workOrdersLoading = true;
            // update self.stop to contain new links
            self.stop.reactive_inspection_tasks_external_cmms =
              checklist.reactive_inspection_tasks_external_cmms;
            self.updateNumAssociatedItems("externalCMMSWorkOrders");
            self.workOrdersLoading = false;
          })
          .catch(ToastService.showError);
      } else {
        // otherwise use the standard WO creation dialog
        var locals = {
          building: self.building,
        };
        // don't need to pass individual locations, just pass the round
        if (self.stop.asset) {
          locals.round = [{ asset: self.stop.asset._id }];
        } else if (self.stop.room) {
          locals.round = [{ room: self.stop.room._id }];
        } else if (self.stop.level) {
          locals.round = [{ level: self.stop.level._id }];
        }

        var createWorkOrderOptions = {
          locals: locals,
          fromStopList: true,
        };

        CreateWorkOrderDialog.show(createWorkOrderOptions)
          .then(function (newWorkOrder) {
            // Insert into the work order list
            if (newWorkOrder) {
              self.stop.reactive_inspection_tasks.push(newWorkOrder[0]);
              // Remove if condition and else block when JIT Checklists FF is removed
              if (FeatureFlagService.isEnabled("jit_checklists")) {
                // TODO: to be fixed properly by WEBAPP-14834
                const stop = self.stop;
                // add it to the stop.reactive_inspection_tasks
                return ChecklistService.updateByBuildingWorkOrder(
                  self.building._id,
                  stop.task,
                  stop.asset ? stop.asset._id : null,
                  stop.room ? stop.room._id : null,
                  stop.level ? stop.level._id : null
                )
                  .then(function () {
                    // add it to the stop's list of work orders
                    self.workOrders.unshift(newWorkOrder[0]);
                    // update count of work orders
                    self.updateNumAssociatedItems("workOrders");
                  })
                  .catch(ToastService.showError);
              } else {
                // add it to the stop.reactive_inspection_tasks
                ChecklistService.update(self.organization._id, self.stop._id, {
                  reactive_inspection_tasks:
                    self.stop.reactive_inspection_tasks,
                })
                  .then(function () {
                    // add it to the stop's list of work orders
                    self.workOrders.unshift(newWorkOrder[0]);
                    // update count of work orders
                    self.updateNumAssociatedItems("workOrders");
                  })
                  .catch(ToastService.showError);
              }
            }
          })
          .catch(ToastService.showError);
      }
    }

    /**
     * Edit or remove an existing link to an external WO
     * @param {Object} externalWoLink   external link object to update
     */
    function editExternalWoLink(externalWoLink) {
      // dialog can only be used by orgs using a 3rd party CMMS
      if (!self.usesThirdPartyCMMS) {
        return;
      }
      WorkOrderExternalCMMSDialog.show({
        locals: {
          checklist: self.stop,
          externalWoLink: externalWoLink,
        },
      }).then(function (checklist) {
        self.stop.reactive_inspection_tasks_external_cmms =
          checklist.reactive_inspection_tasks_external_cmms;

        // update the count in case the edit action deleted an external link
        self.updateNumAssociatedItems("externalCMMSWorkOrders");
        self.workOrdersLoading = false;
      });
    }

    function deselectStop() {
      self.selected = false;
      // emit event to navigate to pin on floor plan
      $rootScope.$broadcast(EVENT_ROUND_TASK_STOP_SELECTED, {
        stop: null,
      });
    }

    function isAssignee(user) {
      var users = self.workOrder.assignee_users;
      for (var i = 0; i < users.length; i++) {
        if (users[i]._id === user._id) {
          return true;
        }
      }
      return false;
    }

    function isWorkOrderClosed() {
      return Boolean(self.workOrder.closed_date);
    }

    function getStatusClass(classes) {
      // Since classes can be an array of classes for x amount, we wnat to purge it of our
      // pass and fail before we add in the correct one
      var FAIL_CLASS = "fail";
      var PASS_CLASS = "pass";
      var SKIP_CLASS = "skip";
      var indexesToRemove = [];

      for (var i = 0; i < classes.length; i++) {
        var className = classes[i];
        if (
          className === PASS_CLASS ||
          className === FAIL_CLASS ||
          className === SKIP_CLASS
        )
          indexesToRemove.push(i);
      }

      while (indexesToRemove.length) {
        classes.splice(indexesToRemove.shift(), 1);
      }

      // Add the correct pass/fail class
      switch (self.stop.status) {
        case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
        case models.CHECKLIST.STATUSES.FAIL:
          classes.push(FAIL_CLASS);
          break;
        case models.CHECKLIST.STATUSES.PASS:
          classes.push(PASS_CLASS);
          break;
        case models.CHECKLIST.STATUSES.SKIP:
          classes.push(SKIP_CLASS);
          break;
        default:
          break;
      }

      return classes;
    }

    function canAnswer(identity) {
      if (self.permissions.checklist.answer_any) {
        return true;
      } else if (
        self.permissions.checklist.answer_own &&
        isAssignee(identity)
      ) {
        // always has perms if they are an assignee
        return true;
      }
      return false;
    }

    function canComplete(identity) {
      if (self.permissions.checklist.complete_any) {
        return true;
      } else if (
        self.permissions.checklist.complete_own &&
        isAssignee(identity)
      ) {
        // always has perms if they are an assignee
        return true;
      }
      return false;
    }

    function getPassFailOptions() {
      return Object.keys(models.CHECKLIST_QUESTION.VALUES).map(function (
        value
      ) {
        return models.CHECKLIST_QUESTION.VALUES[value];
      });
    }

    function skip() {
      SkipStopDialog.show({
        locals: {
          checklists: [self.stop],
        },
      })
        .then(function (skippedChecklists) {
          var skippedChecklist = skippedChecklists[0];
          self.stop.skip_reasons = skippedChecklist.skip_reasons;
          var previousStatus = self.stop.status;
          self.stop.status = skippedChecklist.status;
          // This helps keep our status visiblity toggles up-to-date and working
          self.stop.checklist_status = models.CHECKLIST.STATUSES.SKIP;
          // Tell the parent to move this stop to the SKIP list
          $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
            stop: self.stop,
            to: models.CHECKLIST.STATUSES.SKIP,
            from: previousStatus.toUpperCase(),
          });
          self.onClose();
        })
        .catch(ToastService.showError)
        .finally(function () {
          updatePinStatusAndColor();
          $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
        });
    }

    function isSkippable() {
      var userCanSkip =
        // user can skip any stop, doesn't need to be assignee
        self.user.permission_group.checklist.skip_any ||
        // user has to be an assignee to skip
        (self.user.permission_group.checklist.skip_own &&
          self.workOrder.assignee_users.map(function (assignee) {
            return assignee._id === self.user._id;
          }).length > 0);
      return (
        self.stop.status === models.CHECKLIST.STATUSES.IN_PROGRESS &&
        userCanSkip
      );
    }

    function isSkipped() {
      return self.stop.status === models.CHECKLIST.STATUSES.SKIP;
    }

    function isSkipReasonEditable(skipReason) {
      if (self.stop.status === models.CHECKLIST.STATUSES.PASS) return false;
      else if (skipReason.cre_account !== self.user.identity._id) return false;
      return true;
    }

    function editSkipReason(skipReason) {
      SkipStopDialog.show({
        locals: {
          checklists: [self.stop],
          reasonId: skipReason._id,
          reasonText: skipReason.text,
        },
      })
        .then(function (updatedChecklist) {
          self.stop.skip_reasons = updatedChecklist.skip_reasons;
          // Tell the parent to move this stop back to the IN_PROGRESS list
          $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
            stop: self.stop,
            to: updatedChecklist.status.toUpperCase(),
            from: self.stop.status.toUpperCase(),
          });
          self.stop.status = updatedChecklist.status;
        })
        .catch(ToastService.showError);
    }

    function updatePinStatusAndColor() {
      self.classes = getStatusClass(self.classes);
      // let the floor plan know ahout the color change
      var eventData = {
        pinId: self.pin._id,
        color: self.pin.color,
      };
      // this fn will only ever spit out ["fail"], or ["pass"], or ["skip"]
      eventData.inspection_status = getStatusClass([]).join("").trim();
      $scope.$emit(EVENT_ROUND_TASK_PIN_COLOR_UPDATE, eventData);
    }
  }
})();
