(function () {
  /**
   * @ngdoc component
   * @name abxMarkupUserLayerList
   *
   * @param {Function} addNewMarkup - Opens dialog to create new user markup
   * @param {Boolean} isOpen - Show/Hide dropdown content
   *
   * @description
   * Container for a list of user markups
   */
  angular.module("akitabox.planView").component("abxMarkupUserLayerList", {
    bindings: {
      addNewMarkup: "<?abxOnClickNewMarkup",
      hideDropdown: "&abxHideDropdown",
    },
    controller: abxMarkupUserLayerListController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-user-layer-list/markup-user-layer-list.html",
  });

  /* @ngInject */
  function abxMarkupUserLayerListController(
    // Angular
    $document,
    $scope,
    // Akitabox
    FeatureFlagService,
    IdentityService,
    MarkupService,
    UserService
  ) {
    var self = this;

    self.canAdminOnly = false;
    self.canDelete = false;
    self.selectedLayer = MarkupService.getActiveMarkup;
    self.$onInit = init;
    self._checkDeletionPermissions = checkDeletionPermissions; // exposed for unit testing

    function init() {
      IdentityService.getCurrent().then(function (identity) {
        if (
          identity.site_permissions &&
          identity.site_permissions.is_super_admin
        ) {
          self.canAdminOnly = true;
        }

        self.currentUser = UserService.getCurrent();

        self.getMarkups = function () {
          return initUserMarkups(self.currentUser);
        };

        checkDeletionPermissions();
      });
    }

    function checkDeletionPermissions() {
      var permissions = UserService.getPermissions();
      self.canDelete = permissions.markup.remove_own;
    }

    function initUserMarkups(user) {
      return MarkupService.getActiveMarkups().filter(
        MarkupService.filterByCreAccount(user)
      );
    }
  }
})();
