(function () {
  angular.module("akitabox.ui.dialogs.bulkAssign", [
    "ngMaterial",
    "akitabox.core.services.building",
    "akitabox.core.services.cancellable",
    "akitabox.core.services.organization",
    "akitabox.core.services.schedule",
    "akitabox.core.services.user",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
    "akitabox.ui.components.multipleAssigneeInput",
  ]);
})();
