(function () {
  angular
    .module("akitabox.ui.components.reportingKpi", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.request",
      "akitabox.core.services.workOrder",
      "akitabox.core.services.workOrderLog",
      "akitabox.core.toast",
      "akitabox.core.utils",
    ])
    .component("abxReportingKpi", {
      bindings: {
        buildings: "<abxBuildings",
        filters: "<abxFilters",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        entityType: "<abxEntityType",
      },
      controller: AbxReportingKpiController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-kpi/reporting-kpi.component.html",
    });
  function AbxReportingKpiController(
    // Angular
    $q,
    $log,
    // Libraries
    moment,
    // Akitabox
    models,
    // Services
    RequestService,
    ToastService,
    WorkOrderService,
    WorkOrderLogService,
    Utils
  ) {
    var self = this;

    var organization = null;
    var parentId = null;
    var buildingInString = null;

    var workOrderStatsFunction;
    var workOrderLogStatsFunction;
    var serviceRequestStatsFunction;

    self.isWorkOrderView = true;
    self.isLoading = true;

    // WORK ORDER STATS
    self.numCompletedWOs = null;
    self.amtOfWorkHrsUsed = null;
    self.avgWorkHrsPerWO = null;
    self.avgWODuration = null;
    self.avgWOCompletedPerDay = null;
    self.reactiveToPreventiveRatio = null;
    self.numOpenedWOs = null;
    self.openToClosedRatio = null;

    // SERVICE REQUEST STATS
    self.numRequestsSubmitted = null;
    self.numRequestsDenied = null;
    self.numRequestsOpened = null; // from service requests
    self.numRequestsCompleted = null; // from service requests
    self.avgInitResponseTime = null;
    self.avgResolutionTime = null;

    var sumDeniedTimeInMs = null;
    var sumOpenedTimeInMs = null;
    var numRespondedToServiceRequests = null;

    self.isEmpty = angular.isEmpty;

    // Lifecycle
    self.$onInit = function () {
      // Set the current active building
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxReportingKpi: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        configureVariables();
      }
      if (
        changes.filters ||
        changes.startDate ||
        changes.endDate ||
        changes.buildings ||
        changes.entityType
      )
        calculateStats();
    };

    function configureVariables() {
      // Configure variables for API calls based on whether we are in a building or multiple buildings view
      if (self.buildings.length === 1) {
        self.building = self.buildings[0];
        parentId = self.building._id;
        workOrderStatsFunction = WorkOrderService.getStatsByBuilding;
        serviceRequestStatsFunction = RequestService.getStatsByBuilding;
        workOrderLogStatsFunction = WorkOrderLogService.getStatsByBuilding;
      } else if (self.buildings.length > 1) {
        organization = self.buildings[0].organization;
        parentId = organization;
        workOrderStatsFunction = WorkOrderService.getStatsByOrganization;
        serviceRequestStatsFunction = RequestService.getStatsByOrganization;
        workOrderLogStatsFunction = WorkOrderLogService.getStatsByOrganization;
        buildingInString =
          "$in," +
          self.buildings
            .map(function (building) {
              return building._id;
            })
            .join(",");
      }
    }

    function buildDateString() {
      return (
        "$lte," + self.endDate.valueOf() + ",$gte," + self.startDate.valueOf()
      );
    }

    function buildParams(params) {
      params = angular.extend(params, self.filters);
      if (organization) params.buildings = buildingInString;
      return params;
    }

    // for stats that hit the WO log route, prepend `task` to task fields
    function buildWoLogParams(params) {
      params = angular.extend(params, self.filters);
      if (organization) params.buildings = buildingInString;
      if (params["completed_date"]) {
        params["task.completed_date"] = params["completed_date"];
        delete params["completed_date"];
      }

      params.lookup_field = "task";

      // prepend task to task fields
      if (params["intent"]) {
        params["task.intent"] = params["intent"];
        delete params["intent"];
      }

      if (params["type_name"]) {
        params["task.type_name"] = params["type_name"];
        delete params["type_name"];
      }

      if (params["trade_name"]) {
        params["task.trade_name"] = params["trade_name"];
        delete params["trade_name"];
      }

      if (params["priority"]) {
        params["task.priority"] = params["priority"];
        delete params["priority"];
      }

      if (params["assignee_users"]) {
        params["task.assignee_users"] = params["assignee_users"];
        delete params["assignee_users"];
      }

      if (params["workers"]) {
        params["work_performed_by"] = params["workers"];
        delete params["workers"];
      }

      return params;
    }

    // Private Functions
    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      if (self.entityType === models.WORK_ORDER.MODEL) {
        self.isWorkOrderView = true;
        self.isLoading = true;
        return calculateWorkOrderStats();
      } else if (self.entityType === models.SERVICE_REQUEST.MODEL) {
        self.isWorkOrderView = false;
        self.isLoading = true;
        return calculateServiceRequestStats();
      }

      $log.error("abxReportingKpi: invalid abx-entity-type");
    }

    function calculateWorkOrderStats() {
      var allCompletedWorkOrdersRequest = getCompletedWorkOrdersStats().then(
        getReactiveToPreventiveRatio
      );
      var totalCompletedWorkHoursRequest =
        getCompletedWorkOrdersTotalWorkHours();
      var requests = [
        allCompletedWorkOrdersRequest,
        totalCompletedWorkHoursRequest,
      ];
      requests.push(getOpenWorkOrdersStats());
      requests.push(getAverageWorkOrderDuration());
      $q.all(requests)
        .then(function () {
          return $q.all([
            computeAverageWorkHoursPerWorkOrder(),
            getOpenToClosedRatio(),
          ]);
        })
        .catch(function () {
          // Ensure some stats are reset
          self.avgWorkHrsPerWO = 0;
          self.openToClosedRatio = ["?", "?"];
          self.reactiveToPreventiveRatio = ["?", "?"];
          ToastService.showError(
            "An error occurred while calculating work order statistics"
          );
        })
        .finally(function () {
          self.isLoading = false;
        });
    }

    function calculateServiceRequestStats() {
      $q.all([
        getNumberOfSubmittedRequests(),
        getNumberOfDeniedRequests(),
        getNumberOfOpenRequests(),
        getNumberOfCompletedRequests(),
        getAverageInitialResponseTime(),
        getAverageResolutionTime(),
      ])
        .catch(function () {
          ToastService.showError(
            "An error occurred while calculating service request statistics"
          );
        })
        .finally(function () {
          self.isLoading = false;
        });
    }

    // Gets the number of completed work orders for the current date range and then computes the average amount
    // completed per day
    function getCompletedWorkOrdersStats() {
      var newParams = {
        completed_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return workOrderStatsFunction(parentId, params)
        .then(function (stats) {
          self.numCompletedWOs = stats[0].result;
          var timeDiff = moment(self.endDate).diff(self.startDate, "days");
          timeDiff = timeDiff > 0 ? timeDiff : 1;
          self.avgWOCompletedPerDay = (self.numCompletedWOs / timeDiff).toFixed(
            2
          );
        })
        .catch(function (err) {
          self.numCompletedWOs = 0;
          self.avgWOCompletedPerDay = 0;
          return $q.reject(err);
        });
    }

    // Gets the total amount of work hours across all completed work orders
    function getCompletedWorkOrdersTotalWorkHours() {
      var newParams;
      var params;

      newParams = {
        work_performed_date: buildDateString(),
        operator: "sum",
        operator_field: "work_minutes",
      };

      params = buildWoLogParams(newParams);

      return workOrderLogStatsFunction(parentId, params)
        .then(function (stats) {
          // convert to hours
          self.amtOfWorkHrsUsed = ~~(stats[0].result / 60);
        })
        .catch(function (err) {
          self.amtOfWorkHrsUsed = 0;
          return $q.reject(err);
        });
    }

    // Gets the ratio between reactive work orders and preventive work orders, depends on total number of
    // completed work orders already being computed
    function getReactiveToPreventiveRatio() {
      var newParams = {
        completed_date: buildDateString(),
      };
      var params = buildParams(newParams);
      if (!self.filters.intent) params.intent = "reactive";
      workOrderStatsFunction(parentId, params)
        .then(function (stats) {
          var totalCategoryA = stats[0].result;
          var totalCategoryB = self.numCompletedWOs - totalCategoryA;
          var totalReactiveWOs =
            params.intent === "reactive" ? totalCategoryA : totalCategoryB;
          var totalPreventiveWOs =
            params.intent === "reactive" ? totalCategoryB : totalCategoryA;
          self.reactiveToPreventiveRatio =
            totalPreventiveWOs === 0 && totalReactiveWOs === 0
              ? [0, 0]
              : Utils.reduceGCD(totalReactiveWOs, totalPreventiveWOs);
        })
        .catch(function (err) {
          self.reactiveToPreventiveRatio = ["?", "?"];
          return $q.reject(err);
        });
    }

    // Computes the average amount of work hours per work order, depends on both the total amount of completed work
    // orders and total amount of work hours used
    function computeAverageWorkHoursPerWorkOrder() {
      var avgWorkHrsQuotient =
        self.amtOfWorkHrsUsed / self.numCompletedWOs || 0;
      self.avgWorkHrsPerWO = avgWorkHrsQuotient.toFixed(2);
    }

    function getOpenWorkOrdersStats() {
      var newParams = {
        start_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return workOrderStatsFunction(parentId, params)
        .then(function (stats) {
          self.numOpenedWOs = stats[0].result;
        })
        .catch(function (err) {
          self.numOpenedWOs = 0;
          return $q.reject(err);
        });
    }

    function getOpenToClosedRatio() {
      var newParams = {
        closed_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return workOrderStatsFunction(parentId, params)
        .then(function (stats) {
          var numClosedWOs = stats[0].result;
          self.openToClosedRatio =
            numClosedWOs === 0 && self.numOpenedWOs === 0
              ? [0, 0]
              : Utils.reduceGCD(self.numOpenedWOs, numClosedWOs);
        })
        .catch(function (err) {
          self.openToClosedRatio = ["?", "?"];
          return $q.reject(err);
        });
    }

    // Gets the average amount of days it takes to complete a work order
    function getAverageWorkOrderDuration() {
      var newParams = {
        completed_date: buildDateString(),
        projection_operator: "subtract",
        projection_fields: "completed_date,start_date",
        operator: "avg",
      };
      var params = buildParams(newParams);
      return workOrderStatsFunction(parentId, params)
        .then(function (stats) {
          self.avgWODuration = formatToDaysHoursMinutesString(stats[0].result);
        })
        .catch(function (err) {
          self.avgWODuration = formatToDaysHoursMinutesString(0);
          return $q.reject(err);
        });
    }

    // Gets the total number of service requests submitted
    function getNumberOfSubmittedRequests() {
      var newParams = {
        created_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return serviceRequestStatsFunction(parentId, params)
        .then(function (stats) {
          self.numRequestsSubmitted = stats[0].result;
        })
        .catch(function (err) {
          self.numRequestsSubmitted = 0;
          return $q.reject(err);
        });
    }

    // Gets the total number of service requests denied
    function getNumberOfDeniedRequests() {
      var newParams = {
        denied_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return serviceRequestStatsFunction(parentId, params)
        .then(function (stats) {
          self.numRequestsDenied = stats[0].result;
        })
        .catch(function (err) {
          self.numRequestsDenied = 0;
          return $q.reject(err);
        });
    }

    function getNumberOfOpenRequests() {
      var newParams = {
        status: "open",
        created_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return serviceRequestStatsFunction(parentId, params)
        .then(function (stats) {
          self.numRequestsOpened = stats[0].result;
        })
        .catch(function (err) {
          self.numRequestsOpened = 0;
          return $q.reject(err);
        });
    }

    function getNumberOfCompletedRequests() {
      var newParams = {
        status: "completed",
        created_date: buildDateString(),
      };
      var params = buildParams(newParams);
      return serviceRequestStatsFunction(parentId, params)
        .then(function (stats) {
          self.numRequestsCompleted = stats[0].result;
        })
        .catch(function (err) {
          self.numRequestsCompleted = 0;
          return $q.reject(err);
        });
    }

    function getAverageResolutionTime() {
      var completedParams = {
        status: "completed",
        projection_operator: "subtract",
        projection_fields: "completed_date,created_date",
        operator: "avg",
        created_date: buildDateString(),
      };

      var cParams = buildParams(completedParams);
      return serviceRequestStatsFunction(parentId, cParams)
        .then(function (completedStats) {
          self.avgResolutionTime = formatToDaysHoursMinutesString(
            completedStats[0].result
          );
        })
        .catch(function (err) {
          self.avgResolutionTime = formatToDaysHoursMinutesString(0);
          return $q.reject(err);
        });
    }

    function getAverageInitialResponseTime() {
      var deniedParams = {
        open_date: null,
        projection_operator: "subtract",
        projection_fields: "denied_date,created_date",
        operator: "sum",
        created_date: buildDateString(),
      };
      var openedParams = {
        projection_operator: "subtract",
        projection_fields: "open_date,created_date",
        operator: "sum",
        created_date: buildDateString(),
      };
      var countParams = {
        status: "$in,denied,completed,open",
        created_date: buildDateString(),
      };
      var dParams = buildParams(deniedParams);
      var oParams = buildParams(openedParams);
      var builtCountParams = buildParams(countParams);

      return serviceRequestStatsFunction(parentId, dParams)
        .then(function (deniedStats) {
          sumDeniedTimeInMs = deniedStats[0].result;
          return serviceRequestStatsFunction(parentId, oParams);
        })
        .then(function (openedStats) {
          sumOpenedTimeInMs = openedStats[0].result;
          return serviceRequestStatsFunction(parentId, builtCountParams);
        })
        .then(function (countStats) {
          numRespondedToServiceRequests = countStats[0].result;
          calculateAverageInitialResponseTime();
        })
        .catch(function (err) {
          self.avgInitResponseTime = 0;
          return $q.reject(err);
        });
    }

    function calculateAverageInitialResponseTime() {
      if (numRespondedToServiceRequests === 0) {
        self.avgInitResponseTime = formatToDaysHoursMinutesString(0);
        return;
      }

      var responseTimeInMilliseconds =
        (sumDeniedTimeInMs + sumOpenedTimeInMs) / numRespondedToServiceRequests;

      self.avgInitResponseTime = formatToDaysHoursMinutesString(
        responseTimeInMilliseconds
      );
    }

    function formatToDaysHoursMinutesString(duration) {
      const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;
      const hoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;
      const days = parseInt(moment.duration(duration).asDays());
      const daysMilliseconds = daysToMilliseconds(days);
      const hours = parseInt(
        moment.duration(duration - daysMilliseconds).asHours()
      );
      const hoursInMilliseconds = hoursToMilliseconds(hours);
      const minutesInMilliseconds =
        duration - daysMilliseconds - hoursInMilliseconds;
      const minutes = parseInt(
        moment.duration(minutesInMilliseconds).asMinutes()
      );
      return `${days} D : ${hours} H : ${minutes} M`;
    }
  }
})();
