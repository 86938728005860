(function () {
  angular
    .module("akitabox.ui.dialogs.bulkAssign")
    .controller("BulkAssignDialogController", BulkAssignDialogController);

  /** @ngInject */
  function BulkAssignDialogController(
    $mdDialog,
    $q,
    CancellableService,
    ScheduleService,
    OrganizationService,
    ToastService,
    UserService,
    WorkOrderService
  ) {
    var self = this;

    self.organization = OrganizationService.getCurrent();

    self.assigneesToBeAdded = [];
    self.assigneesToBeRemoved = [];
    /** @prop {Building} self.buildings - provided by the service, the buildings to search for assignees on */
    /** @prop {Building} self.building - provided by the service, the buildings to search for assignees on */
    self.cancellablePromise = null;
    self.disabled = false;
    /** @prop {Array<number>} self.selectedIndexes - provided by the service, array of the selected WOs/MSs indexes */
    self.loading = false;
    /** @prop {Array<string>} self.maintenanceSchedules - provided by the service, array of maintenance schedule ids, defaults to [] */
    self.message = null;
    self.progress = 0;
    self.assignType = null;
    /** @prop {Array<Task>} self.workOrders - provided by the service, defaults to [] */

    self.onAddListChangeHandler = onAddListChangeHandler;
    self.onAssignClickHandler = onAssignClickHandler;
    self.onCancelClickHandler = onCancelClickHandler;
    self.onRemoveListChangeHandler = onRemoveListChangeHandler;

    init();

    function init() {
      self.loading = false;
      self.assignType = self.workOrders
        ? "work orders"
        : "maintenance schedules";
    }

    /**
     * Iterate through each work order and add the new designated assignees to
     * them
     *
     * @param {Array<Task|FutureTask>} models
     *
     * @return {Promise<void>}
     */
    function assign(models) {
      if (
        !models.length ||
        (!self.assigneesToBeRemoved.length && !self.assigneesToBeAdded.length)
      ) {
        return $q.resolve(); // no one to add or remove, don't do anything
      }

      // Make sure we start our progress bar off correctly
      var currentAmountProcessed = 0;
      var amountToProcess = self.selectedIndexes.length;

      self.message =
        "Please note this action could take a few minutes to apply.";

      function startProcessing() {
        /**
         * Reset these every time we restart this process
         */
        self.cancellablePromise = null;
        var fns = [];
        var modelsToProcess = [];

        while (models.length) {
          if (modelsToProcess.length >= 5) {
            // We want to process 5 work orders at a time
            fns.push(processModels(modelsToProcess));
            // reset this to process the next 5 work orders
            modelsToProcess = [];
          }
          modelsToProcess.push(models.shift());
        }

        if (modelsToProcess.length) {
          // leftover work orders still need to be processed, do so here
          fns.push(processModels(modelsToProcess));
        }

        self.cancellablePromise = CancellableService.executeSeries(fns);
        return self.cancellablePromise.promise;
      }

      return startProcessing();

      /**
       * Creates a fn that returns an array of promises that run the assign api call for each WO/MS
       *
       * @param {Array<Task|FutureTask>} models - WO or MS array to be assigned through the api
       *
       * @return {function(): Promise<void>}
       */
      function processModels(models) {
        return function () {
          var promises = [];
          while (models.length) {
            promises.push(processModel(models.shift()));
          }
          return allSettled(promises);
        };
      }

      /**
       * Recursive fn that assembles the correct assignees together and makes
       * the api call to persist them.  Repeats until no workOrder is left from
       * workOrdersToUpdate array or if user closes the dialog
       *
       * @param {Task|FutureTask} model - the work order that needs its assignees updated
       *
       * @return {Promise<void>}
       */
      function processModel(model) {
        if (!model) {
          return $q.resolve(null);
        }

        var assignees = model.assignee_users || [];
        var newAssignees = [];

        if (!assignees.length) {
          // WO had no assignee's to being with, so just set it equal to the
          // user's "add" assignees list
          newAssignees = self.assigneesToBeAdded;
        } else {
          // Since WO assignees isn't empty, we need to remove the ones the
          // users indicated and then add the ones they wanted as well
          newAssignees = removeAssigneesFromList(
            angular.copy(assignees), // we copy here to not modify the original assignees
            self.assigneesToBeRemoved
          );
          newAssignees = addAssigneesToList(
            newAssignees,
            self.assigneesToBeAdded
          );
        }

        /**
         * check if new assignees is exactly same as old assignees
         * Don't send api request if they are
         */
        if (
          newAssignees.length &&
          assignees.length &&
          newAssignees.length === assignees.length
        ) {
          var same = true;

          for (var i = 0; i < newAssignees.length; i++) {
            if (newAssignees[i]._id !== assignees[i]._id) {
              same = false;
              break;
            }
          }

          if (same) {
            // update progress bar
            currentAmountProcessed++;
            self.progress = Math.floor(
              (currentAmountProcessed / amountToProcess) * 100
            );
            // dont return a model, nothing was updated
            return $q.resolve();
          }
        }

        if (newAssignees.length) {
          // The API needs an array of only string ids, so we transform it here
          newAssignees = newAssignees.map(function (newAssignee) {
            return newAssignee._id;
          });
        }

        var assignFn = self.workOrders
          ? WorkOrderService.assign
          : ScheduleService.update;

        const buildingId = model.building._id || model.building;

        return assignFn(buildingId, model._id, {
          assignee_users: newAssignees,
        }).then(function (updatedModel) {
          var userFilter = {};
          if (
            updatedModel.assignee_users &&
            updatedModel.assignee_users.length
          ) {
            var userIds = updatedModel.assignee_users.map(function (user) {
              return user._id;
            });
            userFilter._id = `$in,${userIds.join(",")}`;
            return UserService.getAll(self.organization._id, userFilter).then(
              function (users) {
                updatedModel.assignee_users = users;
                // update progress nar
                currentAmountProcessed++;
                self.progress = Math.floor(
                  (currentAmountProcessed / amountToProcess) * 100
                );
                return updatedModel;
              }
            );
          } else {
            // update progress bar
            currentAmountProcessed++;
            self.progress = Math.floor(
              (currentAmountProcessed / amountToProcess) * 100
            );
            return updatedModel;
          }
        });
      }
    }

    /**
     * Adds all the users from the assigneesToBeAdded list to the assignees list
     * without adding any duplicates. Used within processModel() only
     *
     * @param {Array<User>} assignees - list of users to be added to
     * @param {Array<User>} assigneesToBeAdded - list of users to be added
     *
     * @return {Array<User>} the new list of users
     */
    function addAssigneesToList(assignees, assigneesToBeAdded) {
      if (!assignees.length) {
        return angular.copy(self.assigneesToBeAdded);
      }

      /**
       * Make sure we aren't adding assignees from the assigneesToBeAdded array
       * that are already in the assignees array (no dups)
       */
      for (var k = 0; k < assigneesToBeAdded.length; k++) {
        var assigneeToBeAdded = assigneesToBeAdded[k];
        var found = null;

        for (var i = 0; i < assignees.length; i++) {
          if (assignees[i]._id === assigneeToBeAdded._id) {
            found = assigneeToBeAdded;
            break;
          }
        }

        if (!found) {
          assignees.push(assigneeToBeAdded);
        }
      }

      return assignees;
    }

    /**
     * Removes any assignee from assigneesToBeRemoved out of assignees.  Used
     * within processModel() only
     *
     * @param {Array<User>} assignees - the list of users to be modified/filtered
     * @param {Array<User>} assigneesToBeRemoved - the list of users that should removed from the assignees list
     *
     * @return {Array<User>} list of users that have been filtered
     */
    function removeAssigneesFromList(assignees, assigneesToBeRemoved) {
      if (!assignees.length) {
        // there are no assignees to start with, so nothing to remove
        return assignees;
      }

      for (var k = 0; k < assigneesToBeRemoved.length; k++) {
        var assigneeToBeRemoved = assigneesToBeRemoved[k];
        var removeIndex = -1;
        for (var i = 0; i < assignees.length; i++) {
          if (assignees[i]._id === assigneeToBeRemoved._id) {
            removeIndex = i;
            break;
          }
        }

        if (removeIndex !== -1) {
          assignees.splice(removeIndex, 1);
        }

        if (!assignees.length) {
          // nothing left to remove, just leave
          break;
        }
      }

      return assignees;
    }

    /**
     * Handles updating the list of assignees to be added to each work order
     *
     * @param {{}} $event - event object returned from <abx-multiple-assignees /> onChange fn
     * @param {string} $event.type - flag to indicate how the list changed (add|replace|remove)
     * @param {Array<User>} $event.value -  the updated assignees list
     *
     * @return void
     */
    function onAddListChangeHandler($event) {
      /**
       * Check if person is on the remove list
       * If they are, remove them from that list before adding them here
       * The only type of changes that fall in this category or "replace" or "add"
       */
      if ($event.type === "replace" || $event.type === "add") {
        for (var i = 0; i < self.assigneesToBeRemoved.length; i++) {
          var assignee = self.assigneesToBeRemoved[i];

          if (assignee._id === $event.model._id) {
            // We need $ngChanges to detect this, so we have to assign it an
            // entirely new object, we can't just change the contents within
            // the old one.
            var newAssigneesToBeRemoved = angular.copy(
              self.assigneesToBeRemoved
            );
            newAssigneesToBeRemoved.splice(i, 1);
            self.assigneesToBeRemoved = newAssigneesToBeRemoved;
            break;
          }
        }
      }

      self.assigneesToBeAdded = $event.value;
      return $q.resolve();
    }

    function onAssignClickHandler() {
      var models;

      if (self.workOrders) {
        models = self.selectedIndexes.map(function (index) {
          return angular.copy(self.workOrders[index]);
        });
      } else if (self.maintenanceSchedules) {
        models = self.selectedIndexes.map(function (index) {
          return angular.copy(self.maintenanceSchedules[index]);
        });
      } else {
        // don't process anything if we don't have either MSs or WOs
        models = [];
      }

      self.saving = true;
      var index = 0;
      assign(models)
        .then(
          function () {
            $mdDialog.hide();
          },
          function (err) {
            return $q.reject(err);
          },
          function notify(assignedModels) {
            // update the list models
            assignedModels.map(function (assignedModel) {
              if (assignedModel.status === "rejected" || !assignedModel.value) {
                // we dont want to be splicing bad models onto our list
                // these assign calls probably failed
                index++;
                return;
              }
              // Make sure we update the correct models here
              var modelsToSplice = self.workOrders
                ? self.workOrders
                : self.maintenanceSchedules;

              modelsToSplice.splice(
                self.selectedIndexes[index],
                1,
                assignedModel.value
              );
              index++;
            });
          }
        )
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Handles when a user clicks "stop" during the processing of this dialog
     * We stop the assigning and show them how many were already processed
     */
    function onCancelClickHandler() {
      if (self.cancellablePromise) {
        self.cancellablePromise.cancel("Work Order assign process has stopped");
        self.cancellablePromise = null;

        var total = self.selectedIndexes.length || 0;
        var numProcessed = Math.floor(total * (self.progress / 100));
        self.message =
          numProcessed + "/" + total + " Work Orders were processed";
      } else if (!self.saving) {
        // User hasn't started applying their changes yet, we just wanna close
        // the dialog
        $mdDialog.cancel();
      }
    }

    /**
     * Handles updating the list of assignees "to-be-removed"
     *
     * @param {{}} $event - event object returned from <abx-multiple-assignees /> onChange fn
     * @param {string} $event.type - flag to indicate how the list changed (add|replace|remove)
     * @param {Array<User>} $event.value -  the updated assignees list
     *
     * @return void
     */
    function onRemoveListChangeHandler($event) {
      /**
       * Check if person is on the add list
       * If they are, remove them from that list before adding them here
       */
      if ($event.type === "replace" || $event.type === "add") {
        for (var i = 0; i < self.assigneesToBeAdded.length; i++) {
          var assignee = self.assigneesToBeAdded[i];

          if (assignee._id === $event.model._id) {
            // We need $ngChanges to detect this, so we have to assign it an
            // entirely new object, we can't just change the contents within
            // the old one.
            var updatedAssigneesToBeAdded = angular.copy(
              self.assigneesToBeAdded
            );
            updatedAssigneesToBeAdded.splice(i, 1);
            self.assigneesToBeAdded = updatedAssigneesToBeAdded;
            break;
          }
        }
      }

      self.assigneesToBeRemoved = $event.value;
      return $q.resolve();
    }

    /**
     * Allows us to process all the promise calls without exiting on a single
     * rejection. Check out Promise.allSettled for more implementation details
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
     *
     * @param {Array<Promise<*>>} promises
     * @return {Promise<void>}
     */
    function allSettled(promises) {
      var results = promises.map(function (promise) {
        return promise.then(
          function (result) {
            return {
              status: "fulfilled",
              value: result,
            };
          },
          function (err) {
            return {
              status: "rejected",
              reason: err,
            };
          }
        );
      });

      return $q.all(results);
    }
  }
})();
