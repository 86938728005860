(function () {
  angular.module("akitabox.core.auth.logout", [
    "ui.router",
    "akitabox.core.router",
    "akitabox.core.services.identity",
    "akitabox.core.services.auth",
    "akitabox.core.services.authn",
    "akitabox.core.services.filter",
    "akitabox.core.services.recent",
    "akitabox.core.toast",
    "akitabox.core.services.session",
    "akitabox.core.services.token",
  ]);
})();
