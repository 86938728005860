(function () {
  angular
    .module("akitabox.desktop.workOrder.list")
    .controller("WorkOrderListController", WorkOrderListController);

  /* @ngInject */
  function WorkOrderListController(
    // Angular
    $location,
    $scope,
    $timeout,
    $q,
    // Constants
    EVENT_WORK_ORDER_CREATE,
    PRIORITIES,
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_SCHEDULED_OPEN,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_CANCELED,
    // Dialogs
    CreateWorkOrderDialog,
    ExportDialog,
    PrintDialog,
    // Services
    FeatureFlagService,
    OrganizationService,
    SessionService,
    TimeZoneService,
    ToastService,
    TradeService,
    TypeService,
    UserService,
    WorkOrderService,
    WorkOrdersBFFService,
    FilterBarManager,
    ManagedMultipleValueFilter,
    ManagedModelFieldFilter,
    ManagedLevelFilter,
    ManagedRoomFilter,
    ManagedAssetFilter,
    ManagedAssigneesFilter,
    ManagedFilterHelpers,
    // Resolve
    building,
    status
  ) {
    var self = this;
    var openStatuses = [
      WORK_ORDER_STATUS_OPEN,
      WORK_ORDER_STATUS_SCHEDULED,
      WORK_ORDER_STATUS_SCHEDULED_OPEN,
    ];

    var assetConfig;
    var assigneesConfig;
    var workPerformedConfig;
    var roomConfig;
    var levelConfig;
    var intentConfig;
    var typeConfig;
    var sourceConfig;
    var priorityConfig;
    var subjectConfig;
    var startDateConfig;
    var completionDateConfig;
    var dueDateConfig;
    var canceledDateConfig;
    var tradeConfig;
    var idConfig;
    var descriptionConfig;
    var srIdConfig;
    var msIdConfig;
    var inspectionProgramConfig;
    var customFieldConfig;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.workOrders = null;
    self.organization = OrganizationService.getCurrent();
    self.building = building;
    self.status = status;
    self.isOpen = openStatuses.indexOf(self.status) > -1;
    self.subtitle = getSubtitle();
    self.actions = getDefaultHeaderActions();
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.showCalendar = SessionService.getWOCalendarState();
    self.PRIORITIES = PRIORITIES;
    self.fetchLimit = 100;

    // wo sorting
    self.sortBy = getInitialSortColumn();
    self.sortOrder = getInitialSortOrder();

    var inspectionsEnabled = self.organization.show_inspections;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?status=${status} portion of the query
        query.status = status;
        // update the list
        changeFilters(query);
      },
    });

    subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    var assetRoomLevelBaseOptions = {
      getBuildingId: function () {
        return self.building._id;
      },
      getOrganization: OrganizationService.getCurrent,
    };

    var roomOptions = angular.extend({}, assetRoomLevelBaseOptions, {
      allowTextValue: true,
      textValueQueryField: "roomNameOrNumber",
    });

    levelConfig = new ManagedLevelFilter(
      self.filterBarManager,
      assetRoomLevelBaseOptions
    );
    roomConfig = new ManagedRoomFilter(self.filterBarManager, roomOptions);
    assetConfig = new ManagedAssetFilter(
      self.filterBarManager,
      assetRoomLevelBaseOptions
    );

    tradeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Trade",
      queryField: "trade",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchTrades().then(ManagedFilterHelpers.mapModelsToEnumOption);
      },
      modelValueToFilterValue: ManagedFilterHelpers.modelToId,
      modelValueToChipText: ManagedFilterHelpers.modelToName,
      filterValueToModelValue: function (tradeId) {
        return TradeService.getById(self.building._id, tradeId).then(function (
          trade
        ) {
          return [trade];
        });
      },
      onAdd: function () {
        if (!self.building.show_trades) {
          return $q.reject();
        }
      },
    });

    idConfig = new ManagedMultipleValueFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      prefix: "WO-",
      inputType: "number",
      modelValueToChipText: function (inputValue) {
        return "WO-" + inputValue;
      },
    });

    descriptionConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Description",
      queryField: "description_text",
    });

    srIdConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Service Request ID",
      queryField: "sr_number",
      inputType: "number",
      prefix: "SR-",
      modelValueToChipText: function (inputValue) {
        return "SR-" + inputValue;
      },
    });

    msIdConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Maintenance Schedule ID",
      queryField: "ms_number",
      inputType: "number",
      prefix: "MS-",
      modelValueToChipText: function (inputValue) {
        return "MS-" + inputValue;
      },
    });

    inspectionProgramConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Inspection Program ID",
        queryField: "ip_number",
        inputType: "number",
        prefix: "IP-",
        modelValueToChipText: function (inputValue) {
          return "IP-" + inputValue;
        },
      }
    );

    ManagedFilterHelpers.associateAssetRoomLevelFilters({
      level: levelConfig,
      room: roomConfig,
      asset: assetConfig,
    });

    priorityConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Priority",
      queryField: "priority",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Low", "Medium", "High", "Emergency"];
      },
      modelValueToFilterValue: function (priority) {
        return priority.toLowerCase();
      },
      modelValueToChipText: function (priority) {
        return priority.charAt(0).toUpperCase() + priority.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        return [queryStringValue];
      },
    });

    intentConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Reactive or Preventive",
      queryField: "intent",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Reactive", "Preventive"];
      },
      modelValueToFilterValue: function (intent) {
        intent = intent.toLowerCase();
        // TODO: Remove this and allow 'inspection as filter type'
        if (intent === "preventive") {
          return "$in,preventive,inspection";
        }
        return intent;
      },
      modelValueToChipText: function (intent) {
        return intent.charAt(0).toUpperCase() + intent.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        if (queryStringValue === "reactive") return ["reactive"];
        return ["preventive"];
      },
      afterRemove: function () {
        typeConfig.invalidateEnumOptions();
      },
    });

    typeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Type",
      inputType: "typeahead",
      optionsGroupKey: "group",
      getQueryField: function (model) {
        if (model) {
          return model.intent === "Reactive"
            ? "issue_type"
            : "maintenance_type";
        } else {
          return this.manager.getModelFieldFilter("issue_type") !== undefined
            ? "issue_type"
            : "maintenance_type";
        }
      },
      getEnumOptions: function () {
        return $q
          .all([
            fetchTypes(),
            intentConfig.getInputValue(self.filterBarManager),
          ])
          .then(function (resultsArray) {
            var types = resultsArray && resultsArray[0];
            var intent = resultsArray && resultsArray[1][0];
            var models = types
              .map(function (type) {
                return {
                  model: type,
                  value: type.name,
                  group: type.intent,
                };
              })
              .filter(function (model) {
                return (
                  !intent || intent.toLowerCase() === model.group.toLowerCase()
                );
              });
            return models;
          });
      },
      modelValueToFilterValue: function (type) {
        return type._id;
      },
      modelValueToChipText: function (type) {
        return type.name + " (" + type.intent + ")";
      },
      filterValueToModelValue: function (typeId) {
        return fetchTypes({ _id: typeId }).then(function (types) {
          if (!types) {
            return [];
          }
          return types;
        });
      },
      onAdd: function () {
        if (!self.building.show_issue_types) {
          return $q.reject();
        }
      },
    });

    var SOURCE_LABELS = [
      "Service Requests",
      "Maintenance Schedules",
      "Reactive Self-Identified",
      "Preventive Self-Identified",
    ];
    var SOURCE_VALUES = [
      "request",
      "future_task",
      "reactive_self_identified",
      "preventive_self_identified",
    ];

    if (self.organization.show_inspections) {
      SOURCE_LABELS.push("Inspections");
      SOURCE_VALUES.push("inspection");
    }

    sourceConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Source",
      queryField: "source",
      inputType: "typeahead",
      getEnumOptions: function () {
        return SOURCE_LABELS;
      },
      modelValueToFilterValue: function (source) {
        return SOURCE_VALUES[SOURCE_LABELS.indexOf(source)];
      },
      modelValueToChipText: function (source) {
        return source;
      },
      filterValueToModelValue: function (queryStringValue) {
        var sourceLabel =
          SOURCE_LABELS[SOURCE_VALUES.indexOf(queryStringValue)];
        if (!sourceLabel) {
          return [];
        }
        return [sourceLabel];
      },
    });

    startDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Start Date",
      queryField: "start_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    completionDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Completion Date",
      queryField: "completed_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    canceledDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Canceled Date",
      queryField: "canceled_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    dueDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Due Date",
      queryField: "due_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    var UNASSIGNED_USER = {
      _id: "null",
      is_deactivated: false,
      email: "Unassigned",
      display_name: "Unassigned",
    };

    assigneesConfig = new ManagedAssigneesFilter(self.filterBarManager, {
      getBuildingId: function () {
        return self.building._id;
      },
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          var models = assignees.map(function (user) {
            var value =
              user.display_name !== user.email
                ? user.display_name + " | " + user.email
                : user.display_name;

            return {
              model: user,
              value: value,
            };
          });

          // Add `Unassigned` as a typeahead option
          models.unshift({
            model: UNASSIGNED_USER,
            value: UNASSIGNED_USER.display_name,
          });

          return models;
        });
      },
    });

    workPerformedConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Work Performed By",
      queryField: "workers",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          return assignees.map(function (user) {
            var value =
              user.display_name !== user.email
                ? user.display_name + " | " + user.email
                : user.display_name;

            return {
              model: user,
              value: value,
            };
          });
        });
      },
      modelValueToFilterValue: function (user) {
        return user._id || user;
      },
      modelValueToChipText: function (user) {
        return user.display_name;
      },
      filterValueToModelValue: function (queryStringValue) {
        return UserService.getById(self.organization._id, queryStringValue);
      },
    });

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(descriptionConfig);
    self.filterBarManager.addFilterConfiguration(srIdConfig);
    self.filterBarManager.addFilterConfiguration(msIdConfig);
    if (inspectionsEnabled) {
      self.filterBarManager.addFilterConfiguration(inspectionProgramConfig);
    }
    self.filterBarManager.addFilterConfiguration(priorityConfig);
    self.filterBarManager.addFilterConfiguration(assigneesConfig);
    self.filterBarManager.addFilterConfiguration(workPerformedConfig);
    self.filterBarManager.addFilterConfiguration(intentConfig);
    self.filterBarManager.addFilterConfiguration(typeConfig);
    self.filterBarManager.addFilterConfiguration(sourceConfig);
    self.filterBarManager.addFilterConfiguration(tradeConfig);
    self.filterBarManager.addFilterConfiguration(levelConfig);
    self.filterBarManager.addFilterConfiguration(roomConfig);
    self.filterBarManager.addFilterConfiguration(assetConfig);
    self.filterBarManager.addFilterConfiguration(startDateConfig);
    self.filterBarManager.addFilterConfiguration(dueDateConfig);
    self.filterBarManager.addFilterConfiguration(completionDateConfig);
    self.filterBarManager.addFilterConfiguration(canceledDateConfig);

    if (self.organization.show_custom_srp_field) {
      var customLabel = self.organization.srp_custom_label;
      var customOptions = self.organization.srp_custom_options;
      if (customOptions && customOptions.length) {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
          inputType: "typeahead",
          getEnumOptions: function () {
            return customOptions;
          },
          modelValueToFilterValue: function (option) {
            return option;
          },
          modelValueToChipText: function (option) {
            return option;
          },
          filterValueToModelValue: function (queryStringValue) {
            return [queryStringValue];
          },
        });
      } else {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
        });
      }
      self.filterBarManager.addFilterConfiguration(customFieldConfig);
    }

    self.filterBarManager.modifyQuery = function (query) {
      var statusQuery = WorkOrderService.getStatusQuery(query);
      Object.assign(query, statusQuery);
    };

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.addWorkOrder = addWorkOrder; // Depracated
    self.onToggleShowCalendar = onToggleShowCalendar;
    self.fetchWorkOrdersForDates = fetchWorkOrdersForDates;
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;
    self.changeFilters = changeFilters;
    self.changeSort = changeSort;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_WORK_ORDER_CREATE, onWorkOrderCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getInitialSortColumn() {
      // first arg is false as the show start date field is irrelevant here
      var column = SessionService.getWorkOrderSortColumn(false, self.status);

      return column;
    }

    function getInitialSortOrder() {
      var order = SessionService.getWorkOrderSortOrder(self.status);

      return order;
    }

    function getSubtitle() {
      var prefix = null;
      var name = "Work Orders";
      switch (status) {
        case WORK_ORDER_STATUS_OPEN:
          prefix = "Open";
          break;
        case WORK_ORDER_STATUS_SCHEDULED:
          prefix = "Scheduled";
          break;
        case WORK_ORDER_STATUS_COMPLETED:
          prefix = "Completed";
          break;
        case WORK_ORDER_STATUS_CANCELED:
          prefix = "Canceled";
          break;
        default:
      }
      return {
        name: prefix ? prefix + " " + name : name,
      };
    }

    function getDefaultHeaderActions() {
      return [
        {
          icon: "print",
          text: "Print / Export",
          onClick: exportWorkOrders,
        },
      ];
    }

    function getFabActions() {
      return [
        {
          icon: "grid_on",
          label: "Export",
          action: exportWorkOrders,
        },
        {
          icon: "print",
          label: "Print",
          action: printWorkOrders,
        },
      ];
    }

    function getExportLocals() {
      var filters = angular.extend(
        {},
        self.activeFilters,
        WorkOrderService.getStatusQuery({
          status: status,
          start_date: self.activeFilters.start_date,
        })
      );
      return {
        route: WorkOrderService.buildListRoute(building._id),
        filters: filters,
      };
    }

    function printWorkOrders() {
      PrintDialog.show({
        locals: getExportLocals(),
      }).catch(ToastService.showError);
    }

    function exportWorkOrders() {
      ExportDialog.show({
        locals: getExportLocals(),
      }).catch(ToastService.showError);
    }

    function fetchAssignees() {
      return UserService.getAll(self.organization._id, {
        buildings: building._id,
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
        // status: "active", may want to filter on deactivated user
      }).catch(ToastService.showError);
    }

    function fetchTypes(params) {
      return TypeService.getTypes(building, params, { cache: false }).catch(
        ToastService.showError
      );
    }

    function fetchTrades(params) {
      return TradeService.getAll(building._id, params).catch(
        ToastService.showError
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * @depracated this function is not invoked by anything
     *
     */
    function addWorkOrder() {
      var locals = { building: self.building };

      CreateWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          // We know we're only getting one back, so take the first item in the returned array
          var newWorkOrder = workOrders[0];

          // Don't add to the list if the statuses don't agree
          if (!self.status.match(newWorkOrder.status)) return;

          if (angular.isArray(self.workOrders)) {
            self.workOrders.unshift(newWorkOrder);
          } else {
            self.workOrders = [newWorkOrder];
          }
          $timeout(function () {
            $scope.$broadcast("list:updateSelectedIndices", workOrders.length);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function onToggleShowCalendar() {
      // no value is passed from the switch. `showCalendar already has the new value`
      SessionService.setWOCalendarState(self.showCalendar);
    }

    function fetchWorkOrdersForDates(rangeStart, rangeEnd) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          // TODO: this query does not cover the case where a work order starts before this date range and ends after it
          // we may need a new back end oute to cover that.
          $or:
            "start_date=$gte," +
            rangeStart.valueOf() +
            ",$lte," +
            rangeEnd.valueOf() +
            ";" +
            "due_date=$gte," +
            rangeStart.valueOf() +
            ",$lte," +
            rangeEnd.valueOf() +
            ";",
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return WorkOrderService.getAllByBuildingId(building._id, filters).then(
          function (workOrders) {
            if (angular.isArray(self.workOrders)) {
              // TODO: we need to be inteligent when combining the query results with self.workOrders-
              // it's very easy to end up with duplicate work orders
              Array.prototype.push.apply(self.workOrders, workOrders);
            } else {
              self.workOrders = workOrders;
            }
            return workOrders.length;
          }
        );
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchWorkOrders(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        if (self.sortBy !== null && self.sortOrder !== null) {
          params.sort_by = self.sortBy + "," + self.sortOrder;
        }

        var filters = angular.extend({}, params, self.activeFilters);

        const setWO = function (workOrders) {
          let wos = workOrders;
          wos = WorkOrdersBFFService.addCalculatedProperties(wos);

          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(wos);
          } else {
            self.workOrders = wos;
          }
          return workOrders.length;
        };

        return WorkOrdersBFFService.getByBuilding(
          self.organization._id,
          building._id,
          filters
        ).then(setWO);
      };

      return $q
        .resolve(self.filterInitPromise)
        .then(doFetch)
        .finally(function () {
          $scope.$broadcast("list:refreshComplete");
        });
    }

    function fetchAllWorkOrders(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        if (self.sortBy !== null && self.sortOrder !== null) {
          params.sort_by = self.sortBy + "," + self.sortOrder;
        }

        var filters = angular.extend({}, params, self.activeFilters);

        return WorkOrderService.getAllByBuildingId(building._id, filters).then(
          function (workOrders) {
            self.workOrders = workOrders;
            return workOrders.length;
          }
        );
      };

      return $q
        .resolve(self.filterInitPromise)
        .then(doFetch)
        .finally(function () {
          $scope.$broadcast("list:refreshComplete");
        });
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.calendarFilters = angular.extend({ status: status }, activeFilters);
      self.workOrders = null;
      $scope.$broadcast("list:refresh");
    }

    function changeSort(sortBy, sortOrder) {
      self.workOrders = null;
      // again, showStartDate irrelevant on bldg list, 1st arg always false
      SessionService.setWorkOrderSortColumn(false, self.status, sortBy);
      SessionService.setWorkOrderSortOrder(self.status, sortOrder);
      self.sortBy = sortBy;
      self.sortOrder = sortOrder;
      $scope.$broadcast("list:refresh");
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}   $event      Angular event
     * @param {WorkOrder[]} workOrders  List of new work orders
     */
    function onWorkOrderCreate($event, workOrders) {
      var addCnt = 0;
      for (var i = 0; i < workOrders.length; ++i) {
        if (workOrders[i].building === self.building._id) {
          if (!self.workOrders) {
            self.workOrders = [];
          }
          self.workOrders.unshift(workOrders[i]);
          addCnt++;
        }
      }
      if (addCnt > 0) {
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      }
    }
  }
})();
