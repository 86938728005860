(function () {
  angular
    .module("akitabox.core.services.identity", [
      "ngCookies",
      "akitabox.core.services.http",
    ])
    .factory("IdentityService", IdentityService);

  /** @ngInject */
  function IdentityService($q, $cookies, HttpService) {
    /**
     * Account service
     *
     * @type {Object}
     */
    var service = {
      getById: getById,
      getCurrent: getCurrent,
      setCurrent: setCurrent,
      getUserId: getUserId,
      get: get,
      update: update,
    };

    var current; // The current user
    var currentRequest;

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get an identity by ID
     *
     * @param  {String}                 identityId  Identity ID
     * @param  {Object}                 params      Query params
     * @return {Promise<Object|Error>}              Promise that resolves with identity
     */
    function getById(identityId, params) {
      if (!params) params = {};
      params.include_site_permissions = true;

      return HttpService.get("/admin/identities/" + identityId, params);
    }

    /**
     * Get the currently logged in user's identity
     *
     * @return {Object} User identity
     */
    function getCurrent() {
      if (current !== undefined) {
        return $q.resolve(current);
      }

      if (currentRequest) {
        // Return the outstanding request
        return currentRequest;
      }

      // Make the request for the first time
      currentRequest = HttpService.get("/identity").then(function (identity) {
        // Don't use returned identity if we've set the current after we
        // initially made this request
        if (current === undefined) {
          current = identity;
        }

        currentRequest = null;
        return current;
      });
      return currentRequest;
    }

    /**
     * Send a request to patch the identity
     * and then update the current
     *
     * @param {Object} data
     */
    function update(data) {
      return HttpService.patch("/identity", data).then(function (identity) {
        setCurrent(identity);
        return current;
      });
    }

    /**
     * Set the current user
     *
     * @param {Object} identity - Account to set to current user
     */
    function setCurrent(identity) {
      current = identity;
    }

    /**
     * Get the currently logged in user's identity ID from cookies
     *
     * @return {String} User identity ID
     */
    function getUserId() {
      // we populate current right after a successful login
      // There shouldn't be a legit case where current is used on a guest user
      return current ? current._id : null;
    }

    /**
     * Get a list of identities
     *
     * @return {Array} List of identities
     */
    function get(params) {
      if (!params) params = {};

      return HttpService.get("/admin/identities", params);
    }
  }
})();
