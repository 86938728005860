(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.serviceRequest
   */
  angular
    .module("akitabox.desktop.directives.details.serviceRequest", [
      "ngSanitize",
      "angular.filter",
      "akitabox.core.router",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.workOrder.create",
      "akitabox.ui",
      "akitabox.core.utils",
    ])
    .directive("abxServiceRequestDetails", AbxServiceRequestDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.serviceRequest
   * @name AbxServiceRequestDetailsDirective
   *
   * @description
   * '<abx-service-request-details>' displays the details of a service request
   *
   * @param {Object}      ng-model           The component's model (request)
   * @param {Object}      abx-building       Building that the request belongs to
   *
   * @usage
   * <hljs lang="html">
   *     <abx-service-request-details
   *         ng-model="vm.request"
   *         abx-building="vm.building">
   *     </abx-service-request-details>
   * </hljs>
   *
   * @ng-inject
   */
  function AbxServiceRequestDetailsDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/details/request/request-details.html",
      require: ["abxServiceRequestDetails", "ngModel"],
      controller: AbxServiceRequestDetailsController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Add external change listener
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model value
       *
       * @param   {Object} value  New model value
       * @return  {Object}        Formatted model value
       */
      function onExternalChange(value) {
        vm.request = angular.copy(value);
        if (value) vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for abx-service-request-details
   *
   * @ngInject
   */
  function AbxServiceRequestDetailsController(
    // Angular
    $timeout,
    // Services
    OrganizationService,
    Router,
    UserService,
    ToastService,
    Utils,
    // Dialogs
    CreateWorkOrderDialog
  ) {
    var self = this;

    var organization = OrganizationService.getCurrent();

    var user = UserService.getCurrent();
    var permissions = UserService.getPermissions();

    // ------------------------
    //   Attributes
    // ------------------------

    self.building = angular.isDefined(self.building) ? self.building : null;
    self.showCustomField = organization.show_custom_srp_field;
    self.customFieldLabel = organization.srp_custom_label;
    self.request = null;
    self.workOrder = null;
    self.canAddWorkOrder = false;
    self.canViewWorkOrder = false;
    self.hasWorkOrder = false;
    self.rendering = true;

    // Functions
    self.init = init;
    self.addWorkOrder = addWorkOrder;
    self.formatPhoneNumber = Utils.formatPhoneNumber;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Initialize the directive, check create permission
     */
    function init() {
      if (self.request.is_new) {
        self.canAddWorkOrder = permissions.task.create;
      } else if (self.request.task) {
        if (permissions.task.read_all) {
          self.canViewWorkOrder = true;
        } else {
          // Can always view work orders assigned to user
          var assignees = self.request.task.assignee_users;
          self.canViewWorkOrder = assignees.indexOf(user._id) > -1;
        }
      }
      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    /**
     * Add work order to the service request
     */
    function addWorkOrder() {
      var locals = angular.extend({
        building: self.building,
        request: self.request,
      });

      CreateWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          var stateParams = {
            accountId: self.building.cre_account,
            buildingId: self.building._id,
            workOrderId: workOrders[0]._id,
          };
          // Will error because of transition to abstract state
          Router.go("app.workOrder", stateParams);
        })
        .catch(ToastService.showError);
    }
  }
})();
