(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.filter
   */
  angular
    .module("akitabox.core.services.filter", [
      "akitabox.core.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.cache",
      "akitabox.core.services.flag",
    ])
    .factory("FilterService", FilterService);

  /** @ngInject */
  /**
   * @ngdoc factory
   * @module akitabox.core.services.filter
   * @name FilterService
   *
   * @description
   * Service that retrieves, stores, and clears cached filter data
   */
  function FilterService(
    // Angular
    $log,
    // Third Party
    moment,
    // Constants
    INTENTS,
    PRIORITIES,
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_OPEN_NEW,
    SERVICE_REQUEST_STATUS_DENIED,
    SERVICE_REQUEST_STATUS_COMPLETED,
    SUPPORTED_FILE_TYPES,
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_SCHEDULED_OPEN,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_CANCELED,
    // Services
    CacheService,
    FeatureFlagService
  ) {
    var service = {
      // Filters
      clearAllFilters: clearAllFilters,
      // Building
      getCachedBuildingIds: getCachedBuildingIds,
      getBuildingFilter: getBuildingFilter,
      setBuildingFilter: setBuildingFilter,
      clearBuildingFilters: clearBuildingFilters,
      // User
      getUserFilter: getUserFilter,
      setUserFilter: setUserFilter,
      clearUserFilters: clearUserFilters,
      // Config
      getAssetConfig: getAssetConfig,
      getDocumentConfig: getDocumentConfig,
      getFloorConfig: getFloorConfig,
      getRequestConfig: getRequestConfig,
      getRoomConfig: getRoomConfig,
      getScheduleConfig: getScheduleConfig,
      getTagConfig: getTagConfig,
      getWorkOrderConfig: getWorkOrderConfig,
    };

    var KEY_FILTERS = "filters";
    var USER_PREFIX = "my";
    var BUILDING_PREFIX = "building";
    var BUILDING_CONFIG = {
      name: "Building",
      label: "Choose a building",
      key: "building",
      type: "entity",
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Return the filter cache
     *
     * @return {Object}
     */
    function getCache() {
      return CacheService.get(KEY_FILTERS) || {};
    }

    /**
     * Set the filter cache
     *
     * @param filters
     */
    function setCache(filters) {
      CacheService.set(KEY_FILTERS, filters);
    }

    /**
     * Returns the filter stored at the specified key
     *
     * @param key
     * @return {Object}
     */
    function getFilter(key) {
      var filters = getCache();
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        return filters[key];
      }
      return null;
    }

    /**
     * Sets the filter at the specified key
     *
     * @param key
     * @param filter
     */
    function setFilter(key, filter) {
      var filters = getCache();
      filters[key] = filter;
      setCache(filters);
    }

    /**
     * Return the full key for the building-level filter
     *
     * @param id
     * @param key
     * @return {String}
     */
    function getBuildingKey(id, key) {
      return BUILDING_PREFIX + ":" + id + ":" + key;
    }

    /**
     * Return the full key for the user-level filter
     *
     * @param key
     * @return {String}
     */
    function getUserKey(key) {
      return USER_PREFIX + ":" + key;
    }

    function parseConstantAsFilterOptions(constant) {
      if (angular.isArray(constant)) {
        return constant.map(function (key) {
          return {
            alias: key,
            value: key,
          };
        });
      } else if (angular.isObject(constant)) {
        return Object.keys(constant).map(function (key) {
          return {
            alias: constant[key],
            value: key,
          };
        });
      }
      return [];
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Clear all filters
     */
    function clearAllFilters() {
      CacheService.clear(KEY_FILTERS);
    }

    /******************* *
     *   Building
     * ****************** */

    /**
     * Return an array of buildings that have cached filters
     *
     * @return {Array}
     */
    function getCachedBuildingIds() {
      var ids = [];
      var filters = getCache();
      var isBuildingKey = new RegExp("^" + BUILDING_PREFIX + ":");
      for (var key in filters) {
        if (
          Object.prototype.hasOwnProperty.call(filters, key) &&
          isBuildingKey.test(key)
        ) {
          var id = key.split(":")[1];
          if (ids.indexOf(id) === -1) {
            ids.push(id);
          }
        }
      }
      return ids;
    }

    /**
     * Return a building-level filter for the specified building
     *
     * @param {String}  id      Building id
     * @param {String}  key     Filter key
     * @return {Object}         Filter Value
     */
    function getBuildingFilter(id, key) {
      return getFilter(getBuildingKey(id, key));
    }

    /**
     * Set a building-level filter for the specified building
     *
     * @param {String}  id      Building id
     * @param {String}  key     Filter key
     * @param {Object}  filter  Filter value
     */
    function setBuildingFilter(id, key, filter) {
      setFilter(getBuildingKey(id, key), filter);
    }

    /**
     * Clear all building-level filters for the specified building
     *
     * @param {String}  id      Building id
     */
    function clearBuildingFilters(id) {
      var filters = getCache();
      var isBuildingKey = new RegExp("^" + BUILDING_PREFIX + ":" + id + ":");
      for (var key in filters) {
        if (
          Object.prototype.hasOwnProperty.call(filters, key) &&
          isBuildingKey.test(key)
        ) {
          // cannot set to undefined, must use delete to get rid of key
          delete filters[key];
        }
      }
      setCache(filters);
    }

    /******************* *
     *   User
     * ****************** */

    /**
     * Return a user-level filter
     *
     * @param  {String}  key    Filter key
     * @return {Object}         Filter Value
     */
    function getUserFilter(key) {
      return getFilter(getUserKey(key));
    }

    /**
     * Set a user-level filter
     *
     * @param {String}  key     Filter key
     * @param {Object}  filter  Filter value
     */
    function setUserFilter(key, filter) {
      setFilter(getUserKey(key), filter);
    }

    /**
     * Clear all user-level filters
     */
    function clearUserFilters() {
      var filters = getCache();
      var isUserKey = new RegExp("^" + USER_PREFIX + ":");
      for (var key in filters) {
        if (
          Object.prototype.hasOwnProperty.call(filters, key) &&
          isUserKey.test(key)
        ) {
          // cannot set to undefined, must use delete to get rid of key
          delete filters[key];
        }
      }
      setCache(filters);
    }

    /******************* *
     *   Config
     * ****************** */

    function getAssetConfig(values, pinTypes, options) {
      var showBuilding = options && options.showBuildings;

      var config = {
        name: {
          name: "Name",
          label: "Enter a name",
          key: "name",
          type: "text",
        },
        level: {
          name: "Floor",
          label: "Choose a floor",
          key: "level",
          type: "entity",
        },
        room: {
          name: "Room",
          label: "Choose a room",
          key: "room",
          type: "entity",
        },
        pin_type: {
          name: "Category",
          label: "Choose a category",
          key: "pin_type",
          type: "pintype",
        },
        percentX: {
          name: "Placed on Floor Plan",
          label: "Choose Yes or No",
          key: "percentX",
          type: "enum",
          values: function () {
            return [
              {
                alias: "Yes",
                value: "$ne,null",
              },
              {
                alias: "No",
                value: "null",
              },
            ];
          },
        },
      };

      if (pinTypes) {
        var allIDHidden = allFieldsHidden("ID", pinTypes);
        if (!allIDHidden) {
          config.id = {
            name: "ID",
            label: "Enter an ID",
            key: "id",
            type: "pinfield",
            field: {
              name: "ID",
              type: "text",
            },
          };
        }

        var allTypeHidden = allFieldsHidden("Type", pinTypes);
        if (!allTypeHidden) {
          config.type = {
            name: "Type",
            label: "Choose a Type",
            key: "type",
            type: "pinfield",
            field: {
              name: "Type",
              type: "enum",
            },
            requiresPinType: true,
          };
        }

        var allManufacturerHidden = allFieldsHidden("Manufacturer", pinTypes);
        if (!allManufacturerHidden) {
          config.manufacturer = {
            name: "Manufacturer",
            label: "Enter a manufacturer",
            key: "manufacturer",
            type: "pinfield",
            field: {
              name: "Manufacturer",
              type: "text",
            },
          };
        }

        var allModelHidden = allFieldsHidden("Model", pinTypes);
        if (!allModelHidden) {
          config.model = {
            name: "Model",
            label: "Enter a model",
            key: "model",
            type: "pinfield",
            field: {
              name: "Model",
              type: "text",
            },
          };
        }

        var allSerialNumberHidden = allFieldsHidden("Serial Number", pinTypes);
        if (!allSerialNumberHidden) {
          config.serial_number = {
            name: "Serial Number",
            label: "Enter a serial number",
            key: "serial_number",
            type: "pinfield",
            field: {
              name: "Serial Number",
              type: "text",
            },
          };
        }

        config.pin_type.values = function () {
          return pinTypes;
        };
      }

      if (showBuilding) {
        config.building = BUILDING_CONFIG;
        config.building.values = values.building;

        // Org list will not have room or asset or floor filters
        delete config.room;
        delete config.pin_type;
        delete config.level;

        return config;
      }

      // Values
      if (values) {
        // Entities
        if (values.floor) {
          config.level.values = values.floor;
        } else {
          $log.error("FilterService.getAssetConfig: floor values are required");
        }

        if (values.room) {
          config.room.values = values.room;
        } else {
          $log.error("FilterService.getAssetConfig: room values are required");
        }
      } else {
        $log.error(
          "FilterService.getAssetConfig: floor and room values are required"
        );
      }

      return config;
    }

    function allFieldsHidden(field, pinTypes) {
      var hiddenCount = 0;
      for (var i = 0; i < pinTypes.length; i++) {
        var pinFields = pinTypes[i].fields;
        for (var j = 0; j < pinFields.length; j++) {
          var pinField = pinFields[j];
          if (pinField.name && pinField.name === field && pinField.is_hidden) {
            hiddenCount++;
          }
        }
      }

      return hiddenCount === pinTypes.length;
    }

    function getDocumentConfig(tagCategories, values, options) {
      var showBuilding = options && options.showBuildings;

      var config = {
        archived: {
          name: "Archived",
          key: "archived",
          type: "text",
          hidden: true,
        },
        name: {
          name: "Name",
          label: "Enter a name",
          key: "name",
          type: "text",
        },
        extension: {
          name: "File Type",
          label: "Choose a file type",
          key: "extension",
          type: "enum",
          values: function () {
            return parseConstantAsFilterOptions(SUPPORTED_FILE_TYPES);
          },
        },
      };

      if (showBuilding) {
        config.building = BUILDING_CONFIG;
        config.building.values = values.building;
      }

      var tagConfig = tagCategories ? getTagConfig(tagCategories) : {};

      return angular.extend({}, config, tagConfig);
    }

    function getTagConfig(tagCategories) {
      var config = {};

      for (var i = 0; i < tagCategories.length; i++) {
        (function (tagCategory) {
          var prefixed = "tags_" + tagCategory.cat_name;
          config[prefixed] = {
            name: tagCategory.cat_alias,
            label: "Enter a " + tagCategory.cat_alias,
            key: prefixed,
            type: "enum",
            values: function () {
              return tagCategory.values.map(function (value) {
                return {
                  value: value.value_name,
                  alias: value.value_alias,
                };
              });
            },
          };
        })(tagCategories[i]);
      }

      return config;
    }

    function getFloorConfig(buildingFetch) {
      var config = {
        name: {
          name: "Name",
          label: "Enter a name",
          key: "name",
          type: "text",
        },
        document: {
          name: "Has Floor Plan",
          label: "Choose Yes or No",
          key: "document",
          type: "enum",
          values: function () {
            return [
              {
                alias: "Yes",
                value: "$ne,null",
              },
              {
                alias: "No",
                value: "null",
              },
            ];
          },
        },
      };

      if (buildingFetch) {
        config.building = {
          name: "Building",
          label: "Choose a building",
          key: "building",
          type: "entity",
          values: buildingFetch,
        };
      }

      return config;
    }

    function getRequestConfig(status, showIssueTypes, values, includeBuilding) {
      // Config
      var config = {
        status: {
          name: "Status",
          key: "status",
          type: "text",
          hidden: true,
        },
        subject: {
          name: "Subject",
          label: "Enter a subject",
          key: "subject",
          type: "text",
        },
        number: {
          name: "ID",
          prefix: "SR-",
          key: "number",
          type: "id",
        },
        issue_type: {
          name: "Issue Type",
          label: "Choose an issue type",
          key: "issue_type",
          type: "entity",
        },
        level: {
          name: "Floor",
          label: "Choose a floor",
          key: "level",
          type: "entity",
        },
        room: {
          name: "Room",
          label: "Choose a room",
          key: "room",
          type: "entity",
        },
        asset: {
          name: "Asset",
          label: "Choose an asset",
          key: "asset",
          type: "entity",
        },
        created_date: {
          name: "Date Submitted",
          label: "Choose a range of dates",
          key: "created_date",
          type: "date",
        },
      };

      if (includeBuilding) {
        config.building = {
          name: "Building",
          label: "Choose a building",
          key: "building",
          type: "entity",
        };
        // Org list will not have room or asset or floor filters
        delete config.room;
        delete config.asset;
        delete config.level;
      }

      if (!showIssueTypes) delete config.issue_type;

      switch (status) {
        case SERVICE_REQUEST_STATUS_NEW:
          break;
        case SERVICE_REQUEST_STATUS_OPEN:
          break;
        case SERVICE_REQUEST_STATUS_OPEN_NEW:
          break;
        case SERVICE_REQUEST_STATUS_DENIED:
          config.denied_date = {
            name: "Date Denied",
            label: "Choose a range of dates",
            key: "denied_date",
            type: "date",
          };
          break;
        case SERVICE_REQUEST_STATUS_COMPLETED:
          config.completed_date = {
            name: "Date Completed",
            label: "Choose a range of dates",
            key: "completed_date",
            type: "date",
          };
          break;
        default:
          $log.error("FilterService.getRequestConfig: invalid status");
          break;
      }

      // Values
      if (values) {
        // Entities
        if (config.level) config.level.values = values.floor;
        if (config.room) config.room.values = values.room;
        if (config.asset) config.asset.values = values.asset;
        if (showIssueTypes) config.issue_type.values = values.issue_type;
        if (includeBuilding) config.building.values = values.building;
      } else {
        $log.error(
          "FilterService.getRequestConfig: floor, room, asset, and issue type values are required"
        );
      }

      return config;
    }

    function getRoomConfig(values, pinType, options) {
      var showBuilding = options && options.showBuildings;
      var config = {
        name: {
          name: "Number",
          label: "Enter a room number",
          key: "name",
          type: "text",
        },
        pin_type: {
          name: "Category",
          label: "Choose a category",
          key: "pin_type",
          type: "pintype",
          hidden: true,
        },
        percentX: {
          name: "Placed on Floor Plan",
          label: "Choose Yes or No",
          key: "percentX",
          type: "enum",
          values: function () {
            return [
              {
                alias: "Yes",
                value: "$ne,null",
              },
              {
                alias: "No",
                value: "null",
              },
            ];
          },
        },
      };
      if (showBuilding) {
        config.building = BUILDING_CONFIG;
        config.building.values = values.building;
      }
      if (pinType) {
        // This is not ideal, but are planning on implementing all filters so this will be refactored then
        // pinType.fields is returned in the correct order set in settings so add them to config in that order
        for (var i = 0; i < pinType.fields.length; i++) {
          var pinField = pinType.fields[i];
          if (pinField.is_hidden) continue;
          var pinFieldName = pinField.name;
          switch (pinFieldName) {
            case "Floor":
              config.level = {
                name: "Floor",
                label: "Choose a floor",
                key: "level",
                type: "entity",
              };
              if (values && values.floor) {
                config.level.values = values.floor;
              }
              break;
            case "Type":
              config.room_type = {
                name: "Room Type",
                label: "Choose a room type",
                key: "room_type",
                type: "pinfield",
                field: {
                  name: "Type",
                  type: "enum",
                },
                requiresPinType: true,
              };
              break;
            case "Floor Type":
              config.floor_type = {
                name: "Floor Type",
                label: "Choose a floor type",
                key: "floor_type",
                type: "pinfield",
                field: {
                  name: "Floor Type",
                  type: "enum",
                },
                requiresPinType: true,
              };
              break;
            case "Square Feet":
              config.sq_feet = {
                name: "Square Footage",
                label: "Enter square footage",
                key: "square_feet",
                type: "pinfield",
                field: {
                  name: "Square Feet",
                  type: "number",
                  data_type: "float",
                },
                requiresPinType: true,
              };
              break;
            case "Name":
              config.room_name = {
                name: "Name",
                label: "Enter room name",
                key: "room_name",
                type: "pinfield",
                field: {
                  name: "Name",
                  type: "text",
                },
              };
            default:
              break;
          }
        }
        config.pin_type.values = function () {
          return [pinType];
        };
      }

      if (!values || !values.floor) {
        $log.error("FilterService.getRoomConfig: floor values are required");
      }

      return config;
    }

    function getScheduleConfig(values, options) {
      // Show maintenance_types and trades, unless specified otherwise
      var showTypes = !options || options.showTypes;
      var showTrades = !options || options.showTrades;
      var showBuilding = options && options.showBuildings;

      var config = {
        status: {
          name: "Status",
          key: "status",
          type: "text",
          hidden: true,
        },
        subject: {
          name: "Subject",
          label: "Enter a subject",
          key: "subject",
          type: "text",
        },
        number: {
          name: "ID",
          prefix: "MS-",
          key: "number",
          type: "id",
        },
        priority: {
          name: "Priority",
          label: "Choose a priority",
          key: "priority",
          type: "enum",
        },
        level: {
          name: "Floor",
          label: "Choose a floor",
          key: "level",
          type: "entity",
        },
        room: {
          name: "Room",
          label: "Choose a room",
          key: "room",
          type: "entity",
        },
        asset: {
          name: "Asset",
          label: "Choose an asset",
          key: "asset",
          type: "entity",
        },
      };

      if (showTypes) {
        config.maintenance_type = {
          name: "Maintenance Type",
          label: "Choose a maintenance type",
          key: "maintenance_type",
          type: "entity",
        };
      }

      if (showTrades) {
        config.trade = {
          name: "Trade",
          label: "Choose a trade",
          key: "trade",
          type: "entity",
        };
      }

      if (showBuilding) {
        config.building = BUILDING_CONFIG;

        delete config.type;
        delete config.trade;
        delete config.level;
        delete config.room;
        delete config.asset; // backend can't filter on these values
      }

      // Values
      if (values) {
        // Entities
        if (config.level) config.level.values = values.floor;
        if (config.room) config.room.values = values.room;
        if (config.asset) config.asset.values = values.asset;
        if (config.building) config.building.values = values.building;
      } else {
        $log.error(
          "FilterService.getScheduleConfig: level, room, and/or asset values are required"
        );
      }

      if (showTypes) {
        if (values.maintenance_type) {
          config.maintenance_type.values = values.maintenance_type;
        } else {
          $log.error(
            "FilterService.getScheduleConfig: maintenance type values are required"
          );
        }
      }

      if (showTrades) {
        if (values.trade) {
          config.trade.values = values.trade;
        } else {
          $log.error(
            "FilterService.getScheduleConfig: trade values are required"
          );
        }
      }

      // Priorities
      config.priority.values = function () {
        return parseConstantAsFilterOptions(PRIORITIES);
      };

      return config;
    }

    function getWorkOrderConfig(status, values, options) {
      // Show types and trades, unless specified otherwise
      var showTypes = !options || options.showTypes;
      var showTrades = !options || options.showTrades;
      var showBuilding = options && options.showBuildings;

      // Config
      var config = {};
      config.status = {
        name: "Status",
        key: "status",
        type: "text",
        hidden: true,
      };
      config.subject = {
        name: "Subject",
        label: "Enter a subject",
        key: "subject",
        type: "text",
      };
      config.number = {
        name: "ID",
        prefix: "WO-",
        key: "number",
        type: "id",
      };
      config.sr_number = {
        name: "Service Request ID",
        prefix: "SR-",
        key: "sr_number",
        type: "id",
      };
      config.ms_number = {
        name: "Maintenance Schedule ID",
        prefix: "MS-",
        key: "ms_number",
        type: "id",
      };
      config.priority = {
        name: "Priority",
        label: "Choose a priority",
        key: "priority",
        type: "enum",
      };
      config.assignee_account = {
        name: "Assignee",
        label: "Choose an assignee",
        key: "assignee_account",
        type: "account",
      };
      config.intent = {
        name: "Reactive or Preventive",
        label: "Choose either reactive or preventive",
        key: "intent",
        type: "enum",
      };
      config.future_task = {
        name: "Maintenance Schedule ID",
        key: "future_task",
        type: "future_task",
        hidden: true,
      };

      if (showTypes) {
        config.type = {
          name: "Type",
          label: "Choose a type",
          key: "type",
          type: "entity",
          isGrouped: "true",
          groupByKey: "intent",
          childQueryKeys: {
            Reactive: "issue_type",
            Preventive: "maintenance_type",
          },
        };
      }
      if (showTrades) {
        config.trade = {
          name: "Trade",
          label: "Choose a trade",
          key: "trade",
          type: "entity",
        };
      }
      config.level = {
        name: "Floor",
        label: "Choose a floor",
        key: "level",
        type: "entity",
      };
      config.room = {
        name: "Room",
        label: "Choose a room",
        key: "room",
        type: "entity",
      };
      config.asset = {
        name: "Asset",
        label: "Choose an asset",
        key: "asset",
        type: "entity",
      };
      if (showBuilding) {
        config.building = BUILDING_CONFIG;

        delete config.type;
        delete config.trade;
        delete config.level;
        delete config.room;
        delete config.asset;
      }

      switch (status) {
        case WORK_ORDER_STATUS_OPEN:
          config.start_date = {
            name: "Start Date",
            label: "Choose a range of dates",
            key: "start_date",
            type: "date",
            maxDate: new moment.utc().endOf("day").valueOf(),
          };
          config.due_date = {
            name: "Due Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
          };
          break;
        case WORK_ORDER_STATUS_SCHEDULED:
          config.start_date = {
            name: "Start Date",
            label: "Choose a range of dates",
            key: "start_date",
            type: "date",
            minDate: new moment.utc().endOf("day").valueOf(),
          };
          config.due_date = {
            name: "Due Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
            minDate: new moment.utc().endOf("day").valueOf(),
          };
          break;
        case WORK_ORDER_STATUS_SCHEDULED_OPEN:
          config.start_date = {
            name: "Start Date",
            label: "Choose a range of dates",
            key: "start_date",
            type: "date",
          };
          config.due_date = {
            name: "Due Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
          };
          break;
        case WORK_ORDER_STATUS_CANCELED:
          config.start_date = {
            name: "Start Date",
            label: "Choose a range of dates",
            key: "start_date",
            type: "date",
          };
          config.due_date = {
            name: "Due Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
          };
          config.canceled_date = {
            name: "Canceled Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
          };
          break;
        case WORK_ORDER_STATUS_COMPLETED:
          config.start_date = {
            name: "Start Date",
            label: "Choose a range of dates",
            key: "start_date",
            type: "date",
          };
          config.due_date = {
            name: "Due Date",
            label: "Choose a range of dates",
            key: "due_date",
            type: "date",
          };
          config.completed_date = {
            name: "Completion Date",
            label: "Choose a range of dates",
            key: "completed_date",
            type: "date",
          };
          break;
        default:
          $log.error("FilterService.getWorkOrderConfig: invalid status");
          break;
      }

      // Values
      if (values) {
        // Assignee
        if (config.assignee_account) {
          config.assignee_account.values = function () {
            return values.assignee_account().then(function (assignees) {
              var unassignedValue = {
                _id: "null",
                name: "Unassigned",
              };
              var assigneeOptions = angular.copy(assignees.slice());
              for (var i = 0; i < assigneeOptions.length; ++i) {
                var assignee = assigneeOptions[i];
                var accountId = assignee.account
                  ? assignee.account._id
                  : assignee._id;
                assigneeOptions[i]._id = accountId;
              }
              assigneeOptions.unshift(unassignedValue);
              return assigneeOptions;
            });
          };
        }
        // Entities
        if (config.level) config.level.values = values.floor;
        if (config.room) config.room.values = values.room;
        if (config.asset) config.asset.values = values.asset;
        if (config.building) config.building.values = values.building;
      } else {
        $log.error(
          "FilterService.getWorkOrderConfig: assignee, level, room, and/or asset values are required"
        );
      }
      if (showTypes) {
        if (values && values.type) {
          config.type.values = values.type;
        } else {
          $log.error(
            "FilterService.getWorkOrderConfig: type values are required if `options.showTypes` is " +
              "not provided as `false`"
          );
        }
      }
      if (showTrades) {
        if (values && values.trade) {
          config.trade.values = values.trade;
        } else {
          $log.error(
            "FilterService.getWorkOrderConfig: trade values are required if `options.showTrades` is " +
              "not provided as `false`"
          );
        }
      }
      // Priority
      config.priority.values = function () {
        return parseConstantAsFilterOptions(PRIORITIES);
      };
      // Intent
      if (config.intent) {
        config.intent.values = function () {
          return parseConstantAsFilterOptions(INTENTS);
        };
      }

      return config;
    }
  }
})();
