(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.statusChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.statusChart", [
      "akitabox.core.lib.moment",
      "akitabox.core.services.chart",
      "akitabox.ui.directives.stackChart",
    ])
    .component("abxStatusChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
        modelName: "@abxModelName",
      },
      controller: AbxStatusChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/status-chart/status-chart.component.html",
    });
  function AbxStatusChartController(
    // Angular
    $log,
    $q,
    $timeout,
    // Libraries
    moment,
    // Constants
    models,
    MINIMUM_RENDER_DELAY,
    // Services
    ChartService,
    ShadowService
  ) {
    var self = this;
    var config = {};
    var GA_CATEGORY = "reporting";

    // Attributes
    self.idTag = "status-chart";
    self.data = [];
    self.groupKey = "_id";
    self.loading = true;
    self.showChart = false;
    self.isWorkOrderChart = false;
    self.hoverLabel = "";
    self.highlightedStack;
    self.legendValues = [];

    var scheduledData = [];
    var openData = [];
    var overdueData = [];
    var completedCanceledData = [];

    var today = moment().utc().endOf("day").toDate();
    var tomorrow = moment().utc().add(1, "days").startOf("day").toDate();

    if (self.modelName === models.WORK_ORDER.MODEL) {
      self.hoverLabel = models.WORK_ORDER.PLURAL;
      self.isWorkOrderChart = true;
    } else {
      self.hoverLabel = models.SERVICE_REQUEST.PLURAL;
    }

    // Functions
    self.onStackChange = onStackChange;
    self.onStackHover = onStackHover;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxStatusChart: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        config = ChartService.getChartConfig(self.buildings, self.modelName);
        if (self.isWorkOrderChart) {
          self.stackOptions = ChartService.getStackOptions(
            self.modelName,
            self.buildings.length > 1,
            ["Age"],
            ["Time Code"]
          );
        } else {
          self.stackOptions = ChartService.getStackOptions(
            self.modelName,
            self.buildings.length > 1,
            null,
            ["Time Code"]
          );
        }
      }
      if (changes.filters) {
        updateFilters();
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings ||
        changes.modelName
      ) {
        self.isWorkOrderChart ? calculateWOStats() : calculateSRStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function onStackHover($event) {
      self.highlightedStack = $event.value;
    }

    function onStackChange($event) {
      ShadowService.setSelectedOption($event.value);
      ShadowService.sendEvent(GA_CATEGORY, "switch-stack-by", self.idTag);
      self.selectedStack = $event.model;
      updateFilters();
      self.isWorkOrderChart ? calculateWOStats() : calculateSRStats();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
        yAxis: self.activeYAxisOption,
      };
      self.showChart = ChartService.shouldShowChart(
        self.modelName,
        ChartService.CHART_TYPE_BAR,
        self.data,
        options
      );
    }

    function calculateSRStats() {
      self.loading = true;
      self.showChart = false;
      var params = {
        group_field: "status",
        created_date: ChartService.buildDateString(
          self.startDate,
          self.endDate
        ),
      };
      params = angular.extend(params, self.filters);

      if (config.buildingInString) params.buildings = config.buildingInString;

      return config
        .statsFunction(config.parentId, params)
        .then(function (data) {
          self.legendValues = [];

          var srTemplate = {
            new: {
              _id: "new",
            },
            open: {
              _id: "open",
            },
            completed: {
              _id: "completed",
            },
            denied: {
              _id: "denied",
            },
          };

          if (params.stack_field) {
            srTemplate.new.results = [];
            srTemplate.open.results = [];
            srTemplate.completed.results = [];
            srTemplate.denied.results = [];
          } else {
            srTemplate.new.result = 0;
            srTemplate.open.result = 0;
            srTemplate.completed.result = 0;
            srTemplate.denied.result = 0;
          }

          for (var i = 0; i < data.length; ++i) {
            var result = data[i];
            var resultField = Object.prototype.hasOwnProperty.call(
              result,
              "result"
            )
              ? "result"
              : "results";
            if (srTemplate[result._id]) {
              srTemplate[result._id][resultField] = result[resultField];
            }
          }

          var statusData = [
            srTemplate.denied,
            srTemplate.completed,
            srTemplate.open,
            srTemplate.new,
          ];

          if (!params.stack_field) {
            self.data = statusData;
            return;
          }

          return ChartService.parseStackData(
            statusData,
            params.stack_field,
            params.stack_field === "building" ? config.buildingMap : null,
            null,
            self.buildings
          );
        })
        .then(function (stackData) {
          if (!stackData) {
            // no stack_field provided, self.data is already set above
            return;
          }
          self.legendValues = stackData.keys.sort(self.stackSort);
          self.data = stackData.data;
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }

    function calculateWOStats() {
      self.loading = true;
      self.showChart = false;

      $q.all([getScheduled(), getOpen(), getOverdue(), getCompletedCanceled()])
        .then(function () {
          self.legendValues = [];
          var data = completedCanceledData.concat(
            overdueData,
            openData,
            scheduledData
          );

          if (!self.filters.stack_field) {
            self.data = data;
            return;
          }

          if (self.filters.stack_field === "intent") {
            self.stackSort = ChartService.sortIntent;
          } else if (self.filters.stack_field === "age_group") {
            self.stackSort = ChartService.sortAge;
          } else {
            self.stackSort = undefined; // sort function must either be undefined or function
          }

          return ChartService.parseStackData(
            data,
            self.filters.stack_field,
            self.filters.stack_field === "building" ? config.buildingMap : null,
            null,
            self.buildings
          );
        })
        .then(function (stackData) {
          if (!stackData) {
            // no stack_field provided, self.data is already set above
            return;
          }
          self.legendValues = stackData.keys.sort(self.stackSort);
          self.data = stackData.data;
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }

    function getScheduled() {
      scheduledData = [];

      var filtersCopy = angular.extend({}, self.filters);
      var groupField = self.filters.stack_field;
      delete filtersCopy.stack_field;
      var params = {
        status: "open",
        start_date: ChartService.buildDateString(tomorrow, self.endDate),
      };
      params = angular.extend(params, filtersCopy);
      if (config.buildingInString) params.buildings = config.buildingInString;
      if (groupField) params.group_field = groupField;

      return config
        .statsFunction(config.parentId, params)
        .then(function (data) {
          if (groupField) {
            scheduledData[0] = {
              _id: "scheduled",
              results: data,
            };
          } else {
            scheduledData[0] = data[0];
            scheduledData[0]._id = "scheduled";
          }
        });
    }

    function getOpen() {
      openData = [];

      var filtersCopy = angular.extend({}, self.filters);
      var groupField = self.filters.stack_field;
      delete filtersCopy.stack_field;
      var params = {
        status: "open",
        start_date: ChartService.buildDateString(self.startDate, today),
        due_date: ChartService.buildDateString(today),
      };
      params = angular.extend(params, filtersCopy);
      if (config.buildingInString) params.buildings = config.buildingInString;
      if (groupField) params.group_field = groupField;

      return config
        .statsFunction(config.parentId, params)
        .then(function (data) {
          if (groupField) {
            openData[0] = {
              _id: "open (not overdue)",
              results: data,
            };
          } else {
            openData[0] = data[0];
            openData[0]._id = "open (not overdue)";
          }
        });
    }

    function getOverdue() {
      overdueData = [];

      var filtersCopy = angular.extend({}, self.filters);
      var groupField = self.filters.stack_field;
      delete filtersCopy.stack_field;
      var params = {
        status: "open",
        start_date: ChartService.buildDateString(self.startDate, self.endDate),
        due_date: ChartService.buildDateString(null, today),
      };
      params = angular.extend(params, filtersCopy);
      if (config.buildingInString) params.buildings = config.buildingInString;
      if (groupField) params.group_field = groupField;

      return config
        .statsFunction(config.parentId, params)
        .then(function (data) {
          if (groupField) {
            overdueData[0] = {
              _id: "overdue",
              results: data,
            };
          } else {
            overdueData[0] = data[0];
            overdueData[0]._id = "overdue";
          }
        });
    }

    function getCompletedCanceled() {
      completedCanceledData = [];

      var params = {
        group_field: "status",
        status: "$in,completed,canceled",
        closed_date: ChartService.buildDateString(self.startDate, self.endDate),
      };
      params = angular.extend(params, self.filters);
      if (config.buildingInString) params.buildings = config.buildingInString;

      return config
        .statsFunction(config.parentId, params)
        .then(function (data) {
          var template = {
            completed: {
              _id: "completed",
            },
            canceled: {
              _id: "canceled",
            },
          };
          if (params.stack_field) {
            template.completed.results = [];
            template.canceled.results = [];
          } else {
            template.completed.result = 0;
            template.canceled.result = 0;
          }
          for (var i = 0; i < data.length; ++i) {
            var result = data[i];
            var resultField = Object.prototype.hasOwnProperty.call(
              result,
              "result"
            )
              ? "result"
              : "results";
            if (template[result._id]) {
              template[result._id][resultField] = result[resultField];
            }
          }
          completedCanceledData = [template.canceled, template.completed];
        });
    }

    function updateFilters() {
      if (self.stackOptions.indexOf(self.selectedStack) < 0) {
        self.selectedStack = self.stackOptions[0];
      }

      self.filters = ChartService.updateStackFilter(
        self.selectedStack,
        self.filters
      );
    }
  }
})();
