import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { ReportResponse } from "@akitabox/api-client";
import { AbxDataGrid, AbxGridColDef } from "../lists/abx-data-grid/AbxDataGrid";
import { useMemo, useState } from "react";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { api } from "../api";
import {
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import axios from "axios";

export interface MergeReportsDialogProps extends DialogProps {
  organizationId: string;
  items: ReportResponse[];
}

export const MergeReportsDialog = (props: MergeReportsDialogProps) => {
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [name, setName] = useState("");

  const columns = useMemo(() => {
    const colsDef: AbxGridColDef<ReportResponse>[] = [
      {
        field: "name",
        type: "string",
        headerName: "Name",
        abxGridColType: "basic",
        minWidth: 150,
      },
      {
        type: "date",
        field: "modified_date",
        abxGridColType: "basic",
        headerName: "Last Modified",
        valueFormatter: (value?: string) =>
          value ? moment(value).format("MMM D, YYYY") : "",
        minWidth: 100,
      },
      {
        type: "date",
        field: "created_date",
        abxGridColType: "basic",
        headerName: "Created Date",
        valueFormatter: (value?: string) =>
          value ? moment(value).format("MMM D, YYYY") : "",
        minWidth: 100,
      },
    ];

    return colsDef;
  }, []);

  const mergeReports = async () => {
    setErrorMessage("");
    setLoading(true);

    // use this function to get the reports as they appear in the list, top (first) -> bottom (last)
    const reports =
      (gridFilteredSortedRowIdsSelector(apiRef) as string[]) || [];

    if (name.length && reports.length) {
      try {
        const response = await api.reports.create({
          organizationId: props.organizationId,
          report: {
            name,
            merge: true,
            reports,
          },
        });

        if (props.onClose) {
          props.onClose({ report: response.data }, "escapeKeyDown");
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setErrorMessage(err.response?.data.error?.message || err);
        } else {
          setErrorMessage("Unknown error while merging reports");
        }
      }
    }

    setLoading(false);
  };

  return (
    <Dialog maxWidth="lg" fullWidth {...props} disableEscapeKeyDown>
      <DialogTitle>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h6" color="inherit" css={{ flexGrow: 1 }}>
            Merge Reports
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => props.onClose && props.onClose({}, "escapeKeyDown")}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box css={(theme) => ({ margin: theme.spacing(1) })}>
          <TextField
            autoFocus
            fullWidth
            required
            label="Report Name"
            value={name}
            placeholder="Enter a name for the merged report"
            onChange={(evt) => setName(evt.target.value)}
          />
        </Box>
        <Box
          css={(theme) => ({
            maxHeight: "500px",
            overflowY: "scroll",
            marginTop: theme.spacing(2),
          })}
        >
          <AbxDataGrid
            apiRef={apiRef}
            rows={props.items}
            columns={columns}
            disableColumnFilter
            rowReordering
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          onClick={() => props.onClose && props.onClose({}, "escapeKeyDown")}
          disabled={loading}
        >
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          disabled={loading || !name}
          onClick={mergeReports}
        >
          {loading ? "Merging..." : errorMessage ? "Retry" : "Merge"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
