(function () {
  angular
    .module("akitabox.ui.dialogs.assign.assign")
    .controller("AssignDialogController", AssignDialogController);

  /* @ngInject */
  function AssignDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    BuildingService,
    ToastService,
    WorkOrderService,
    ScheduleService,
    UserService,
    OrganizationService
  ) {
    var self = this;

    // Attributes
    // self.buildingId is available here, provided by this dialog's .service.js
    // Either self.workOrderId or self.scheduleId is available here, provided by this dialog's .service.js
    self.assignees = [];
    self.building = null;
    self.loading = false;
    self.saving = false;

    // if self.workOrderId is available, dialog is for tasks, otherwise it is for maintenance schedules
    self.entityType = self.workOrderId ? "task" : "schedule";
    self.entityId = self.workOrderId || self.scheduleId;
    self.service = self.workOrderId ? WorkOrderService : ScheduleService;
    self.assignFunction = self.workOrderId
      ? WorkOrderService.assign
      : ScheduleService.update;
    self.assignType = self.workOrderId ? "work order" : "maintenance schedule";
    // Functions
    self.cancel = $mdDialog.cancel;
    self.onAssigneesChangeHandler = onAssigneesChangeHandler;
    self.onSaveAssigneesHandler = onSaveAssigneesHandler;

    init();

    /**
     * Run any initialization code during the start-up of this dialog
     */
    function init() {
      self.loading = true;

      $q.all([
        setInitialAssigneesAndEntity(self.buildingId, self.entityId),
        setInitialBuilding(self.buildingId),
      ])
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function onAssigneesChangeHandler($event) {
      self.assignees = $event.value;
      return $q.resolve();
    }

    function onSaveAssigneesHandler() {
      assign(self.assignees).finally(function () {
        self.disabled = false;
      });
    }

    /**
     * Send the assign request to our API
     */
    function assign(assignees) {
      self.saving = true;

      // Need to make sure assignees is just an array of ids
      var mappedAssignees = assignees.map(function (assignee) {
        return assignee._id;
      });
      return self
        .assignFunction(self.buildingId, self.entityId, {
          assignee_users: mappedAssignees,
        })
        .then(function (updatedEntity) {
          $mdDialog.hide(updatedEntity);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function setInitialAssigneesAndEntity(buildingId, entityId) {
      return self.service.getById(buildingId, entityId).then(function (entity) {
        var org = OrganizationService.getCurrent();
        var assigneeUsers = entity.assignee_users || [];
        var userIds = assigneeUsers.map(function (user) {
          return user._id || user;
        });
        UserService.get(org._id, {
          _id: `$in,${userIds.join(",")}`,
        }).then(function (users) {
          self.assignees = users;
          self.entity = entity;
        });
      });
    }

    function setInitialBuilding(buildingId) {
      return BuildingService.getById(buildingId).then(function (building) {
        self.building = building;
      });
    }
  }
})();
