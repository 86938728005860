(function () {
  angular
    .module("akitabox.desktop.export.list")
    .controller("ExportListController", ExportListController);

  /* @ngInject */
  function ExportListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    moment,
    // Dialogs
    DownloadDocumentDialog,
    // Services
    JobService,
    OrganizationService,
    BuildingService,
    AttachmentService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.exports = null;

    // Functions
    self.fetchExports = fetchExports;
    self.fetchSingleExport = fetchSingleExport;
    self.downloadHandler = downloadHandler;
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();

    // ------------------------
    //   Public Functions
    // ------------------------
    function fetchExports(skip, limit) {
      var params;
      params = {
        skip: skip,
        limit: limit,
      };
      if (self.building) {
        params["target_building"] = `$in,${self.building._id}`;
      }

      var filters = angular.extend({}, params);

      return JobService.get(self.organization._id, filters).then(function (
        exports
      ) {
        self.exports = exports.map((item) => {
          return {
            ...item,
            created_date: moment(item.created_date).format("MM/DD/YY hh:mm A"),
          };
        });
      });
    }

    function fetchSingleExport(id) {
      return JobService.getById(id, self.organization._id).then((response) => {
        if (response.is_completed) {
          let exportToUpdate = self.exports.find((item) => id === item._id);
          exportToUpdate.is_completed = response.is_completed;
          exportToUpdate.is_errored = response.is_errored;
          exportToUpdate.is_pending = response.is_pending;
          exportToUpdate.is_processing = response.is_processing;
        }
      });
    }

    function downloadHandler(exportSelected) {
      fetchAttachments(exportSelected);
    }

    /**
     * Fetch documents linked to the job iff the job has completed
     */
    function fetchAttachments(job) {
      var isExport = job.type === "export";
      var isCompleted = job.status === "completed";
      if ((isExport && !isCompleted) || !job.target_building) return;

      var params = {
        entity_id: job._id,
        entity_type: "job",
      };

      const buildingId = job.target_building;
      // $scope.building._id
      AttachmentService.getAll(buildingId, params)
        .then(function (attachments) {
          if (!angular.isEmpty(attachments)) {
            var documents = [];
            for (var i = 0; i < attachments.length; i += 1) {
              documents.push(attachments[i].document);
            }
            download(documents);
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Download provided documents
     */
    function download(documents) {
      var locals = {
        docs: documents,
      };
      DownloadDocumentDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }
  }
})();
