// Two resources on using Sisense JS in React:
// https://gitlab.com/SisenseJS/react-sample/-/blob/master/src/sisense/dashboard.js
// Recently released beta version of SisenseJS React (2023): https://github.com/sisense/sisensejs-components

import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SisenseContextProvider } from "@sisense/sdk-ui";
import CloseIcon from "@mui/icons-material/Close";
import { stylesheet } from "../../stylesheet";
import { toBlob } from "html-to-image";
import {
  DashboardModel,
  QueryWidgetSelection,
  SIENSE_WIDGET_CALLBACK_ARGS,
  WidgetModel,
} from "./types";
import { ResizableBox } from "react-resizable";
import { LoadingButton } from "@mui/lab";
import { ExecuteMultiQueryByWidgetIds } from "./ExecuteMultiQueryByWidgetIds";

export interface WidgetContainer {
  id: string;
  widgetId: string;
  widgetType: string | undefined;
  title: string;
}

export interface WidgetSelection {
  widgetTitle: string;
  widgetDiv: HTMLElement | null;
  height: number | undefined;
  width: number | undefined;
}

export type DataFormat = {
  decimalFormat: string | number;
  symbol?: string;
  color?: string;
};

export interface InsightsQueryData {
  widgetType: string;
  dataFormat: DataFormat[];
  widgetTitle: string;
  showTitle: boolean;
  widgetId: string;
  columns: any[];
  rows: any[];
  error?: Error;
}

export interface ImageData {
  url: string;
  widgetTitle: string;
}

export interface InsightsWidgetSelectionDialogProps extends DialogProps {
  appContext: any;
  sisenseEnv: string;
  selectedDashboard: string;
  dashboards: DashboardModel[];
  onWidgetMultiselect: (
    images: ImageData[],
    tableData: InsightsQueryData[] | undefined
  ) => void;
  setSelectedDashboard: React.Dispatch<React.SetStateAction<string>>;
  generatePurlDisplay: (file: File) => Promise<string>;
}

export const InsightsWidgetSelectionDialog: FunctionComponent<
  InsightsWidgetSelectionDialogProps
> = ({
  appContext,
  sisenseEnv,
  selectedDashboard,
  setSelectedDashboard,
  dashboards,
  onWidgetMultiselect,
  generatePurlDisplay,
  onClose,
  ...props
}) => {
  const [widgetContainers, setWidgetContainers] = useState<WidgetContainer[]>(
    []
  );
  const [widgetContainersLoaded, setWidgetContainersLoaded] =
    useState<boolean>(false);
  const [selectedWidgets, setSelectedWidgets] = useState<any[]>([]);
  const [checkboxStates, setCheckboxStates] = useState<any>({});
  const [loadedWidgets, setLoadedWidgets] = useState<any[]>([]);
  const [isResettingFilters, setIsResettingFilters] = useState<boolean>(false);
  const [isInsertingWidgets, setIsInsertingWidgets] = useState<boolean>(false);
  const [resizedSelections, setResizedSelections] = useState<WidgetSelection[]>(
    []
  );
  const [toggleStates, setToggleStates] = useState<Map<string, boolean>>();
  const [queryWidgetSelections, setQueryWidgetSelections] = useState<
    QueryWidgetSelection[]
  >([]);
  const [confirmClicked, setConfirmClicked] = useState<boolean>(false);
  // used to correctly trigger our widgets to render
  const widgetContainerRef = useRef<HTMLDivElement>(null);

  const loadWidgetContainers = useCallback(async () => {
    if (selectedDashboard) {
      return appContext.dashboards
        .load(selectedDashboard)
        .then((dashboard: any) => {
          const containers: WidgetContainer[] = [];
          const initialCheckboxStates: any = {};
          // $$widgetsMap has the actual widget ids
          const widgetIds = Object.keys(dashboard.$$widgets.$$widgetsMap);
          // get widgets array -- 2nd $$widgets contains array of widgets
          const widgets = dashboard.$$widgets.$$widgets;

          // filter out chart types that cannot be rendered
          for (let i = 0; i < widgets.length; i++) {
            const widget = widgets[i].$$model;
            if (
              widget.type === "richtexteditor" ||
              widget.type === "BloX" ||
              widget.type === "histogramwidget"
            )
              continue;
            if (
              widget.type === "indicator" ||
              widget.type === "pivot2" ||
              widget.type === "tablewidget" ||
              widget.type === "tablewidgetagg"
            ) {
              containers.push({
                widgetType: widget.type,
                widgetId: widgetIds[i],
                id: `widget${i}`,
                title: widget.title,
              });
            } else {
              containers.push({
                widgetType: undefined,
                widgetId: widgetIds[i],
                id: `widget${i}`,
                title: widget.title,
              });
            }

            initialCheckboxStates[`widget${i}`] = false;
          }

          containers.sort((a, b) => {
            if (a.widgetType === b.widgetType)
              return a.title.localeCompare(b.title);

            return a.widgetType ? 1 : -1;
          });

          setWidgetContainers(containers);
          setCheckboxStates(initialCheckboxStates);
          setToggleStates(
            new Map(widgets.map((widget: any) => [widget.$$model.oid, true]))
          );
          setWidgetContainersLoaded(true);
        });
    }
  }, [appContext.dashboards, selectedDashboard]);

  const handleToggleLegend = (key: string) => {
    const currentWidget = loadedWidgets?.find((w) => w.$$model.oid === key);
    if (!currentWidget) return;

    const updatedToggleStates = new Map(toggleStates);
    const toggled = updatedToggleStates.get(key);
    updatedToggleStates.set(key, !toggled);
    setToggleStates(updatedToggleStates);

    currentWidget.on(
      "beforeviewloaded",
      (
        event: any,
        args: { widget: WidgetModel; element: HTMLElement; options: any }
      ) =>
        onBeforeViewLoadedHandler(
          event,
          args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
          {
            spacing: [3, 3, 3, 55],
            margin: 1,
            showLegend: updatedToggleStates.get(key),
          }
        )
    );

    // column chart legends do not render unless an action is applied to reset the filters
    if (currentWidget.$$model.type === "chart/column" && !toggled) {
      currentWidget?.$$dashboard.$$filtersScope.resetFilters();
    } else {
      currentWidget.refresh();
    }
  };

  const resetState = () => {
    setConfirmClicked(false);
    setSelectedWidgets([]);
    setWidgetContainers([]);
    setLoadedWidgets([]);
    setWidgetContainersLoaded(false);
    setQueryWidgetSelections([]);
  };

  const reloadWidgetsForDashboards = useCallback(async () => {
    resetState();
    await loadWidgetContainers();
  }, [loadWidgetContainers]);

  const onBeforeViewLoadedHandler = (
    event: any,
    args: SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
    customChartStyles: any = null
  ) => {
    const {
      options, // for chart - final Highcharts options, for scattermap - Object that contains the map
    } = args;

    if (event.type !== "chart/pie") {
      if (options.chart) {
        options.chart.showAxes = true;
      }

      if (!options.xAxis) {
        options.xAxis = {
          labels: {},
        };
      }
      options.xAxis.visible = true;
      options.xAxis.labels.enabled = true;

      if (!options.yAxis) {
        options.yAxis = {};
      }
      options.yAxis.visible = true;
      if (options.legend) {
        options.legend.margin = 20;
      }
    }

    if (event.type === "chart/pie") {
      if (customChartStyles && customChartStyles.spacing) {
        options.chart.spacing = customChartStyles.spacing;
        options.legend.margin = customChartStyles.margin;
      }
    }
    if (!customChartStyles && options.chart) {
      options.chart.height = 400;
    }
    if (customChartStyles && "showLegend" in customChartStyles) {
      options.legend.enabled = customChartStyles.showLegend;
    } else if (options.legend) {
      options.legend.enabled = true;
    }
  };

  const loadWidgets = useCallback(() => {
    if (
      selectedDashboard &&
      widgetContainers.length &&
      widgetContainersLoaded
    ) {
      appContext.dashboards
        .load(selectedDashboard)
        .then((dashboard: DashboardModel) => {
          const widgets: any[] = [];
          widgetContainers.forEach((container: WidgetContainer) => {
            const widget = dashboard.widgets.get(container.widgetId);
            if (!widget) {
              return;
            }

            // https://sisense.dev/reference/embedding/sisense.js/sisense-widget.html
            // .container is the designated DOM element to hold the widget contents
            const widgetElem = document.getElementById(container.id);
            if (widgetElem) {
              widget.container = widgetElem as HTMLDivElement;
              if (!container.widgetType) {
                widget.on("beforeviewloaded", (event, args) =>
                  onBeforeViewLoadedHandler(
                    event,
                    args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"]
                  )
                );
              }
            }
            widgets.push(widget);
          });

          setLoadedWidgets(widgets);

          const filtersElem = document.getElementById("filters");
          if (filtersElem) {
            filtersElem.innerHTML = "";
            dashboard.renderFilters(filtersElem as HTMLDivElement);
          }

          dashboard.refresh();
        });
    }
  }, [
    appContext.dashboards,
    selectedDashboard,
    widgetContainers,
    widgetContainersLoaded,
  ]);

  const handleWidgetCheckboxSelect = (
    widgetId: string,
    containerId: string,
    event: any
  ) => {
    const checked = event.target.checked;
    const newCheckboxStates = { ...checkboxStates };
    newCheckboxStates[containerId] = checked;
    setCheckboxStates(newCheckboxStates);

    checked
      ? setSelectedWidgets([...selectedWidgets, widgetId])
      : setSelectedWidgets(selectedWidgets.filter((w) => widgetId !== w));
  };
  /* 
    We want to render widgets dynamically like this -- the problem is that SisenseJS requires a widget container to be loaded on the DOM
    in order to attach the loaded widgets to the DOM. So we have to fetch the widgets from an API to determine how many widgets we have
    and then load Sisense JS to attach the widgets onto the containers.
  */
  const renderWidgetContainers = function (): ReactNode {
    return (
      <div>
        {widgetContainers.map((container: WidgetContainer) => {
          const key = container.widgetId;
          const isChecked = toggleStates?.get(key) || false;
          return (
            <div
              className="widget-container"
              key={container.widgetId}
              ref={widgetContainerRef}
              css={(theme) => ({ marginBottom: theme.spacing(1) })}
            >
              <Box
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  css={(theme) => ({
                    fontWeight: "bold",
                    marginLeft: theme.spacing(1),
                  })}
                >
                  {container.title}
                </span>
                <span>
                  {!container.widgetType && (
                    <FormControlLabel
                      style={{ marginLeft: "30px" }}
                      control={
                        <Switch
                          checked={isChecked}
                          onChange={() => {
                            handleToggleLegend(key);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={"Legend"}
                    />
                  )}
                  {!container.widgetType && (
                    <Tooltip title="Widget will be inserted as an image with dashboard filters applied">
                      <InfoIcon></InfoIcon>
                    </Tooltip>
                  )}
                  {container.widgetType && (
                    <Tooltip title="Widget data will be fetched and inserted with dashboard filters applied">
                      <InfoIcon></InfoIcon>
                    </Tooltip>
                  )}
                  <Checkbox
                    checked={checkboxStates[container.id]}
                    onChange={(event: any) => {
                      handleWidgetCheckboxSelect(
                        container.widgetId,
                        container.id,
                        event
                      );
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </span>
              </Box>
              {!container.widgetType && (
                <div
                  css={(theme) => ({
                    padding: theme.spacing(1),
                    height: "450px",
                    marginBottom: theme.spacing(1),
                    border: "1.5px solid #c5c5c5",
                    overflow: "hidden",
                  })}
                >
                  <ResizableBox
                    height={420}
                    width={1000}
                    css={ss.resizable}
                    onResizeStop={() => {
                      handleResize(container.widgetId);
                    }}
                    maxConstraints={[1020, 440]}
                    style={{ border: "2px solid gray" }}
                  >
                    <div id={container.id}></div>
                  </ResizableBox>
                </div>
              )}
              {container.widgetType === "indicator" && (
                <div
                  css={(theme) => ({
                    padding: theme.spacing(1),
                    height: "300px",
                    width: "inherit",
                    marginBottom: theme.spacing(1),
                    border: "1.5px solid #c5c5c5",
                    overflow: "auto",
                  })}
                >
                  <div id={container.id}></div>
                </div>
              )}
              {container.widgetType && container.widgetType !== "indicator" && (
                <div
                  css={(theme) => ({
                    padding: theme.spacing(1),
                    height: "450px",
                    width: "inherit",
                    marginBottom: theme.spacing(1),
                    border: "1.5px solid #c5c5c5",
                    overflow: "hidden",
                  })}
                >
                  <span
                    css={
                      container.widgetType === "pivot2"
                        ? ss.pivotImage
                        : ss.tableImage
                    }
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const handleResize = (widgetId: string) => {
    const currentWidget = loadedWidgets?.find(
      (w) => w.$$model.oid === widgetId
    );
    if (!currentWidget) return;

    currentWidget.on(
      "beforeviewloaded",
      (
        event: any,
        args: { widget: WidgetModel; element: HTMLElement; options: any }
      ) =>
        onBeforeViewLoadedHandler(
          event,
          args as SIENSE_WIDGET_CALLBACK_ARGS["beforeviewloaded"],
          {
            spacing: [3, 3, 3, 55],
            margin: 1,
            showLegend: toggleStates?.get(widgetId) || false,
          }
        )
    );
    currentWidget.refresh();
  };

  const onConfirmMultiselect = useCallback(
    async (tableData?: InsightsQueryData[]) => {
      const images: ImageData[] = [];

      for (let i = 0; i < resizedSelections.length; i++) {
        const widgetContainer = resizedSelections[i];
        if (widgetContainer.widgetDiv) {
          const blob = await toBlob(widgetContainer.widgetDiv, {
            cacheBust: true,
            skipFonts: false,
          });
          if (!blob) return;
          const file = new File([blob], `${widgetContainer.widgetTitle}.png`, {
            type: "image/png",
          });
          const purl = await generatePurlDisplay(file);
          const imgString = `<img className="insights-widget-img" style="height:${widgetContainer.height}px; width:${widgetContainer.width}px;" src="${purl}"/>`;
          images.push({
            url: imgString,
            widgetTitle: widgetContainer.widgetTitle,
          });
        }
      }

      setIsInsertingWidgets(false);
      setConfirmClicked(false);
      resetState();

      onWidgetMultiselect(images, tableData);
    },
    [onWidgetMultiselect, resizedSelections]
  );

  useEffect(() => {
    if (selectedDashboard) {
      reloadWidgetsForDashboards();
    }
  }, [reloadWidgetsForDashboards, selectedDashboard]);

  useEffect(() => {
    if (widgetContainerRef.current && widgetContainersLoaded) {
      loadWidgets();
    }
  }, [loadWidgets, widgetContainerRef, widgetContainersLoaded]);

  const resetDashboardFilters = useCallback(async () => {
    setIsResettingFilters(true);

    // because we are persisting changes, a dashboard.refresh() will not reset the filters
    // we need to access the $$dashboard object's filters on any loaded widget to call resetFilters()
    // the generic dashboard object's $$filtersScope is not defined which is why we are using the widget's $$dashboard object
    const widget = loadedWidgets[0];
    widget?.$$dashboard.$$filtersScope.resetFilters();

    setIsResettingFilters(false);
  }, [loadedWidgets]);

  useEffect(() => {
    if (loadedWidgets.length) {
      setTimeout(() => {
        // reset filter will reset to whatever the dashboard is published with
        // column charts do not render legends unless a filter is applied, so we need to do this on load
        loadedWidgets[0].$$dashboard.$$filtersScope.resetFilters();
      }, 1000);
    }
  }, [loadedWidgets, resetDashboardFilters]);

  const handleConfirmClicked = useCallback(async () => {
    setIsInsertingWidgets(true);
    const resizedSelections: WidgetSelection[] = [];
    const queryWidgetSelections: QueryWidgetSelection[] = [];
    widgetContainers.forEach((c: WidgetContainer) => {
      if (!c.widgetType && checkboxStates[c.id]) {
        const resized = document.getElementById(c.id);
        if (resized) {
          const selection: WidgetSelection = {
            widgetTitle: c.title,
            widgetDiv: resized,
            height: resized?.clientHeight,
            width: resized?.clientWidth,
          };
          resizedSelections.push(selection);
        }
      } else if (c.widgetType && checkboxStates[c.id]) {
        queryWidgetSelections.push({
          widgetOid: c.widgetId,
          widgetTitle: c.title,
          widgetType: c.widgetType,
        });
      }
    });

    setResizedSelections(resizedSelections);
    setQueryWidgetSelections(queryWidgetSelections);
    setConfirmClicked(true);
  }, [checkboxStates, widgetContainers]);

  return (
    <div>
      {/* Using Compose SDK with Sisense JS to render tables */}
      <SisenseContextProvider url={sisenseEnv} ssoEnabled={true}>
        {
          <Dialog
            fullWidth
            maxWidth="xl"
            {...props}
            PaperProps={{
              style: {
                height: "100%",
              },
            }}
            css={ss.dialog}
          >
            <DialogTitle
              component={Box}
              css={() => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              })}
            >
              <Typography variant="h6" color="inherit">
                Insert Insights Charts
              </Typography>
              <IconButton
                color="inherit"
                onClick={() => {
                  resetState();
                  onClose?.({}, "escapeKeyDown");
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent css={{ overflow: "hidden" }}>
              <Box
                css={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  css={(theme) => ({
                    display: "flex",
                    gap: theme.spacing(1),
                    marginTop: theme.spacing(2),
                  })}
                >
                  <FormControl css={{ flex: "1 0 auto" }}>
                    <InputLabel id="dashabords-select-label">
                      Dashboard
                    </InputLabel>
                    <Select
                      label="Dashboard"
                      labelId="dashabords-select-label"
                      onChange={(e) => {
                        // set our dashbaord on the parent
                        setSelectedDashboard(e.target.value);
                        reloadWidgetsForDashboards();
                      }}
                      value={selectedDashboard}
                      size="medium"
                      variant="outlined"
                    >
                      {dashboards
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map((dashboard) => (
                          <MenuItem value={dashboard.oid} key={dashboard._id}>
                            {dashboard.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedDashboard && (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={resetDashboardFilters}
                        disabled={isResettingFilters || !widgetContainersLoaded}
                      >
                        RESET FILTERS
                      </Button>
                      <LoadingButton
                        loading={isInsertingWidgets}
                        variant="contained"
                        color="primary"
                        disabled={isInsertingWidgets || !selectedWidgets.length}
                        onClick={handleConfirmClicked}
                      >
                        Confirm
                      </LoadingButton>
                      {confirmClicked && (
                        <ExecuteMultiQueryByWidgetIds
                          queryWidgetSelections={queryWidgetSelections}
                          dashboardOid={selectedDashboard}
                          selectedDashboard={selectedDashboard}
                          confirmSelections={onConfirmMultiselect}
                          externalFilters={[]}
                        ></ExecuteMultiQueryByWidgetIds>
                      )}
                    </>
                  )}
                </Box>
                <Box
                  css={(theme) => ({
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(1),
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    overflow: "hidden",
                  })}
                >
                  <Box
                    css={(theme) => ({
                      flex: 1,
                      overflowY: "auto",
                      paddingRight: theme.spacing(1),
                    })}
                  >
                    {renderWidgetContainers()}
                  </Box>
                  <div
                    id="filters"
                    css={(theme) => ({
                      padding: theme.spacing(2),
                      flexBasis: "25%",
                    })}
                  ></div>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        }
      </SisenseContextProvider>
    </div>
  );
};

const ss = stylesheet({
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  pivotImage: {
    display: "flex",
    height: 400,
    width: "inherit",
    background: `url("/img/Pivottable.svg") no-repeat`,
  },
  tableImage: {
    display: "flex",
    height: 400,
    width: "inherit",
    background: `url("/img/Table.svg") no-repeat`,
  },
  resizable: {
    position: "relative",
    overflow: "hidden",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 35,
      height: 35,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      backgroundPosition: "bottom right",
      padding: "0 3px 3px 0",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      boxSizing: "border-box",
      cursor: "se-resize",
    },
  },
  app: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
    ".widget": {
      marginBottom: 7,
      border: "1.5px solid #c5c5c5",
      borderRadius: 4,
    },
  },
});
