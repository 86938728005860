(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.asset
   */
  angular
    .module("akitabox.desktop.directives.details.asset", [
      "akitabox.constants",
      "akitabox.core.toast",
      "akitabox.core.services.asset",
      "akitabox.core.services.pinValue",
    ])
    .directive("abxAssetDetails", AbxAssetDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.asset
   * @name AbxAssetDetailsDirective
   *
   * @description
   * `<abx-asset-details>`displays the fields of an asset in editable/non-editable form, depending the user's permissions
   *
   * @params {Object}    ng-model          The directive's model (asset)
   * @params {Object}    ng-model-options  Allows modifying how ng-model is updated
   * @params {function}  ng-change         Function called after ng-model has been changed
   * @params {Object}    abx-building      The building that the asset belongs to
   *
   * @usage
   * <hljs lang="html">
   *     <abx-asset-details
   *         ng-model="vm.floor"
   *         ng-change="vm.onAssetChanged(asset)"
   *         abx-building="vm.building">
   *     </abx-asset-details>
   * </hljs>
   *
   * @ngInject
   */
  function AbxAssetDetailsDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/details/asset/asset-details.html",
      require: ["abxAssetDetails", "ngModel"],
      controller: AbxAssetDetailsController,
      bindToController: true,
      controllerAs: "vm",
      link: postLink,
      scope: {
        building: "<abxBuilding",
      },
    };

    function postLink($scope, $elem, attrs, ctrls) {
      var vm = ctrls[0];

      vm.ngModelCtrl = ctrls[1];
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model
       *
       * @param   {Object} value  The new model value
       * @returns {Object}        The formatted model value
       */
      function onExternalChange(value) {
        vm.asset = angular.copy(value);
        vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for AbxAssetDetails directive
   *
   * @ngInject
   */
  function AbxAssetDetailsController(
    // Angular
    $q,
    $timeout,
    $state,
    // Constants
    enums,
    // Services
    AssetService,
    FeatureFlagService,
    PinValueService,
    ToastService,
    OrganizationService,
    UserService
  ) {
    var self = this;
    var userPermissions = UserService.getPermissions();

    // --------------------
    //   Attributes
    // --------------------

    self.asset = null;
    self.building = angular.isDefined(self.building) ? self.building : null;
    self.organization = OrganizationService.getCurrent();
    self.canEdit = userPermissions.asset.update;
    self.name = null;
    self.installationYear = null;
    self.quantity = null;
    self.quantityUnit = null;
    self.quantityUnitOptions = Object.values(enums.QUANTITY_UNIT.DISPLAY_SHORT);
    self.size = null;
    self.sizeUnit = null;
    self.sizeUnitOptions = Object.values(enums.SIZE_UNIT.DISPLAY_SHORT);
    self.levelValue = null;
    self.roomValue = null;
    self.rendering = true;
    self.pinTypeUrl = null;
    self.buildingUrl = null;

    // Functions
    self.init = init;
    self.update = update;
    self.updateField = updateField;
    self.getValuesLength = getValuesLength;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Method to change the  name of this asset
     *
     * @param {String}  name  New name of the asset
     */
    function update() {
      var data = {};
      // Name
      if (self.name !== self.asset.name) {
        data.name = self.name;
      }
      // Installation Date
      var assetInstallationYear =
        self.asset.installation_date &&
        new Date(self.asset.installation_date).getFullYear();
      if (self.installationYear !== assetInstallationYear) {
        if (self.installationYear || self.installationYear === 0) {
          if (self.installationYear < 1000 || self.installationYear > 9999) {
            return $q.reject("Invalid year");
          }
          var installationDate = new Date(self.installationYear, 0, 2); // 01/02/YYYY
          data.installation_date = installationDate.getTime();
        } else {
          data.installation_date = null;
        }
      }
      // Quantity & Quantity Unit
      let quantityUnit;
      for (const key in enums.QUANTITY_UNIT.DISPLAY_SHORT) {
        if (enums.QUANTITY_UNIT.DISPLAY_SHORT[key] === this.quantityUnit) {
          quantityUnit = key;
          break;
        }
      }
      if (self.asset.quantity !== self.quantity) {
        data.quantity = self.quantity;
      }
      if (self.asset.quantity_unit !== quantityUnit) {
        data.quantity_unit = quantityUnit;
      }
      // Size & Size Unit
      let sizeUnit;
      for (const key in enums.SIZE_UNIT.DISPLAY_SHORT) {
        if (enums.SIZE_UNIT.DISPLAY_SHORT[key] === this.sizeUnit) {
          sizeUnit = key;
          break;
        }
      }
      if (self.asset.size !== self.size) {
        data.size = self.size;
      }
      if (self.asset.size_unit !== sizeUnit) {
        data.size_unit = sizeUnit;
      }

      var params = { include_values: true };
      var room = self.asset.room || null;
      var floor = self.asset.level || null;
      var pinType = self.asset.pinType;

      return AssetService.updateById(
        self.building._id,
        self.asset._id,
        data,
        params
      )
        .then(function (updatedAsset) {
          // asset name is changing, assume pin type, room, floor stay the
          // same; have to re-attach to updated object
          updatedAsset.room = room;
          updatedAsset.level = floor;
          updatedAsset.pinType = pinType;
          self.asset = updatedAsset;
          setModelValue();
        })
        .catch(function (err) {
          ToastService.showError(err);
          return $q.reject(err);
        });
    }

    /**
     * Method which updates self.asset and propagates it up to its parent
     *
     * @param {Object}  field  The pin field object that belongs to the changed pin value
     * @param {Object}  newValue  The new value of the pin value
     */
    function updateField(field, newValue) {
      // Room and Level field updates need some extra handling
      if (
        angular.isString(self.levelFieldId) &&
        self.levelFieldId === field._id
      ) {
        if (newValue && newValue._id) {
          // they are changing the level to something
          self.asset.level = newValue;
          self.asset.values[self.levelFieldId].name = newValue.name;
          self.asset.values[self.levelFieldId].value = newValue._id;
        } else {
          // they are clearing the level
          self.asset.level = null;
          self.asset.room = null;
          self.asset.values[self.levelFieldId].name = null;
          self.asset.values[self.levelFieldId].value = "";

          if (self.roomFieldId) {
            self.asset.values[self.roomFieldId].name = null;
            self.asset.values[self.roomFieldId].value = "";
          }
        }
      } else if (
        angular.isString(self.roomFieldId) &&
        self.roomFieldId === field._id
      ) {
        if (newValue && newValue._id) {
          // they are changing the room to something
          self.asset.room = newValue;
          self.asset.values[self.roomFieldId].name = newValue.name;
          self.asset.values[self.roomFieldId].value = newValue._id;
        } else {
          // they are clearing the room
          self.asset.room = null;
          self.asset.values[self.roomFieldId].name = null;
          self.asset.values[self.roomFieldId].value = "";
        }
      } else {
        for (var key in self.asset.values) {
          var assetValue = self.asset.values[key];
          if (assetValue.pinField === field._id) {
            if (field.data_type === "document_array") {
              assetValue.documents = newValue.documents;
              assetValue.value = newValue.value;
            } else if (field.data_type === "tag_filter") {
              assetValue.value = newValue.value;
            } else {
              assetValue.value = newValue;
            }
            break;
          }
        }
      }

      setModelValue();
    }

    function getValuesLength(pinValues) {
      return Object.keys(pinValues).length;
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initializes properties and calls necessary functions
     */
    function init() {
      self.buildingUrl = getBuildingUrl();
      if (self.asset) {
        self.pinTypeUrl = getPinTypeUrl(self.asset.pinType.uri);
        self.name = self.asset.name;
        self.installationYear = self.asset.installation_date
          ? new Date(self.asset.installation_date).getFullYear()
          : null;
        self.quantity = self.asset.quantity;
        if (self.asset.quantity_unit) {
          self.quantityUnit =
            enums.QUANTITY_UNIT.DISPLAY_SHORT[self.asset.quantity_unit];
        }
        self.size = self.asset.size;
        if (self.asset.size_unit) {
          self.sizeUnit = enums.SIZE_UNIT.DISPLAY_SHORT[self.asset.size_unit];
        }
        // Need to save room/level pinfield IDs for later use (easier to access)
        // when we have to update them, so we don't traverse the .values object each time
        var assetValues = self.asset.values;
        self.levelValue = PinValueService.getFloorPinValue(assetValues);
        self.levelFieldId = self.levelValue ? self.levelValue.pinField : null;
        self.roomValue = PinValueService.getRoomPinValue(assetValues);
        self.roomFieldId = self.roomValue ? self.roomValue.pinField : null;
      }

      // Allow pin value list to populate
      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    function setModelValue() {
      var model = angular.extend(self.ngModelCtrl.$modelValue, self.asset);
      self.ngModelCtrl.$setViewValue(model);
    }

    /**
     * Generates the correct front-end url to the pin type
     *
     * @param {String} url
     *
     * @returns {String}
     */
    function getPinTypeUrl(url) {
      return `/organization/settings/categories/${
        url.split("/pin_types/")[1]
      }/fields`;
    }

    /**
     * Generates url to the building dashboad
     */
    function getBuildingUrl() {
      return $state.href("app.building.detail", {
        buildingId: self.building._id,
      });
    }
  }
})();
