(function () {
  angular
    .module("akitabox.desktop")
    .config(configBlock)
    .factory("httpInterceptor", httpInterceptor);

  /* @ngInject */
  function configBlock($httpProvider, $sceDelegateProvider, storeProvider) {
    $httpProvider.interceptors.push("httpInterceptor");
    // Whitelist resource urls
    $sceDelegateProvider.resourceUrlWhitelist(["self"]);
    // Configure storage
    storeProvider.setStore("localStorage");
  }

  /* @ngInject */
  function httpInterceptor($injector, $location, EnvService) {
    var service = {
      responseError: responseError,
    };
    return service;

    function responseError(response) {
      var $q = $injector.get("$q");
      var $state = $injector.get("$state");
      var buildingId = response.config.url.split("/")[4];

      switch (response.status) {
        case 401:
          if (response.config.url === `${EnvService.getApiUrl()}/identity`) {
            // we're just poking around here to see if user is logged in, we don't want these requests to trigger redirects
            // this is a specific request to check if the user is logged in
            return $q.reject(response);
          } else if (
            $state.current.name !== "auth.login" &&
            $state.current.name !== "auth.logout"
          ) {
            const qs = new URLSearchParams($location.search()).toString();
            let redirect_url = `${$location.protocol()}://${$location.host()}${$location.path()}${
              qs ? `?${qs}` : ""
            }`;
            if (redirect_url === `${EnvService.getBaseUrl()}/`) {
              redirect_url = "";
            }
            $state.go("auth.logout", { redirect_url });
          }
          break;
        case 402:
          $state.go("app.building.detail", {
            buildingId: buildingId,
          });
          break;
        case 503:
          $state.go("root.503");
          break;
      }
      return $q.reject(response);
    }
  }
})();
