(function () {
  angular
    .module("akitabox.desktop.inspectionProgram.detail")
    .controller(
      "InspectionProgramOverviewController",
      InspectionProgramOverviewController
    );

  /** @ngInject */
  function InspectionProgramOverviewController(
    // Angular
    $log,
    $location,
    $state,
    $q,
    $scope,
    // Third party
    moment,
    // Events
    EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE,
    EVENT_INSPECTION_PROGRAM_UPDATE,
    EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD,
    // Constants
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_CANCELED,
    WORK_ORDER_STATUS_OVERDUE,
    INTENT_INSPECTION_PROGRAM,
    INSPECTION_PROGRAM_CARDS,
    // Resolves
    organization,
    inspectionProgram,
    // Services
    BuildingService,
    TimeZoneService,
    ToastService,
    WorkOrderService,
    Router
  ) {
    var self = this;
    var DATE_FORMAT = "MMMM D, YYYY";

    // Public Attributes
    self.inspectionProgram = inspectionProgram;
    self.building = BuildingService.getCurrent();
    self.organization = organization;
    self.buildings = [];
    self.open = {
      summary: true,
      details: true,
    };
    self.displaySummary = false;
    self.activityParams = {
      inspection_program: self.inspectionProgram._id,
    };
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Inspection Program",
      entity_action_html: "This <b>Inspection Program</b> was created",
      time: self.inspectionProgram.created_date,
      account: self.inspectionProgram.created_by,
    };

    self.selectInputOpen = false;

    self.statusOpen = WORK_ORDER_STATUS_OPEN;
    self.statusScheduled = WORK_ORDER_STATUS_SCHEDULED;
    self.statusCompleted = WORK_ORDER_STATUS_COMPLETED;
    self.statusCanceled = WORK_ORDER_STATUS_CANCELED;
    self.statusOverdue = WORK_ORDER_STATUS_OVERDUE;

    self.openInspectionProgramWOs = [];
    self.scheduledInspectionProgramWOs = [];
    self.completedInspectionProgramWOs = [];
    self.canceledInspectionProgramWOs = [];
    self.selectedFilters = {
      iterations: [],
      buildings: [],
    };
    self.filters = null;

    self.utcOffset = TimeZoneService.getCurrentUTCOffset();

    // Functions
    self.onCardToggled = onCardToggled;
    self.fetchInspectionProgramWOs = fetchInspectionProgramWOs;
    self.filtersUpdated = filtersUpdated;
    self.getWorkOrderBuilding = getWorkOrderBuilding;
    self.getWorkOrderSubtext = getWorkOrderSubtext;
    self.onInputOpen = onInputOpen;
    self.onInputClose = onInputClose;
    self.viewAllWorkOrders = viewAllWorkOrders;
    self.isLoading = false;

    self.$onInit = function () {
      if (!self.inspectionProgram) {
        $log.error("inspectionProgram is required");
        return;
      }

      $scope.$on(
        EVENT_INSPECTION_PROGRAM_UPDATE,
        handleInspectionProgramChange
      );

      // Get the breadcrumbs set
      $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE, {
        page: "ip-overview",
      });
      // Set loading state
      $scope.$emit(EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD, { isLoading: true });
      self.isLoading = true;
      $q.all([
        fetchAllInspectionProgramWOs(),
        fetchOrgBuildings(self.organization._id),
      ]).then(function () {
        self.displaySummary = Boolean(
          self.openInspectionProgramWOs.length ||
            self.scheduledInspectionProgramWOs.length ||
            self.completedInspectionProgramWOs.length ||
            self.canceledInspectionProgramWOs.length
        );
        $scope.$emit(EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD, {
          isLoading: false,
        });
        self.isLoading = false;
      });
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function onInputClose() {
      self.selectInputOpen = !self.selectInputOpen;
    }

    function onInputOpen() {
      self.selectInputOpen = true;
    }

    function fetchOrgBuildings(org) {
      BuildingService.getAllByOrganization(org)
        .then(function (buildings) {
          self.buildings = buildings;
        })
        .catch(ToastService.showError);
    }

    function handleInspectionProgramChange($event, data) {
      if (data) {
        $state.reload();
      }
    }

    /**
     * so that the filter component can notify when it picks new filters
     */
    function filtersUpdated(filter, filters) {
      self.selectedFilters[filter] = filters;
      var params = {
        iterations: self.selectedFilters.iterations.map(function (iteration) {
          return iteration._id;
        }),
        buildings: self.selectedFilters.buildings.map(function (building) {
          return building._id;
        }),
      };
      self.filters = encodeURIComponent(JSON.stringify(params));
      $location.search("filters", self.filters);
    }

    function fetchAllInspectionProgramWOs() {
      return $q.all([
        self.fetchInspectionProgramWOs(self.statusOpen),
        self.fetchInspectionProgramWOs(self.statusScheduled),
        self.fetchInspectionProgramWOs(self.statusCompleted),
        self.fetchInspectionProgramWOs(self.statusCanceled),
      ]);
    }

    function fetchInspectionProgramWOs(status) {
      var params = {
        status: status,
        inspection_program: inspectionProgram._id,
        limit: 10,
      };

      return WorkOrderService.getBFFIPByOrganization(
        self.organization._id,
        params
      )
        .then(function (workOrders) {
          switch (status) {
            case self.statusOpen:
              self.openInspectionProgramWOs = workOrders;
              break;
            case self.statusScheduled:
              self.scheduledInspectionProgramWOs = workOrders;
              break;
            case self.statusCompleted:
              self.completedInspectionProgramWOs = workOrders;
              break;
            case self.statusCanceled:
              self.canceledInspectionProgramWOs = workOrders;
              break;
          }
          return workOrders;
        })
        .catch(function (err) {
          switch (status) {
            case self.statusOpen:
              self.openInspectionProgramWOs = [];
              break;
            case self.statusScheduled:
              self.scheduledInspectionProgramWOs = [];
              break;
            case self.statusCompleted:
              self.completedInspectionProgramWOs = [];
              break;
            case self.statusCanceled:
              self.canceledInspectionProgramWOs = [];
              break;
          }
          return ToastService.showError(err);
        });
    }

    function getWorkOrderBuilding(workOrder) {
      var buildingObject = self.buildings.find(function (building) {
        return building._id === (workOrder.building._id || workOrder.building);
      });

      return buildingObject ? buildingObject.name : "";
    }

    function getWorkOrderSubtext(workOrder, status) {
      switch (status) {
        case self.statusOpen:
          return (
            "Due " +
            moment(workOrder.due_date)
              .utcOffset(self.utcOffset)
              .format(DATE_FORMAT)
          );
        case self.statusOverdue:
          return (
            "Was due " +
            moment(workOrder.due_date)
              .utcOffset(self.utcOffset)
              .format(DATE_FORMAT)
          );
        case self.statusScheduled:
          return (
            "Scheduled to start " +
            moment(workOrder.start_date)
              .utcOffset(self.utcOffset)
              .format(DATE_FORMAT) +
            " - " +
            "Due " +
            moment(workOrder.due_date)
              .utcOffset(self.utcOffset)
              .format(DATE_FORMAT)
          );
        case self.statusCompleted:
          if (new Date() > new Date(workOrder.due_date)) {
            return (
              "Was due " +
              moment(workOrder.due_date)
                .utcOffset(self.utcOffset)
                .format(DATE_FORMAT) +
              " - Completed " +
              moment(workOrder.completed_date)
                .utcOffset(self.utcOffset)
                .format(DATE_FORMAT) +
              " by " +
              workOrder.completed_by.display_name
            );
          } else {
            return (
              "Due " +
              moment(workOrder.due_date)
                .utcOffset(self.utcOffset)
                .format(DATE_FORMAT) +
              " - Completed " +
              moment(workOrder.completed_date)
                .utcOffset(self.utcOffset)
                .format(DATE_FORMAT) +
              " by " +
              workOrder.completed_by.display_name
            );
          }
        case self.statusCanceled:
          return (
            "Canceled on " +
            moment(workOrder.canceled_date)
              .utcOffset(self.utcOffset)
              .format(DATE_FORMAT) +
            " by " +
            workOrder.canceled_by.display_name
          );
        default:
          return "";
      }
    }
    /**
     * Can be used for actions on toggle for the various cards
     *
     * @param {String} cardName
     */
    function onCardToggled(cardName) {
      // prevent card toggle for summary card when selecting bldg or iteration
      if (
        cardName === INSPECTION_PROGRAM_CARDS.SUMMARY &&
        self.selectInputOpen
      ) {
        return true;
      }

      self.open[cardName] = !self.open[cardName];
    }

    function viewAllWorkOrders(workOrderStatus) {
      Router.go("app.workOrders", {
        status: workOrderStatus,
        ip_number: inspectionProgram.number,
      });
    }
  }
})();
